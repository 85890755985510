import { create, StoreApi } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { CartDataActions, CartDataProduct, CartDataProductsItem, CartDataState } from ".";
import { CouponDiscountType, Product, ProductType } from "../../data/types/entities";
import { toastController } from "@ionic/core/components";
import { getCurrentLanguage } from "../Redux/Reducers/state";
import { Coupon } from "../coupons";
import { AppMetricaProvider } from "../AppMetrica/AppMetrica";

const initialState: CartDataState = {
  cartSubtotalValue: 0,
  cartProducts: [],
  cartProductsMap: {},
  coupons: [],
  rewardPoints: 0,
};

const addToCart =
  (set: StoreApi<CartDataState & CartDataActions>["setState"]): CartDataActions["addToCart"] =>
  (item) => {
    if (!item.purchasable) {
      return;
    }

    set((state): CartDataState => {
      const newProductsMap: CartDataState["cartProductsMap"] = {
        ...state.cartProductsMap,
      };

      if (item.stockQuantity < 1) {
        toastController
          .create({
            message: getCurrentLanguage()?.["The product is out of stock"],
            duration: 800,
          })
          .then((toast) => toast.present());
        return state;
      }

      if (item.stockQuantity <= newProductsMap[item.id]?.quantity) {
        toastController
          .create({
            message: getCurrentLanguage()?.["Product Quantity is Limited!"],
            duration: 800,
          })
          .then((toast) => toast.present());
        return state;
      }

      let roundedSubTotalValue = state.cartSubtotalValue;

      if (newProductsMap[item.id]) {
        if (newProductsMap[item.id].quantity + 1 <= newProductsMap[item.id].stockQuantity) {
          newProductsMap[item.id].quantity++;
          roundedSubTotalValue = Math.round((state.cartSubtotalValue + parseFloat(item.price)) * 100) / 100;
        }
      } else {
        newProductsMap[item.id] = {
          quantity: 1,
          id: item.id,
          type: item.type,
          name: item.name,
          parentId: item.type === ProductType.VARIATION ? item.parentId! : item.id,
          price: item.price,
          purchasable: item.purchasable,
          stockQuantity: item.stockQuantity,
          categories: item.categories,
          onSale: item.onSale,
          weight: item.weight ?? "0",
          attributes: item.attributes ?? null,
        };
        roundedSubTotalValue = Math.round((state.cartSubtotalValue + parseFloat(item.price)) * 100) / 100;
      }

      AppMetricaProvider.reportEvent("addProductToCart", newProductsMap[item.id]);

      return {
        ...state,
        cartSubtotalValue: roundedSubTotalValue,
        cartProducts: Object.values(newProductsMap),
        cartProductsMap: newProductsMap,
      };
    });
  };

const repeatOrder =
  (set: StoreApi<CartDataState & CartDataActions>["setState"]): CartDataActions["repeatOrder"] =>
  (products, orderLineItems) => {
    set((state): CartDataState => {
      let cartSubtotalValue = state.cartSubtotalValue;
      const cartProductsMap: CartDataState["cartProductsMap"] = { ...state.cartProductsMap };

      const updateCartProduct = (product: CartDataProductsItem, quantity: number) => {
        const newQuantity = (cartProductsMap[product.id]?.quantity || 0) + quantity;

        if (newQuantity <= product.stockQuantity) {
          cartProductsMap[product.id] = {
            ...product,
            quantity: newQuantity,
          };
          cartSubtotalValue += parseFloat(product.price) * newQuantity;
        } else {
          cartProductsMap[product.id] = {
            ...product,
            quantity: product.stockQuantity,
          };
          cartSubtotalValue += parseFloat(product.price) * newQuantity;
        }
      };

      const handleProduct = (product: Product) => {
        const matchingLineItem = orderLineItems?.find((lineItem) => lineItem.productId === product.id);

        const productItem = {
          id: product.id,
          name: product.name,
          type: product.type,
          price: product.price,
          onSale: product.onSale,
          weight: product.weight ?? "0",
          categories: product.categories,
          purchasable: product.purchasable,
          stockQuantity: product.stockQuantity,
          attributes: product.attributes ?? null,
          quantity: matchingLineItem?.quantity || 1,
          parentId: product.type === ProductType.VARIATION ? product.parentId! : product.id,
        };

        updateCartProduct(productItem, productItem.quantity);
      };

      products.forEach(handleProduct);

      return {
        ...state,
        cartSubtotalValue,
        cartProducts: Object.values(cartProductsMap),
        cartProductsMap,
      };
    });
  };

const useCartDataState = create<CartDataState & CartDataActions>()(
  persist<CartDataState & CartDataActions>(
    (set, get) => ({
      ...initialState,

      addToCart: addToCart(set),

      repeatOrder: repeatOrder(set),

      removeFromCart: (productId) => {
        AppMetricaProvider.reportEvent("removeProductFromCart", { id: productId });

        set((state): CartDataState => {
          const newProductsMap: CartDataState["cartProductsMap"] = {
            ...state.cartProductsMap,
          };
          if (newProductsMap[productId] && newProductsMap[productId].quantity > 0) {
            newProductsMap[productId].quantity--;
          } else {
            return state;
          }

          const roundedTotalValue =
            Math.round((state.cartSubtotalValue - parseFloat(newProductsMap[productId].price)) * 100) / 100;

          if (newProductsMap[productId].quantity <= 0) {
            delete newProductsMap[productId];
          }

          return {
            ...state,
            cartSubtotalValue: roundedTotalValue,
            cartProducts: Object.values(newProductsMap),
            cartProductsMap: newProductsMap,
            coupons: [],
            rewardPoints: 0,
          };
        });
      },

      resetCart: () => {
        AppMetricaProvider.reportEvent("resetCart");

        set(initialState);
      },

      replaceCartProducts: (products) => {
        AppMetricaProvider.reportEvent("replaceCart", {
          products: products.map(({ id, price, name, parentId, stockQuantity, weight }) => ({
            id,
            price,
            name,
            parentId,
            stockQuantity,
            weight,
          })),
        });

        set((state) => {
          if (!products.length) {
            return {
              ...state,
              cartSubtotalValue: 0,
              cartProducts: [],
              cartProductsMap: {},
            };
          }

          const newProductsMap: CartDataState["cartProductsMap"] = products.reduce(
            (mapObj, product) => {
              if (mapObj[product.id]) {
                if (product.stockQuantity < 1) {
                  delete mapObj[product.id];
                } else {
                  mapObj[product.id] = {
                    id: product.id,
                    price: product.price,
                    parentId: product.type === ProductType.VARIATION ? product.parentId! : product.id,
                    name: product.name,
                    purchasable: product.purchasable,
                    type: product.type,
                    stockQuantity: product.stockQuantity,
                    quantity:
                      product.stockQuantity >= state.cartProductsMap[product.id]?.quantity
                        ? state.cartProductsMap[product.id].quantity
                        : Math.trunc(product.stockQuantity),
                    categories: product.categories,
                    onSale: product.onSale,
                    weight: product.weight ?? "0",
                    attributes: product.attributes ?? null,
                  };
                }
              }

              return mapObj;
            },
            { ...state.cartProductsMap }
          );

          const newCartProducts = Object.values(newProductsMap);
          const newCartTotalValue = newCartProducts.reduce(
            (sum, item) => sum + (item.quantity * (parseFloat(item.price) * 100)) / 100,
            0
          );

          return {
            ...state,
            cartSubtotalValue: newCartTotalValue,
            cartProducts: newCartProducts,
            cartProductsMap: newProductsMap,
          };
        });
      },

      applyCoupon: async (coupon) => {
        const { coupons, cartProducts, cartSubtotalValue } = get();
        if (!(await Coupon.validateCouponService(coupon, cartProducts, coupons, cartSubtotalValue))) {
          return;
        }

        set((state) => {
          const newCoupons = [...state.coupons];

          newCoupons.push(coupon);
          return {
            ...state,
            coupons: newCoupons,
          };
        });
      },

      removeCoupon: (code) => {
        set((state) => {
          const newCoupons = state.coupons.filter((couponItem) => couponItem.code !== code);
          return {
            ...state,
            coupons: newCoupons,
          };
        });
      },

      addRewardPoints: (points) => {
        set((state) => {
          const rewardPoints = state.rewardPoints + points;
          return {
            ...state,
            rewardPoints,
          };
        });
      },

      resetRewardPoints: () => {
        set((state) => ({ ...state, rewardPoints: 0 }));
      },

      getCartTotal: () => {
        const { cartSubtotalValue, coupons, rewardPoints, cartProducts } = get();
        if (!coupons.length) {
          return cartSubtotalValue - rewardPoints;
        }

        const discount = coupons.reduce((_discount, coupon) => {
          const discountAmount = coupon.discountAmount;
          switch (coupon.discountType) {
            case CouponDiscountType.PERCENT:
              return (_discount += (cartSubtotalValue / 100) * discountAmount);
            case CouponDiscountType.FIXED_CART:
              return (_discount += discountAmount);
            case CouponDiscountType.FIXED_PRODUCT:
              return (_discount += cartProducts.length * discountAmount);
            default:
              return _discount;
          }
        }, 0);

        return cartSubtotalValue - discount - rewardPoints;
      },

      getProductsTotalWeight: () => {
        const { cartProducts } = get();
        const totalWeight = cartProducts.reduce(
          (total, product) => (total += parseFloat(product.weight) * product.quantity),
          0
        );
        return totalWeight;
      },
    }),
    {
      name: "cart-data",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const useCartData = () => {
  return useCartDataState((state) => ({
    ...state,
    cartTotalValue: state.getCartTotal(),
  }));
};

export { useCartDataState, useCartData };
