import React from 'react';

// Import Styles
import classNames from "classnames/bind";
import styles from "./PageTitle.module.scss";

interface IPageTitle {
    title: string,
    hideTitleBreakpoint?: string,
}

const cx = classNames.bind(styles);

const PageTitle:React.FC<IPageTitle> = ({title, hideTitleBreakpoint}) => {
    return (
        <h1 className={cx('title', hideTitleBreakpoint)}>
            {title}
        </h1>
    );
};

export default PageTitle;