import BaseClient from "../base-client";
import { ICategoriesClient } from "../../types/clients";
import { BaseClientResponse } from "../base-client";
import { convertParhatoCategory } from "./utils";
import { ParhatoCategory } from "./types";

const CATEGORIES_PER_PAGE = 100;

class CategoriesClient extends BaseClient implements ICategoriesClient {
  async getAllCategories() {
    let page = 1;
    let response: BaseClientResponse<ParhatoCategory[]>;
    let allCategories: ParhatoCategory[] = [];
    do {
      response = await this.instance.get<ParhatoCategory[]>("/wp-json/wc/v3/products/categories", {
        params: {
          per_page: CATEGORIES_PER_PAGE,
          page: page,
        },
      });
      page++;
      allCategories = [...allCategories, ...response.data];
    } while (response.data.length >= 100);

    const allCat = allCategories.map(convertParhatoCategory);
    const subCategories = allCat.filter((x) => x.parent !== "0");
    const mainCategories = allCat.filter((x) => x.parent === "0");

    return { allCategories: allCat, subCategories, mainCategories };
  }

  async getMainCategories() {
    const response = await this.instance.get<ParhatoCategory[]>("/wp-json/wc/v3/products/categories", {
      params: {
        per_page: CATEGORIES_PER_PAGE,
        parent: 0,
      },
    });
    return response.data.map(convertParhatoCategory);
  }

  async getSubCategories(id: string) {
    const response = await this.instance.get<ParhatoCategory[]>("/wp-json/wc/v3/products/categories", {
      params: {
        per_page: CATEGORIES_PER_PAGE,
        parent: id,
      },
    });
    return response.data.map(convertParhatoCategory);
  }

  async getCategoryById(id: string) {
    const response = await this.instance.get<ParhatoCategory[]>(`/wp-json/wc/v3/products/categories?include=${id}`);
    return convertParhatoCategory(response.data[0]);
  }
}

export default CategoriesClient;
