import React from "react";
// Import Ionic
import { IonButton, IonIcon } from "@ionic/react";

// Import Icons
import { basketSvg } from "../../assets/icons/icons";
import { add, remove } from "ionicons/icons";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ProductDetailCounter.module.scss";
import { Product as TProduct } from "../../../data/types/entities";
import { RemoveFromCartHandler } from "../../../providers/cartDataStore";

interface IProductDetailCounter {
  isEmpty: boolean;
  isInStock: boolean;
  product: TProduct;
  selectedVariation: TProduct | null;
  cartQuantity: string | number;
  onAddToCart: (product: TProduct) => void;
  onRemoveFromCart: RemoveFromCartHandler;
}

const cx = classNames.bind(styles);

// For InnenrHtml Markup
const createMarkup = (product: any, selectedVariation: any) => {
  const _prod = product.type !== "variable" ? product : selectedVariation;
  if (_prod.price_html) {
    return { __html: _prod.price_html };
  }

  return { __html: `<span><span>₽${_prod.price}</span></span>` };
};

const ProductDetailCounter: React.FC<IProductDetailCounter> = ({
  isEmpty = false,
  isInStock = false,
  product,
  selectedVariation,
  cartQuantity,
  onAddToCart,
  onRemoveFromCart,
}) => {
  return (
    <div className={cx("counterBackdrop")}>
      <div className={cx("counter")}>
        {isEmpty ? (
          <div className={cx("counterWrapper", { disabled: !product?.purchasable })}>
            <div className={cx("counterPrice")}>
              <span
                className={cx("counterPriceSpan")}
                dangerouslySetInnerHTML={createMarkup(product, selectedVariation)}
              ></span>
            </div>
            {isInStock && (
              <div className={cx("counterEmpty")} onClick={() => onAddToCart(product)}>
                <IonIcon src={basketSvg} className={cx("counterEmptyIcon")} />
              </div>
            )}
          </div>
        ) : (
          <div className={cx("counterWrapper")}>
            <div className={cx("counterPrice")}>
              <span
                className={cx("counterPriceSpan")}
                dangerouslySetInnerHTML={createMarkup(product, selectedVariation)}
              ></span>
            </div>

            {product.stockStatus === "instock" && (
              <div className={cx("counterQuantity")}>
                <IonButton
                  fill={"clear"}
                  onClick={() => onRemoveFromCart(selectedVariation?.id ?? product.id)}
                  className={cx("ion-no-padding ion-no-margin", "counterQuantityButton")}
                >
                  <IonIcon src={remove} />
                </IonButton>
                <p>{cartQuantity}</p>
                <IonButton
                  disabled={
                    !(
                      (product.type !== "variable" && product.stockQuantity >= Number(cartQuantity) + 1) ||
                      (product.type === "variable" &&
                        selectedVariation &&
                        selectedVariation.stockQuantity >= Number(cartQuantity) + 1)
                    )
                  }
                  fill={"clear"}
                  onClick={() => onAddToCart(product)}
                  className={cx("ion-no-padding  ion-no-margin", "counterQuantityButton")}
                >
                  <IonIcon src={add} />
                </IonButton>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailCounter;
