import BaseClient from "../base-client";
import { IOrdersClient } from "../../types/clients";
import { CreateOrderData, CreatePaymentPayload, PaymentFormData, ReviewSet, TOrder } from "../../types/entities";
import { IrsKomOrderHistory, IrsKomOrderHistoryItem, IrsKomOrderSubmitResponseData } from "./types";
import { convertOrderHistory, convertOrderHistoryDetails, convertTORderToIrskomReqBody } from "./utils";
import { AddReviewOrderMutationHookResult, AddReviewOrderMutationVariables } from "../../../generated/graphql";

class OrdersClient extends BaseClient implements IOrdersClient {
  async getOrders(): Promise<TOrder[]> {
    const response = await this.instance.get<IrsKomOrderHistory[]>("/order/history");

    return response.data.map(convertOrderHistory);
  }

  async getOrderById(id: string | number): Promise<TOrder> {
    const [itemRes, orderRes] = await Promise.all([
      this.instance.get<IrsKomOrderHistoryItem[]>("/order/history/items", {
        params: {
          orderId: id,
        },
      }),
      this.instance.get<IrsKomOrderHistory>("/order/history/order", {
        params: {
          orderId: id,
        },
      }),
    ]);

    return convertOrderHistoryDetails(itemRes.data, orderRes.data);
  }

  async createOrder(order: CreateOrderData): Promise<TOrder> {
    const irsKomOrderReqBody = convertTORderToIrskomReqBody(order);

    const response = await this.instance.post<IrsKomOrderSubmitResponseData>("/order/submit", irsKomOrderReqBody);

    return {
      ...order,
      id: response.data.id,
      number: response.data.number,
    };
  }

  async createPayment(payload: CreatePaymentPayload): Promise<PaymentFormData> {
    const response = await this.instance.get<PaymentFormData>("order/sber/payment", {
      params: payload,
    });

    return response.data;
  }

  async getReviewSetOrderById(orderId: string): Promise<ReviewSet> {
    throw new Error("getReviewSetOrderById is not implemented");

  }

  async addReviewOrder(orderReqData: AddReviewOrderMutationVariables):Promise<AddReviewOrderMutationHookResult>{
    throw new Error("getReviewSetOrderById is not implemented");
  }
}

export default OrdersClient;
