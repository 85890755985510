import React from 'react';

// Import Ionic
import {IonActionSheet} from "@ionic/react";

// Import Icons
import {arrowForwardOutline} from "ionicons/icons";

interface  ICouponActionSheet {
    showActionSheet: any,
    setShowActionSheet: any,
    setCouponValue: any,
    couponValue: any,

}
// Панель которая появляется если ставить !showActionSheet
// Временно не отображается
const CouponActionSheet:React.FC<ICouponActionSheet> = (
    {
        showActionSheet,
        setShowActionSheet,
        setCouponValue,
        couponValue,
    }
    ) => {
    return (
        <IonActionSheet
            isOpen={showActionSheet}
            onDidDismiss={() => setShowActionSheet(false)}
            header='Demo Coupons'
            buttons={[
                {
                    icon: `${arrowForwardOutline}`,
                    text: 'Product Fixed (fp). A fixed total discount for selected products only',
                    handler: () => {
                        couponValue = 'fp';
                        setCouponValue('fp');
                    }
                },
                {
                    icon: `${arrowForwardOutline}`,
                    text: 'Cart Fixed (fc). A fixed total discount for the entire cart',
                    handler: () => {
                        couponValue = 'fc';
                        setCouponValue('fc');
                    }
                },
                {
                    icon: `${arrowForwardOutline}`,
                    text: 'Product Percentage (percentage). A percentage discount for selected products only',
                    handler: () => {
                        couponValue = 'percentage';
                        setCouponValue('percentage');
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {

                    }
                }
            ]}
        />
    );
};

export default CouponActionSheet;