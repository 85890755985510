import React from "react";

// Import Ionic
import { IonAvatar, IonItem, IonLabel, IonList } from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ReviewComments.module.scss";
import { ProductReview as TProductReview } from "../../../data/types/entities";

interface IReviewComments {
  reviews: TProductReview[];
}

const createMarkup = (rev: TProductReview["review"]) => {
  return { __html: rev };
};

const getFormatDate = (stringDateTime: string) => {
  const dateTime = new Date(stringDateTime);
  const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
  const minutes = dateTime.getMinutes().toString().padStart(2, "0");
  return `${dateTime.getDate()}.${month}.${dateTime.getFullYear()} ${dateTime.getHours()}:${minutes}`;
};

const cx = classNames.bind(styles);

const ReviewComments: React.FC<IReviewComments> = ({ reviews }) => {
  return (
    <IonList className={`${cx("ratingUsers")} ion-no-padding`}>
      {reviews.map((r) => (
        <IonItem key={r.id}>
          <IonAvatar slot="start">
            <img alt={""} src="assets/avatar.png" />
          </IonAvatar>
          <IonLabel>
            <h2>{r.fullName}</h2>
            <ul className={`${cx("ratingProduct")} product-rating`}>
              <li>
                <div className={cx("starsOuter")}>
                  <div className={cx("starsInner")} style={{ width: r.rating * 20 + "%" }}></div>
                </div>
              </li>
            </ul>
            <h6>{getFormatDate(r.dateCreated)}</h6>
            <p dangerouslySetInnerHTML={createMarkup(r.review)}></p>
          </IonLabel>
        </IonItem>
      ))}
    </IonList>
  );
};

export default ReviewComments;
