import React from "react";
import { IonIcon, IonInput } from "@ionic/react";
import { search } from "ionicons/icons";
import { searchIcon } from "../assets/icons/icons";
import classNames from "classnames/bind";
import styles from "./HomePageSearchBox.module.scss";
import { useHistory } from "react-router";

const cx = classNames.bind(styles);

const HomePageSearchBox = ({ placeholder }: { placeholder: string }) => {
  let history = useHistory();
  return (
    <div
      className={cx("searchBox")}
      onClick={() => history.push("/search-main-categories", { isSearchInputFocused: true })}
    >
      <div className={cx("searchBox__wrapper")}>
        <IonInput
          readonly
          type="search"
          placeholder={placeholder}
          className={cx("searchBox__input", "ion-no-padding")}
        />
        <div>
          <div className={cx("searchIcon", "searchIcon--sm", "ion-hide-md-up")}>
            <IonIcon className={cx("searchIcon__icon", "searchIcon__icon--sm")} icon={searchIcon} />
          </div>
          <div className={cx("searchIcon", "searchIcon--md", "ion-hide-md-down")}>
            <IonIcon className={cx("searchIcon__icon", "searchIcon__icon--md")} icon={search} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageSearchBox;
