import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { authClient } from "../../data/clients";
import { VerificationCodeResponse } from "../../data/types/entities";

const useSendVerificationCode = (options?: UseMutationOptions<VerificationCodeResponse, any, string>) =>
  useMutation(async (phone) => {
    const data = await authClient.sendVerificationCode(phone);

    return data;
  }, options);

export default useSendVerificationCode;
