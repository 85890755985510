import { SocialSharing } from "@ionic-native/social-sharing";
import {
  IonButton,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  isPlatform,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import { cartOutline, copyOutline, logOutOutline, shareSocialOutline } from "ionicons/icons";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";
import { ParhatoRESTApi } from "../../providers/ParhatoRESTApi/ParhatoRESTApi";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import WooComAPI from "../../providers/WooComAPI/woo-com-api";
import { isEmailValid, isPhoneNumberValid } from "../../utils/utils";
import { CustomPhoneInput } from "../CustomPhoneInput/CustomPhoneInput";
import commonFormStyles from "../../components/UI/CommonFormElements/CommonFormElements.module.scss";
import styles from "./MyAccountComponent.module.scss";
import classNames from "classnames/bind";
import Footer from "../Footer/Footer";
import { PasswordInput } from "../PasswordInput/PasswordInput";
import ToolbarDesktop from "../Toolbar/ToolbarDesktop/ToolbarDesktop";
import CommonFormElement from "../UI/CommonFormElements/CommonFormElement";
import MaxWidthContainer from "../UI/MaxWidthContainer/MaxWidthContainer";
import { CustomerAddresses } from "../CustomerAddresses/CustomerAddresses";
import { CustomerAddressType } from "../../generated/graphql";
import { useCustomerAddresses } from "../../providers/CustomerAddresses/CustomerAddressesDataContext";
import { AddressesOnPresentModalOverloads } from "../../types";
import { CustomerAddressesSkeleton } from "./CustomerAddressesSkeleton";
import { useCustomerAddressesStateData } from "../../providers/CustomerAddresses/CustomerAddressesStateContext";
import { ValidatePasswordInput } from "../ValidatePasswordInput/ValidatePasswordInput";
import { ShippingAddressManually } from "../ShippingAddressManually/ShippingAddressManually";
import { useAuth } from "../../contexts/auth-context/AuthContextProvider";
import { User, UserWithPassword } from "../../data/types/entities";
import { useGetProfileQuery, useUpdateProfileMutation } from "../../providers/profile";
import { useGetSettingsQuery } from "../../providers/settings";
import Barcode from "react-barcode";

const cx = classNames.bind(styles);
const commonForm = classNames.bind(commonFormStyles);

type userDataType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  city?: string;
  street?: string;
  houseNumber?: string;
};

const comparePassword = (password: string | undefined | null, confirmPassword: string | undefined | null) => {
  return password && confirmPassword && confirmPassword !== password;
};

const MyAccountComponent = () => {
  const { data: appSettings } = useGetSettingsQuery();
  const { user, login, logout } = useAuth();

  const { data: profileData } = useGetProfileQuery({
    userId: user?.id,
    options: {
      enabled: appSettings?.hasCardLoyalty && !!user?.id,
      cacheTime: 0,
    },
  });

  const { mutate: updateProfile } = useUpdateProfileMutation();
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();
  const [presentToast] = useIonToast();
  const { loading: addressesLoading, customerAddresses, deleteCustomerAddress } = useCustomerAddresses();

  const { setCustomerAddress, setIsAddressModalOpen, isAddressModalOpen } = useCustomerAddressesStateData();

  const [showLoading, setShowLoading] = useState(false);
  const [firstName, setFirstName] = useState<string>(user?.firstName || "");
  const [lastName, setLastName] = useState<string>(user?.lastName || "");
  const [email, setEmail] = useState<string>(user?.email || "");
  const [phone, setPhone] = useState<string>(user?.phone || "");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [earnedPoints, setEarnedPoints] = useState(0);
  const userDataRef = useRef<userDataType>({});
  const referralLinkRef = useRef(null as any);

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
  const [passwordError, setPasswordError] = useState("");

  const ts = useMemo(() => state.config.languageJson, []);

  const isFieldsChanged = (): boolean => {
    return (
      firstName !== userDataRef.current.firstName ||
      lastName !== userDataRef.current.lastName ||
      email !== userDataRef?.current?.email ||
      phone !== userDataRef.current.phone ||
      !!password
    );
  };

  const isFormValid = (): boolean => {
    return (
      (((email ? isEmailValid(email) : true) || email === userDataRef?.current?.email) &&
        (!phone || isPhoneNumberValid(phone)) &&
        !password) ||
      (isPasswordValid && password === confirmPassword)
    );
  };

  useIonViewWillEnter(() => {
    resetComponentState(user);
    appSettings?.isBonusPointsEnabled && getBonusPointsAmount();
  }, [user, appSettings]);

  useEffect(() => {
    setIsSubmitButtonDisabled(!isFieldsChanged() || !isFormValid());
  }, [firstName, lastName, email, phone, password, confirmPassword]);

  const getBonusPointsAmount = () => {
    setShowLoading(true);
    ParhatoRESTApi.getBonusPointsAmount()
      .then((res) => {
        setEarnedPoints(res.points);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const resetComponentState = (user: User | undefined) => {
    userDataRef.current = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
    };

    setFirstName(userDataRef.current.firstName || "");
    setLastName(userDataRef.current.lastName || "");
    setEmail(userDataRef?.current?.email || "");
    setPhone(userDataRef.current.phone || "");
    setPassword("");
    setConfirmPassword("");
    setIsPasswordValid(false);
    setIsSubmitButtonDisabled(true);
  };

  const updateInfo = () => {
    setShowLoading(true);

    const userPayload: UserWithPassword = {
      id: user?.id!,
      firstName: firstName!,
      lastName: lastName!,
      token: user?.token!,
      email: email,
      phone: phone,
      userName: user?.userName,
      password: password ?? undefined,
    };

    if (password && isFormValid()) {
      userPayload.password = password;
    }

    updateProfile(
      { userId: user?.id!, userPayload },
      {
        onError: (e) => {
          setShowLoading(false);
          if (e.error && e.error.code === "woocommerce_rest_customer_invalid_email") {
            presentToast({
              message: ts["Invalid email, or such email is already in use"],
              color: "danger",
              duration: 2000,
            });
          } else {
            presentToast({
              message: ts["Error Updating Data!"],
              color: "danger",
              duration: 2000,
            });
          }
        },
        onSuccess: () => {
          login(userPayload);

          setShowLoading(false);

          resetComponentState(userPayload);

          presentToast({
            message: ts["Data successfully updated"],
            color: "success",
            duration: 2000,
          });

          setShowLoading(false);
        },
      }
    );
  };

  const goToMyOrders = () => {
    history.push("/my-orders");
  };

  const logoutHandler = () => {
    dispatch({
      type: "REMOVE_CUSTOMER_DATA",
    });
    logout();
  };

  const copyReferralLink = () => {
    if (isPlatform("hybrid")) {
      SocialSharing.share(`${referralLinkRef.current.value}`);
    } else {
      copyReferralLinkDesktop();
    }
  };

  const copyReferralLinkDesktop = () => {
    document.execCommand("copy");

    presentToast({
      position: "bottom",
      message: ts["Copied to clipboard!"],
      color: "success",
      duration: 2000,
    });
  };

  const redirectToDeleteAccount = () => {
    window.open(appSettings?.deleteAccountLink);
  };

  return (
    <div className={cx("wrapper")}>
      <IonLoading
        isOpen={showLoading || addressesLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={ts["Please wait..."]}
        duration={20000}
      />
      <ToolbarDesktop showShippingZone />
      <MaxWidthContainer smContainer={true} classList={cx("myAccountContainer")}>
        <IonRow className={cx("myAccountButtons")}>
          <IonCol size="12" sizeMd="6">
            <IonButton
              onClick={goToMyOrders}
              color={"primary"}
              className={cx("button", "button--primary")}
              expand="block"
              type="button"
            >
              {ts["Go to My orders"]}
              <IonIcon icon={cartOutline} />
            </IonButton>
          </IonCol>
          <IonCol size="12" sizeMd="6">
            <IonButton
              onClick={logoutHandler}
              className={cx("button", "button--dark", "button--last")}
              expand="block"
              type="button"
            >
              {ts["Log Out"]}
              <IonIcon icon={logOutOutline} />
            </IonButton>
          </IonCol>
        </IonRow>
        {appSettings?.isReferralEnabled && (
          <IonRow className={cx("referral")}>
            <IonCol size={"12"} className={cx("referral__title", "ion-no-padding")}>
              <h2 className={cx("myAccountFormTitle")}>{ts["Referral system"]}</h2>
            </IonCol>
            <IonCol size={"12"} className={cx("ion-no-padding")}>
              <IonRow className={cx("referral__linkWrapper")}>
                <IonCol size={"12"} sizeSm={"6"} className={cx("ion-no-padding", "earnedPoints")}>
                  <span className={cx("earnedPoints__title")}>{ts["Earned points"]}</span>: {earnedPoints}
                </IonCol>
                <IonCol size={"12"} className={cx("ion-no-padding")}>
                  <IonLabel className={cx("myAccountForm__label")}>{ts["Your referral link"]}</IonLabel>
                  <IonItem
                    lines="none"
                    className={cx(
                      "myAccountForm__item",
                      "referral__item",
                      "row-no-padding ion-no-padding",
                      "ion-no-margin"
                    )}
                  >
                    <IonInput
                      ref={referralLinkRef}
                      className={`${commonForm("commonElement")} ${cx("myAccountForm__input", "referral__input")}`}
                      type="text"
                      readonly={true}
                      value={WooComAPI.url + "/my-account/?ref=" + user?.userName}
                    />
                    {isPlatform("hybrid") ? (
                      <span onClick={copyReferralLink} className={cx("referral__iconWrapper")}>
                        <IonIcon icon={shareSocialOutline} />
                      </span>
                    ) : (
                      <span onClick={copyReferralLink} className={cx("referral__iconWrapper")}>
                        <IonIcon icon={copyOutline} />
                      </span>
                    )}
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        )}
        {appSettings?.isCustomerAdressesEnabled && (
          <IonRow className="ion-no-margin ion-no-padding">
            <IonCol size={"12"} className={cx("referral__title", "ion-no-padding")}>
              <h2 className={cx("myAccountFormTitle")}>{ts["Addresses"]}</h2>
            </IonCol>
            <IonCol className="ion-no-margin ion-no-padding" size={"12"}>
              {customerAddresses?.length || !addressesLoading ? (
                <CustomerAddresses
                  addresses={customerAddresses}
                  onPresentModal={
                    ((shippingAddressData: CustomerAddressType) => {
                      setCustomerAddress(shippingAddressData);
                      setIsAddressModalOpen(true);
                    }) as AddressesOnPresentModalOverloads
                  }
                  deleteAddress={deleteCustomerAddress}
                />
              ) : (
                <CustomerAddressesSkeleton />
              )}
            </IonCol>
          </IonRow>
        )}

        <form
          className={cx("myAccountForm")}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <h2 className={cx("myAccountFormTitle")}>{ts["Change account details"]}</h2>

          <CommonFormElement label={ts["First Name"]}>
            <IonInput
              value={firstName}
              className={commonForm("commonElement")}
              type="text"
              name="firstName"
              required
              onIonChange={(e) => setFirstName(e.detail.value ?? "")}
            />
          </CommonFormElement>

          <CommonFormElement label={ts["Last Name"]}>
            <IonInput
              value={lastName}
              type="text"
              className={commonForm("commonElement")}
              name="lastName"
              required
              onIonChange={(e) => setLastName(e.detail.value ?? "")}
            />
          </CommonFormElement>

          {appSettings?.updateUserForm.showPhoneNumberInput && (
            <CommonFormElement
              label={ts["Contact number"]}
              isValid={!phone || isPhoneNumberValid(phone)}
              errorMessage={ts["Please enter a valid phone number"]}
            >
              <CustomPhoneInput
                className={commonForm("commonElement", "commonElementMaskedInput", {
                  commonInvalid: phone && !isPhoneNumberValid(phone),
                })}
                value={phone}
                onCustomChange={(val, isValid) => {
                  setPhone(val);
                }}
              />
            </CommonFormElement>
          )}

          {appSettings?.updateUserForm.isUpdatePasswordEnabled && (
            <>
              <h2 className={cx("myAccountFormTitle")}>{ts["Change Password"]}</h2>

              <CommonFormElement
                label={`${ts["New Password"]} ${ts["dont fill to save current password"]}`}
                isValid={!(password && !isPasswordValid)}
                errorMessage={ts[passwordError]}
                classListItem={cx("myAccountFormItem")}
                classListError={cx("myAccountFormAlert")}
              >
                <ValidatePasswordInput
                  required={true}
                  value={password!}
                  setPassword={(value) => setPassword(value)}
                  setPasswordError={(error) => setPasswordError(error)}
                  setIsPasswordValid={(isValid) => setIsPasswordValid(isValid)}
                  className={commonForm("commonElement", { commonInvalid: password && !isPasswordValid })}
                />
              </CommonFormElement>
              <CommonFormElement
                label={ts["Confirm New Password"]}
                isValid={confirmPassword === password}
                errorMessage={!confirmPassword ? `${ts["Confirm the password"]}` : `${ts["Password mismatch"]}`}
              >
                <PasswordInput
                  required
                  value={confirmPassword}
                  className={commonForm("commonElement", {
                    commonInvalid: comparePassword(password, confirmPassword) || !!(password && !confirmPassword),
                  })}
                  onIonChange={(e) => setConfirmPassword(e.detail.value ?? "")}
                />
              </CommonFormElement>
            </>
          )}

          <IonRow className="ion-justify-content-end">
            <IonCol className="ion-no-padding" size="12" sizeMd="6">
              <IonButton
                onClick={updateInfo}
                disabled={isSubmitButtonDisabled}
                className={cx("button", "ion-no-padding")}
                expand="block"
                type="submit"
              >
                {ts["Save changes"]}
              </IonButton>
            </IonCol>
          </IonRow>

          {appSettings?.hasCardLoyalty && profileData?.discountCardNumber && (
            <IonRow className={"ion-no-margin ion-no-padding ion-margin-top ion-justify-content-center"}>
              <IonCol size={"12"} className={cx("referral__title", "ion-no-padding")}>
                <h2 className={cx("myAccountFormTitle")}>{ts["Discount card"]}</h2>
              </IonCol>

              <IonCol className={cx("barcodeCol", "ion-no-padding")} size="12" sizeMd="6">
                <Barcode value={profileData?.discountCardNumber} format="CODE128" marginTop={16} />
              </IonCol>
            </IonRow>
          )}

          {appSettings?.deleteAccountLink && (
            <>
              <h2 className={cx("myAccountFormTitle")}>{ts["Account deletion"]}</h2>

              <p className={cx("MyAccountForm_description")}>{ts["Delete account description"]}</p>

              <IonRow className="ion-justify-content-end">
                <IonCol className="ion-no-padding" size="12" sizeMd="6">
                  <IonButton
                    onClick={redirectToDeleteAccount}
                    className={cx("button", "ion-no-padding")}
                    expand="block"
                    type="button"
                    color="danger"
                  >
                    {ts["Delete account"]}
                  </IonButton>
                </IonCol>
              </IonRow>
            </>
          )}
        </form>

        <IonModal
          onDidDismiss={() => {
            setIsAddressModalOpen(false);
          }}
          isOpen={isAddressModalOpen}
        >
          <ShippingAddressManually />
        </IonModal>
      </MaxWidthContainer>
      <Footer />
    </div>
  );
};

export { MyAccountComponent };
