import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IonCol, IonInput, IonLabel, IonLoading, IonModal, IonRow, IonSelectOption } from "@ionic/react";
import ToolbarDesktop from "../Toolbar/ToolbarDesktop/ToolbarDesktop";
import MaxWidthContainer from "../UI/MaxWidthContainer/MaxWidthContainer";
import PageTitle from "../PageTitle/PageTitle";
import CommonFormElement from "../UI/CommonFormElements/CommonFormElement";
import Select from "../Select/Select";
import { CustomPhoneInput } from "../CustomPhoneInput/CustomPhoneInput";
import ContinueButton from "../ContinueButton/ContinueButton";
import Footer from "../Footer/Footer";
import classNames from "classnames/bind";
import styles from "./ShippingAddressComponent.module.scss";
import commonFormStyles from "../UI/CommonFormElements/CommonFormElements.module.scss";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { DatetimeChangeEventDetail } from "@ionic/core/components";
import { ShippingAddressManually } from "../ShippingAddressManually/ShippingAddressManually";
import { useCustomerAddressesStateData } from "../../providers/CustomerAddresses/CustomerAddressesStateContext";
import { SearchAddressInput } from "../SearchAddressInput/SearchAddressInput";
import Checkbox from "../Checkbox/Checkbox";
import { PaymentTabButton, ShippingTabButton } from "../../types";
import { Switcher } from "../Switcher/Switcher";
import { SwitcherSkeleton } from "../UI/SwitcherSkeleton/SwitcherSkeleton";
import { useAuth } from "../../contexts/auth-context";
import { CustomerAddressInput } from "./CustomerAddressInput/CustomerAddressInput";
import { useGetSettingsQuery } from "../../providers/settings";
import { useOrderData } from "../../providers/orderDataStore";
import { ISODate, PickupPoint, ShippingMethodDeliveryType, ShippingMethodType } from "../../data/types/entities";
import { SelectPickupPoint } from "./SelectPickupPoint/SelectPickupPoint";
import { convertCustomerAddress, generateDeliveryRangeText } from "../../utils/utils";
import { useShippingZonesData } from "../../providers/shippingZones";
import useGetDeliveryDatesQuery from "../../providers/shippingAndPayment/useGetDeliveryDatesQuery";
import { SelectDeliveryDate } from "./SelectDeliveryDate/SelectDeliveryDate";
import useGetDeliveryIntervalsQuery from "../../providers/shippingAndPayment/useGetDeliveryIntervalsQuery";

const cx = classNames.bind(styles);
const commonForm = classNames.bind(commonFormStyles);

interface IShippingAddressComponent {
  setShippingMethod: (methodId: string) => void;
  setPaymentMethod: (methodId: string) => void;
  shippingTabsButtons: ShippingTabButton[];
  paymentTabsButtons: PaymentTabButton[];
  showLoading: boolean;
  isGettingSmsCodeLoading: boolean;
  isPhoneNumberInputValid: boolean;
  setIsPhoneNumberInputValid: (value: boolean) => void;
  handleDeliveryDate: (e: CustomEvent<DatetimeChangeEventDetail>) => void;
  isWhatsappPhoneNumberValid: boolean;
  setIsWhatsappPhoneNumberValid: (value: boolean) => void;
  isFormValid: () => boolean;
  submit: () => void;
  pickupPoints: PickupPoint[] | undefined;
}

const ShippingAddressComponent: React.FC<IShippingAddressComponent> = ({
  setShippingMethod,
  setPaymentMethod,
  paymentTabsButtons,
  shippingTabsButtons,
  showLoading,
  isPhoneNumberInputValid,
  setIsPhoneNumberInputValid,
  handleDeliveryDate,
  isWhatsappPhoneNumberValid,
  setIsWhatsappPhoneNumberValid,
  isFormValid,
  submit,
  pickupPoints,
  isGettingSmsCodeLoading,
}) => {
  const { state } = useContext(AppContext);

  const ts = useMemo(() => {
    return state.config.languageJson;
  }, [state.config.languageJson]);
  const { user, isUserAuthenticated } = useAuth();

  const { data: appSettings } = useGetSettingsQuery();
  const {
    firstName,
    lastName,
    deliveryDate,
    deliveryInterval,
    shippingMethod,
    paymentMethod,
    phoneNumber,
    customerNote,
    whatsAppNumber,
    address,
    updateOrderData,
  } = useOrderData();

  const { shippingZone } = useShippingZonesData();

  const { data: deliveryDateList } = useGetDeliveryDatesQuery(
    { shippingMethodId: shippingMethod?.id },
    {
      enabled: appSettings?.isDeliveryDateEnabled,
      onSuccess: (data) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if ((!deliveryDate || deliveryDate.getTime() < today.getTime()) && data && data.length > 0) {
          updateOrderData({ deliveryDate: new Date(data[0]) });
        }
      },
    }
  );
  const { data: deliveryIntervals = [] } = useGetDeliveryIntervalsQuery(
    {
      shippingMethodId: shippingMethod?.id,
      date: deliveryDate?.toISOString().slice(0, 10) as ISODate | undefined,
    },
    {
      enabled: appSettings?.isDeliveryDateEnabled,
      onSuccess: () => {
        updateOrderData({ deliveryInterval: "" });
      },
    }
  );

  const { setIsAddressModalOpen, isAddressModalOpen } = useCustomerAddressesStateData();
  const [selectedAddressId, setSelectedAddressId] = useState(address?.id);

  useEffect(() => {
    updateOrderData({ address: null });
    setSelectedAddressId(undefined);
  }, [shippingMethod]);

  return (
    <>
      <ToolbarDesktop breadcrumbs={[{ title: ts["Order Detail"] }]} />
      <IonLoading isOpen={showLoading || isGettingSmsCodeLoading} message={ts["Please wait..."]} />
      <MaxWidthContainer classList={"global-content"} smContainer={true} mdContainer={true} lgContainer={true}>
        <PageTitle hideTitleBreakpoint={"ion-hide-md-down"} title={ts["Order Detail"]} />
        <div className={cx("shippingWrapper")}>
          <form>
            <IonRow className={"ion-justify-content-center"}>
              <IonCol size={"12"} sizeMd={"6"}>
                <CommonFormElement
                  label={
                    isUserAuthenticated ? ts.Phone : ts["An SMS code will be sent to this number to confirm the order"]
                  }
                  isValid={isPhoneNumberInputValid}
                  errorMessage={ts["Please enter a valid phone number"]}
                >
                  <CustomPhoneInput
                    disabled={!!isUserAuthenticated}
                    className={commonForm("commonElement", { commonInvalid: !isPhoneNumberInputValid })}
                    value={phoneNumber || ""}
                    onCustomChange={(val, isValid) => {
                      updateOrderData({ phoneNumber: val });
                      setIsPhoneNumberInputValid(isValid);
                    }}
                  />
                </CommonFormElement>
                <CommonFormElement label={ts["First Name"]} isValid={!!firstName}>
                  <IonInput
                    type="text"
                    value={firstName}
                    required
                    name="shipping_firstname"
                    onIonChange={(e) => updateOrderData({ firstName: e.detail.value || "" })}
                    placeholder={ts["First name input placeholder"]}
                    className={commonForm("commonElement", { commonInvalid: !firstName })}
                  />
                </CommonFormElement>
                <CommonFormElement label={`${ts["Last Name"]} (${ts.optional})`}>
                  <IonInput
                    type="text"
                    name="shipping_lastname"
                    value={lastName}
                    onIonChange={(e) => updateOrderData({ lastName: e.detail.value || "" })}
                    placeholder={ts["Last name input placeholder"]}
                    className={commonForm("commonElement")}
                  />
                </CommonFormElement>

                {showLoading || !shippingTabsButtons.length ? (
                  <div className={cx("switcher__wrapper")}>
                    <SwitcherSkeleton />
                  </div>
                ) : (
                  <div className={cx("switcher__wrapper")}>
                    <IonLabel className={cx("switcher__label")}>{ts["Shipping Method"]}</IonLabel>
                    <Switcher
                      buttons={shippingTabsButtons}
                      value={shippingMethod?.methodId}
                      setValue={setShippingMethod}
                    />
                    {shippingMethod?.methodDeliveryType === ShippingMethodDeliveryType.nextDay && (
                      <p className="m-1 text-slate-700 text-sm">
                        {ts["Delivery date"]}: <strong>{generateDeliveryRangeText(shippingMethod)}</strong>
                      </p>
                    )}
                    {!shippingMethod && (
                      <p className="m-1 text-[var(--ion-color-danger)] text-sm">
                        {ts["Please, select the shipping method"]}
                      </p>
                    )}
                  </div>
                )}
                {showLoading || !paymentTabsButtons.length ? (
                  <div className={cx("switcher__wrapper")}>
                    <SwitcherSkeleton />
                  </div>
                ) : (
                  <div className={cx("switcher__wrapper")}>
                    <IonLabel className={cx("switcher__label")}>{ts["Payment Method"]}</IonLabel>
                    <Switcher buttons={paymentTabsButtons} setValue={setPaymentMethod} value={paymentMethod?.id} />
                    {!paymentMethod && (
                      <p className="m-1 text-[var(--ion-color-danger)] text-sm">
                        {ts["Please, select the payment method"]}
                      </p>
                    )}
                  </div>
                )}

                {shippingMethod?.methodType === ShippingMethodType.pickupPoint ? (
                  <SelectPickupPoint
                    pickupPoints={pickupPoints ?? []}
                    onSelectChange={(point) => {
                      if (point) {
                        const { id, name, address, latitude, longitude } = point;
                        updateOrderData({
                          address: {
                            id,
                            name,
                            fullAddress: address,
                            latitude,
                            longitude,
                          },
                        });
                      } else {
                        updateOrderData({
                          address: null,
                        });
                      }
                    }}
                    initialPointId={address?.id}
                    ts={ts}
                  />
                ) : appSettings?.addressSettings.showCustomerAddresses && user?.id ? (
                  <CustomerAddressInput
                    // customerAddresses={customerAddresses}
                    onSelectChange={(customerAddress) => {
                      if (!customerAddress) {
                        return;
                      }

                      if (customerAddress.id !== selectedAddressId) {
                        setSelectedAddressId(customerAddress.id);
                        updateOrderData({
                          address: convertCustomerAddress(customerAddress),
                        });
                      }
                    }}
                    selectedAddressId={selectedAddressId}
                    ts={ts}
                    invalidText={
                      shippingZone && address && !address.fullAddress.includes(shippingZone.name)
                        ? `${ts["Select an address in the zone"]} "${shippingZone.name}" ${ts["or change the delivery zone on the main page"]}`
                        : ""
                    }
                  />
                ) : (
                  <>
                    <CommonFormElement
                      classListItem={cx("shipping__addressManually")}
                      label={ts["Delivery address"]}
                      isValid={!!selectedAddressId}
                    >
                      <SearchAddressInput
                        onOptionSelect={({ address: sAddress }) => {
                          const fullAddress = sAddress?.locality + ", " + sAddress?.street + ", " + sAddress?.house;

                          updateOrderData({
                            address: {
                              apartmentNumber: address?.apartmentNumber ?? "",
                              city: sAddress?.locality ?? "",
                              state: sAddress?.locality ?? "",
                              street: sAddress?.street ?? "",
                              house: sAddress?.house ?? "",
                              entrance: address?.entrance ?? "",
                              isFlat: address?.isFlat ?? false,
                              floor: address?.floor ?? "",
                              intercom: "",
                              fullAddress,
                              latitude: String(sAddress?.location ? sAddress?.location[0] : ""),
                              longitude: String(sAddress?.location ? sAddress?.location[1] : ""),
                            },
                          });
                        }}
                        address={address?.fullAddress ?? ""}
                        invalid={!address}
                      />
                    </CommonFormElement>

                    {appSettings?.addressSettings.showFlatDelivery && (
                      <>
                        <Checkbox
                          checked={address?.isFlat ?? false}
                          itemClassName={cx("checkboxItem")}
                          label={ts["Delivery to the apartment"]}
                          onIonChange={(e: any) => {
                            updateOrderData({
                              address: { ...address!, isFlat: e.detail.checked },
                            });
                          }}
                        />
                        <div className={cx("dropdown", { dropdown__active: address?.isFlat ?? false })}>
                          {address?.isFlat && (
                            <>
                              <CommonFormElement label={ts["Entrance"]} isValid={!!address?.entrance}>
                                <IonInput
                                  type="text"
                                  required={address?.isFlat ?? false}
                                  name="entrance"
                                  value={address?.entrance}
                                  onIonChange={(e) => {
                                    updateOrderData({
                                      address: { ...address!, entrance: e.detail.value! },
                                    });
                                  }}
                                  placeholder={ts["Entrance input placeholder"]}
                                  className={commonForm("commonElement", { commonInvalid: !address?.entrance })}
                                />
                              </CommonFormElement>
                              <CommonFormElement label={ts["Floor"]} isValid={!!address?.floor}>
                                <IonInput
                                  required={address?.isFlat ?? false}
                                  type="text"
                                  name="floor"
                                  value={address?.floor}
                                  onIonChange={(e) => {
                                    updateOrderData({
                                      address: { ...address!, floor: e.detail.value! },
                                    });
                                  }}
                                  placeholder={ts["Floor input placeholder"]}
                                  className={commonForm("commonElement", { commonInvalid: !address?.floor })}
                                />
                              </CommonFormElement>
                              <CommonFormElement label={ts["Apartment number"]} isValid={!!address?.apartmentNumber}>
                                <IonInput
                                  required={address?.isFlat ?? false}
                                  type="text"
                                  name="apartment-number"
                                  value={address?.apartmentNumber}
                                  onIonChange={(e) => {
                                    updateOrderData({
                                      address: { ...address!, apartmentNumber: e.detail.value! },
                                    });
                                  }}
                                  placeholder={ts["Apartment input number placeholder"]}
                                  className={commonForm("commonElement", {
                                    commonInvalid: !address?.apartmentNumber,
                                  })}
                                />
                              </CommonFormElement>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}

                <IonModal
                  onDidDismiss={() => {
                    setIsAddressModalOpen(false);
                  }}
                  isOpen={isAddressModalOpen}
                >
                  <ShippingAddressManually />
                </IonModal>

                {appSettings?.isDeliveryDateEnabled &&
                  !!deliveryDateList &&
                  !!shippingMethod?.methodId &&
                  shippingMethod?.methodDeliveryType !== ShippingMethodDeliveryType.nextDay && (
                    <>
                      <SelectDeliveryDate
                        deliveryDateList={deliveryDateList}
                        isValid={!!deliveryDate}
                        onChange={handleDeliveryDate}
                        value={deliveryDate?.toISOString() ?? null}
                        className={commonForm("commonElement")}
                      />

                      <CommonFormElement label={ts["Delivery interval"]} isValid={!!(deliveryDate && deliveryInterval)}>
                        <Select
                          value={deliveryInterval}
                          disabled={!deliveryInterval && deliveryIntervals.length < 1}
                          placeholder={ts["Select delivery interval"]}
                          languageJson={ts}
                          onChange={(e: any) => updateOrderData({ deliveryInterval: e.detail.value })}
                          isValid={!!(deliveryDate && deliveryInterval)}
                        >
                          {deliveryIntervals.length > 0 &&
                            deliveryIntervals.map((interval) => {
                              return (
                                <IonSelectOption key={interval.id} value={interval.text}>
                                  {interval.text}
                                </IonSelectOption>
                              );
                            })}
                        </Select>
                      </CommonFormElement>
                    </>
                  )}
                <CommonFormElement label={`${ts["Order note"]} (${ts.optional})`}>
                  <IonInput
                    type="text"
                    name="order-note"
                    value={customerNote}
                    onIonChange={(e) => updateOrderData({ customerNote: e.detail.value || "" })}
                    className={commonForm("ion-no-padding", "commonElement")}
                  />
                </CommonFormElement>
                <CommonFormElement
                  label={`${ts["WhatsApp number"]} (${ts.optional})`}
                  isValid={!whatsAppNumber || isWhatsappPhoneNumberValid}
                  errorMessage={ts["Please enter a valid phone number"]}
                >
                  <CustomPhoneInput
                    className={commonForm("commonElement", {
                      commonInvalid: whatsAppNumber && !isWhatsappPhoneNumberValid,
                    })}
                    value={whatsAppNumber}
                    onCustomChange={(val, isValid) => {
                      updateOrderData({ whatsAppNumber: val });
                      setIsWhatsappPhoneNumberValid(isValid);
                    }}
                  />
                </CommonFormElement>

                <div className={cx("ion-hide-md-down")}>
                  <ContinueButton
                    onClick={() => submit()}
                    disabled={
                      !isFormValid() ||
                      (address?.isFlat && (!address || !address.apartmentNumber || !address.floor || !address.entrance))
                    }
                    buttonText={ts.Next}
                  />
                </div>
              </IonCol>
            </IonRow>
          </form>
        </div>
      </MaxWidthContainer>
      <Footer />
    </>
  );
};

export { ShippingAddressComponent };
