import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

// Import Icons
import { chatboxOutline } from "ionicons/icons";

// Import Ionic
import { IonIcon, IonLoading, IonRow } from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./Reviews.module.scss";

// Import Components
import ReviewComments from "./ReviewComments/ReviewComments";
import ReviewGrade from "./ReviewGrade/ReviewGrade";
import useGetProductReviewsQuery from "../../providers/products/useGetProductReviewsQuery";

const cx = classNames.bind(styles);

const Reviews = ({ productId }: { productId: string }) => {
  const { data: reviews, isFetching: isFetchingReviews } = useGetProductReviewsQuery(productId);

  useEffect(() => {
    if (reviews && !isFetchingReviews) {
      let total = 0;
      for (let value of reviews) {
        total = total + value.rating;
      }
      if (reviews.length === 0) {
        setAverage(0);
      } else {
        setAverage(total / reviews.length);
      }
      calculateAll();
    }
  }, [reviews]);

  const { state } = useContext(AppContext);

  let [average, setAverage] = useState(0);
  let [r1, setR1] = useState(0);
  let [r2, setR2] = useState(0);
  let [r3, setR3] = useState(0);
  let [r4, setR4] = useState(0);
  let [r5, setR5] = useState(0);

  useEffect(() => {
    setAverage(0);
    setR1(0);
    setR2(0);
    setR3(0);
    setR4(0);
    setR5(0);
  }, [productId]);

  const calculateAll = () => {
    let r1 = 0,
      r2 = 0,
      r3 = 0,
      r4 = 0,
      r5 = 0;
    let total = reviews!.length;

    reviews?.forEach((review) => {
      switch (review.rating) {
        case 1:
          r1++;
          break;
        case 2:
          r2++;
          break;
        case 3:
          r3++;
          break;
        case 4:
          r4++;
          break;
        case 5:
          r5++;
          break;
      }
    });

    const setRating = (ratingCount: number, setter: (rating: number) => void) => {
      if ((100 / total) * ratingCount) {
        setter((100 / total) * ratingCount);
      } else {
        setter(0);
      }
    };

    setRating(r1, setR1);
    setRating(r2, setR2);
    setRating(r3, setR3);
    setRating(r4, setR4);
    setRating(r5, setR5);
  };

  return (
    <>
      <IonLoading isOpen={isFetchingReviews} message={state.config.languageJson["Please wait..."]} duration={20000} />
      <ReviewGrade
        id={productId}
        rating1={r1}
        rating2={r2}
        rating3={r3}
        rating4={r4}
        rating5={r5}
        reviewsLength={reviews?.length || 0}
        average={average.toFixed(1)}
        languageJson={state.config.languageJson}
      />

      <ReviewComments reviews={reviews || []} />

      {!isFetchingReviews && reviews?.length === 0 && (
        <IonRow className={cx("noReviewsRow")}>
          <IonIcon className={cx("noReviewsRowIcon")} icon={chatboxOutline} />
          <h5>{state.config.languageJson["No reviews"]}</h5>
        </IonRow>
      )}
    </>
  );
};

export default Reviews;
