import React from "react";

// Import Ionic
import { IonButton, IonToolbar } from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ContinueButton.module.scss";

const cx = classNames.bind(styles);

interface IContinueButton {
  onClick?: () => void;
  buttonText?: string | number;
  disabled?: boolean;
  className?: string;
  color?: string;
  type?: "button" | "submit";
}

const ContinueButton: React.FC<IContinueButton> = ({
  onClick = () => false,
  disabled = false,
  buttonText = "button",
  className = "",
  color = "primary",
  type = "button",
}) => {
  return (
    <IonToolbar className={cx("continueToolbar")}>
      <IonButton
        disabled={disabled}
        expand="block"
        color={color}
        className={cx("continueToolbarButton", className)}
        onClick={onClick}
        type={type}
      >
        {buttonText}
      </IonButton>
    </IonToolbar>
  );
};

export default ContinueButton;
