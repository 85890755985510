import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { authClient } from "../../data/clients";
import { LoginVariables, User } from "../../data/types/entities";

const useLoginMutation = (options?: UseMutationOptions<User, any, LoginVariables>) =>
  useMutation(
    ["login"],
    async ({ username, password }) => {
      const user = await authClient.login({ username, password });

      return user;
    },
    options
  );

export default useLoginMutation;
