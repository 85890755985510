// Import React Libraries
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router";

// Import Styles
import styles from "./AuthBySms.module.scss";
import classNames from "classnames/bind";
import commonFormStyles from "../../components/UI/CommonFormElements/CommonFormElements.module.scss";

// Import Ionic
import { IonPage, IonHeader, IonButton, IonContent, IonLoading, IonInput, useIonToast } from "@ionic/react";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

import { useAuth } from "../../contexts/auth-context";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import Footer from "../../components/Footer/Footer";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import { useAuthBySms, useGetSmsCode } from "../../providers/Auth";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import CommonFormElement from "../../components/UI/CommonFormElements/CommonFormElement";
import { AuthByCode } from "../../components/AuthByCode/AuthByCode";
import { CustomPhoneInput } from "../../components/CustomPhoneInput/CustomPhoneInput";
import { useSmsVerificationData } from "../../providers/smsVerifivation";

const cx = classNames.bind(styles);
const commonForm = classNames.bind(commonFormStyles);

interface ISignInCredentials {
  phone: string;
}

const normalizeLogin = (login: string) => {
  if (/^\+?[0-9]+$/.test(login)) {
    let parsedLogin = login.replace("+", "");

    if (parsedLogin.length === 11 && parsedLogin.startsWith("8")) {
      parsedLogin = "7" + parsedLogin.slice(1);
    }
    return parsedLogin;
  }

  return "";
};

const AuthBySms: React.FC = (props: any) => {
  const { state } = useContext(AppContext); // For Store
  const ts = useMemo(() => state.config.languageJson, []);
  const history = useHistory();
  const [present] = useIonToast();
  const [isSmsAlertOpened, setIsAlertSmsOpened] = useState(false);
  const verificationCodeData = useRef<{ code_id: string; phone_number: string } | null>(null);

  const toggleIsSmsAlertOpened = useCallback(() => {
    setIsAlertSmsOpened((prev) => !prev);
  }, [isSmsAlertOpened]);

  const { login } = useAuth();

  const { mutate, isLoading } = useGetSmsCode();
  const { mutate: mutateAuth, isLoading: isAuthLoading } = useAuthBySms();
  const { setWaitingTimer, canGetNewCode, sentSmsAgain, phoneNumber: prevPhoneNumber } = useSmsVerificationData();

  const { handleSubmit, control, reset, getValues } = useForm<FieldValues>({
    mode: "onSubmit",
  });

  const authUserBySms = useCallback((code: string) => {
    if (!verificationCodeData.current) return;

    mutateAuth(
      {
        phone: verificationCodeData.current?.phone_number,
        verificationCode: code,
        verificationCodeId: verificationCodeData.current?.code_id,
      },
      {
        onError: (error) => {
          if (error?.response?.data?.message === "Verification code data is not valid") {
            present({
              color: "danger",
              message: ts["Invalid verification code"],
              duration: 1500,
              position: "bottom",
            });
          }

          console.log(error, "error");
        },
        onSuccess: (data) => {
          login(data);
          toggleIsSmsAlertOpened();
          present({
            color: "success",
            message: ts["You have been successfully authorized!"],
            duration: 1500,
            position: "bottom",
          });
          let timer = setTimeout(() => {
            clearTimeout(timer);
            history.replace("/my-account");
          }, 1500);
        },
      }
    );
  }, []);

  const handleAuth: SubmitHandler<FieldValues> = (data) => {
    const { phone } = data as ISignInCredentials;

    const normalizedPhone = normalizeLogin(phone);

    if (!normalizedPhone.length) return;

    if (!canGetNewCode) {
      toggleIsSmsAlertOpened();
      return;
    }

    if (prevPhoneNumber !== normalizedPhone) {
      getCode(normalizedPhone, () => {
        setWaitingTimer(normalizedPhone);
        toggleIsSmsAlertOpened();
      });
    } else {
      toggleIsSmsAlertOpened();
    }
  };

  const requestNewCode = () => {
    if (!canGetNewCode) return;

    const phone = getValues("phone");
    const normalizedPhone = normalizeLogin(phone);
    if (!normalizedPhone.length) return;

    getCode(normalizedPhone, sentSmsAgain);
  };

  const getCode = (phone: string, onSucceedCb: () => void) => {
    mutate(
      { phone },
      {
        onError: (error) => {
          console.log(error, "on error");
        },
        onSuccess: (data) => {
          verificationCodeData.current = {
            code_id: data.verificationCodeId,
            phone_number: data.phoneNumber,
          };

          // AppMetricaProvider.reportEvent("login", { ...data, token: undefined });
          onSucceedCb();
          return data;
        },
      }
    );
  };

  useEffect(() => {
    reset();
  }, [props.match.params.name]);

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton />
      </IonHeader>

      <IonContent>
        <ToolbarDesktop />
        <IonLoading isOpen={isLoading || isAuthLoading} message={ts["Please wait..."]} />
        <div className={`${cx("about-us", "loginContent")} global-content`}>
          <MaxWidthContainer maxWidthBreakpoint={"Sm"} classList={cx("loginForm")}>
            <form onSubmit={handleSubmit(handleAuth)}>
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CommonFormElement
                    label={"Чтобы войти введите номер телефона. Мы отправим вам смс-код"}
                    isValid={true}
                    errorMessage={ts["Please enter a valid phone number"]}
                  >
                    <CustomPhoneInput
                      className={commonForm("commonElement", { commonInvalid: false })}
                      value={value || ""}
                      onCustomChange={(val, isValid) => {
                        onChange(val);
                      }}
                    />
                  </CommonFormElement>
                )}
              />

              <IonButton color="secondary" className=" ion-margin-start ion-margin-end" expand="block" type="submit">
                {ts[canGetNewCode ? "Send code" : "Enter code"]}
              </IonButton>
            </form>
            <AuthByCode
              isOpen={isSmsAlertOpened}
              toggleIsOpen={toggleIsSmsAlertOpened}
              auth={authUserBySms}
              requestNewCode={requestNewCode}
            />
          </MaxWidthContainer>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export { AuthBySms };
