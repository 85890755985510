import { ApolloError } from "@apollo/client";
import { useCustomerAddressesLazyQuery } from "../../generated/graphql";
import { CustomerAddress, CustomerAddressLazyQuery } from "./CustomerAddressesTypes";

interface IUseCustomerAddressesData {
  loading: boolean;
  getCustomerAddressesData: CustomerAddressLazyQuery;
  customerAddresses: CustomerAddress[];
  error: ApolloError | undefined;
}

export function useCustomerAddressesData(): IUseCustomerAddressesData {
  const [getCustomerAddressesData, { data, loading, error }] = useCustomerAddressesLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const customerAddresses =
    data?.customerAddresses?.edges.map(({ node }) => {
      if (node.location) {
        const reversedCoordinates: number[] = [...node.location.coordinates].reverse();

        return {
          ...node,
          location: {
            ...node.location,
            coordinates: reversedCoordinates,
          },
        };
      }

      return node;
    }) ?? [];

  return {
    loading,
    customerAddresses,
    getCustomerAddressesData,
    error,
  };
}
