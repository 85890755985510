import { IBannersClient } from "../../types/clients";
import { Banner } from "../../types/entities";
import BaseClient from "../base-client";

class BannersClient extends BaseClient implements IBannersClient {
  async getBanners() {
    const response = await this.instance.get<any>(
      "/api/ionicbecruxsettings/ionic_becrux_get_all_banners/?insecure=cool"
    );

    const banners: Banner[] = response?.data?.data?.map((banner: any) => {
      return {
        id: banner.banners_id,
        imageSrc: banner.banners_image,
        link: banner.banners_url,
        type: banner.type,
      };
    });

    return banners;
  }
}

export default BannersClient;
