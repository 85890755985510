import { DEFAULT_CART_REMIND_DELAY_MINUTES } from "../../../config/constansts";
import { ISettingsClient } from "../../types/clients";
import { AppSettings } from "../../types/entities";
import BaseClient from "../base-client";

export type ParhatoAppSettings = {
  [k: string]: string;
};

export type WooComPages = {
  id: string;
  content: {
    protected: boolean;
    rendered: string;
  };
  slug: string;
}[];

class SettingsClient extends BaseClient implements ISettingsClient {
  async getSettings() {
    const settings = (
      await this.instance.get<ParhatoAppSettings>(
        "/api/ionicbecruxsettings/ionic_becrux_get_all_settings/?insecure=cool"
      )
    ).data;

    const ids = [
      settings.about_page_id || undefined,
      settings.refund_page_id || undefined,
      settings.terms_page_id || undefined,
    ];

    const includes = ids.filter((item) => Boolean(item)).join(",");
    const { data } = await this.instance.get<WooComPages>(`/wp-json/wp/v2/pages/?include=${includes}`);

    const pages: { [k: string]: string } = data.reduce(
      (result, page) => ({ ...result, [page.id]: page.content.rendered }),
      {}
    );

    const appSettings: AppSettings = {
      aboutPageId: parseInt(settings.about_page_id) || 0,
      privacyPageId: parseInt(settings.privacy_page_id) || 0,
      refundPageId: parseInt(settings.refund_page_id) || 0,
      termsPageId: parseInt(settings.terms_page_id) || 0,
      homePage: parseInt(settings.home_style),
      categoryPage: parseInt(settings.category_style),
      introPage: parseInt(settings.intro_page),
      myOrdersPage: parseInt(settings.my_orders_page),
      newsPage: parseInt(settings.news_page),
      wishListPage: parseInt(settings.wish_list_page),
      shippingAddressPage: parseInt(settings.shipping_address_page),
      contactUsPage: pages[settings.about_page_id],
      editProfilePage: parseInt(settings.edit_profile_page),
      settingPage: parseInt(settings.setting_page),
      addressPage: settings.bill_ship_info === "1",
      downloadPage: settings.downloads === "1",
      wishListIcons: true,

      multiLanguage: settings.wpml_enabled === "1",
      multiCurrency: settings.wp_multi_currency === "1",

      showVendorInfo: settings.mvf_enabled === "1",
      showWcVendorInfo: settings.mvf_enabled === "2",

      enableGeoFencing: settings.geo_fencing === "1",
      enableDeliveryTracking: settings.delivery_tracking === "1",
      enableWpPointReward: settings.wp_point_reward === "1",

      rateApp: parseInt(settings.rate_app),
      sharedData: {
        title: "Parhato",
        text: `Parhato - это ваш личный помощник по доставке продуктов и других различных товаров, начиная от детского питания и заканчивая бытовой химией.\n\n`,
        androidUrl: "https://play.google.com/store/apps/details?id=ru.parhato.app",
        iosUrl: "https://apps.apple.com/ru/app/parhato/id1572201263",
      },
      shareApp: parseInt(settings.share_app),
      defaultIcons: settings.sidebar_menu_icon === "1",
      currentSettings: parseInt(settings.update_order),
      checkOutPage: parseInt(settings.one_page_checkout),

      email: settings.contact_us_email,
      latitude: settings.latitude,
      longitude: settings.longitude,
      newProductDuration: settings.new_product_duration,
      siteUrl: settings.site_url,
      orderCancelButton: settings.cancel_order_button === "1",
      cancelOrderTime: parseInt(settings.cancel_order_hours),
      minimumOrderAmount: parseInt(settings.order_minimum_amount) ?? 500,
      showNewVersionAlert: settings.show_new_version_alert === "yes",
      newVersionAlertTitle: settings.new_version_alert_title,
      newVersionAlertMessage: settings.new_version_alert_message,
      newVersionAlertButtonText: settings.new_version_alert_button_text,
      minVersionAndroid: settings.min_version_android,
      minVersionIOS: settings.min_version_ios,
      shouldCheckItemsAvailability: false,
      showProductReviews: true,
      showCouponsInCart: true,

      isBonusPointsEnabled: true,
      isShippingZonesEnabled: true,
      isDeliveryDateEnabled: true,
      orderHistoryPagination: true,
      isCustomerAdressesEnabled: true,
      isReferralEnabled: true,

      signUpPage: {
        nameFieldEnabled: false,
        lastNameFieldEnabled: false,
      },

      deleteAccountLink: "https://parhato.ru/delete-me/",
      showSendCustomerRequestButton: true,
      addToCart: "",
      cardProduct: false,
      updateUserForm: {
        isUpdatePasswordEnabled: true,
        showPhoneNumberInput: true,
      },
      isOnlinePaymentEnabled: false,
      privacyPolicyPageLink: "https://parhato.ru/privacy/",
      isSeoOnHomePageEnabled: true,
      companyPhoneNumber: "+7 (928) 737-50-50",
      isContentInFooterShown: true,
      hasCardLoyalty: false,
      showSliderCatalogButton: false,
      addressSettings: {
        showCustomerAddresses: true,
        showFlatDelivery: true,
        addressInputDependsOnShippingMethod: false,
      },
      homePageCategories: {
        customersChoice: { title: "Customers Choice", isEnabled: true },
        featured: { title: "Featured Products", isEnabled: true },
        sale: { title: "On Sale Products", isEnabled: true },
        latest: { title: "Latest Addest", isEnabled: true },
      },
      showDefaultShippingZone: true,
      showOrderPageProductCardCounter: false,
      cartMinAmountFromMethods: true,
      siteDomain: "parhato.ru",

      loginPage: {
        phoneOrEmailFieldEnabled: true,
      },
      notifications: {
        cartRemindDelayMinutes: settings.cart_remind_delay_minutes
          ? parseInt(settings.cart_remind_delay_minutes)
          : DEFAULT_CART_REMIND_DELAY_MINUTES,
      },
      orderDetailPage: {
        reviewOrderEnabled: true,
        repeatOrderEnabled: true,
      }
    };

    return appSettings;
  }
}

export default SettingsClient;
