import { PRODUCTS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { productsClient } from "../../data/clients";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { Product, ProductsQueryParams } from "../../data/types/entities";

const useGetNewestProductsQuery = (props: ProductsQueryParams = {}, options: UseQueryOptions<Product[]> = {}) => {
  return useQuery<Product[], any>(
    ["products", "newest", props],
    async () => {
      return await productsClient.getNewestProducts(props);
    },
    {
      cacheTime: PRODUCTS_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: PRODUCTS_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );
};

export default useGetNewestProductsQuery;
