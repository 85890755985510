import React, { useContext } from "react";
import { IonCol, IonIcon, IonLabel, IonRow } from "@ionic/react";

import styles from "./Tabs.module.scss";

import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import classNames from "classnames/bind";
import { useAuth } from "../../contexts/auth-context";
import { basketSharp, heartCircleSharp, homeSharp, listSharp, personCircleSharp } from "ionicons/icons";
import { useCartData } from "../../providers/cartDataStore";

interface IItemProps {
  icon: string;
  route: string;
  altRoute?: string;
  text: string;
  notification?: number;
  onClick: () => void;
}

const cx = classNames.bind(styles);

const Tabs: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  const { state } = useContext(AppContext);
  const { cartTotalValue, cartProducts } = useCartData();

  const items: IItemProps[] = [
    {
      text: state.config.languageJson["Home"],
      icon: homeSharp,
      route: "/home",
      onClick: () => {
        history.push("/home3");
      },
    },
    {
      text: state.config.languageJson["Catalog"],
      icon: listSharp,

      route: "/search-main-categories",
      onClick: () => {
        history.push("/search-main-categories");
      },
    },
    {
      text: cartTotalValue.toFixed(2) + " ₽",
      icon: basketSharp,
      route: "/cart",
      notification: cartProducts.reduce((acc, item) => item.quantity + acc, 0),
      onClick: () => {
        history.push("/cart");
      },
    },
    {
      text: state.config.languageJson["Favorite"],
      icon: heartCircleSharp,
      route: "/wish-list",
      onClick: () => {
        history.push("/wish-list");
      },
    },
    {
      text: state.config.languageJson["Profile"],
      icon: personCircleSharp,
      route: "/my-account",
      altRoute: "/auth",
      onClick: () => {
        if (!user) {
          if (!pathname.startsWith("/login")) {
            history.push("/auth");
          }
        } else {
          history.push("/my-account");
        }
      },
    },
  ];
  return (
    <IonRow>
      <div className={cx("tabs", "ion-hide-md-up")}>
        {items.map((x) => {
          const isActive = pathname.startsWith(x.route) || pathname.startsWith(x.altRoute as any);
          return (
            <IonCol key={x.route} size="2.4" onClick={x.onClick}>
              <div className={cx("item")}>
                <div className={cx("iconContainer")}>
                  <IonIcon src={x.icon} className={cx(`${isActive ? "iconActive" : "icon"}`)} />
                  {/* <img alt={x.text} className={cx(`${ !isActive ? "iconActive" : "icon"}`)} src={isActive ? x.iconActive : x.icon} /> */}
                  {x.notification !== undefined && (
                    <div className={cx("notification")}>
                      <span className={cx("notificationCounter")}>{x.notification}</span>
                    </div>
                  )}
                </div>
                <IonLabel className={isActive ? cx("activeLabel") : cx("label")}>{x.text}</IonLabel>
              </div>
            </IonCol>
          );
        })}
      </div>
    </IonRow>
  );
};

export default Tabs;
