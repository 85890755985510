import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { deliveryDatesClient } from "../../data/clients";
import { SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { DeliveryInterval, ISODate } from "../../data/types/entities";

interface DeliveryIntervalsQueryAttrs {
  shippingMethodId?: string;
  date?: ISODate;
}

const useGetDeliveryIntervalsQuery = (
  { shippingMethodId, date }: DeliveryIntervalsQueryAttrs = {},
  options: UseQueryOptions<DeliveryInterval[]> = {},
) => {
  return useQuery<DeliveryInterval[]>(
    ["delivery-intervals", shippingMethodId, date],
    async () => {
      if (deliveryDatesClient) {
        const data = await deliveryDatesClient.getDeliveryIntervals(
          shippingMethodId,
          date,
        );
        return data;
      }

      throw new Error("deliveryDatesClient not implemented");
    },
    {
      cacheTime: SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS,
      staleTime: SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS,
      refetchInterval: SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS,
      refetchIntervalInBackground: true,
      ...options,
    },
  );
};

export default useGetDeliveryIntervalsQuery;
