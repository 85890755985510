import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { productsClient } from "../../data/clients";
import { Product } from "../../data/types/entities";
import { PRODUCT_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";

const useGetProductVariations = (variations: (string | number)[], options: UseQueryOptions<Product[]> = {}) => {
  return useQuery<Product[], any>(
    ["product", "variations", variations],
    async () => {
      return await Promise.all(variations.map((id) => productsClient.getProductById(String(id))));
    },
    {
      cacheTime: PRODUCT_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: PRODUCT_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );
};

export default useGetProductVariations;
