import React, { useCallback, useContext, useMemo, useState } from "react";
import { AddressSearchMap } from "../AddressSearchMap/AddressSearchMap";
import { AddressForm } from "../AddressForm/AddressForm";
import { useIonAlert } from "@ionic/react";
import { useCustomerAddressesStateData } from "../../providers/CustomerAddresses/CustomerAddressesStateContext";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { CustomerAddressInput } from "../../generated/graphql";
import { useCustomerAddresses } from "../../providers/CustomerAddresses/CustomerAddressesDataContext";
import CommonModal from "../UI/CommonModal/CommonModal";
import { YMaps } from "react-yandex-maps";

import classNames from "classnames/bind";
import styles from "./ShippingAddressManually.module.scss";
import { useGetShippingZonesQuery } from "../../providers/shippingAndPayment";

const cx = classNames.bind(styles);

const ShippingAddressManually = () => {
  const { state } = useContext(AppContext);

  const ts = useMemo(() => {
    return state.config.languageJson;
  }, [state.config.languageJson]);

  const { updateCustomerAddress, createCustomerAddress } = useCustomerAddresses();
  const { customerAddress, setCustomerAddress, setIsAddressModalOpen } = useCustomerAddressesStateData();
  const { data: cities = [] } = useGetShippingZonesQuery();

  const [showAddressForm, setShowAddressForm] = useState(false);

  const [presentAlert] = useIonAlert();

  const onSubmit = useCallback(
    (data: CustomerAddressInput) => {
      presentAlert({
        header: ts["Please make sure that the entered address matches the one on the map"],
        cssClass: "custom-alert",
        backdropDismiss: false,
        buttons: [
          {
            text: ts["Back to map"],
            handler: () => {
              setShowAddressForm(false);
            },
          },
          {
            text: ts["Confirm"],
            cssClass: "alert-button-confirm",
            handler: () => {
              const formData = {
                ...data,
                isFlat: !!data.apartmentNumber,
                latitude: customerAddress?.location?.coordinates[0] || "",
                longitude: customerAddress?.location?.coordinates[1] || "",
                state: data.city,
              };

              if (customerAddress?.id) {
                updateCustomerAddress(customerAddress?.id, formData);
              } else {
                createCustomerAddress(formData);
              }
              setIsAddressModalOpen(false);
              setCustomerAddress(undefined);
            },
          },
        ],
      });
    },
    [customerAddress]
  );

  return (
    <CommonModal
      onDismiss={() => {
        if (!showAddressForm) {
          setIsAddressModalOpen(false);
          setCustomerAddress(undefined);
          return;
        }

        setShowAddressForm(false);
      }}
      title={ts["Add a new shipping address"]}
      classNameContent={cx("addressModalContent", showAddressForm ? "addressModalContent-scrollVisible" : "")}
    >
      <YMaps
        query={{
          load: "package.full",
          apikey: process.env.REACT_APP_YANDEX_MAP_API_KEY,
        }}
      >
        {showAddressForm ? (
          <AddressForm onSubmit={onSubmit} cities={cities} />
        ) : (
          <AddressSearchMap setShowAddressForm={setShowAddressForm} />
        )}
      </YMaps>
    </CommonModal>
  );
};

export { ShippingAddressManually };
