import React from "react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ProductDetailSlideImg.module.scss";
import { Image } from "../../Image/Image";
import { ProductImage } from "../../../data/types/entities";

const cx = classNames.bind(styles);

type IProductDetailSlideImg = {
  image?: ProductImage;
  alt?: string;
};

const ProductDetailSlideImg: React.FC<IProductDetailSlideImg> = ({ image, alt = "" }) => {
  return (
    <div className={cx("slideImgWrapper")}>
      <Image image={image} alt={alt} downsize={false} className={cx("slideImg")} />
    </div>
  );
};

export default ProductDetailSlideImg;
