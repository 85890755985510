import React from 'react'
import { Popover } from '@headlessui/react'

interface PopUpTooltipProps {
  text: string;
  btnStyles?: string;
  panelStyles?: string;
  containerStyles?: string;
}

const PopUpTooltip = ({ text, containerStyles, btnStyles, panelStyles }: PopUpTooltipProps) => {
  return (
    <Popover className={`relative ${containerStyles ? containerStyles : ""}`}>
      <Popover.Button className={`${btnStyles ? btnStyles : ''}`}>?</Popover.Button>

      <Popover.Panel className={`absolute z-10 ${panelStyles ? panelStyles : ''}`}>
        {text}
      </Popover.Panel>
    </Popover>
  )
};

export default PopUpTooltip;