import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { usersClient } from "../../data/clients";
import { User, UserWithPassword } from "../../data/types/entities";

type TVariables = {
  userId: string;
  userPayload: Partial<UserWithPassword>;
};

const useUpdateProfileMutation = (
  options?: UseMutationOptions<Partial<User>, any, TVariables>,
) =>
  useMutation(
    ["update-user"],
    async ({ userId, userPayload }) => {
      const data = await usersClient.updateProfile(userId, userPayload);
      return data;
    },
    options,
  );

export default useUpdateProfileMutation;
