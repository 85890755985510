import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { shippingZonesClient } from "../../data/clients";
import { SHIPPING_ZONES_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { ShippingZone } from "../../data/types/entities";

const useGetShippingZonesQuery = (options: UseQueryOptions<ShippingZone[]> = {}) => {
  return useQuery<ShippingZone[], any>(
    ["shipping-zones"],
    async () => {
      return await shippingZonesClient.getShippingZones();
    },
    {
      cacheTime: SHIPPING_ZONES_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: SHIPPING_ZONES_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );
};

export default useGetShippingZonesQuery;
