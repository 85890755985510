import React, { useContext } from "react";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";

// Import Icons
import { star } from "ionicons/icons";

// Import Ionic
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, useIonModal } from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ReviewGrade.module.scss";

// Import Components
import AddReviewModal from "../../AddReviewModal/AddReviewModal";
import { useHistory } from "react-router";
import { useAuth } from "../../../contexts/auth-context";

interface IReviewGrade {
  id: any;
  rating1: number;
  rating2: number;
  rating3: number;
  rating4: number;
  rating5: number;
  average: number | string;
  reviewsLength: number;
  languageJson: any;
}

const cx = classNames.bind(styles);

const ReviewGrade: React.FC<IReviewGrade> = ({
  id,
  rating1,
  rating2,
  rating3,
  rating4,
  rating5,
  average,
  reviewsLength,
  languageJson,
}) => {
  const { user } = useAuth();
  const { state } = useContext(AppContext);
  const history = useHistory(); // For Navigation

  const [present, dismiss] = useIonModal(AddReviewModal, {
    onDismiss: () => dismiss(),
    id: id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    languageJson: state.config.languageJson,
  });

  const openAddReviewModal = () => {
    if (user?.id) {
      present({ cssClass: "addReviewModal" });
    } else {
      history.push("/auth");
    }
  };

  return (
    <>
      {rating5 != null && (
        <IonGrid class={cx("grade")}>
          <IonRow>
            <IonCol>
              <h4>{languageJson["Ratings & Reviews"]}</h4>
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-center">
            <IonCol size="5">
              <h1>
                {average}
                <IonIcon className={cx("mg-l5")} color="primary" icon={star} />
              </h1>
              <h6>
                {languageJson["Total ratings"]}: {reviewsLength}
              </h6>
            </IonCol>
            <IonCol size="7">
              <ul>
                <li>
                  5
                  <IonIcon className={cx("mg-l5")} color="primary" icon={star} />
                  <span
                    className={cx("gradeBlock", "animate")}
                    style={{ width: rating5 + "%", backgroundColor: "#57bb8a" }}
                  ></span>
                </li>
                <li>
                  4
                  <IonIcon className={cx("mg-l5")} color="primary" icon={star} />
                  <span
                    className={cx("gradeBlock", "animate")}
                    style={{ width: rating4 + "%", backgroundColor: "#9ace6a" }}
                  ></span>
                </li>
                <li>
                  3
                  <IonIcon className={cx("mg-l5")} color="primary" icon={star} />
                  <span
                    className={cx("gradeBlock", "animate")}
                    style={{ width: rating3 + "%", backgroundColor: "#ffcf02" }}
                  ></span>
                </li>
                <li>
                  2
                  <IonIcon className={cx("mg-l5")} color="primary" icon={star} />
                  <span
                    className={cx("gradeBlock", "animate")}
                    style={{ width: rating2 + "%", backgroundColor: "#ff9f02" }}
                  ></span>
                </li>
                <li>
                  1
                  <IonIcon className={cx("mg-l5")} color="primary" icon={star} />
                  <span
                    className={cx("gradeBlock", "animate")}
                    style={{ width: rating1 + "%", backgroundColor: "#ff6f31" }}
                  ></span>
                </li>
              </ul>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" color="primary" onClick={openAddReviewModal}>
                {languageJson["Rate and write a review"]}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};

export default ReviewGrade;
