export interface AppMetricaConfigInterface {
  handleFirstActivationAsUpdate?: boolean;
  locationTracking?: boolean;
  sessionTimeout?: number;
  crashReporting?: boolean;
  appVersion?: string;
  logs?: boolean;
  location?: AppMetricaLocationInterface;
  preloadInfo?: any;
}

export interface AppMetricaLocationInterface {
  latitude: number;
  longitude: number;
  altitude: number;
  accuracy: number;
  verticalAccuracy: number;
  course: number;
  speed: number;
  timestamp: number;
}

export interface AppMetricaInterface {
  activate: (config: { apiKey: string } & AppMetricaConfigInterface) => void;
  reportEvent: (eventName: string, options?: any) => void;
  reportError: (errorName: string, description?: string) => void;
  setLocation: (location: AppMetricaLocationInterface) => void;
  setLocationTracking: (enabled: boolean) => void;
}

interface AppMetricaProviderInterface extends Pick<AppMetricaInterface, "reportEvent" | "reportError"> {
  apiKey: string;
  _appMetrica: null | AppMetricaInterface;
  isActive: boolean;

  initialize: (config?: AppMetricaConfigInterface) => void;
}

const AppMetricaProvider: AppMetricaProviderInterface = {
  apiKey: process.env.REACT_APP_APPMETRICA_API_KEY!,
  _appMetrica: null,
  isActive: false,

  reportEvent(...args) {
    if (this.isActive) {
      return this._appMetrica!.reportEvent(...args);
    }
  },

  reportError(...args) {
    if (this.isActive) {
      return this._appMetrica!.reportError(...args);
    }
  },

  initialize(config) {
    this._appMetrica = (window as any).appMetrica;
    this._appMetrica!.activate({
      apiKey: this.apiKey,
      ...config,
    });

    this.isActive = true;
  },
};

export { AppMetricaProvider };
