import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { productsClient } from "../../data/clients";
import { Product } from "../../data/types/entities";
import { PRODUCT_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";

const useGetProductByIdQuery = (id: string, options: UseQueryOptions<Product> = {}) => {
  // const queryClient = useQueryClient();

  return useQuery<Product, any>(
    ["products", id],
    async () => {
      if (!id) {
        throw new Error("useGetProductByIdQuery: invalid id");
      }
      return await productsClient.getProductById(id);
    },
    {
      cacheTime: PRODUCT_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: PRODUCT_QUERY_CACHE_EXPIRE_TIME_MS,
      // Commented due to some differents between detail products and list products in IrsKom API
      // initialData: () => {
      //   const data = queryClient.getQueryData<Product[]>(["products"]);
      //   return data?.find((product) => String(product.id) === id);
      // },
      ...options,
    }
  );
};

export default useGetProductByIdQuery;
