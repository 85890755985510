import React from "react";

// Import Ionic
import { IonIcon, IonLabel } from "@ionic/react";

// Import Icons
import { star } from "ionicons/icons";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ProductDetailRating.module.scss";

interface IProductDetailRating {
  rating?: string;
}

const cx = classNames.bind(styles);

const ProductDetailRating: React.FC<IProductDetailRating> = ({ rating = "0,0" }) => {
  return (
    <IonLabel className={cx("ratingLabel")}>
      <IonIcon className={cx("ratingIcon")} icon={star} />
      {rating}
    </IonLabel>
  );
};

export default ProductDetailRating;
