import React, { createContext, useReducer } from 'react';
import { c_reducers, AppState, initialState } from './state';

export interface AppContextState {
  state: AppState;
  dispatch: React.Dispatch<any>;
}

export const AppContext = createContext<AppContextState>({
  state: initialState,
  dispatch: () => undefined
});

type storeType = {
  isReady: boolean,
  dispatch: (v: any) => void
}

export const store: storeType = {
  isReady: false,
  dispatch: () => new Error("Dispatch is not ready"),
};

export const AppContextProvider: React.FC = (props => {

  const [state, dispatch] = useReducer(c_reducers, initialState);

  if(!store.isReady) {
    store.isReady = true;
    store.dispatch = (params: any) => dispatch(params);
  }

  return (
    <AppContext.Provider value={{
      state: state,
      dispatch
    }}>
      {props.children}
    </AppContext.Provider>
  )
});