import WooComAPI from "../../WooComAPI/woo-com-api";
import $ from "jquery";
import { isPlatform } from "@ionic/react";
import { Preferences } from "@capacitor/preferences";
import { SplashScreen } from "@capacitor/splash-screen";

export const initialState = {
  wishListProductsTemp: [] as any[], // For WishList Products
  recentViewedProductsTemp: [] as any[], // For RecentlyViewed Products
  cartProductsTemp: [] as any, // For Cart Products
  cartQuantityTemp: 0, // For Cart Products Quantity
  singleProductPageDataTemp: [] as any[], // For Single Product Data
  customerDataTemp: {} as any, // For Customer Data
  storePageDataTemp: [], // For Featured Vendors Data
  billing: {
    // For Billing Address
    first_name: "",
    last_name: "",
    company: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    email: "",
    phone: "",
  },
  billingCountryName: "",
  billingStateName: "",
  shipping: {
    // For Shipping Address
    first_name: "",
    last_name: "",
    company: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
  },
  shippingCountryName: "",
  shippingStateName: "",
  device: "",
  couponArrayTemp: [] as any,
  headerHexColor: "#51688F",
  checkOutPageText: "Place Your Order",
  appTheme: "default",
  darkMode: false,
  tempHomePage: 3,
  splashScreenHide: false,
  myOrders: [] as any[],
};

export const reducer2 = (state: any, action: any) => {
  // For Add WishList Products
  if (action.type === "ADD_WISHLIST_PRODUCTS") {
    // For With Local Storage
    if (action.localStorage === true) {
      initialState.wishListProductsTemp = action.wishListProducts;
      return {
        ...state,
        wishListProducts: action.wishListProducts,
      };
    }
    // For Without Local Storage
    else {
      addWishList(action.wishListProducts);
      return {
        ...state,
        wishListProducts: initialState.wishListProductsTemp,
        // ...state, wishListProducts: [...state.wishListProducts, action.wishListProducts]
      };
    }
  }
  // For Remove WishList Products
  if (action.type === "REMOVE_WISHLIST_PRODUCTS") {
    removeWishList(action.wishListProducts);
    return {
      ...state,
      wishListProducts: initialState.wishListProductsTemp,
    };
  }
  // For Remove Customer Data
  if (action.type === "REMOVE_CUSTOMER_DATA") {
    logOut();
    return {
      ...state,
      customerData: {},
      myOrders: [],
    };
  }

  // For App Theme Tick Mark Change
  if (action.type === "APP_THEME") {
    return {
      ...state,
      appTheme: action.appTheme,
    };
  }

  return state;
};

// For Add WishList array products
const addWishList = (item: any) => {
  initialState.wishListProductsTemp.push(item);
  Preferences.set({ key: "wishListProducts", value: JSON.stringify(initialState.wishListProductsTemp) });
  // this.toast("Added To Wish List!");
};

// For Removing WishList array products
const removeWishList = (item: any) => {
  initialState.wishListProductsTemp.forEach((value, index) => {
    if (value.id == item.id) {
      initialState.wishListProductsTemp.splice(index, 1);
      Preferences.set({ key: "wishListProducts", value: JSON.stringify(initialState.wishListProductsTemp) });
    }
  });
  // this.toast("Added To Wish List!");
};

// For Customer Logout
const logOut = () => {
  WooComAPI.setAccessToken("");
  Preferences.set({ key: "accessToken", value: "" }); // #TODO: new authContext

  initialState.customerDataTemp = {};
  Preferences.set({ key: "customerData", value: JSON.stringify(initialState.customerDataTemp) }); // #TODO: new authContext

  resetData();
  // this.fb.logout();
};

//For Reset Data
const resetData = () => {
  initialState.billing = {
    first_name: "",
    last_name: "",
    company: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
    email: "",
    phone: "",
  };
  initialState.billingCountryName = "";
  initialState.billingStateName = "";
  initialState.shipping = {
    first_name: "",
    last_name: "",
    company: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    country: "",
  };
  initialState.shippingCountryName = "";
  initialState.shippingStateName = "";
};

// --------------------------------------------------- FOR Currency Transform -----------------------------------------------------------------
export const currencyTransform = (value: any) => {
  let currency: any = $("<textarea />").html(localStorage.currency).text();
  let position = localStorage.currencyPos;
  let decimal = localStorage.decimals;
  let v = parseFloat(value).toFixed(decimal);
  if (v.toString() == "NaN") {
    if (position == "left") {
      return currency + "" + value;
    } else return value + " " + currency;
  } else {
    if (position == "left") {
      return currency + "" + v;
    } else return v + " " + currency;
  }
};

// --------------------------------------------------- FOR Hide Splash Screen -----------------------------------------------------------------
export const hideSplashScreen = () => {
  if (isPlatform("cordova")) {
    setTimeout(() => {
      if (!initialState.splashScreenHide) {
        SplashScreen.hide();
        initialState.splashScreenHide = true;
      }
    }, 2000);
  }
};
export default reducer2;
