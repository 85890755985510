import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { AuthContext, initialState } from "./auth-context";
import { authReducer } from "./reducer";
import { queryClient } from "../../config/query-client";
import { LocalStorageOperations } from "../../data/storage/LocalStorageOperations";
import { User } from "../../data/types/entities";
import { authClient } from "../../data/clients";
import { ActionType } from "./types";
import { history } from "../../providers/Redux/Reducers/history";
import WooComAPI from "../../providers/WooComAPI/woo-com-api";
import { client } from "../../apollo";
import { useOrderState } from "../../providers/orderDataStore";

type authContextInstanceType = {
  isReady: boolean;
  dispatch: (v: ActionType) => void;
  logout: () => void;
};

export const authContextInstance: authContextInstanceType = {
  isReady: false,
  dispatch: () => new Error("Dispatch is not ready"),
  logout: () => {},
};

const AuthProvider = (props: any) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const login = useCallback(
    async (user: User) => {
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
      authClient.setToken(user.token);
      LocalStorageOperations.saveUserInfo(user);
      WooComAPI.setAccessToken(user.token);
    },
    [dispatch]
  );

  const updateUserData = useCallback((user: Partial<User>) => {
    dispatch({
      type: "UPDATE_USER_DATA",
      payload: {
        user,
      },
    });

    LocalStorageOperations.updateUserInfo(user || {});
  }, []);

  const logout = useCallback(
    async (revokeAccessToken = false) => {
      dispatch({
        type: "LOGOUT",
      });

      // Clearing cache so that one authorized user
      // will not see data from previously authorized user
      queryClient.clear();
      client.clearStore();
      useOrderState.getState().reset();
      authClient.setToken("");
      LocalStorageOperations.removeUserInfo();
      WooComAPI.setAccessToken("");
      history.replace("/auth");
    },
    [dispatch]
  );

  useEffect(() => {
    LocalStorageOperations.getUserData().then((user) => {
      if (user) {
        login(user);
      }
    });
  }, []);

  const value = useMemo(() => {
    return {
      ...state,
      login,
      logout,
      updateUserData,
    };
  }, [state, login, logout, updateUserData]);

  if (!authContextInstance.isReady) {
    authContextInstance.isReady = true;
    authContextInstance.dispatch = (params: ActionType) => dispatch(params);
    authContextInstance.logout = logout;
  }
  // useWhatChanged([value], 'value');
  return <AuthContext.Provider value={value} {...props} />;
};
const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
