import { useMutation } from "@tanstack/react-query";
import { ordersClient } from "../../data/clients";
import { CreateOrderData, TOrder } from "../../data/types/entities";
import { queryClient } from "../../config/query-client";

const useCreateOrderMutation = () => {
  return useMutation<Partial<TOrder>, any, CreateOrderData>(
    ["order", "create"],
    async (orderData: CreateOrderData) => {
      return ordersClient.createOrder(orderData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["orders"] });
      },
    }
  );
};

export default useCreateOrderMutation;
