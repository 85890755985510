import { CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { UseQueryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { categoriesClient } from "../../data/clients";
import { Category } from "../../data/types/entities";
import { CategoriesMapObj } from "../../data/types/entities";

const useGetSubCategoriesQuery = (id: string, options: UseQueryOptions<Category[]> = {}) => {
  const queryClient = useQueryClient();

  return useQuery<Category[], any>(
    ["subCategories", id],
    async () => {
      return await categoriesClient.getSubCategories(id);
    },
    {
      cacheTime: CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS,
      initialData: () => {
        return queryClient
          .getQueryData<CategoriesMapObj>(["all-categories"])
          ?.subCategories.filter((cat) => String(cat.parent) === id);
      },
      ...options,
    }
  );
};

export default useGetSubCategoriesQuery;
