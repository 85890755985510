import axios from "axios";
import { PAxiosInstance } from "../base-client";
import { authContextInstance } from "../../../contexts/auth-context/AuthContextProvider";

const headers = {
  "Content-Type": "application/json",
};

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_IRSKOM_URL!,
  headers,
}) as PAxiosInstance;

httpClient.interceptors.request.use((config) => {
  if (httpClient.token) {
    config.headers!.Authorization = `Bearer ${httpClient.token}`;
  }
  return config;
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      //here we have a type guard check, error inside this if will be treated as AxiosError
      const response = error?.response;
      if (response) {
        //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
        const statusCode = response?.status;
        if (statusCode === 403 || statusCode === 401) {
          authContextInstance.logout();
        }
      }
    }

    return error;
  }
);
