// Import React Libraries
import React, {  useEffect, useState } from 'react';

// Import Ionic Components
import {
  IonItem,
  IonTextarea,
  IonButton,
  IonInput,
  useIonToast,
  useIonLoading
} from '@ionic/react';


// Import styles
import classNames from 'classnames/bind';
import styles from './SendUserRequestFormModal.module.scss'
import { TextareaChangeEventDetail } from '@ionic/core/components';
import { CloudfunctionsAPI } from '../../providers/CloudfunctionsAPI/CloudfunctionsAPI';
import { CustomPhoneInput } from '../CustomPhoneInput/CustomPhoneInput';

// Import Components
import CommonModal from "../UI/CommonModal/CommonModal";
import { isEmailValid } from '../../utils/utils';
import { useAuth } from '../../contexts/auth-context';


const cx = classNames.bind(styles);

const SendUserRequestFormModal: React.FC<{dismissModal: () => void, state: any}> = ({
  dismissModal,
  state
}) => {


  const ts = state.config.languageJson;
  const { user } = useAuth();
  const [ request, setRequest ] = useState("");
  const [ userEmail, setUserEmail ] = useState("");
  const [ phoneNumber, setPhoneNumber ] = useState("");
  
  const [ isPhoneNumberInputDisabled, setIsPhoneNumberInputDisabled ] = useState(false);
  const [ isUserEmailInputDisabled, setIsUserEmailInputDisabled ] = useState(false);
  const [ isPhoneNumberInputValid, setIsPhoneNumberInputValid ] = useState(false);
  
  const [ presentToast ] = useIonToast();
  const [ presentLoading, dismissLoading ] = useIonLoading();

  useEffect(() => {
    if(!!user?.email) {
      setUserEmail(user.email);
      setIsUserEmailInputDisabled(true);
    }

    if(!!user?.phone) {
      setPhoneNumber(user.phone);
      setIsPhoneNumberInputDisabled(true);
      setIsPhoneNumberInputValid(true);
    }
  }, []);

  function isButtonDisabled() {
    return request.length === 0 || (!!userEmail && !isEmailValid(userEmail)) || (!!phoneNumber && !isPhoneNumberInputValid);
  }

  function handleSendButtonClick() {

    presentLoading(ts["Please wait..."]);

    const data = {
      email: userEmail,
      phone: phoneNumber,
      request: request
    }

    
    CloudfunctionsAPI.postUserRequest(data)
    .then(res => {
      if (res.ok) {
        presentToast({
          message: ts["Message Send Successfully"],
          color: "success",
          duration: 2000,
        });

        dismissModal();
      }
      else {
        presentToast({
          message: ts["An unexpected error occurred"],
          color: "danger",
          duration: 2000,
        });
      }
    })
    .catch(() => {
      presentToast({
        message: ts["An unexpected error occurred"],
        color: "danger",
        duration: 2000,
      });
    })
    .finally(() => {
      dismissLoading();
    });
  }

  function handlePhoneInput(val: string, isValid: boolean) {
    setPhoneNumber(val);
    setIsPhoneNumberInputValid(isValid);
  }

  return <>
    <CommonModal
      title={ts["Didn't find the product you were looking for? Write to us"]}
      onDismiss={dismissModal}
    >
      <>
        <form>
          <div className={"commonModalSupTitle"}>{ts["Message"]}</div>
          <IonItem className={cx("SendUserRequestForm_descriptionItem", "ion-no-padding ion-margin-start ion-margin-end")} lines="none">
            <IonTextarea
              className={cx("SendUserRequestForm_description")}
              rows={10}
              value={request}
              placeholder={ts['Enter the names of the products that you did not find'] + "..."}
              name="desc"
              required
              onIonChange={(e: CustomEvent<TextareaChangeEventDetail>) => setRequest(e.detail.value!)}
            />
          </IonItem>

          <div className={"commonModalSupTitle"}>{`${ts["Email"]}(${ts["optional"]})`}</div>
          <IonItem
            data-invalid-text={ts['Please enter a valid e-mail']}
            className={cx(
              "SendUserRequestForm_descriptionItem",
              "ion-no-padding ion-margin-start ion-margin-end",
              {"SendUserRequestForm_descriptionItem__required": (userEmail && !isEmailValid(userEmail))}
            )}
            disabled={isUserEmailInputDisabled}
            lines="none"
          >
            <IonInput
              className={cx("SendUserRequestForm_description")}
              value={userEmail}
              placeholder={ts['Email placeholder']}
              name="email"
              onIonChange={(e: CustomEvent<TextareaChangeEventDetail>) => setUserEmail(e.detail.value!)}
            />
          </IonItem>

          <div className={"commonModalSupTitle"}>{`${ts["Phone"]}(${ts["optional"]})`}</div>
          <IonItem
            data-invalid-text={ts['Please enter a valid phone number']}
            className={cx(
              "SendUserRequestForm_descriptionItem",
              "ion-no-padding ion-margin-start ion-margin-end",
              {"SendUserRequestForm_descriptionItem__required": phoneNumber && !isPhoneNumberInputValid}
            )}
            lines="none"
            disabled={isPhoneNumberInputDisabled}
          >
            <div className={cx("SendUserRequestForm_description", "SendUserRequestForm_maskedInputWrapper")}>
              <CustomPhoneInput
                value={phoneNumber}
                onCustomChange={handlePhoneInput}
              />
            </div>

          </IonItem>

          <IonButton
            className={cx("SendUserRequestForm_button", "ion-no-margin", 'ion-no-padding')}
            expand="block"
            onClick={handleSendButtonClick}
            disabled={isButtonDisabled()}
          >
            {ts['Send']}
          </IonButton>
        </form>
      </>
    </CommonModal>
  </>
}

export default SendUserRequestFormModal;