import BaseClient from "../base-client";
import { ParhatoOrder } from "./types";
import {
  convertCreateOrderToParhato,
  convertParhatoOrderToBaseOrder,
} from "./utils";
import {
  CreateOrderData,
  CreatePaymentPayload,
  PaymentFormData,
  ReviewSet,
  TOrder,
} from "../../types/entities";
import { IOrdersClient } from "../../types/clients";
import {
  AddReviewOrderDocument,
  AddReviewOrderMutation,
  AddReviewOrderMutationVariables,
  ReviewsSetOrderByIdDocument,
  ReviewsSetOrderByIdQuery,
  ReviewsSetOrderByIdQueryVariables,
} from "../../../generated/graphql";
import { client } from "../../../apollo";

type ORDERS_PARAMS = {
  page: number;
  customer: string;
};

class OrdersClient extends BaseClient implements IOrdersClient {
  async getOrders(params: ORDERS_PARAMS): Promise<TOrder[]> {
    const response = await this.instance.get<ParhatoOrder[]>(
      "wp-json/wc/v3/orders/",
      {
      params: { ...params },
    },
    );

    return response.data.map(convertParhatoOrderToBaseOrder);
  }
  async getOrderById(id: string): Promise<TOrder> {
    const response = await this.instance.get<ParhatoOrder>(
      `/wp-json/wc/v3/orders/${id}`,
    );

    return convertParhatoOrderToBaseOrder(response.data);
  }

  async createOrder(orderData: CreateOrderData) {
    const parhatoOrder = convertCreateOrderToParhato(orderData);

    await this.instance.post<unknown>(
      "wp-json/parhato-api/v1/orders",
      parhatoOrder,
    );

    // return convertParhatoOrderToBaseOrder(response.data);
    return { ...orderData };
  }

  async createPayment(payload: CreatePaymentPayload): Promise<PaymentFormData> {
    throw new Error("createPayment not implemented");
  }

  async getReviewSetOrderById(orderId: string): Promise<ReviewSet> {
    const reviewSet: ReviewSet = await client
      .query<ReviewsSetOrderByIdQuery, ReviewsSetOrderByIdQueryVariables>({
        query: ReviewsSetOrderByIdDocument,
        variables: {
          id: orderId,
        },
      })
      .then((res) => {
        return {
          orderNumber: res.data.customerOrderById.orderNumber,
          reviewSet: res.data.customerOrderById.reviewSet.edges?.[0]?.node,
        };
      });

    return reviewSet;
  }

  async addReviewOrder(orderReqData: AddReviewOrderMutationVariables): Promise<any> {
    const res = await client.mutate<AddReviewOrderMutation, AddReviewOrderMutationVariables>({
      mutation: AddReviewOrderDocument,
      variables: {
        reviewData: orderReqData.reviewData,
        orderNumber: orderReqData.orderNumber,
      },
    });

    return res;
  }
}

export default OrdersClient;
