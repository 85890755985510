import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

// Import Ionic
import { IonCol, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonRow } from "@ionic/react";

// Import Components
import MaxWidthContainer from "../UI/MaxWidthContainer/MaxWidthContainer";

// Import Style
import classNames from "classnames/bind";
import styles from "./Footer.module.scss";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { useGetSettingsQuery } from "../../providers/settings";
import { applyRegExOnPhoneNumber } from "../../providers/utils/utils";

const cx = classNames.bind(styles);

const Footer: React.FC = () => {
  const history = useHistory();
  const { state } = useContext(AppContext);
  const { data: appSettings, isLoading, isError } = useGetSettingsQuery();

  const goToPage = (path: string) => {
    history.push(path);
  };

  if (isLoading || isError) {
    return <></>;
  }

  const { homePageCategories: { featured, latest, sale, customersChoice } = {} } = appSettings;

  return (
    <footer className={cx("footer", "ion-hide-md-down")}>
      <div className={cx("footerContainer")}>
        <MaxWidthContainer>
          <IonRow className={cx("footerTop", { "margin-bottom": appSettings?.isContentInFooterShown })}>
            <IonCol>
              <IonList lines={"none"} className={cx("footerList")}>
                <IonListHeader className={cx("footerListHeader")}>
                  <IonLabel>Покупателям</IonLabel>
                </IonListHeader>
                {customersChoice?.isEnabled && (
                  <IonItem>
                    <IonLabel>
                      <span
                        onClick={() => {
                          goToPage("/products/0/0/popular");
                        }}
                        className={cx("footerLink")}
                      >
                        {state.config.languageJson[`${customersChoice.title}`]}
                      </span>
                    </IonLabel>
                  </IonItem>
                )}

                {latest?.isEnabled && (
                  <IonItem>
                    <IonLabel>
                      <span
                        onClick={() => {
                          goToPage("/products/0/0/latest");
                        }}
                        className={cx("footerLink")}
                      >
                        {state.config.languageJson[`${latest.title}`]}
                      </span>
                    </IonLabel>
                  </IonItem>
                )}

                {featured?.isEnabled && (
                  <IonItem>
                    <IonLabel>
                      <span
                        onClick={() => {
                          goToPage("/products/0/0/featured");
                        }}
                        className={cx("footerLink")}
                      >
                        {state.config.languageJson[`${featured.title}`]}
                      </span>
                    </IonLabel>
                  </IonItem>
                )}

                {sale?.isEnabled && (
                  <IonItem>
                    <IonLabel>
                      <span
                        onClick={() => {
                          goToPage("/products/0/0/sale");
                        }}
                        className={cx("footerLink")}
                      >
                        {state.config.languageJson[`${sale.title}`]}
                      </span>
                    </IonLabel>
                  </IonItem>
                )}
              </IonList>
            </IonCol>
            <IonCol>
              <IonList lines={"none"} className={cx("footerList")}>
                <IonListHeader className={cx("footerListHeader")}>
                  <IonLabel>О нас</IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonLabel>
                    <span onClick={() => goToPage("/contact-us")} className={cx("footerLink")}>
                      {state.config.languageJson["Contacts"]}
                    </span>
                  </IonLabel>
                </IonItem>
              </IonList>
            </IonCol>
            <IonCol className={cx("footerContacts")}>
              <a
                href={`tel:+${applyRegExOnPhoneNumber(appSettings?.companyPhoneNumber || "")}`}
                className={cx("footerContactsPhone")}
              >
                {appSettings?.companyPhoneNumber}
              </a>
              <p className={cx("footerContactsTitle")}>Заказывайте товары круглосуточно</p>
              <div className={cx("footerMedia")}>
                <div className={cx("footerMediaItem")}>
                  <IonImg />
                </div>
                <div className={cx("footerMediaItem")}>
                  <IonImg />
                </div>
                <div className={cx("footerMediaItem")}>
                  <IonImg />
                </div>
              </div>
            </IonCol>
          </IonRow>
          {appSettings?.isContentInFooterShown && (
            <IonRow className={cx("footerBottom", "ion-align-items-start")}>
              <IonCol size={"6"}>
                © parhato.ru -
                <span
                  onClick={() => {
                    goToPage("/privacy-policy");
                  }}
                  className={cx("footerBottomLink")}
                >
                  это доставка продуктов питания, бытовой химии и текстиля.
                </span>
                Все права защищены.
              </IonCol>
              <IonCol size={"6"}>
                <span>Есть вопрос? Напишите нам письмо или воспользуйтесь кнопкой обратной связи.</span>
              </IonCol>
            </IonRow>
          )}
        </MaxWidthContainer>
      </div>
    </footer>
  );
};

export default Footer;
