import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
// Import providers
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

// Import Ionic
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  useIonModal,
} from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./my-order-detail.module.scss";

// Import custom components
import { MyOrderProduct } from "../../components/MyOrderProduct/MyOrderProduct";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../../components/Footer/Footer";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import { useGetOrderByIdQuery } from "../../providers/Orders";
import { reload, starHalf, starSharp } from "ionicons/icons";
import { useCartData } from "../../providers/cartDataStore";
import { useGetIncludeProductsQuery } from "../../providers/products";
import { useGetSettingsQuery } from "../../providers/settings";
import OrderReview, { OrderReviewProps } from "../../components/OrderReview";
import useReviewSetOrderById from "../../providers/Orders/useGetReviewSerOrderById";

const cx = classNames.bind(styles);

const MyOrderDetail: React.FC = (props: any) => {
  const { state } = useContext(AppContext);

  const history = useHistory();

  const ts = useMemo(() => state.config.languageJson, []);

  const { data: order, isLoading: loadingOrderDetail } = useGetOrderByIdQuery(props.match.params.id);

  const { data: orderReviewSet, isLoading: loadingOrderReviewSet, isRefetching } = useReviewSetOrderById(String(order?.id), {
    enabled: !!order?.id,

  })

  const { data: appSettings } = useGetSettingsQuery();

  const { orderDetailPage } = appSettings ?? {};

  const { data: products } = useGetIncludeProductsQuery({
    options: {
      enabled: !!order?.lineItems?.length,
    },
    include: order?.lineItems?.map((product) => product.productId + "")!,
  });

  const { repeatOrder } = useCartData();

  const [presentModalRateOrder, dismissModalOrderReview] = useIonModal(OrderReview, {
    orderNumber: String(order?.id),
    onDismiss: () => dismissModalOrderReview(),
  } as OrderReviewProps);

  const getSingleProductDetail = (id: number | string) => {
    history.push("/product-detail/" + id);
  };

  const addCurrency = (v: any) => {
    if (Number.isNaN(v)) {
      return;
    }
    return v + " " + (order?.currencySymbol ?? "₽");
  };

  const handleRepeatOrder = () => {
    if (!products?.length || !order || !order.lineItems || !order.lineItems.length) {
      return;
    };

    repeatOrder(products, order.lineItems);
    history.push('/cart');
  };

  const handleShowOrderReview = () => {
    presentModalRateOrder();
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton showSearchButton />
      </IonHeader>
      <IonContent>
        <ToolbarDesktop />
        <IonLoading isOpen={loadingOrderDetail && loadingOrderReviewSet && isRefetching} message={ts["Please wait..."]} duration={10000} />
        <div className={`${cx("my-order-detail")} global-content`}>
          {!loadingOrderDetail && (
            <MaxWidthContainer maxWidthBreakpoint={"Md"}>
              <IonLoading isOpen={loadingOrderDetail && loadingOrderReviewSet && isRefetching} message={ts["Please wait..."]} duration={20000} />

              <IonCard className={cx("order-details")}>
                <IonCardHeader className={cx("order-details-header")} color="primary">
                  <IonCardTitle className={cx("order-details-title")}>{ts["Order Detail"]}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent className={cx("order-details-content", "ion-padding ion-margin-top", (orderDetailPage?.repeatOrderEnabled || orderDetailPage?.reviewOrderEnabled) && 'mt-0')}>
                  {(orderDetailPage?.repeatOrderEnabled || orderDetailPage?.reviewOrderEnabled) && (
                    <div className="flex items-center h-full gap-2 text-sm py-1 px-[22px]">
                      {orderDetailPage?.repeatOrderEnabled && (
                        <div
                          onClick={handleRepeatOrder}
                          className={cx('repeat', "flex items-center cursor-pointer rounded-xl p-2 hover:underline active:underline")}
                        >
                          <IonIcon icon={reload} className={cx('repeatIcon', "text-")} />
                          <div className="ml-2 text-black">
                            {ts["Repeat order"]}
                          </div>
                        </div>
                      )}
                      {order?.status === 'completed' && orderDetailPage?.reviewOrderEnabled && !orderReviewSet?.reviewSet ? (
                        <div
                          onClick={handleShowOrderReview}
                          className={cx('rate', "rounded-xl flex items-center cursor-pointer p-2 hover:underline active:underline")}
                          color="primary"
                        >
                          <IonIcon icon={starHalf} className={cx('rateIcon')} />
                          <div className="ml-2 text-black">
                            {ts["Order review"]}
                          </div>
                        </div>
                      ) : (
                        <div className={cx('rate', "flex items-center")}>
                          <div >{ts['Your Rating']}: {orderReviewSet?.reviewSet?.productQuality}</div>
                          <IonIcon icon={starSharp} className={cx('rateIcon', 'ml-2 text-red-600')} />
                        </div>
                      )}
                    </div>
                  )}
                  <IonCardSubtitle className={cx("order-details-subtitle")}>{ts["Shipping Address"]}</IonCardSubtitle>
                  <p className={cx("order-details-description")}>
                    {!order?.address?.house ? (
                      <>
                        <span>{`${ts["Address"]}: `}</span>
                        {order?.address?.fullAddress}
                        <br />
                      </>
                    ) : (
                      <>
                        <span>{`${ts["City"]}: `}</span>
                        {order?.address.city}
                        <br />
                        <span>{`${ts["Street"]}: `}</span>
                        {order?.address.street}
                        <br />
                        <span>{`${ts["House number"]}: `}</span>
                        {order?.address.house}
                        <br />
                        {order?.address.isFlat && (
                          <>
                            <span>{`${ts["Entrance"]}: `}</span>
                            {order?.address.entrance}
                            <br />
                            {!!order?.address.intercom && (
                              <>
                                <span>{`${ts["Intercom code"]}: `}</span>
                                {order?.address.intercom}
                                <br />
                              </>
                            )}
                            <span>{`${ts["Floor"]}: `}</span>
                            {order?.address.floor}
                            <br />
                            <span>{`${ts["Apartment number"]}: `}</span>
                            {order?.address.apartmentNumber}
                          </>
                        )}
                      </>
                    )}
                  </p>

                  {order?.deliveryTime && order.deliveryDate && (
                    <>
                      <IonCardSubtitle className={cx("order-details-subtitle")}>
                        {ts["Delivery date and interval"]}
                      </IonCardSubtitle>
                      <p className={cx("order-details-description")}>
                        <span>Дата: </span>
                        {order?.deliveryDate}
                        <br />
                        <span>Время: </span>
                        {order?.deliveryTime}
                      </p>
                    </>
                  )}
                  {order?.shippingLine?.methodTitle && (
                    <>
                      <IonCardSubtitle className={cx("order-details-subtitle")}>
                        {ts["Shipping Method"]}
                      </IonCardSubtitle>
                      <p className={cx("order-details-description")}>{order.shippingLine.methodTitle}</p>
                    </>
                  )}

                  {order?.paymentMethod?.title && (
                    <>
                      <IonCardSubtitle className={cx("order-details-subtitle")}>{ts["Payment Method"]}</IonCardSubtitle>
                      <p className={cx("order-details-description")}>{order.paymentMethod.title}</p>
                    </>
                  )}
                </IonCardContent>
              </IonCard>
              <IonCard className={cx("products-wrapper")}>
                <IonCardHeader className={cx("order-details-header")} color="primary">
                  <IonCardTitle className={cx("order-details-title")}>{ts.Products}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent className={cx("order-details-content")}>
                  {order?.lineItems !== undefined &&
                    order.lineItems.map((item) => (
                      <MyOrderProduct
                        onClick={() => item.id && getSingleProductDetail(item.id)}
                        key={item.id}
                        item={item}
                        currencySymbol={order.currencySymbol ?? "₽"}
                      />
                    ))}
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader color="primary" className={cx("order-details-header")}>
                  <IonCardTitle className={cx("order-details-title")}>{ts.Total}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent className={cx("ion-padding ion-margin-top", "order-details-description")}>
                  {addCurrency(+order?.total! + +order?.discountTotal!) && (
                    <IonRow>
                      <IonCol size="6">{ts["Products Price"]}</IonCol>
                      <IonCol className="ion-text-right" size="6">
                        {addCurrency(+order?.total! + +order?.discountTotal!)}
                      </IonCol>
                    </IonRow>
                  )}

                  {addCurrency(+order?.shippingTotal!) && order?.shippingLine !== undefined && (
                    <IonRow>
                      <IonCol size="6">{ts["Shipping Cost"]}</IonCol>
                      <IonCol className="ion-text-right" size="6">
                        {addCurrency(+order?.shippingTotal!)}
                      </IonCol>
                    </IonRow>
                  )}
                  {addCurrency(+order?.discountTotal!) && (
                    <IonRow>
                      <IonCol size="6">{ts.Discount}</IonCol>
                      <IonCol className="ion-text-right" size="6">
                        {addCurrency(+order?.discountTotal!)}
                      </IonCol>
                    </IonRow>
                  )}

                  <IonRow>
                    <IonCol size="6">{ts.Total}</IonCol>
                    <IonCol className="ion-text-right" size="6">
                      <strong>{addCurrency(+order?.total!)}</strong>
                    </IonCol>
                  </IonRow>

                  {order?.driverRating && (
                    <IonRow>
                      <IonCol size="6">{ts["Your rating for the driver"]}</IonCol>
                      <IonCol className="ion-text-right" size="6">
                        <strong>{order.driverRating?.rating}</strong>
                      </IonCol>
                    </IonRow>
                  )}
                </IonCardContent>
              </IonCard>
              {order?.customerNote && (
                <IonCard>
                  <IonCardHeader color="primary" className={cx("order-details-header")}>
                    <IonCardTitle className={cx("order-details-title")}>{ts["Order Notes"]}</IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent
                    className={cx("ion-no-padding ion-margin-top", "order-details-description", "order-details-note")}
                  >
                    <p className={cx("order-details-description")}>{order.customerNote}</p>
                  </IonCardContent>
                </IonCard>
              )}
              {
                // order !== undefined || order?.meta_data !== undefined && (
                //   getTrackingId() !== '' && (
                //     <IonCard>
                //       <IonCardHeader className="ion-padding">
                //         <IonCardTitle>
                //           {ts['Track Order']}
                //         </IonCardTitle>
                //       </IonCardHeader>
                //       <IonCardContent className="ion-padding ion-margin-top">
                //         <IonRow>
                //           <IonCol size="6">
                //             {getTrackingId()}
                //           </IonCol>
                //           <IonCol size="6" class="ion-text-right">
                //             <IonButton color="secondary" onClick={() => openTrackingPage()}> {ts.Track}
                //               <IonIcon icon={locate}></IonIcon>
                //             </IonButton>
                //           </IonCol>
                //         </IonRow>
                //       </IonCardContent>
                //     </IonCard>
                //   )
                // )
              }
              {
                // (order?.status != 'cancelled' && order?.status != 'completed' && order?.status != 'refunded' && order?.status != 'failed' && order?.status != 'processing') && (
                //   state.config.orderCancelButton && (
                //     <IonButton color="danger" expand="block" onClick={() => cancelOrder()}>
                //       {ts['Cancel order']}
                //     </IonButton>
                //   )
                // )
              }
            </MaxWidthContainer>
          )}
        </div>
        <Footer />
      </IonContent>
    </IonPage >
  );
};

export default MyOrderDetail;
