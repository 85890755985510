import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useCustomerAddressesData } from "./CustomerAddressesHooks";
import {
  CustomerAddressesDocument,
  CustomerAddressInput,
  useCreateCustomerAddressMutation,
  useDeleteCustomerAddressMutation,
  useUpdateCustomerAddressMutation,
} from "../../generated/graphql";
import { CustomerAddress } from "./CustomerAddressesTypes";
import { ID } from "../../types";
import { ApolloError } from "@apollo/client";
import { useAuth } from "../../contexts/auth-context";

const CustomerAddressesDataContext = createContext<{
  loading: boolean;
  customerAddresses: CustomerAddress[];
  customerAddressesError: ApolloError | undefined;
  createCustomerAddress: (address: CustomerAddressInput) => void;
  updateCustomerAddress: (addressId: ID, address: CustomerAddressInput) => void;
  deleteCustomerAddress: (addressId: ID) => void;
  refetchCustomerAddresses: () => void;
}>(undefined!);

export const CustomerAddressesDataProvider = ({ children }: { children: React.ReactChild }) => {
  const {
    loading: loadingAddresses,
    customerAddresses,
    getCustomerAddressesData,
    error: customerAddressesError,
  } = useCustomerAddressesData();

  const { user } = useAuth();

  const [createCustomerAddressMutation, { loading: createCustomerAddressLoading }] = useCreateCustomerAddressMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: [CustomerAddressesDocument],
  });

  const [updateCustomerAddressMutation, { loading: updateCustomerAddressLoading }] = useUpdateCustomerAddressMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: [CustomerAddressesDocument],
  });

  const [deleteCustomerAddressMutation, { loading: deleteCustomerAddressLoading }] = useDeleteCustomerAddressMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: [CustomerAddressesDocument],
  });

  const loading = useMemo(() => {
    return (
      loadingAddresses || createCustomerAddressLoading || updateCustomerAddressLoading || deleteCustomerAddressLoading
    );
  }, [loadingAddresses]);

  useEffect(() => {
    if (user?.token) {
      getCustomerAddressesData();
    }
  }, [user]);

  const value = useMemo(() => {
    async function createCustomerAddress(address: CustomerAddressInput) {
      await createCustomerAddressMutation({
        variables: {
          address,
        },
      });
    }

    async function refetchCustomerAddresses() {
      getCustomerAddressesData();
    }

    async function updateCustomerAddress(addressId: ID, address: CustomerAddressInput) {
      await updateCustomerAddressMutation({
        variables: {
          address,
          addressId,
        },
      });
    }

    async function deleteCustomerAddress(addressId: ID) {
      await deleteCustomerAddressMutation({
        variables: {
          addressId,
        },
      });
    }

    return {
      loading,
      customerAddresses,
      refetchCustomerAddresses,
      customerAddressesError,
      createCustomerAddress,
      updateCustomerAddress,
      deleteCustomerAddress,
    };
  }, [
    loading,
    customerAddresses,
    getCustomerAddressesData,
    customerAddressesError,
    createCustomerAddressMutation,
    updateCustomerAddressMutation,
    deleteCustomerAddressMutation,
  ]);

  return <CustomerAddressesDataContext.Provider value={value}>{children}</CustomerAddressesDataContext.Provider>;
};

export const useCustomerAddresses = () => useContext(CustomerAddressesDataContext);
