import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Map, MapStateCenter, withYMaps } from "react-yandex-maps";
import { IonButton, IonCol, IonIcon, IonSpinner, isPlatform } from "@ionic/react";
import { Geolocation } from "@capacitor/geolocation";

import { useEffectExceptOnMount } from "../../hooks/useEffectExceptOnMount";
import { convertGeoObjToParhatoAddressObject, getFullAddress } from "../../utils/utils";
import { ParhatoAddressObject } from "../../providers/ParhatoRESTApi/ParhatoRESTApi";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { locate, arrowForwardOutline } from "ionicons/icons";
import { useCustomerAddressesStateData } from "../../providers/CustomerAddresses/CustomerAddressesStateContext";
import debounce from "lodash.debounce";

import placemarkOrange from "../../assets/icon/placemark-orange.svg";
import classnames from "classnames/bind";
import "./YandexMapGlobal.css";
import styles from "./YandexMap.module.scss";
import { LocationPromptAlert, LocationServiceEnableAlert } from "../UI/LocationAlerts/LocationAlerts";

const cx = classnames.bind(styles);

export const HEADQUARTERS = [43.315434, 45.68801];

interface IYandexMapProps {
  setUserDraggingMap: (bool: boolean) => void;
  userDraggingMap: boolean;
  setAddress: (addr: string) => void;
  showAddressForm: () => void;
  onSetCustomerAddress: (newAddress: Partial<ParhatoAddressObject>) => void;
  mapState: MapStateCenter;
  ymaps?: any;
}

const YandexMapComponent = ({
  setUserDraggingMap,
  userDraggingMap,
  ymaps,
  mapState,
  setAddress,
  onSetCustomerAddress,
  showAddressForm,
}: IYandexMapProps) => {
  const { state } = useContext(AppContext);
  const { customerAddress } = useCustomerAddressesStateData();

  const centerMarkerRef = useRef<HTMLImageElement>(null);

  const ts = useMemo(() => state.config.languageJson, [state.config.languageJson]);

  const [map, setMap] = useState<any>();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isLoadingCoords, setIsLoadingCoords] = useState<boolean>(false);
  const [isGeoCodingError, setIsGeoCodingError] = useState<boolean>(false);

  const [currentLocation, setCurrentLocation] = useState<number[] | null>(null);

  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);

  const [showLocationPrompt, setShowLocationPrompt] = useState<boolean>(false);
  const [showEnableLocationService, setShowEnableLocationService] = useState<boolean>(false);

  // Change map centering when address change
  useEffectExceptOnMount(() => {
    if (map && mapState.center && !userDraggingMap) {
      map.panTo(mapState.center, {
        duration: 800,
      });
      setUserDraggingMap(false);
    }
  }, [mapState?.center, userDraggingMap]);

  useEffect(() => {
    if (!customerAddress && isMapLoaded) {
      checkGeolocationServices();
    }
  }, [isMapLoaded]);

  const setMapPointer = async () => {
    const position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });

    const geoResponse = await ymaps.geocode([position.coords.latitude, position.coords.longitude]);
    const address = await convertGeoObjToParhatoAddressObject(geoResponse.geoObjects.get(0));

    setUserDraggingMap(false);
    onSetCustomerAddress(address);
    setAddress(getFullAddress(address) || "");
    setCurrentLocation([position.coords.latitude, position.coords.longitude]);
    setIsGeoCodingError(false);
  };

  const setMapPointerWithTimeout = () => {
    setMapPointer();
    setTimeout(() => {
      setMapPointer();
    }, 2000);
  };

  const askLocationPermissionForCapacitor = async () => {
    try {
      const checkPermissions = await Geolocation.checkPermissions();
      let locationStatus = checkPermissions.location;
      if (locationStatus === "granted") {
        // SET MAP POINTER IF LOCATION PERMISSION IS ALREADY GRANTED
        setMapPointerWithTimeout();
      } else if (locationStatus === "prompt" || locationStatus === "prompt-with-rationale") {
        await Geolocation.requestPermissions({ permissions: ["location"] });
        locationStatus = (await Geolocation.checkPermissions()).location;
        if (locationStatus === "granted") {
          setMapPointerWithTimeout();
        }
      } else {
        const locationPermission = await Geolocation.requestPermissions({ permissions: ["location"] });

        //  && locationPermission.coarseLocation === 'denied'
        if (locationPermission.location === "denied") {
          setShowLocationPrompt(true);
        }

        locationStatus = (await Geolocation.checkPermissions()).location;
        if (locationStatus === "granted") {
          setMapPointerWithTimeout();
        }
      }
    } catch (error: any) {
      error.message === "Location services are not enabled" && setShowEnableLocationService(true);
      setIsGeoCodingError(true);
    }
  };

  const askLocationPermissionForDesktop = async () => {
    // if (!navigator || !navigator.permissions || !navigator.geolocation) throw new Error('Something went wrong!');
    if (!navigator || !navigator.geolocation) {
      setShowLocationPrompt(true);
      return;
    }

    if (!navigator.permissions) {
      // SAFARI BELOW VERSION 16 DOESN'T SUPPORT THE navigation.permissions
      await navigator.geolocation.getCurrentPosition(
        () => {
          setShowLocationPrompt(false);
          setMapPointerWithTimeout();
        },
        () => {
          setShowLocationPrompt(true);
        }
      );

      return;
    }

    //ASK PERMISSIONS FROM BROWSER
    const locationStatus = await navigator.permissions.query({ name: "geolocation" }).then((result) => result.state);

    // THE CODE BELOW SHOULD BE MOVED INTO A SEPARATE FUNCTION BECAUSE ITS NOT A PERMISSIONS REQUEST
    if (locationStatus === "granted") {
      setMapPointerWithTimeout();
    } else if (locationStatus === "prompt") {
      setShowLocationPrompt(true);

      await navigator.geolocation.getCurrentPosition(() => {
        // CALL BACK WHEN THE USER HAS ALLOWED GEOLOCATION
        setShowLocationPrompt(false);
        setMapPointerWithTimeout();
      });
    } else {
      setShowLocationPrompt(true);
    }
  };

  const handleGeolocationBtn = () => {
    checkGeolocationServices();
  };

  const checkGeolocationServices = async () => {
    try {
      if (isPlatform("capacitor")) {
        askLocationPermissionForCapacitor();
      } else {
        askLocationPermissionForDesktop();
      }
    } catch (error: any) {
      error.message === "Location services are not enabled" && setShowEnableLocationService(true);
      setIsGeoCodingError(true);
    }
  };

  const moveUpPlacemark = useCallback(() => {
    if (isMapLoaded) {
      setIsLoadingCoords(true);
      setIsDragging(true);
    }
  }, [centerMarkerRef, setIsDragging, isMapLoaded]);

  const moveDownPlacemark = useCallback(
    debounce(async () => {
      if (isMapLoaded) {
        setIsDragging(false);
        setIsLoadingCoords(false);
        setUserDraggingMap(true);

        try {
          const geoResponse = await ymaps.geocode(map.getCenter());
          const address = await convertGeoObjToParhatoAddressObject(geoResponse.geoObjects.get(0));

          onSetCustomerAddress(address);
          setAddress(getFullAddress(address) || "");
          setIsGeoCodingError(false);
        } catch (error) {
          setIsGeoCodingError(true);
          onSetCustomerAddress({ location: map.getCenter() });
        }
      }
    }, 1300),
    [map, centerMarkerRef, setIsDragging, setIsLoadingCoords, onSetCustomerAddress, isMapLoaded]
  );

  return (
    <div className={cx("container")} onPointerDown={moveUpPlacemark} onPointerUp={moveDownPlacemark}>
      {!isMapLoaded ? (
        <IonCol className={cx("loading")}>
          <IonSpinner name="lines" className={cx("loadingSpinner")} />
        </IonCol>
      ) : (
        <div className={cx("markerIcon", isDragging ? "markerDragging" : "markerIsNotDrag")}>
          <img ref={centerMarkerRef} src={placemarkOrange} alt="map" />
        </div>
      )}
      <LocationPromptAlert
        ts={ts}
        setShowLocationPrompt={(bool) => setShowLocationPrompt(bool)}
        showLocationPrompt={showLocationPrompt}
        message={
          ts[
            "Please allow access to your location to use this feature. We use your location to improve your experience"
          ]
        }
      />
      <LocationServiceEnableAlert
        ts={ts}
        isOpen={showEnableLocationService}
        onDismiss={() => setShowEnableLocationService(false)}
      />
      <Map
        instanceRef={(ref) => {
          setMap(ref);
        }}
        options={{
          suppressMapOpenBlock: true,
          suppressObsoleteBrowserNotifier: true,
          yandexMapDisablePoiInteractivity: true,
        }}
        style={{
          height: "100%",
          width: "100%",
        }}
        onLoad={() => setIsMapLoaded(true)}
        defaultState={{ center: currentLocation ?? mapState?.center!, zoom: 17, controls: [] }}
        onClick={(event: any) => {
          const location: number[] = event.get("coords") as number[];
          map.setCenter(location);
        }}
      >
        <IonButton
          size="small"
          title={ts["My geolocation"]}
          className={cx("locateBtn")}
          onClick={() => handleGeolocationBtn()}
          onPointerDown={(e) => e.stopPropagation()}
          onPointerUp={(e) => e.stopPropagation()}
        >
          <IonIcon icon={locate} className={cx("locateBtnIcon")} />
        </IonButton>
      </Map>
      <div className={cx(styles.confirmAddressButton_wrapper)}>
        <IonButton
          className={cx(styles.confirmAddressButton)}
          disabled={!customerAddress && !isGeoCodingError}
          onPointerDown={(e) => e.stopPropagation()}
          onPointerUp={(e) => {
            e.stopPropagation();
            // Using setTimeout here - because tap event propagates
            // to component which is overlapped by this component
            setTimeout(() => {
              showAddressForm();
            }, 200);
          }}
        >
          <span>{ts["Enter the address manually"]}</span>
        </IonButton>
        <IonButton
          className={cx(styles.forwardButton)}
          disabled={!customerAddress && !isGeoCodingError}
          onPointerDown={(e) => e.stopPropagation()}
          onPointerUp={(e) => {
            e.stopPropagation();
            // Using setTimeout here - because tap event propagates
            // to component which is overlapped by this component
            setTimeout(() => {
              showAddressForm();
            }, 200);
          }}
        >
          {isLoadingCoords ? (
            <IonSpinner className={styles.confirmAddressButtonSpinner} color="dark" name="crescent" />
          ) : (
            <IonIcon icon={arrowForwardOutline} />
          )}
        </IonButton>
      </div>
    </div>
  );
};

const YandexMap = withYMaps(YandexMapComponent, false);

export { YandexMap };
