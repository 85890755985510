const normalizeLogin = (login: string) => {
  if (/^\+?[0-9]+$/.test(login)) {
    let parsedLogin = login.replace("+", "");

    if (parsedLogin.length === 11 && parsedLogin.startsWith("8")) {
      parsedLogin = "7" + parsedLogin.slice(1);
    }
    return parsedLogin;
  } else {
    return login;
  }
};

export { normalizeLogin };
