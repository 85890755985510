import React from "react";
import classNames from "classnames/bind";
import styles from "./MaxWidthContainer.module.scss";

const cx = classNames.bind(styles);

interface  IMaxWidthContainer {
    children: React.ReactNode
    maxWidth?: number,
    maxWidthBreakpoint?: 'Sm' | 'Md' | 'Lg' | 'Xl' ,
    smContainer?: boolean,
    mdContainer?: boolean,
    lgContainer?: boolean,
    xlContainer?: boolean,
    breakPoints?: boolean,
    classList?: string,
}

const MaxWidthContainer: React.FC<IMaxWidthContainer> = ({
        smContainer= false,
        mdContainer= false,
        lgContainer= false,
        xlContainer = false,
        breakPoints= false,
        maxWidthBreakpoint= false ,
        maxWidth,
        classList,
        ...props
    }) => {
    return (
        <div
            className={cx(
            "container",
                !!maxWidthBreakpoint ? `container${maxWidthBreakpoint}`
                :
                breakPoints ?  `${cx("containerSm")} ${cx("containerMd")} ${cx("containerLg")} ${cx("containerXl")}`
                :
                {"containerSm": smContainer, "containerMd": mdContainer, "containerLg": lgContainer, "containerXl": xlContainer},
            classList
            )}
            style={!!maxWidth ? {maxWidth: `${maxWidth}px`} : {}}
        >
            {props.children}
        </div>
    )
}

export default MaxWidthContainer;