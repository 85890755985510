import { client } from "../../../apollo";
import {
  DeliveryDatesDocument,
  DeliveryDatesQuery,
  DeliveryDatesQueryVariables,
  DeliveryIntervalsDocument,
  DeliveryIntervalsQuery,
  DeliveryIntervalsQueryVariables,
} from "../../../generated/graphql";
import { IDeliveryDatesClient } from "../../types/clients";
import { DeliveryInterval, ISODate } from "../../types/entities";
import BaseClient from "../base-client";

class DeliveryDatesClient extends BaseClient implements IDeliveryDatesClient {
  async getDeliveryDates(shippingMethodId: string) {
    const dates: ISODate[] = await client
      .query<DeliveryDatesQuery, DeliveryDatesQueryVariables>({
        query: DeliveryDatesDocument,
        variables: {
          shippingMethodId,
        },
      })
      .then((res) => {
        if (res.data) {
          return res.data.deliveryDates as ISODate[];
        }

        return [];
      });

    return dates;
  }

  async getDeliveryIntervals(shippingMethodId: string, date: ISODate) {
    const intervals: DeliveryInterval[] = await client
      .query<DeliveryIntervalsQuery, DeliveryIntervalsQueryVariables>({
        query: DeliveryIntervalsDocument,
        variables: {
          shippingMethodId,
          date,
        },
      })
      .then((res) => {
        if (res.data) {
          return res.data.deliveryIntervals.map((interval) => ({
            id: interval.id,
            text: `${interval.timeStart.slice(0, 5)} - ${interval.timeEnd.slice(0, 5)}`,
          }));
        }

        return [];
      });

    return intervals;
  }
}

export default DeliveryDatesClient;
