import React from 'react';
import classNames from "classnames/bind";
import styles from "./AboutCompanySEO.module.scss";

const cx = classNames.bind(styles);

const AboutCompanySEO: React.FC = () => {
    return (
        <div className={cx("about", "ion-hide-md-down")}>
            <h3>Доставка продуктов на дом в Грозном</h3>

            <p>Parhato.ru — это онлайн-магазин по доставке товаров на дом и в офис в Грозном. </p>
            <p>В нашем интернет-магазине представлен широкий ассортимент продуктов питания и других товаров, начиная от
                детского питания и заканчивая бытовой химией. Заказ собирается на складе, где строго соблюдаются правила
                товарного соседства и контроль сроков годности. Для доставки продуктов используются соответствующие
                автомобили, способные обеспечить сохранность товара и необходимую температуру. Каждый товар проходит
                тщательный контроль качества. </p>
            <p>Благодаря своевременной доставке, в том числе и в день заказа или в день праздника, и коротким временным
                интервалам получения, Parhato.ru обеспечивает высокий уровень сервиса. Купить свежие продукты питания с
                доставкой на дом в Грозном можно на сайте интернет-магазина, в мобильном приложении, с помощью <a
                    href="tel:+79287375050">контактного центра</a> или <a href="https://parhato.ru/contacts/"> других видов
                    связи,</a> представленных на сайте. Наш курьер доставит заказ до двери. </p>
            <p>Наш онлайн-супермаркет всегда радует своих клиентов выгодными предложениями, <a
                href="https://app.parhato.ru/products/0/0/sale/">акциями</a>, новинками и доступными ценами. </p>
            <p>Parhato.ru - удобство и качество! </p>
        </div>
    );
}

export default AboutCompanySEO;
