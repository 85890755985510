import { QueryFunctionContext, QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import { TOrder } from "../../data/types/entities";
import { ordersClient } from "../../data/clients";
import { ORDERS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";

type QueryParams = {
  page: number;
  per_page: number;
  customer: string;
};

type Options = UseInfiniteQueryOptions<TOrder[], any>;

const useGetOrdersInfiniteQuery = (config: { options?: Options; params?: QueryParams } = {}) => {
  return useInfiniteQuery<TOrder[], any>(
    ["orders", config.params!],

    async (queryContext: QueryFunctionContext<QueryKey, QueryParams>) => {
      const { pageParam = { page: config.params?.page } } = queryContext;

      return await ordersClient.getOrders({
        page: pageParam.page!,
        customer: config.params?.customer,
      });
    },

    {
      getNextPageParam: (lastRes, allOrders) => {
        const perPage = config.params?.per_page ? config.params.per_page : 10;
        const itemsAmount = allOrders.reduce((a: number, b: any[]) => a + b.length, 0);

        if (lastRes.length === perPage) {
          return {
            page: Math.ceil(itemsAmount / perPage) + 1,
          };
        }

        return undefined;
      },

      cacheTime: ORDERS_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: ORDERS_QUERY_CACHE_EXPIRE_TIME_MS,
      ...config.options,
    }
  );
};

export default useGetOrdersInfiniteQuery;
