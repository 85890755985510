import React, { useCallback, useContext, useMemo, useState } from "react";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { IonActionSheet, IonLoading, useIonToast } from "@ionic/react";
import { useGetShippingZonesQuery } from "../../providers/shippingAndPayment";
import { useGetSettingsQuery } from "../../providers/settings";
import { useShippingZonesData } from "../../providers/shippingZones";
import "./ShippingZonesModalWidget.scss";

interface ActionSheetButton<T = any> {
  text?: string;
  role?: "cancel" | "destructive" | "selected" | string;
  icon?: string;
  cssClass?: string | string[];
  id?: string;
  htmlAttributes?: { [key: string]: any };
  handler?: () => boolean | void | Promise<boolean | void>;
  data?: T;
}

interface ShippingZonesModalWidgetProps {
  setIsModalOpened: (state: boolean) => void;
}

const ShippingZonesModalWidget = ({ setIsModalOpened }: ShippingZonesModalWidgetProps) => {
  const { state } = useContext(AppContext);
  const { setShippingZone } = useShippingZonesData();
  const ts = useMemo(() => state.config.languageJson, [state.config.languageJson]);
  const [presentToast] = useIonToast();
  const [showLoading, setShowLoading] = useState(true);

  const { data: appSettings } = useGetSettingsQuery();
  const presentErrorToast = useCallback(() => {
    presentToast({
      message: ts["An unexpected error occurred"],
      duration: 2000,
      color: "danger",
    });
  }, [presentToast, ts]);

  const { data: shippingZones } = useGetShippingZonesQuery({
    onError: presentErrorToast,
    enabled: appSettings?.isShippingZonesEnabled && appSettings?.showDefaultShippingZone,
  });

  const actionButtons = useMemo(() => {
    if (shippingZones) {
      return shippingZones.map((zone): ActionSheetButton => {
        return {
          text: zone.name,
          id: zone.id,
          handler: () => {
            setShippingZone(zone);
          },
        };
      });
    }

    return [];
  }, [shippingZones, setShippingZone]);

  return (
    <>
      <IonLoading isOpen={showLoading} message={ts["Please wait..."]} />

      {!!actionButtons?.length && (
        <IonActionSheet
          onWillDismiss={() => {
            setIsModalOpened(false);
          }}
          onDidPresent={() => {
            setShowLoading(false);
          }}
          backdropDismiss={false}
          keyboardClose={false}
          mode="ios"
          cssClass={"shipping-zones-action-sheet"}
          isOpen={true}
          header={ts["Select shipping zone"]}
          buttons={actionButtons}
        ></IonActionSheet>
      )}
    </>
  );
};

const ShippingZonesWrapper = (props: ShippingZonesModalWidgetProps) => {
  return <ShippingZonesModalWidget {...props} />;
};

export { ShippingZonesWrapper as ShippingZonesModalWidget };
