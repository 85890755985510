// Import React Libraries
import React, { useContext, useEffect, useMemo } from "react";
// import { onePageCheckOut } from '../../../providers/Redux/Reducers/reducers2';
import { useHistory } from "react-router";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";

// Import Styles
import styles from "./Login.module.scss";
import classNames from "classnames/bind";
import commonFormStyles from "../../../components/UI/CommonFormElements/CommonFormElements.module.scss";

// Import Ionic
import { IonPage, IonHeader, IonButton, IonContent, IonLoading, IonInput } from "@ionic/react";

import { PasswordInput } from "../../../components/PasswordInput/PasswordInput";
import Footer from "../../../components/Footer/Footer";
import MaxWidthContainer from "../../../components/UI/MaxWidthContainer/MaxWidthContainer";
import CommonFormElement from "../../../components/UI/CommonFormElements/CommonFormElement";
import ToolbarMobile from "../../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import ToolbarDesktop from "../../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import { InputChangeEventDetail } from "@ionic/core/components";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { normalizeLogin } from "./utils";
import { useLoginMutation } from "../../../providers/Auth";
import { useAuth } from "../../../contexts/auth-context";
import { useGetSettingsQuery } from "../../../providers/settings";
import { AppMetricaProvider } from "../../../providers/AppMetrica/AppMetrica";

const cx = classNames.bind(styles);
const commonForm = classNames.bind(commonFormStyles);

interface ISignInCredentials {
  username: string;
  password: string;
}

const CREDENTIALS_ERRORS = ["invalid_username", "invalid_email", "incorrect_password"];

const Login: React.FC = (props: any) => {
  const { state } = useContext(AppContext); // For Store
  const ts = useMemo(() => state.config.languageJson, []);
  const history = useHistory();

  const { login } = useAuth();

  const { mutate, isLoading } = useLoginMutation();
  const { data: appSettings } = useGetSettingsQuery();

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onSubmit",
  });

  const handleLogin: SubmitHandler<FieldValues> = (data) => {
    const { password, username } = data as ISignInCredentials;

    const normalizedLogin = normalizeLogin(username);

    mutate(
      { password, username: normalizedLogin },
      {
        onError: (error) => {
          if (CREDENTIALS_ERRORS.includes(error.response.data.code) || error.response.status === 401) {
            setError("password", { message: ts["Invalid username or password."] });
          } else {
            setError("password", { message: ts[error.response.data.message] });
          }
        },
        onSuccess: (data) => {
          AppMetricaProvider.reportEvent("login", { ...data, token: undefined });

          login(data);
          history.goBack();
        },
      }
    );
  };

  useEffect(() => {
    reset();
  }, [props.match.params.name]);

  const openSignUpPage = () => {
    history.push("/sign-up");
  };

  const openForgotPage = () => {
    history.push("/forgot-password");
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton />
      </IonHeader>

      <IonContent>
        <ToolbarDesktop />
        <IonLoading isOpen={isLoading} message={ts["Please wait..."]} />
        <div className={`${cx("about-us", "loginContent")} global-content`}>
          <MaxWidthContainer maxWidthBreakpoint={"Sm"} classList={cx("loginForm")}>
            <form onSubmit={handleSubmit(handleLogin)}>
              <Controller
                name="username"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CommonFormElement
                    label={
                      appSettings?.loginPage.phoneOrEmailFieldEnabled
                        ? ts["Username, phone number or email"]
                        : ts["Phone number"]
                    }
                  >
                    <IonInput
                      required
                      type="text"
                      value={value}
                      className={commonForm("commonElement")}
                      placeholder={
                        appSettings?.loginPage.phoneOrEmailFieldEnabled
                          ? ts["Username, phone number or email"]
                          : ts["Phone number"]
                      }
                      onIonChange={(e: CustomEvent<InputChangeEventDetail>) => onChange(e.detail.value!)}
                    />
                  </CommonFormElement>
                )}
              />

              <Controller
                name="password"
                control={control}
                rules={{
                  required: ts["Required field"],
                }}
                render={({ field: { onChange, value } }) => (
                  <CommonFormElement
                    label={ts.Password}
                    errorMessage={errors.password?.message}
                    isValid={!errors.password?.message && value}
                  >
                    <PasswordInput
                      value={value}
                      placeholder={ts["Enter password"]}
                      className={commonForm("commonElement")}
                      onIonChange={(e: any) => onChange(e.detail.value)}
                    />
                  </CommonFormElement>
                )}
              />
              <input type="submit" hidden />
              <IonButton
                color="secondary"
                className="ion-text-capitalize ion-margin-start ion-margin-end"
                expand="block"
                type="submit"
              >
                {ts["Login button"]}
              </IonButton>
            </form>

            <IonButton expand="full" fill="clear" color="dark" onClick={() => openForgotPage()}>
              {ts["Forgotten password?"]}
            </IonButton>
            <IonButton
              onClick={() => openSignUpPage()}
              className="ion-margin-start ion-margin-end ion-margin-top"
              style={{ color: "black" }}
              expand="block"
              fill="outline"
            >
              {ts.Register}
            </IonButton>
          </MaxWidthContainer>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Login;
