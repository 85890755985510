import React, { useMemo } from "react";
import { Link } from "react-router-dom";

// Import Ionic
import { IonButton, IonCard, IonCardContent, IonCol, IonGrid, IonRow } from "@ionic/react";

// Import Styles
import styles from "./ProductCard.module.scss";
import classNames from "classnames/bind";

import { Image } from "../../Image/Image";
import { Product as TProduct } from "../../../data/types/entities";
import { CartDataProductsItem, useCartData } from "../../../providers/cartDataStore";
import { AttributeBadge } from "../../../fsd/shared/AttributeBadge/AttributeBadge";

interface IProductCard {
  parentProduct: TProduct;
  cartItem: CartDataProductsItem;
  showCounter?: boolean;
  className?: string;
}

const cx = classNames.bind(styles);

const ProductCard: React.FC<IProductCard> = ({ parentProduct, cartItem, showCounter = true, className = "" }) => {
  const { addToCart, removeFromCart } = useCartData();

  const linkToVariationProduct = useMemo(() => {
    let link = `/product-detail/${encodeURI(cartItem.parentId)}`;

    if (cartItem.parentId !== cartItem.id && cartItem.attributes?.length) {
      link += cartItem.attributes
        ?.reduce((url: string, { option, name }) => {
          return `${url}&${encodeURI(name)}=${encodeURI(option ?? "")}`;
        }, "?")
        .replace("?&", "?");
    }

    return link;
  }, [cartItem]);

  const attributes = cartItem.attributes?.map((attr) => {
    return (
      <div key={attr.id} className="flex items-center content-center pl-1 sm:mr-2">
        <div className="pr-1 sm:pr-2">{attr.name}:</div>
        <AttributeBadge value={attr.option ?? ""} isColorPrimary />
      </div>
    );
  });

  return (
    <Link to={linkToVariationProduct}>
      <IonCard className={cx("productCard", "ion-no-padding", className)}>
        <IonCardContent className="ion-no-padding">
          <IonGrid className={cx("productContent")}>
            <IonRow className={cx("productContentRow")}>
              <IonCol className={cx("productContentImg")} size={"3"} sizeMd={"2"}>
                <Image image={parentProduct.images[0]} />
              </IonCol>
              <IonCol className={cx("productContentDesc")}>
                <IonRow>
                  <IonCol class="ion-text-left">{cartItem.name}</IonCol>
                </IonRow>
                {!!cartItem.attributes?.length && <IonRow>{attributes}</IonRow>}
                <IonRow className={cx("ion-align-items-center")}>
                  <IonCol class="ion-text-left" size="6">
                    <span>
                      <p className={cx("productCardPrice", "ellipsis !text-base ")}>{cartItem.price} ₽</p>
                    </span>
                  </IonCol>
                  {showCounter && (
                    <IonCol class="ion-text-right ion-no-padding" size="6">
                      <IonRow class={cx("ion-float-right", styles.buttons)} onClick={(e) => e.preventDefault()}>
                        <IonButton
                          fill="outline"
                          size="small"
                          color="secondary"
                          onClick={() => removeFromCart(cartItem.id)}
                        >
                          <p>-</p>
                        </IonButton>
                        <span className="quantity">{(+cartItem.quantity.toFixed(2)).toLocaleString("ru-RU")}</span>
                        <IonButton
                          fill="outline"
                          size="small"
                          color="secondary"
                          onClick={() => addToCart(cartItem)}
                          disabled={cartItem.quantity + 1 > cartItem.stockQuantity}
                        >
                          <p>+</p>
                        </IonButton>
                      </IonRow>
                    </IonCol>
                  )}
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </Link>
  );
};

export default ProductCard;
