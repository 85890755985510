import React from "react";
import { IonSkeletonText } from "@ionic/react";
import classNames from "classnames/bind";
import styles from "./SwitcherSkeleton.module.scss";

const cx = classNames.bind(styles);

const SwitcherSkeleton = () => {
  return (
    <div className={cx("switcher__wrapper")}>
      <IonSkeletonText className={cx("switcher__title")} />
      <div className={cx("buttons-wrapper")}>
        <IonSkeletonText className={cx("switcher__button")} />
        <IonSkeletonText className={cx("switcher__button")} />
      </div>
    </div>
  );
};

export { SwitcherSkeleton };
