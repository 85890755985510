import { StoreApi, create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { WishListDataActions, WishListDataState } from ".";

const initialState: WishListDataState = {
  wishListProducts: [],
};

const toggleWish =
  (set: StoreApi<WishListDataState & WishListDataActions>["setState"]): WishListDataActions["toggleWish"] =>
  (productId) => {
    set((state): WishListDataState => {
      if (state.wishListProducts.some((el) => el === productId)) {
        return {
          ...state,
          wishListProducts: state.wishListProducts.filter((product) => product !== productId),
        };
      }

      return {
        ...state,
        wishListProducts: [...state.wishListProducts, productId],
      };
    });
  };

const isWishProduct =
  (get: StoreApi<WishListDataState & WishListDataActions>["getState"]): WishListDataActions["isWishProduct"] =>
  (productId) => {
    const { wishListProducts } = get();
    return wishListProducts.some((el) => el === productId);
  };

const useWishListDataState = create<WishListDataState & WishListDataActions>()(
  persist<WishListDataState & WishListDataActions>(
    (set, get) => ({
      ...initialState,
      toggleWish: toggleWish(set),
      isWishProduct: isWishProduct(get),
    }),
    {
      name: "wishlist-data",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const useWishListData = () => {
  return useWishListDataState((state) => ({
    ...state,
  }));
};

export { useWishListData, useWishListDataState };
