import { IAuthClient } from "../../types/clients";
import {
  ResetPasswordVariables,
  LoginVariables,
  User,
  VerifySMSCodeVariables,
  RegisterUserCredentials,
  RegisterResponse,
  VerificationCodeResponse,
} from "../../types/entities";
import BaseClient from "../base-client";
import { IrskomLoginResponse, IrskomUser } from "./types";

class AuthClient extends BaseClient implements IAuthClient {
  async login({ username, password }: LoginVariables) {
    const { data: userData } = await this.instance.post<IrskomLoginResponse>("/account/login", {
      email: username,
      password,
    });

    this.setToken(userData.token);

    const { data: accountData } = await this.instance.get<IrskomUser>("account/details");

    const user: User = {
      id: userData.userId,
      userName: userData.userName,
      firstName: accountData.firstName,
      lastName: accountData.lastName,
      phone: userData.userName,
      token: userData.token,
    };

    return user;
  }

  setToken(token: string) {
    this.instance.token = token;
  }

  async sendVerificationCode(phone: string) {
    try {
      const { data: userExistData } = await this.instance.get<any>(`account/user/phone?phone=${phone}`);

      if (!userExistData?.id) {
        const error = {
          message: "UserDoesn'tExistErrorMessage",
        };
        throw error;
      }
    } catch (error: any) {
      if (error?.message === "UserDoesn'tExistErrorMessage") {
        throw error;
      }
    }

    const { data } = await this.instance.get<any>(`otp/send?phone=${phone}`);

    return data;
  }

  async resetPassword(params: ResetPasswordVariables) {
    // const { data: userExistData } = await this.instance.get<any>(`account/user/phone?phone=${params.phone}`);
    const res = await this.instance.post<any>("user/password/reset", {
      phoneNumber: params.phone,
      newPassword: params.newPassword,
      confrimationCode: params.verificationCode,
    });

    return res.data;
  }

  async verifyCode(params: VerifySMSCodeVariables) {
    const res = await this.instance.get<any>("otp/verify", {
      params: {
        phone: params.phoneNumber,
        code: params.code,
      },
    });

    return res.status;
  }

  async register(params: RegisterUserCredentials): Promise<RegisterResponse> {
    let newNumber = "";
    if (params.phone.startsWith("7")) {
      newNumber = `+${params.phone}`;
    }
    const credentials = {
      FirstName: params.firstName,
      LastName: params.lastName,
      PhoneNumber: newNumber,
      Password: params.password,
      Sex: 0,
      ConfirmPassword: params.password,
    };

    const { data } = await this.instance.post<any>("account/register/personal", credentials);

    return { ...data, userName: newNumber };
  }

  async getSmsCode(phone: string): Promise<VerificationCodeResponse> {
    return Promise.resolve({
      phoneNumber: "",
      verificationCodeId: "",
    });
  }

  async authBySmsCode(params: any) {
    return false;
  }

  async getRegisterVerificationCode(phone: string): Promise<VerificationCodeResponse> {
    try {
      const { data: userExistData } = await this.instance.get<any>(`account/user/phone?phone=${phone}`);

      if (!!userExistData?.id) {
        const error = {
          message: "AlreadyExistMessage",
        };
        throw error;
      }
    } catch (error: any) {
      if (error?.message === "AlreadyExistMessage") {
        throw error;
      }
    }

    const { data } = await this.instance.get<any>(`otp/send?phone=${phone}`);

    return data;
  }
}

export default AuthClient;

// number > code -> confirm > new password confirm
