import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { IonItem, IonLabel, IonInput, IonList, IonSpinner, useIonToast } from "@ionic/react";

import classNames from "classnames/bind";
import styles from "./SearchAddressInput.module.scss";

import { ParhatoRESTApi, ParhatoAddressObject } from "../../providers/ParhatoRESTApi/ParhatoRESTApi";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import CommonFormElement from "../UI/CommonFormElements/CommonFormElement";
import commonFormStyles from "../UI/CommonFormElements/CommonFormElements.module.scss";
import { getFullAddress } from "../../utils/utils";

export namespace SearchAddressInputTypes {
  export type Props = {
    setUserDraggingMap?: (bool: boolean) => void;
    onOptionSelect: (geoObj: SelectedOption) => void;
    searchPrefix?: string;
    disabled?: boolean;
    invalid?: boolean;
    address: string;
  };

  export type SelectedOption = {
    address?: ParhatoAddressObject;
  };
}

const cx = classNames.bind(styles);
const commonForm = classNames.bind(commonFormStyles);

const SearchAddressInput: React.FC<SearchAddressInputTypes.Props> = ({
  setUserDraggingMap,
  onOptionSelect,
  address,
  searchPrefix = "",
  disabled = false,
  invalid = false,
}) => {
  const { state } = useContext(AppContext);
  const ts = useMemo(() => state.config.languageJson, []);
  const [presentToast] = useIonToast();

  const [addresses, setAddresses] = useState<ParhatoAddressObject[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [isRequestFinished, setIsRequestFinished] = useState(false);
  const [isInputInFocus, setIsInputInFocus] = useState(false);

  const [inputAddress, setInputAddress] = useState("");

  const wrapperRef = useRef<HTMLDivElement>(null);

  const lastReqAbortController = useRef<AbortController | null>(null);
  const debounceTimeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!inputAddress || address !== inputAddress) {
      handleAddressInput(inputAddress);
    }
  }, [inputAddress]);

  useEffect(() => {
    setInputAddress(address);
  }, [address]);

  useEffect(() => {
    setAddresses([]);
  }, [searchPrefix]);

  function getAddresses(s: string) {
    setIsRequestFinished(false);

    const abortController = new AbortController();
    lastReqAbortController.current = abortController;

    ParhatoRESTApi.searchAddress(searchPrefix + " " + s, abortController.signal)
      .then((responseData) => {
        const data = responseData
          .filter((address) => address.locality)
          .map((address) => {
            address["location"] = address.point.split(" ").reverse().map(Number);
            address["point"] = address.point.split(" ").reverse().join(" ");
            return address;
          });

        setAddresses(data);
        setIsRequestFinished(true);
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          setIsRequestFinished(true);
          setShowDropdown(false);
          presentToast({
            message: ts["Address search error, please select a location on the map"],
            duration: 2000,
            color: "danger",
          });
        }
      });
  }

  function handleAddressInput(value: string) {
    lastReqAbortController.current?.abort();
    if (debounceTimeoutId.current) {
      clearTimeout(debounceTimeoutId.current);
    }

    debounceTimeoutId.current = setTimeout(() => {
      if (value && !isOptionSelected) {
        setAddresses([]);
        getAddresses(value);
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
      setIsOptionSelected(false);
      debounceTimeoutId.current = null;
    }, 1000);
  }

  function handleDropdownItemClick(selectedAddress: ParhatoAddressObject) {
    setShowDropdown(false);
    setIsOptionSelected(true);
    onOptionSelect({ address: selectedAddress });
    setUserDraggingMap && setUserDraggingMap(false);
    setInputAddress(getFullAddress(selectedAddress));
  }

  return (
    <CommonFormElement label="" isValid={!invalid || (isInputInFocus && showDropdown)}>
      <div className={cx("dropdown_wrapper")} ref={wrapperRef}>
        <IonInput
          className={commonForm(
            "ion-no-padding",
            "commonElement",
            { commonInvalid: invalid },
            "commonElementMaskedInput"
          )}
          value={inputAddress}
          type="text"
          name="search-address"
          required={!disabled}
          onIonChange={(e) => setInputAddress(e.detail.value!)}
          disabled={disabled}
          placeholder={"Адрес"}
          onIonFocus={() => {
            setShowDropdown(addresses.length > 0);
            setIsInputInFocus(true);
          }}
          onIonBlur={(e) => {
            if (e.detail.relatedTarget instanceof Element && !wrapperRef.current?.contains(e.detail.relatedTarget)) {
              setIsInputInFocus(false);
              setShowDropdown(false);
            }
          }}
        />
        {isInputInFocus && showDropdown && (
          <IonList className={cx("dropdown")} tabIndex={0}>
            {addresses.length ? (
              addresses.map((addr) => (
                <IonItem
                  key={addr.text}
                  className={cx("dropdown_item")}
                  type="button"
                  onMouseDown={(e) => {
                    handleDropdownItemClick(addr);
                    e.preventDefault();
                  }}
                  detail={false}
                  tabIndex={0}
                  button
                >
                  <IonLabel className={cx("dropdown_label")}>{getFullAddress(addr)}</IonLabel>
                </IonItem>
              ))
            ) : isRequestFinished ? (
              <IonItem className={cx("dropdown_item", "ion-text-center")} lines="none">
                <IonLabel>{ts["Nothing found"]}</IonLabel>
              </IonItem>
            ) : (
              <IonSpinner name="dots" className={cx("dropdown_spinner")} />
            )}
          </IonList>
        )}
      </div>
    </CommonFormElement>
  );
};

export { SearchAddressInput };
