import { CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { UseQueryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { categoriesClient } from "../../data/clients";
import { CategoriesMapObj, Category } from "../../data/types/entities";

const useGetCategoryByIdQuery = (id: string, options: UseQueryOptions<Category> = {}) => {
  const queryClient = useQueryClient();

  return useQuery<Category, any>(
    ["category", id],
    async () => {
      if (id) {
        return await categoriesClient.getCategoryById(id);
      }

      throw new Error("useGetCategoryByIdQuery: invalid id");
    },
    {
      cacheTime: CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS,
      initialData: () => {
        const data = queryClient.getQueryData<CategoriesMapObj>(["all-categories"]);
        return data?.allCategories.find((cat) => String(cat.id) === id);
      },
      ...options,
    }
  );
};

export default useGetCategoryByIdQuery;
