import React, { useContext, useMemo } from "react";
import { IonList, IonItem, IonLabel, IonIcon, IonRow, useIonAlert } from "@ionic/react";
import { addOutline, createOutline, trashOutline } from "ionicons/icons";
import { CustomerAddressType } from "../../generated/graphql";
import { CustomerAddress as CustomerAddressesType } from "../../providers/CustomerAddresses/CustomerAddressesTypes";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import classNames from "classnames/bind";
import styles from "./CustomerAddresses.module.scss";
import { AddressesOnPresentModalOverloads, ID } from "../../types";

const cx = classNames.bind(styles);

interface ICustomerAddresses {
  addresses: CustomerAddressesType[];
  onPresentModal: AddressesOnPresentModalOverloads;
  deleteAddress: (addressId: ID) => void;
}

const CustomerAddresses: React.FC<ICustomerAddresses> = ({ onPresentModal, addresses, deleteAddress }) => {
  const { state } = useContext(AppContext);
  const ts = useMemo(() => state.config.languageJson, [state.config.languageJson]);
  const [present] = useIonAlert();

  return (
    <IonList inset={false} className="ion-no-margin">
      {addresses?.map((address) => {
        return (
          <IonItem className="ion-no-padding" key={address?.id}>
            <span
              slot={"end"}
              className={cx("create")}
              onClick={() => {
                onPresentModal(address as CustomerAddressType);
              }}
            >
              <IonIcon icon={createOutline} slot={"end"} />
            </span>
            <span
              slot={"end"}
              className={cx("delete")}
              onClick={() => {
                present({
                  header: ts["Want to delete?"],
                  buttons: [
                    {
                      text: ts["Cancel"],
                      role: "cancel",
                    },
                    {
                      text: ts["Delete"],
                      role: "destructive",
                      handler: () => deleteAddress(address?.id),
                    },
                  ],
                });
              }}
            >
              <IonIcon icon={trashOutline} slot={"end"} />
            </span>
            <IonLabel className={cx("address")}>
              {`${address.city}, ${address.street}, д. ${address.house}${
                address.isFlat ? ", кв. " + address.apartmentNumber : ""
              }`}
            </IonLabel>
          </IonItem>
        );
      })}
      <IonItem className={`${cx("newAddress")} ion-no-padding`} onClick={() => onPresentModal()}>
        <IonLabel>
          <IonRow className={"ion-no-margin ion-no-padding ion-align-items-center ion-justify-content-center"}>
            <span className={"ion-padding-end"}>{ts["Add address"]}</span>
            <IonIcon className={"newAddress_icon"} color={"primary"} icon={addOutline} />
          </IonRow>
        </IonLabel>
      </IonItem>
    </IonList>
  );
};

export { CustomerAddresses };
