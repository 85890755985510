import React from "react";

import { IonSkeletonText } from "@ionic/react";

import classNames from "classnames/bind";
import styles from "./SkeletonItem.module.scss";

const cx = classNames.bind(styles);

const SkeletonItem = () => {
  return (
    <div className={cx(styles.skeletonItem)}>
      <IonSkeletonText className={cx(styles.skeletonItem_image)} animated style={{ height: 130 }} />
      <div className={cx(styles.skeletonItem_header)}>
        <IonSkeletonText className={cx(styles.skeletonItem_price)} animated style={{ height: 25 }} />
        <IonSkeletonText className={cx(styles.skeletonItem_cartButton)} animated style={{ height: 25 }} />
      </div>
    </div>
  );
};
export default SkeletonItem;
