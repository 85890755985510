import { User, UserWithPassword } from "../../types/entities";
import { IUsersClient } from "../../types/clients";
import BaseClient from "../base-client";
import { convertIrskomUserToUser } from "./utils";
import { IrskomUser } from "./types";

class UsersClient extends BaseClient implements IUsersClient {
  async getProfile(): Promise<User> {
    const res = await this.instance.get<IrskomUser>("account/details");

    return convertIrskomUserToUser(res.data);
  }

  async updateProfile(
    userId: string,
    updateData: Partial<UserWithPassword>,
  ): Promise<Partial<User>> {
    const userSendData: IrskomUser = {
      birthDay: "01.01.1990",
      discountCardImage: "",
      discountCardNumber: "",
      firstName: updateData.firstName ?? "",
      id: userId,
      lastName: updateData.lastName ?? "",
      phoneNumber: updateData.phone!,
      sex: 0,
    };

    await this.instance.post("account/update", {
      ...userSendData,
    });

    return updateData;
  }
}

export default UsersClient;
