import BaseClient from "../base-client";
import { Category } from "../../types/entities";
import { convertTreeCategories } from "./utils";
import { IrsKomCategory } from "./types";
import { ICategoriesClient } from "../../types/clients";

class CategoriesClient extends BaseClient implements ICategoriesClient {
  private async getFlatAllCategories(): Promise<Category[]> {
    const response = await this.instance.get<IrsKomCategory[]>("/Category/categories/tree");
    const data = convertTreeCategories(response.data);

    return data;
  }

  async getAllCategories() {
    const allCategories = await this.getFlatAllCategories();
    const subCategories = allCategories.filter((cat) => cat.parent);
    const mainCategories = allCategories.filter((cat) => !cat.parent);

    return { allCategories, subCategories, mainCategories };
  }

  async getMainCategories() {
    const allCategories = await this.getFlatAllCategories();
    const mainCategories = allCategories.filter((cat) => !cat.parent);

    return mainCategories;
  }

  async getSubCategories(id: string) {
    const allCategories = await this.getFlatAllCategories();
    const subCategories = allCategories.filter((x) => x.parent === id);

    return subCategories;
  }

  async getCategoryById(id: string) {
    const allCategories = await this.getFlatAllCategories();
    const category = allCategories.find((cat) => id === cat.id);
    if (!category) {
      throw new Error("Not Found");
    }

    return category;
  }
}

export default CategoriesClient;
