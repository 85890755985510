import React from "react";

// Import Ionic
import { IonButton, IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react";
import { currencyTransform } from "../../../providers/Redux/Reducers/reducers2";
import { CouponDiscountType } from "../../../data/types/entities";

interface ICouponDetail {
  languageJson: { [k: string]: string };
  amount: string | number;
  couponCode: string;
  discountType: any;
  onRemoveClick: () => void;
}

// Детальная информация о купоне
// Временно не отображается
const CouponDetail: React.FC<ICouponDetail> = ({ languageJson, amount, couponCode, discountType, onRemoveClick }) => {
  return (
    <IonCard className="card-product">
      <IonCardContent>
        <IonRow>
          <IonCol size="6">{languageJson["Coupon Code"]}</IonCol>
          <IonCol size="6" class="ion-text-right">
            {couponCode}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="6">{languageJson["Coupon Amount"]}</IonCol>
          <IonCol size="6" class="ion-text-right">
            {discountType === CouponDiscountType.PERCENT
              ? `%${parseFloat(String(amount)).toFixed(2)}`
              : currencyTransform(amount)}
          </IonCol>
        </IonRow>
        <IonRow>
          {discountType === CouponDiscountType.PERCENT && (
            <IonCol size="12">{languageJson["A percentage discount for the entire cart"]}</IonCol>
          )}
          {discountType === CouponDiscountType.FIXED_CART && (
            <IonCol size="12">{languageJson["A fixed total discount for the entire cart"]}</IonCol>
          )}
          {discountType === CouponDiscountType.FIXED_PRODUCT && (
            <IonCol size="12">{languageJson["A fixed total discount for selected products only"]}</IonCol>
          )}

          <IonCol size="12">
            <IonButton size="small" color="secondary" onClick={onRemoveClick}>
              {languageJson.Remove}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default CouponDetail;
