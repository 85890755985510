import React, { useEffect, useState } from "react";
import { IonSelectOption } from "@ionic/react";
import CommonFormElement from "../../UI/CommonFormElements/CommonFormElement";
import Select from "../../Select/Select";

import classNames from "classnames/bind";
import styles from "../ShippingAddressComponent.module.scss";
import { PickupPoint } from "../../../data/types/entities";

interface ISelectPickupPoint {
  pickupPoints: PickupPoint[];
  selectedPoint?: PickupPoint;
  initialPointId?: string;
  onSelectChange: (pickupPoint: PickupPoint | null) => void;
  ts: any;
}

const cx = classNames.bind(styles);

const SelectPickupPoint: React.FC<ISelectPickupPoint> = ({
  pickupPoints,
  selectedPoint,
  onSelectChange,
  initialPointId,
  ts,
}) => {
  const [pickupPoint, setPickupPoint] = useState<PickupPoint | null>(null);

  const handlePointSelect = (e: any) => {
    if (!selectedPoint) {
      setPickupPoint(e.detail.value);
    }

    onSelectChange(e.detail.value);
  };

  useEffect(() => {
    if (initialPointId && !pickupPoint) {
      setPickupPoint(pickupPoints.find((point) => point.id === initialPointId) ?? null);
    }
  }, [initialPointId, pickupPoints, pickupPoint]);

  return (
    <CommonFormElement classListItem={cx("shipping__addressSelect")} label={ts["Pickup point"]}>
      <Select
        disabled={!pickupPoints?.length}
        value={selectedPoint || pickupPoint}
        isValid={!!pickupPoint}
        placeholder={ts["Pickup point"]}
        languageJson={ts}
        className={"shippingAddress_deliverySelect"}
        onChange={handlePointSelect}
      >
        {pickupPoints?.length > 0 &&
          pickupPoints.map((point) => {
            return (
              <IonSelectOption key={point.id} value={point}>
                {point.address}
              </IonSelectOption>
            );
          })}
      </Select>
    </CommonFormElement>
  );
};

export { SelectPickupPoint };
