import { useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { useState } from "react";

const useViewVisible = () => {
  const [isVisible, setIsVisible] = useState(true);

  useIonViewWillEnter(() => {
    setIsVisible(true);
  });

  useIonViewWillLeave(() => {
    setIsVisible(false);
  });

  return isVisible;
};

export default useViewVisible;
