import React from 'react';

// Import Ionic
import {IonIcon} from "@ionic/react";

// Import Icons
import {chevronForwardOutline} from "ionicons/icons";

// Import Styles
import classNames from "classnames/bind";
import styles from "./Breadcrumbs.module.scss";
import {useHistory} from "react-router-dom";

interface IBreadcrumbs {
    breadcrumbs?: {title: string, handler?: () => void}[],
}

const cx = classNames.bind(styles);


const Breadcrumbs:React.FC<IBreadcrumbs> = ({
    breadcrumbs
                                            }) => {

    let history = useHistory();
    const openProducts = (value: string ) => {
        history.push(`/${value}`);
    };

    return (
        <div className={cx('breadcrumbs')}>
            <span className={cx('breadcrumb')} onClick={() => { openProducts( 'home3' ) }}>
                Главная
                <IonIcon className={cx('breadcrumb__icon')} icon={chevronForwardOutline}/>
            </span>
            {breadcrumbs?.map((item, index: number) => {
                return <span className={cx('breadcrumb')} key={index} onClick={  (index !== breadcrumbs?.length - 1) ? item.handler : undefined}>
                    {item.title}
                    {
                        (index !== breadcrumbs?.length - 1)
                        &&
                        <IonIcon className={cx('breadcrumb__icon')} icon={chevronForwardOutline}/>
                    }
                </span>
            })}

        </div>
    );
};

export default Breadcrumbs;