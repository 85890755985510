import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { couponsClient } from "../../data/clients";
import { Coupon } from "../../data/types/entities";

const useGetCouponMutation = (options?: UseMutationOptions<Coupon | null, any, string>) =>
  useMutation(async (code) => {
    if (couponsClient) {
      const coupon = await couponsClient.getCoupon(code);
      return coupon;
    }

    throw new Error("couponsClient not implemented");
  }, options);

export default useGetCouponMutation;
