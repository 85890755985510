import { fetchWithTimeout } from '../utils/utils';
import { WooComAPI } from '../WooComAPI/woo-com-api';

export interface DriverRating {
    orderId: number,
    driverId: number,
    driverFullName: string,
    rating: number,
    comment: string,
    userId: number,
    startTime: {
        _seconds: number,
        _nanoseconds: number,
    },
}

export const CloudfunctionsAPI = {
    baseUrl: process.env.REACT_APP_FIREBASE_URL!,

    postUserRequest(data: {
        email: string,
        phone: string,
        request: string
    }): Promise<Response> {
        const url = this.baseUrl + "";

        return fetchWithTimeout(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            timeout: 20000,
        });
    },

    postDriverRating(data: {
        orderId: number,
        driverId: number,
        driverFullName: string,
        rating: number,
        comment?: string,
    }): Promise<Response> {
        const url = this.baseUrl + "/driver-ratings";

        return fetchWithTimeout(url, {
            method: "POST",
            headers: getRequestHeaders(),
            body: JSON.stringify(data),
            timeout: 20000,
        });
    },

    getDriverRatings(params?: { orderIdsToInclude?: number[] }): Promise<DriverRating[]> {
        let url = this.baseUrl + "/driver-ratings";
        if (params) {
            const { orderIdsToInclude } = params;
            const urlParams = new URLSearchParams();

            if (orderIdsToInclude && orderIdsToInclude.length > 0) {
                urlParams.append("include", String(orderIdsToInclude));
            }

            url += `?${urlParams}`;
        }

        return fetchWithTimeout(url, {
            method: "GET",
            headers: getRequestHeaders(),
        }).then(res => res.json());
    },

    retrieveDriverRating(orderId: number): Promise<DriverRating> {
        const url = this.baseUrl + "/driver-ratings/" + orderId;

        return fetchWithTimeout(url, {
            method: "GET",
            headers: getRequestHeaders(),
        }).then(res => {
            if(!res.ok) {
                throw new Error(String(res.status));
            }

            return res.json();
        });
    }

}

const getRequestHeaders = () => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Accept', 'application/json');

    if (WooComAPI.accessToken) {
        requestHeaders.set('Authorization', WooComAPI.getBearerAuthToken());
    }

    return requestHeaders;
}