import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  useIonToast,
} from "@ionic/react";

// Import Icons
import { addOutline, basketOutline, closeOutline, heart, heartOutline, removeOutline } from "ionicons/icons";

import { Image } from "../Image/Image";

// Import Styles
import classNames from "classnames/bind";
import "./Product.scss";
import styles from "./Product.module.scss";
import { useGetSettingsQuery } from "../../providers/settings";
import { ProductType, Product as TProduct } from "../../data/types/entities";
import { useCartData } from "../../providers/cartDataStore";
import PopUpTooltip from "../UI/PopoverTooltip";
import { useWishListData } from "../../providers/wishListStore";

const cx = classNames.bind(styles);

interface ProductProps {
  product: TProduct;
  type: any;
  lazyload?: boolean;
}

// ! Do not remove "item", because it causes an error when adding an item to the cart
const Product = ({ product: item, type, lazyload }: ProductProps) => {
  const { toggleWish, isWishProduct } = useWishListData();
  const { addToCart: addToCartStore, removeFromCart, cartProductsMap } = useCartData();
  const [presentToast] = useIonToast();
  let history = useHistory(); // For Push page
  const { state } = useContext(AppContext); // For Store
  const { data: appSettings } = useGetSettingsQuery();

  const ts = useMemo(() => state.config.languageJson, []);
  const cartQuantity = cartProductsMap[item.id]?.quantity ?? 0;

  // For New Product Check
  function checkProductNew(p: any) {
    const pDate = new Date(p.dateCreated);
    const date = pDate.getTime() + state.config.newProductDuration * 86400000;
    const todayDate = new Date().getTime();
    return date > todayDate;
  }

  // For InnenrHtml Markup
  function createMarkup() {
    return { __html: item.priceHtml ? item.priceHtml : item.price + " ₽" };
  }

  function addToCart(product: TProduct) {
    if (product.stockQuantity === cartQuantity) {
      presentToast({
        message: ts["Product Quantity is Limited!"],
        duration: 600,
      });
      return;
    }

    if (product.type === "variable") {
      showProductDetail(product.id);
    } else if (product.stockQuantity > cartQuantity) {
      addToCartStore(product);
    }
  }

  function removeProductFromCart(product: TProduct) {
    removeFromCart(product.id);
  }

  function showProductDetail(id: TProduct["id"]) {
    history.push("/product-detail/" + id);
  }

  return (
    <div
      className={cx(
        "skeletonWidth",
        "product-component",
        {
          productsSpecial:
            item !== null &&
            item !== undefined &&
            type !== "main-page-list" &&
            type !== "recent" &&
            type !== "wishList" &&
            type !== "order-detail-list",
        },
        {
          bottomMargin: type === "newList",
        },
        styles.product
      )}
    >
      {type === "main-page-list" || type === "recent" || type === "wishList" ? (
        //  TODO: Здесь отображаются карточки для слайдеров на главной странице
        appSettings?.cardProduct ? (
          <IonCard
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              showProductDetail(item.id);
            }}
            className={cx(
              "ion-no-margin ion-margin-top animated fadeIn",
              "productCommon__card",
              { wishProduct: type === "wishList" },
              "cardIrskom",
              styles.cardNoShadow
            )}
          >
            {checkProductNew(item) && (
              <IonBadge className={cx("new-product-label")}>{state.config.languageJson.NEW}</IonBadge>
            )}
            <Image
              lazyLoad={lazyload}
              draggable={false}
              style={{ maxWidth: "100%", height: "150px" }}
              image={item?.images[0]}
            />
            <IonCardHeader className={cx("cardIrskomHeader")}>
              <IonCardTitle className={cx("cardIrskomHeaderTitle")}>{item.name}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent className={cx("cardIrskomContent")}>
              <div className={`${item.discountPrice ? "flex items-center gap-4 pb-2" : ""}`}>
                {(item.stockQuantity > 0 || item.type === ProductType.VARIABLE) &&
                  (item.discountPrice === null ? (
                    <p
                      className={cx("price ellipsis", styles.price, styles.price__general, "cardIrskomContentPrice")}
                      dangerouslySetInnerHTML={createMarkup()}
                    ></p>
                  ) : (
                    <>
                      <div className="flex flex-col">
                        <div className="flex items-center gap-1">
                          <div className="text-xs font-bold">По карте</div>

                          <PopUpTooltip
                            text="Цена для зарегистрированных пользователей"
                            btnStyles="inline-block rounded-full bg-slate-200 w-4 h-4 text-xs"
                            panelStyles="bottom-4 -right-12 text-xs p-2 bg-white text-black rounded border-1"
                          />
                        </div>
                        <p
                          className={cx(
                            "price ellipsis",
                            styles.price,
                            styles.price__general,
                            "cardIrskomContentPrice",
                            item.discountPrice ? "irskomDiscountPrice" : ""
                          )}
                          dangerouslySetInnerHTML={createMarkup()}
                        ></p>
                      </div>
                      {item.discountPrice && (
                        <div className="flex colum flex-col">
                          <div className="font-bold text-xs">Без карты</div>
                          <div className="text-sm font-bold">{`${Number(item.discountPrice).toLocaleString(
                            "ru-RU"
                          )} ₽`}</div>
                        </div>
                      )}
                    </>
                  ))}
              </div>
              {!cartQuantity ? (
                <div className={cx("cardIrskomContentQuantity")}>
                  {item.stockQuantity > 0 ? (
                    <div
                      className={cx(
                        "ion-text-center ion-no-padding",
                        { "ion-text-right": type === "wishList" },
                        { disabled: !item?.purchasable }
                      )}
                      onClick={(e) => {
                        addToCart(item);
                        e.stopPropagation();
                      }}
                    >
                      {item.stockStatus === "instock" && (
                        // <IonIcon icon={basketOutline} className={cx(styles.icon)} />
                        <div className={cx(styles.basketButton)}>{state.config.languageJson["Add to cart"]}</div>
                      )}
                    </div>
                  ) : (
                    <div
                      className={cx("ion-text-center ion-no-padding", { "ion-text-right": type === "wishList" })}
                      onClick={(e) => {
                        showProductDetail(item.id);
                        e.stopPropagation();
                      }}
                    >
                      {item.stockStatus === "instock" && <IonIcon icon={basketOutline} className={cx(styles.icon)} />}
                    </div>
                  )}
                </div>
              ) : (
                <div className={cx("product-card-counter")} onClick={(e) => e.stopPropagation()}>
                  <div className={cx("product-card-background")}>
                    <div onClick={() => removeProductFromCart(item)} className={cx(styles.iconBackground)}>
                      {item.stockStatus === "instock" && (
                        <IonIcon className={cx("counter-button")} icon={removeOutline} />
                      )}
                    </div>
                    <div className={cx(styles.quantityCounterIrskom)}>
                      <IonLabel>{cartQuantity}</IonLabel>
                    </div>
                    <div onClick={() => addToCart(item)} className={cx(styles.iconBackground)}>
                      {item.stockStatus === "instock" && item.stockQuantity > cartQuantity && (
                        <IonIcon className={cx("counter-button")} icon={addOutline} />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </IonCardContent>
            {type === "wishList" && (
              <div
                onClick={(e) => {
                  toggleWish(item.id);
                  e.stopPropagation();
                }}
                className={cx("wishProduct__removeButton")}
              >
                <IonIcon icon={closeOutline} className={"wishList__removeIcon"} />
              </div>
            )}
            {type !== "wishList" && (
              <div className={cx("productsSpecialLeft__heart", "productCommon__heart")}>
                <div
                  onClick={(e) => {
                    toggleWish(item.id);
                    e.stopPropagation();
                  }}
                >
                  <IonIcon
                    className={cx("productHeartIcon")}
                    color="primary"
                    icon={isWishProduct(item.id) ? heart : heartOutline}
                  />
                </div>
              </div>
            )}
          </IonCard>
        ) : (
          <IonCard
            className={cx(
              "ion-no-margin ion-margin-top animated fadeIn",
              "productCommon__card",
              { wishProduct: type === "wishList" },
              styles.cardNoShadow
            )}
          >
            <IonCardHeader className={cx("ion-no-padding", "min-height", "ion-card-header")}>
              {checkProductNew(item) && (
                <IonBadge className={cx("new-product-label")}>{state.config.languageJson.NEW}</IonBadge>
              )}
              <Image
                lazyLoad={lazyload}
                draggable={false}
                style={{ maxWidth: "100%", height: "150px" }}
                image={item?.images[0]}
                onClick={() => showProductDetail(item.id)}
              />
            </IonCardHeader>
            <IonCardContent className={cx({ wishProduct__cardContent: type === "wishList" }, "ion-no-padding")}>
              <IonGrid
                className={cx("ion-no-padding ion-padding-horizontal ion-padding-top", styles.cardContent, {
                  wishProduct__grid: type === "wishList",
                })}
              >
                {!cartQuantity ? (
                  // TODO: show counter or basket icon
                  <IonRow className={cx({ wishProduct__priceWrapper: type === "wishList" })}>
                    <IonCol size="9" className="ion-no-padding ion-text-start">
                      {(item.stockQuantity > 0 || item.type === ProductType.VARIABLE) && (
                        <p
                          className={cx("price ellipsis", styles.price, styles.price__general)}
                          dangerouslySetInnerHTML={createMarkup()}
                        ></p>
                      )}
                    </IonCol>
                    {item.stockQuantity > 0 ? (
                      <IonCol
                        size="3"
                        className={cx(
                          "ion-text-center ion-no-padding",
                          { "ion-text-right": type === "wishList" },
                          { disabled: !item?.purchasable }
                        )}
                        onClick={(e) => {
                          addToCart(item);
                          e.stopPropagation();
                        }}
                      >
                        {item.stockStatus === "instock" && <IonIcon icon={basketOutline} className={cx(styles.icon)} />}
                      </IonCol>
                    ) : (
                      <IonCol
                        size="3"
                        className={cx("ion-text-center ion-no-padding", { "ion-text-right": type === "wishList" })}
                        onClick={(e) => {
                          showProductDetail(item.id);
                          e.stopPropagation();
                        }}
                      >
                        {item.stockStatus === "instock" && <IonIcon icon={basketOutline} className={cx(styles.icon)} />}
                      </IonCol>
                    )}
                  </IonRow>
                ) : (
                  //  TODO: COUNTER
                  <IonRow
                    className={cx({ wishProduct__counter: type === "wishList" }, styles.quantityCounter)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IonCol
                      size="3"
                      className="ion-text-center ion-no-padding"
                      onClick={() => removeProductFromCart(item)}
                    >
                      {item.stockStatus === "instock" && (
                        <IonIcon icon={removeOutline} className={cx(styles.icon, styles.removeQuantity)} />
                      )}
                    </IonCol>
                    <IonCol size="6" className={cx(styles.quantityValue)}>
                      <IonLabel>{cartQuantity}</IonLabel>
                    </IonCol>
                    <IonCol
                      size="3"
                      className={cx("ion-text-center ion-no-padding", { disabled: !item?.purchasable })}
                      onClick={() => addToCart(item)}
                    >
                      {item.stockStatus === "instock" && cartQuantity + 1 <= item.stockQuantity && (
                        <IonIcon icon={addOutline} className={cx(styles.icon, styles.addQuantity)} />
                      )}
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </IonCardContent>
            {type === "wishList" && (
              <div
                onClick={(e) => {
                  toggleWish(item.id);
                  e.stopPropagation();
                }}
                className={cx("wishProduct__removeButton")}
              >
                <IonIcon icon={closeOutline} className={"wishList__removeIcon"} />
              </div>
            )}
            {type !== "wishList" && (
              <div className={cx("productsSpecialLeft__heart", "productCommon__heart")}>
                <div
                  onClick={(e) => {
                    toggleWish(item.id);
                    e.stopPropagation();
                  }}
                >
                  <IonIcon
                    className={cx("productHeartIcon")}
                    color="primary"
                    icon={isWishProduct(item.id) ? heart : heartOutline}
                  />
                </div>
              </div>
            )}
          </IonCard>
        )
      ) : // TODO: Другой вид когда переходишь на главное странице по клику показать все
      // TODO: недочеты в верстке и работе счетчика
      type === "order-detail-list" ? (
        //====================================  list view for shop page ====================================
        <IonGrid className={cx("listProductContent")}>
          <IonRow className={cx("listProductContentRow")}>
            <IonCol className={cx("listProductContentImg")} size={"3"} sizeMd={"2"}>
              <Image lazyLoad={lazyload} draggable={false} image={item?.images[0]} />
            </IonCol>
            <IonCol className={cx("listProductContentDesc")}>
              <IonRow>
                <IonCol class="ion-text-left">
                  <span className={cx("descMaxHeight")}>{item.name}</span>
                </IonCol>
              </IonRow>
              <IonRow className={cx("ion-align-items-center")}>
                <IonCol class="ion-text-left" size="6">
                  <span>
                    <p className={cx("listProductCardPrice", "ellipsis")} dangerouslySetInnerHTML={createMarkup()}></p>
                  </span>
                </IonCol>
                <IonCol class="ion-text-right ion-no-padding" size="6">
                  <IonRow class={cx("ion-float-right", styles.buttons)}>
                    <IonButton
                      fill="outline"
                      size="small"
                      color="secondary"
                      onClick={(e) => {
                        removeProductFromCart(item);
                        e.stopPropagation();
                      }}
                    >
                      <p>-</p>
                    </IonButton>
                    <span className="quantity">{cartQuantity}</span>
                    <IonButton
                      fill="outline"
                      size="small"
                      color="secondary"
                      onClick={(e) => {
                        addToCart(item);
                        e.stopPropagation();
                      }}
                    >
                      <p>+</p>
                    </IonButton>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : (
        //  ЗДЕСЬ КАРТОЧКИ КОТОРЫЕ ОТОБРАЖАЮТСЯ ПОСЛЕ ПОИСКА ТОВАРА ИЛИ ПРИ КЛИКЕ ПОКАЗАТЬ ВСЕ АКЦИИ, НОВИНКИ, СПЕЦ.ПРЕДЛОЖЕНИЕ
        <IonGrid className={cx("new-list-view")} onClick={() => showProductDetail(item.id)}>
          <IonRow className={cx("productsSpecial__wrapper")}>
            <IonCol sizeXs="4" sizeSm={"12"} className={cx("product-image", "productSpecial__imageWrapper")}>
              <div className={cx("badges")}>
                {checkProductNew(item) && (
                  <IonBadge color="danger" className={cx("new", "badge")}>
                    {state.config.languageJson.NEW}
                  </IonBadge>
                )}
              </div>
              <Image
                lazyLoad={lazyload}
                draggable={false}
                id="image"
                image={item?.images[0]}
                style={{ maxWidth: "100%", height: "150px" }}
              />

              <div className={cx("productsSpecialLeft__heart", "ion-hide-sm-down")}>
                {type === "list-for-wish" ? (
                  <div
                    onClick={(e) => {
                      toggleWish(item.id);
                      e.stopPropagation();
                    }}
                    className={cx("wishProduct__removeButton")}
                  >
                    <IonIcon icon={closeOutline} className={"wishList__removeIcon"} />
                  </div>
                ) : !isWishProduct(item.id) ? (
                  <div
                    onClick={(e) => {
                      toggleWish(item.id);
                      e.stopPropagation();
                    }}
                  >
                    <IonIcon color="secondary" className={cx("productHeartIcon")} icon={heartOutline} />
                  </div>
                ) : (
                  <div
                    onClick={(e) => {
                      toggleWish(item.id);
                      e.stopPropagation();
                    }}
                  >
                    <IonIcon className={cx("productHeartIcon")} color="secondary" icon={heart} />
                  </div>
                )}
              </div>
            </IonCol>
            <IonCol sizeXs="8" sizeSm={"12"} className={cx("productsSpecial__descriptionWrapper")}>
              <IonRow className={cx("descriptionWrapper", "descriptionWrapper__row")}>
                <div>
                  <IonRow>
                    <IonCol sizeXs="10" className={cx("ion-no-padding")}>
                      <div className={cx("stars-outer")}>
                        <div
                          className={cx("stars-inner")}
                          style={{ width: (parseFloat(item.averageRating ?? "0") / 5) * 100 + "%" }}
                        ></div>
                      </div>
                    </IonCol>
                    <IonCol
                      sizeXs="2"
                      className={cx("productHeartIcons", "ion-text-right ion-no-padding ion-hide-sm-up")}
                    >
                      {type === "list-for-wish" ? (
                        <div
                          onClick={(e) => {
                            toggleWish(item.id);
                            e.stopPropagation();
                          }}
                          className={cx("wishProduct__removeButton")}
                        >
                          <IonIcon icon={closeOutline} className={"wishList__removeIcon"} />
                        </div>
                      ) : !isWishProduct(item.id) ? (
                        <div
                          onClick={(e) => {
                            toggleWish(item.id);
                            e.stopPropagation();
                          }}
                        >
                          <IonIcon color="secondary" className={cx("productHeartIcon")} icon={heartOutline} />
                        </div>
                      ) : (
                        <div
                          onClick={(e) => {
                            toggleWish(item.id);
                            e.stopPropagation();
                          }}
                        >
                          <IonIcon className={cx("productHeartIcon")} color="secondary" icon={heart} />
                        </div>
                      )}
                    </IonCol>
                  </IonRow>
                  <div className={cx("productsSpecial__description")}>{item.name}</div>
                </div>
                <IonRow className={cx("productBasket", "ion-hide-sm-up")}>
                  {(item.stockQuantity > 0 || item.type === ProductType.VARIABLE) && (
                    <IonCol sizeSm={"12"}>
                      <p className={cx("price ellipsis", styles.price)} dangerouslySetInnerHTML={createMarkup()}></p>
                    </IonCol>
                  )}
                  {/* TODO: basket icon and counter*/}
                  <IonCol sizeSm={"12"} className={cx("productBasketWrapper")}>
                    {!cartQuantity ? (
                      <div>
                        {item.stockQuantity > 0 ? (
                          <div
                            className={cx(
                              "ion-text-center ion-no-padding leading-none",
                              { "ion-text-right": type === "wishList" },
                              { disabled: !item?.purchasable }
                            )}
                            onClick={(e) => {
                              addToCart(item);
                              e.stopPropagation();
                            }}
                          >
                            {appSettings?.addToCart ? (
                              <div className={cx(styles.basketButtonList)}>
                                {state.config.languageJson["Add to cart"]}
                              </div>
                            ) : (
                              <IonIcon icon={basketOutline} className={cx("productBasketIcon")} />
                            )}
                          </div>
                        ) : (
                          <div
                            className={cx("ion-text-center ion-no-padding", { "ion-text-right": type === "wishList" })}
                            onClick={(e) => {
                              showProductDetail(item.id);
                              e.stopPropagation();
                            }}
                          >
                            {appSettings?.addToCart ? (
                              <div className={cx(styles.basketButtonList)}>
                                {state.config.languageJson["Add to cart"]}
                              </div>
                            ) : item.stockStatus === "instock" ? (
                              <IonIcon icon={basketOutline} className={cx("productBasketIcon")} />
                            ) : (
                              <span className={cx("out-of-stock")}>{state.config.languageJson["Out of Stock"]}</span>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <IonRow className={cx("quantityCounter")} onClick={(e) => e.stopPropagation()}>
                        <IonCol
                          size="3"
                          className="ion-text-center ion-no-padding"
                          onClick={() => removeProductFromCart(item)}
                        >
                          {item.stockStatus === "instock" && (
                            <IonIcon icon={removeOutline} className={cx(styles.icon, styles.removeQuantity)} />
                          )}
                        </IonCol>
                        <IonCol size="6" className={cx(styles.quantityValue)}>
                          <IonLabel>{cartQuantity}</IonLabel>
                        </IonCol>
                        <IonCol
                          size="3"
                          className={cx("ion-text-center ion-no-padding", { disabled: !item?.purchasable })}
                          onClick={() => addToCart(item)}
                        >
                          {item.stockStatus === "instock" && item.stockQuantity > cartQuantity && (
                            <IonIcon icon={addOutline} className={cx(styles.icon, styles.addQuantity)} />
                          )}
                        </IonCol>
                      </IonRow>
                    )}
                  </IonCol>
                </IonRow>
              </IonRow>
              <IonRow className={cx("ion-hide-sm-down")}>
                {!cartQuantity && (
                  <IonCol sizeSm={"9"} className={cx("ion-no-padding", "productBasket__priceWrapper")}>
                    {(item.stockQuantity > 0 || item.type === ProductType.VARIABLE) && (
                      <p
                        className={cx("price ellipsis", styles.price, "productBasket__price")}
                        dangerouslySetInnerHTML={createMarkup()}
                      ></p>
                    )}
                  </IonCol>
                )}
                <IonCol
                  sizeSm={!cartQuantity ? "3" : "12"}
                  className={cx({ productBasketWrapper: !cartQuantity }, "ion-no-padding")}
                >
                  {!cartQuantity ? (
                    <IonRow>
                      {item.stockQuantity > 0 ? (
                        <IonCol
                          size="12"
                          className={cx("ion-text-right ion-no-padding", { disabled: !item?.purchasable })}
                          onClick={(e) => {
                            addToCart(item);
                            e.stopPropagation();
                          }}
                        >
                          {item.stockStatus === "instock" && (
                            <IonIcon icon={basketOutline} className={cx("productBasketIcon")} />
                          )}
                        </IonCol>
                      ) : (
                        <IonCol
                          size="12"
                          className="ion-text-right ion-no-padding"
                          onClick={(e) => {
                            showProductDetail(item.id);
                            e.stopPropagation();
                          }}
                        >
                          {item.stockStatus === "instock" ? (
                            <IonIcon icon={basketOutline} className={cx("productBasketIcon")} />
                          ) : (
                            <span className={cx("out-of-stock")}>{state.config.languageJson["Out of Stock"]}</span>
                          )}
                        </IonCol>
                      )}
                    </IonRow>
                  ) : (
                    <IonRow className={cx(styles.quantityCounter)} onClick={(e) => e.stopPropagation()}>
                      <IonCol
                        size="3"
                        className="ion-text-center ion-no-padding"
                        onClick={() => removeProductFromCart(item)}
                      >
                        {item.stockStatus === "instock" && (
                          <IonIcon icon={removeOutline} className={cx(styles.icon, styles.removeQuantity)} />
                        )}
                      </IonCol>
                      <IonCol size="6" className={cx(styles.quantityValue)}>
                        <IonLabel>{cartQuantity}</IonLabel>
                      </IonCol>
                      <IonCol
                        size="3"
                        className={cx("ion-text-center ion-no-padding", { disabled: !item?.purchasable })}
                        onClick={() => addToCart(item)}
                      >
                        {item.stockStatus === "instock" && item.stockQuantity > cartQuantity && (
                          <IonIcon icon={addOutline} className={cx(styles.icon, styles.addQuantity)} />
                        )}
                      </IonCol>
                    </IonRow>
                  )}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </div>
  );
};

export default Product;
