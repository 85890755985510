// Import Ionic Components
import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  useIonAlert,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";

// Import React Libraries
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

// Import Styles
import classNames from "classnames/bind";
import styles from "./cart.module.scss";
import "./cart.scss";

// Import Components
import Footer from "../../components/Footer/Footer";
import ProductCard from "../../components/Cart/ProductCard/ProductCard";
import IsEmptyCart from "../../components/Cart/IsEmptyCart/IsEmptyCart";
import TotalPrice from "../../components/Cart/TotalPrice/TotalPrice";
import ContinueButton from "../../components/ContinueButton/ContinueButton";
import CouponUnited from "../../components/Cart/Coupon/CouponUnited";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import PageTitle from "../../components/PageTitle/PageTitle";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import { BonusPoints } from "../../components/Cart/BonusPoints/BonusPoints";
import { useGetSettingsQuery } from "../../providers/settings";
import { useAuth } from "../../contexts/auth-context/AuthContextProvider";
import { ShippingMethod, Product as TProduct } from "../../data/types/entities";
import { useCartData } from "../../providers/cartDataStore";
import { useGetIncludeProductsQuery } from "../../providers/products";
import { CartItemSkeleton } from "../../components/UI/CartItemSkeleton/CartItemSkeleton";
import { useGetCouponMutation } from "../../providers/coupons";
import { useGetShippingMethodsQuery } from "../../providers/shippingAndPayment";
import { useShippingZonesData } from "../../providers/shippingZones";
import { getLowerValueObj } from "../../utils";
import { AppMetricaProvider } from "../../providers/AppMetrica/AppMetrica";

const cx = classNames.bind(styles);

const Cart: React.FC = () => {
  const { user } = useAuth();
  const {
    cartProducts,
    cartTotalValue,
    cartSubtotalValue,
    replaceCartProducts,
    applyCoupon,
    getProductsTotalWeight,
    resetCart,
  } = useCartData();

  const [presentAlert] = useIonAlert();
  const { state } = useContext(AppContext); // For Store
  let history = useHistory(); // For Navigation
  const ts = useMemo(() => state.config.languageJson, []);

  const [shouldCheckProducts, setShouldCheckProducts] = useState(false);

  const { data: appSettings } = useGetSettingsQuery();
  const [minimumOrderAmount, setMinimumOrderAmount] = useState(appSettings?.minimumOrderAmount);

  const { shippingZone } = useShippingZonesData();
  const shippingZoneId = shippingZone?.id || "";

  const { data: products, isFetching: isIncludeProductsLoading } = useGetIncludeProductsQuery({
    include: cartProducts.map((product) => String(product.parentId)),
    options: {
      enabled: shouldCheckProducts,
    },
  });
  const { mutate: getCouponMutation, isLoading: isCouponLoading } = useGetCouponMutation();
  const productsMapRef = useRef<{ [k: string]: TProduct }>({});

  useIonViewWillEnter(() => {
    if (cartProducts) {
      setShouldCheckProducts(true);
    }
  }, [cartProducts]);

  useIonViewWillLeave(() => {
    setShouldCheckProducts(false);
  });

  const shouldEnableShippingQuery =
    (!appSettings?.isShippingZonesEnabled && appSettings?.cartMinAmountFromMethods) || !!shippingZoneId;

  const { data: shippingMethods } = useGetShippingMethodsQuery(String(shippingZoneId), getProductsTotalWeight(), {
    enabled: shouldEnableShippingQuery,
  });

  useEffect(() => {
    const lowerValueObj = getLowerValueObj<ShippingMethod, "minAmount">(shippingMethods || [], "minAmount");

    const minimumOrderAmount = lowerValueObj?.minAmount ?? 0;

    setMinimumOrderAmount(Number(minimumOrderAmount));
  }, [shippingMethods]);

  useEffect(() => {
    if (products) {
      productsMapRef.current = products.reduce((mapObj, product) => {
        mapObj[product.id] = product;
        return mapObj;
      }, {} as { [k: string]: TProduct });

      replaceCartProducts(products);
      setShouldCheckProducts(false);
    }
  }, [products]);

  const showInvalidCouponAlert = () => {
    presentAlert({
      header: ts["Alert"],
      message: ts["Invalid Coupon Code!"],
      buttons: [ts["Ok"]],
    });
  };

  const getCoupon = async (code: string) => {
    if (code) {
      getCouponMutation(code, {
        onSuccess: async (data) => {
          if (data) {
            applyCoupon(data);
          } else {
            showInvalidCouponAlert();
          }
        },
      });
    }
  };

  const proceedToCheckOut = async () => {
    AppMetricaProvider.reportEvent("cartData", { data: cartProducts });
    history.push("/shipping-address");
  };

  const openProductsPage = () => {
    history.push("/search-main-categories");
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showSearchButton={true} />
      </IonHeader>
      <IonContent>
        <ToolbarDesktop breadcrumbs={[{ title: ts["Cart Page"] }]} />
        {/* вывод сообщения когда введен купон  */}
        <IonLoading isOpen={isIncludeProductsLoading} message={ts["Please wait..."]} duration={20000} />
        <div className={`global-content ${cx({ cartContentEmpty: !cartSubtotalValue })}`}>
          {cartProducts?.length === 0 ? (
            <IsEmptyCart
              openProductsPage={() => openProductsPage()}
              languageJsonCartIsEmpty={ts["Your Cart is empty"]}
              languageJsonContinueShopping={ts["Continue Shopping"]}
              languageJsonExplore={ts.Explore}
            />
          ) : (
            <IonGrid className={cx("cartGrid")}>
              <MaxWidthContainer smContainer={true} mdContainer={true} lgContainer={true}>
                <PageTitle hideTitleBreakpoint={"ion-hide-md-down"} title={ts["Cart Page"]} />
                <IonRow className={cx("cartWrapper")}>
                  {/* карточки товаров */}
                  <IonCol sizeLg={"8"} className={cx("cartProducts")}>
                    <IonRow className="flex justify-end">
                      <IonButton fill="clear" size="small" onClick={resetCart}>
                        {ts["Clear"]}
                      </IonButton>
                    </IonRow>
                    {isIncludeProductsLoading
                      ? cartProducts?.map((cartItem) => {
                          return <CartItemSkeleton key={cartItem.id} />;
                        })
                      : cartProducts?.map((cartItem) => {
                          const product = productsMapRef.current[cartItem.parentId];
                          return product ? (
                            <ProductCard
                              className="first-of-type:mt-1"
                              cartItem={cartItem}
                              parentProduct={product}
                              key={cartItem.id}
                            />
                          ) : null;
                        })}
                  </IonCol>
                  {/*<MapComponent />*/}
                  <IonCol sizeLg={"4"} className={cx("cartTotalInfo")}>
                    {/* Блок купонов */}
                    {products?.length !== 0 && appSettings?.showCouponsInCart && (
                      <CouponUnited getCoupon={getCoupon} isCouponLoading={isCouponLoading} />
                    )}
                    {!!user && appSettings?.isBonusPointsEnabled && <BonusPoints />}
                    {/* итоговая цена */}
                    {products?.length !== 0 && (
                      <TotalPrice
                        languageJson={ts}
                        discount={cartSubtotalValue - cartTotalValue}
                        subtotal={cartSubtotalValue}
                        total={cartTotalValue}
                      />
                    )}
                    {/*  cart continue button */}
                    {products?.length !== 0 && !!appSettings && (
                      <IonCard className={cx("ion-hide-md-down")}>
                        <ContinueButton
                          onClick={() => proceedToCheckOut()}
                          disabled={cartSubtotalValue < minimumOrderAmount!}
                          buttonText={
                            cartSubtotalValue < minimumOrderAmount!
                              ? `Не менее ${minimumOrderAmount!} рублей`
                              : ts.Proceed
                          }
                        />
                      </IonCard>
                    )}
                  </IonCol>
                </IonRow>
              </MaxWidthContainer>
            </IonGrid>
          )}
        </div>
        <Footer />
      </IonContent>

      <IonFooter className={cx("oneButtonFooter")}>
        <div className={cx("ion-hide-md-up")}>
          {products?.length !== 0 && !!appSettings && (
            <ContinueButton
              onClick={() => proceedToCheckOut()}
              disabled={cartSubtotalValue < minimumOrderAmount!}
              buttonText={
                cartSubtotalValue < minimumOrderAmount! ? `Не менее ${minimumOrderAmount!} рублей` : ts.Proceed
              }
            />
          )}
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Cart;
