import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { authClient } from "../../data/clients";
import { SmsAuthVariables } from "../../data/types/entities";

const useAuthBySms = (options?: UseMutationOptions<any, any, SmsAuthVariables>) =>
  useMutation(
    ["auth-by-sms"],
    async (variables) => {
      const data = await authClient.authBySmsCode(variables);

      return data;
    },
    options
  );

export default useAuthBySms;
