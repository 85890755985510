// Import React Libraries
import React, { useContext } from 'react';

// Import Ionic Components
import {
  IonFab,
  IonFabButton,
  IonIcon,
  useIonModal,
} from '@ionic/react';


// Import styles
import { helpOutline } from 'ionicons/icons';
import classNames from 'classnames/bind';
import styles from './SendUserRequestFormFab.module.scss'

import SendUserRequestFormModal from '../SendUserRequestFormModal/SendUserRequestFormModal';
import { AppContext } from '../../providers/Redux/Reducers/AppContext';

const cx = classNames.bind(styles);

const SendUserRequestFormFab: React.FC = () => {

  const { state } = useContext(AppContext);

  const handleDismiss = () => {
    dismissModal();
  }

  const [presentModal, dismissModal] = useIonModal(SendUserRequestFormModal, {
    dismissModal: handleDismiss,
    state
  });

  const handleFabButtonClick = () => {
    presentModal({
      swipeToClose: true,
      cssClass: cx("sendFeedbackModal"),
    });

  }

  return <>
    <IonFab className={cx('fab')} horizontal="end" vertical="bottom" slot="fixed">
      <IonFabButton onClick={handleFabButtonClick} className={cx('fabButton')}>
        <IonIcon icon={helpOutline} />
      </IonFabButton>
    </IonFab>
  </>;
}

export { SendUserRequestFormFab };