import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { ordersClient } from "../../data/clients";
import { CreatePaymentPayload, PaymentFormData } from "../../data/types/entities";

const useCreatePaymentMutation = (options: UseMutationOptions<PaymentFormData, any, CreatePaymentPayload> = {}) => {
  return useMutation(
    ["payment", "create"],
    async (payload: CreatePaymentPayload) => {
      return ordersClient.createPayment(payload);
    },
    options
  );
};

export default useCreatePaymentMutation;
