import React from 'react';

// Import Ionic
import {IonLabel} from "@ionic/react";

// Import Styles
import styles from "./ProductDetailInStock.module.scss";
import classNames from "classnames/bind";

interface IProductDetailInStock {
    isInStock: boolean,
    label?: string,
    languageJson: any,
}

const cx = classNames.bind(styles);

const ProductDetailInStock:React.FC<IProductDetailInStock> = ({isInStock = false, languageJson, label}) => {

    if (label?.length){
      return <IonLabel className={cx("stockStatus")} color='danger'>
        {label}
      </IonLabel>
    }

    return <>
        {
            isInStock ? (
                <IonLabel className={cx("stockStatus")} color="success">
                    {languageJson['In Stock']}
                </IonLabel>
            ) : (
                <IonLabel className={cx("stockStatus")} color='danger'>
                    {languageJson['Out of Stock']}
                </IonLabel>
            )
        }
     </>
};

export default ProductDetailInStock;