import React from "react";

import { IonButton, IonCard } from "@ionic/react";
import Product from "../Product/Product";
import { Product as TProduct } from "../../data/types/entities";
import classNames from "classnames/bind";
import styles from "./ProductsSlider.module.scss";
import { CustomSlider } from "../CustomSlider/CustomSlider";

type SliderExtraButton = {
  extraButtonTitle?: string;
  isHaveExtraButton?: boolean;
  extraButtonHandler?: () => void;
};

interface ProductsSliderProps extends SliderExtraButton {
  isHavePagination?: boolean;
  items: TProduct[];
}

const cx = classNames.bind(styles);

const ProductsSlider = ({
  items,
  isHaveExtraButton,
  extraButtonTitle = "All",
  extraButtonHandler,
  isHavePagination = false,
}: ProductsSliderProps) => {
  return (
    <CustomSlider>
      {items.map((item) => {
        return (
          <div key={item.id} className={cx("ion-slide-max-width", "slider__item")}>
            <Product lazyload={false} product={item} type={"main-page-list"} />
          </div>
        );
      })}
      {isHaveExtraButton && (
        <div className={cx("ion-hide-md-up", "ion-slide-max-width", "see-all-slide")}>
          <div className={cx("skeletonWidth", "see-all-wrapper")}>
            <IonCard className={cx("shadow")}>
              <IonButton className={cx("see-all")} onClick={extraButtonHandler}>
                {extraButtonTitle}
              </IonButton>
            </IonCard>
          </div>
        </div>
      )}
    </CustomSlider>
  );
};

export { ProductsSlider };
