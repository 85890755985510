import React from "react";
import { IonIcon, IonSegment, IonSegmentButton, IonText } from "@ionic/react";

import classNames from "classnames/bind";
import styles from "./Switcher.module.scss";

import { PaymentTabButton, ShippingTabButton, TabButton } from "../../types";

const cx = classNames.bind(styles);

interface IShippingMethodTabs<U extends TabButton> {
  buttons: U[];
  setValue: (methodId: U["id"]) => void;
  value?: U["id"];
}

const Switcher = ({ buttons, value, setValue }: IShippingMethodTabs<ShippingTabButton | PaymentTabButton>) => {
  return (
    <div className={`${cx("wrapper")}`}>
      <IonSegment value={value} scrollable={true} className={`${cx("segment")}`}>
        {buttons.map((button) => {
          return (
            <IonSegmentButton
              onClick={() => {
                setValue(button.id);
              }}
              className={cx("segment__button", { "segment__button-active": value === button.id })}
              key={button.id}
              value={button.id}
              disabled={!!button.disabled}
            >
              <IonIcon className={cx("segment__button-icon")} size={"large"} icon={button.icon} />
              <IonText className={cx("segment__button-text")}>{button.title}</IonText>
              {button.description && (
                <IonText className={cx("segment__button-description")}>{button.description}</IonText>
              )}
            </IonSegmentButton>
          );
        })}
      </IonSegment>
    </div>
  );
};

export { Switcher };
