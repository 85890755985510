import { ParhatoAddressObject } from "../providers/ParhatoRESTApi/ParhatoRESTApi";
import { isPlatform } from "@ionic/react";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { CustomerAddress } from "../providers/CustomerAddresses/CustomerAddressesTypes";
import { Address, ShippingMethod } from "../data/types/entities";

export function isPhoneNumberValid(phoneNumber: string | undefined): boolean {
  if (!phoneNumber) {
    return false;
  }

  const test = phoneNumber.replace(/[-\s\(\)]/g, "");
  return /^\+?[0-9]{7,}$/.test(test);
}

export const goToSettings = async () => {
  if (isPlatform("ios")) {
    await NativeSettings.openIOS({ option: IOSSettings.LocationServices });
  } else if (isPlatform("android")) {
    await NativeSettings.openAndroid({ option: AndroidSettings.Location });
  }
};

export const isEmailValid = (email: string) => {
  return /^\w[a-zA-Z0-9.\-_]{0,64}[@]\w[[a-zA-Z]{0,40}[.]\w[a-zA-Z]{1,8}$/.test(email);
};

export const convertGeoObjToParhatoAddressObject = ({ properties, geometry }: any): ParhatoAddressObject => {
  const ParhatoAddressObject: ParhatoAddressObject =
    properties._data.metaDataProperty.GeocoderMetaData.Address.Components.reduce(
      (acc: any, value: { kind: string; name: string }) => {
        acc[value.kind] = value.name;
        return acc;
      },
      {}
    );

  ParhatoAddressObject["text"] = properties._data.text;
  ParhatoAddressObject["point"] = geometry._coordinates.join(" ");
  ParhatoAddressObject["location"] = geometry._coordinates;

  return ParhatoAddressObject;
};

export const getCustomerFullAddress = (
  address: { city?: string; street?: string; house?: string; apartmentNumber?: string; isFlat?: boolean },
  type?: "for_YMap"
) => {
  if (type) {
    return `${address.city}, ${address.street}, ${address.house}`;
  }

  if (address.isFlat) {
    return `${address.city}, ${address.street}, ${address.house}, ${address.apartmentNumber}`;
  } else {
    return `${address.city}, ${address.street}, ${address.house}`;
  }
};

export const getFullAddress = (selectedAddress: ParhatoAddressObject) => {
  let fullAddress = selectedAddress.locality;
  fullAddress += selectedAddress.district ? ", " + selectedAddress.district : "";
  fullAddress += selectedAddress.street ? ", " + selectedAddress.street : "";
  fullAddress += selectedAddress.house ? ", " + selectedAddress.house : "";

  const secondAddress: string = selectedAddress.text.replace(`${selectedAddress.province}, `, "");

  return fullAddress !== "undefined" ? fullAddress : secondAddress;
};

export const isBetweenEightAndTwenty = (value: string) => {
  return /^.{8,24}$/.test(value);
};

export const hasOneNumber = (value: string) => {
  return /\d/.test(value);
};

export const hasLowercase = (value: string) => {
  return /[a-z]/.test(value);
};

export const hasUppercase = (value: string) => {
  return /[A-Z]/.test(value);
};

const passwordRequirements = [
  {
    enable: false,
    check: (value: string) => !hasUppercase(value),
    message: "The string must contain at least one capital letter",
  },
  {
    enable: false,
    check: (value: string) => !hasLowercase(value),
    message: "The string must contain at least one lowercase letter",
  },
  {
    enable: true,
    check: (value: string) => !isBetweenEightAndTwenty(value),
    message: "The string must be between 8 and 24 characters long",
  },
  {
    enable: true,
    check: (value: string) => !hasOneNumber(value),
    message: "The string must contain at least one number",
  },
];

export function validatePassword(value: string) {
  for (const requirement of passwordRequirements) {
    if (requirement.enable && requirement.check(value)) {
      return requirement.message;
    }
  }
  return "";
}

export function generateObjectsWithId(length: number = 10) {
  const objectList = [...Array(length).keys()].map((el) => {
    return { id: el };
  });
  return objectList;
}

export const convertCustomerAddress = (customerAddress: CustomerAddress): Address => {
  const address: Address = {
    id: customerAddress.id,
    apartmentNumber: customerAddress?.apartmentNumber ?? "",
    city: customerAddress?.city ?? "",
    fullAddress: customerAddress ? getCustomerFullAddress(customerAddress) : "",
    state: customerAddress?.state ?? "",
    street: customerAddress?.street ?? "",
    entrance: customerAddress?.entrance,
    floor: customerAddress?.floor,
    house: customerAddress?.house,
    intercom: customerAddress?.intercom,
    isFlat: customerAddress?.isFlat,
    latitude: customerAddress?.location ? customerAddress?.location.coordinates[0] : "",
    longitude: customerAddress?.location ? customerAddress?.location.coordinates[1] : "",
  };

  return address;
};

export const generateDeliveryRangeText = (shippingMethod: ShippingMethod) => {
  const min = shippingMethod.minDeliveryDays ?? 0;
  const max = shippingMethod.maxDeliveryDays ?? 0;

  const now = new Date();

  const minDate = new Date();
  minDate.setDate(now.getDate() + min);

  const maxDate = new Date();
  maxDate.setDate(now.getDate() + max);

  return `${minDate.toLocaleDateString()} - ${maxDate.toLocaleDateString()}`;
};

export const getCurrentCurrencySymbol = (): string => {
  return "₽";
};

export const getLowerValueObj = <T, K extends keyof T>(arrayOfObjects: T[], objKey: K): T | null => {
  let parsedObjects = arrayOfObjects?.map((obj) => ({
    ...obj,
    [objKey]: parseInt(String(obj[objKey])),
  }));

  let lowerObj = parsedObjects?.reduce((min, obj) => (obj[objKey] < min[objKey] ? obj : min), parsedObjects[0]);

  return lowerObj ?? null;
};
