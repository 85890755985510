import BaseClient from "../base-client";
import { IShippingMethodsClient } from "../../types/clients";
import { PickupPoint, ShippingMethod } from "../../types/entities";
import {
  PickupPointsByZoneIdDocument,
  PickupPointsByZoneIdQuery,
  ShippingMethodsByIdDocument,
  ShippingMethodsByIdQuery,
} from "../../../generated/graphql";
import { client } from "../../../apollo";
import { convertShippingMethod, getCurrentShippingZoneId } from "./utils";

class ShippingMethodsClient extends BaseClient implements IShippingMethodsClient {
  async getShippingMethods(zoneId?: string, productsWeight?: number): Promise<ShippingMethod[]> {
    const methods: ShippingMethod[] = await client
      .query<ShippingMethodsByIdQuery>({
        query: ShippingMethodsByIdDocument,
        variables: {
          zoneId,
          productsWeight,
        },
      })
      .then((res) => {
        if (res.data) {
          return res.data.customerShippingMethodsByZoneId.edges.map(({ node }) => convertShippingMethod(node));
        }

        return [];
      });

    return methods;
  }

  async getPickupPoints(): Promise<PickupPoint[]> {
    const points: PickupPoint[] = await client
      .query<PickupPointsByZoneIdQuery>({
        query: PickupPointsByZoneIdDocument,
        variables: {
          zoneId: getCurrentShippingZoneId(),
        },
      })
      .then((res) => {
        if (res.data) {
          return res.data.pickupPointsByZoneId;
        }

        return [];
      });

    return points;
  }
}

export default ShippingMethodsClient;
