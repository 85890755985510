import { Product as TProduct } from "../../data/types/entities";

const getLowestPriceVariation = (allVariations: Array<TProduct>): TProduct | null => {
  const instockVariations = allVariations.filter((variation) => variation.stockStatus === "instock");
  if (instockVariations.length > 0) {
    let lowestPriceVariation = instockVariations[0];
    instockVariations.forEach((variation) => {
      if (variation.price < lowestPriceVariation.price) {
        lowestPriceVariation = variation;
      }
    });

    return lowestPriceVariation;
  }

  return null;
};

export { getLowestPriceVariation };
