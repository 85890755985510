import React from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import classNames from "classnames/bind";
import styles from "./OrderSummary.module.scss";
import { Address, ShippingMethod, ShippingMethodDeliveryType } from "../../data/types/entities";
import { generateDeliveryRangeText } from "../../utils";

interface IOrderSummaryDetails {
  title: string;
  languageJson: any;
  address: Address;
  deliveryDate?: Date | null;
  deliveryInterval: string;
  shippingMethod: ShippingMethod | null;
  shippingIsDefine: any;
  paymentMethodTitle: string;
}

const cx = classNames.bind(styles);

const OrderSummaryDetails: React.FC<IOrderSummaryDetails> = ({
  title,
  languageJson,
  address,
  deliveryDate,
  deliveryInterval,
  shippingMethod,
  shippingIsDefine,
  paymentMethodTitle,
}) => {
  return (
    <IonCard className={cx("orderSummary")}>
      <IonCardHeader className={cx("orderSummaryHeader")} color="primary">
        <IonCardTitle className={cx("orderSummaryTitle")}>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className={cx("orderSummaryContent")}>
        {shippingIsDefine && (
          <>
            <IonCardSubtitle className={cx("orderSummarySubtitle")}>{languageJson["Shipping Address"]}</IonCardSubtitle>
            <p className={cx("orderSummaryDescription")}>
              <span>{`${languageJson["Address"]}: `}</span>
              {address.fullAddress}
              <br />
              {address.isFlat && (
                <>
                  <span>{`${languageJson["Entrance"]}: `}</span>
                  {address.entrance}
                  <br />
                  {!!address.intercom && (
                    <>
                      <span>{`${languageJson["Intercom code"]}: `}</span>
                      {address.intercom}
                      <br />
                    </>
                  )}
                  <span>{`${languageJson["Floor"]}: `}</span>
                  {address.floor}
                  <br />
                  <span>{`${languageJson["Apartment number"]}: `}</span>
                  {address.apartmentNumber}
                </>
              )}
            </p>
          </>
        )}

        {deliveryDate && shippingMethod?.methodDeliveryType === ShippingMethodDeliveryType.default && (
          <>
            <IonCardSubtitle className={cx("orderSummarySubtitle")}>
              {languageJson["Delivery date and interval"]}
            </IonCardSubtitle>
            <p className={cx("orderSummaryDescription")}>
              <span>Дата: </span>
              {deliveryDate.toLocaleDateString()}
              {deliveryInterval && (
                <>
                  <br />
                  <span>Время: </span>
                  {deliveryInterval}
                </>
              )}
            </p>
          </>
        )}

        {shippingMethod?.methodDeliveryType === ShippingMethodDeliveryType.nextDay && (
          <>
            <IonCardSubtitle className={cx("orderSummarySubtitle")}>{languageJson["Delivery date"]}</IonCardSubtitle>
            <p className={cx("orderSummaryDescription")}>{generateDeliveryRangeText(shippingMethod)}</p>
          </>
        )}

        <IonCardSubtitle className={cx("orderSummarySubtitle")}>{languageJson["Payment Method"]}</IonCardSubtitle>
        <p className={cx("orderSummaryDescription")}>{paymentMethodTitle}</p>
        {shippingMethod && (
          <>
            <IonCardSubtitle className={cx("orderSummarySubtitle")}>{languageJson["Shipping Method"]}</IonCardSubtitle>
            <p className={cx("orderSummaryDescription")}>{shippingMethod.title}</p>
          </>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default OrderSummaryDetails;
