import React, { useEffect, useState } from "react";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import classNames from "classnames/bind";
import styles from "./OrderSummary.module.scss";
import { Product as TProduct } from "../../data/types/entities";
import { CartDataProductsItem } from "../../providers/cartDataStore";
import ProductCard from "../Cart/ProductCard/ProductCard";
import { useGetSettingsQuery } from "../../providers/settings";

interface IOrderSummaryProductsList {
  productsList: TProduct[];
  cartProducts: CartDataProductsItem[];
  title: string;
}

const cx = classNames.bind(styles);

const OrderSummaryProductsList: React.FC<IOrderSummaryProductsList> = ({ productsList, cartProducts, title }) => {
  const [productsMap, setProductsMap] = useState<{ [k: string]: TProduct }>({});
  const { data: appSettings } = useGetSettingsQuery();

  useEffect(() => {
    if (productsList) {
      const _prodMap = productsList.reduce((mapObj, product) => {
        mapObj[product.id] = product;
        return mapObj;
      }, {} as { [k: string]: TProduct });
      setProductsMap(_prodMap);
    }
  }, [productsList]);

  return (
    <IonCard className={cx("orderSummary")}>
      <IonCardHeader className={cx("orderSummaryHeader")} color="primary">
        <IonCardTitle className={cx("orderSummaryTitle")}>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className={cx("orderSummaryContent")}>
        {cartProducts?.map((cartItem) => {
          const product = productsMap[cartItem.parentId];
          return product ? (
            <ProductCard
              showCounter={appSettings?.showOrderPageProductCardCounter}
              cartItem={cartItem}
              parentProduct={product}
              key={cartItem.id}
            />
          ) : null;
        })}
      </IonCardContent>
    </IonCard>
  );
};

export default OrderSummaryProductsList;
