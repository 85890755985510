import React from "react";

import { IonContent, IonHeader, IonPage } from "@ionic/react";

import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import { MyAccountComponent } from "../../components/MyAccountComponent/MyAccountComponent";

const MyAccountPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showShippingZone />
      </IonHeader>
      <IonContent>
        <MyAccountComponent />
      </IonContent>
    </IonPage>
  );
};

export { MyAccountPage };
