import React from "react";
import { IonSkeletonText } from "@ionic/react";

const ProductSkeleton = () => {
  return (
    <div>
      <IonSkeletonText className="sktImg" animated style={{ height: 110 }} />
      <IonSkeletonText className="sktName" animated style={{ height: 15 }} />
      <IonSkeletonText className="sktImg" animated style={{ height: 25 }} />
    </div>
  );
};

export default ProductSkeleton;
