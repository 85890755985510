import { ReviewsSetOrderByIdQuery } from "../../generated/graphql";
export type Banner = {
  id: string;
  imageSrc: string;
  type?: string;
  link?: string;
};

export type CategoriesMapObj = {
  allCategories: Category[];
  subCategories: Category[];
  mainCategories: Category[];
};

export type Category = {
  id: string;
  description?: string;
  image?: {
    alt?: string;
    id?: string;
    name?: string;
    src: string;
  };
  name: string;
  parent?: string;
  count?: number;
};

export type ProductAttribute = {
  id: number | string;
  name: string;
  options?: string[];
  option?: string;
  position?: number;
  visible?: boolean;
  variation?: boolean;
};

export type ProductDefaultAttribute = {
  id: number | string;
  name: string;
  option: string;
};

export enum ProductType {
  SIMPLE = "simple",
  VARIABLE = "variable",
  VARIATION = "variation",
}

export enum ProductImageTypes {
  base64 = "base64",
  img = "img",
}

export type ProductImage = {
  src: string;
  type: keyof typeof ProductImageTypes;
};

export type Product = {
  id: string;
  name: string;
  price: string;
  onSale: boolean;
  type: ProductType;
  stockStatus: string;
  description: string;
  attributes: ProductAttribute[];
  categories: { id: string; name: string; slug: string }[];
  stockQuantity: number;
  parentId?: string;
  averageRating?: string;
  purchasable: boolean;
  images: ProductImage[];
  priceHtml?: string;
  soldIndividually: boolean;
  variations?: string[];
  defaultAttributes?: ProductDefaultAttribute[];
  groupedProducts?: string[];
  dateCreated?: string;
  isHit?: boolean;
  isNew?: boolean;
  discountPrice: string | null;
  weight?: string;
  videoUrl?: string;
};

export type ProductReview = {
  dateCreated: string;
  id: string;
  review: string;
  rating: number;
  fullName: string;
};

export type AppSettings = {
  aboutPageId: number; // only on state and reducers
  privacyPageId: number; // only on state and reducers
  termsPageId: number; // only on state and reducers
  refundPageId: number; // we got on response empty string

  homePage: number;
  categoryPage: number;
  introPage: number;
  myOrdersPage: number;
  newsPage: number;
  wishListPage: number;
  shippingAddressPage: number;
  contactUsPage?: string | TrustedHTML;
  editProfilePage: number;
  settingPage: number;
  addressPage: boolean; // only on state and reducers
  downloadPage: boolean;
  wishListIcons: boolean;
  cardProduct: boolean;

  multiLanguage: boolean;
  multiCurrency: boolean;

  showVendorInfo: boolean;
  showWcVendorInfo: boolean;

  enableGeoFencing: boolean; // only on state and reducers
  enableDeliveryTracking: boolean; // only on state and reducers
  enableWpPointReward: boolean;

  rateApp: number;
  sharedData: {
    title: string;
    text: string;
    iosUrl: string;
    androidUrl: string;
  };
  shareApp: number; // only on state and reducers
  defaultIcons: boolean; // only on state and reducers
  currentSettings: number; // only on state and reducers
  checkOutPage: number;

  email: string; // only on state and reducers, also on contact us, but unused
  latitude: string; // only on state and reducers, also on contact us, but unused
  longitude: string; // only on state and reducers, also on contact us, but unused
  newProductDuration: string;
  addToCart: string;

  siteUrl: string;
  orderCancelButton: boolean;
  cancelOrderTime: number;
  minimumOrderAmount: number;
  showNewVersionAlert: boolean;
  newVersionAlertTitle: string;
  newVersionAlertMessage: string;
  newVersionAlertButtonText: string;
  minVersionAndroid: string;
  minVersionIOS: string;
  showProductReviews: boolean;
  shouldCheckItemsAvailability: boolean;
  showCouponsInCart: boolean;
  orderHistoryPagination: boolean;
  isShippingZonesEnabled: boolean;
  showDefaultShippingZone: boolean;
  isDeliveryDateEnabled: boolean;
  isBonusPointsEnabled: boolean;
  isReferralEnabled: boolean;

  deleteAccountLink: string;
  showSendCustomerRequestButton: boolean;
  isCustomerAdressesEnabled: boolean;
  updateUserForm: {
    isUpdatePasswordEnabled: boolean;
    showPhoneNumberInput: boolean;
  };
  isOnlinePaymentEnabled: boolean;
  privacyPolicyPageLink: string;
  isSeoOnHomePageEnabled: boolean;
  companyPhoneNumber: string;
  isContentInFooterShown: boolean;
  hasCardLoyalty: boolean;
  showSliderCatalogButton: boolean;

  addressSettings: {
    showCustomerAddresses: boolean;
    showFlatDelivery: boolean;
    addressInputDependsOnShippingMethod: boolean;
  };

  homePageCategories: {
    customersChoice: {
      title: string;
      isEnabled: boolean;
    };
    featured: {
      title: string;
      isEnabled: boolean;
    };
    sale: {
      title: string;
      isEnabled: boolean;
    };
    latest: {
      title: string;
      isEnabled: boolean;
    };
  };
  showOrderPageProductCardCounter: boolean;
  cartMinAmountFromMethods: boolean;
  siteDomain: string;

  signUpPage: {
    nameFieldEnabled: boolean;
    lastNameFieldEnabled: boolean;
  };

  loginPage: {
    phoneOrEmailFieldEnabled: boolean;
  };
  notifications?: {
    cartRemindDelayMinutes: number;
  };

  orderDetailPage: {
    reviewOrderEnabled: boolean;
    repeatOrderEnabled: boolean;
  };
};

export type TOrder = {
  id: string | number;
  total: number;
  number?: string;
  status?: string;
  paymentStatus?: string;
  lineItems?: LineItem[];
  dateCreated?: string;
  shippingLine?: ShippingLine;
  currencySymbol?: string;
  discountTotal?: string;
  shippingTotal?: string;
  customerNote?: string;
  deliveryDate?: Date;
  deliveryTime?: string;
  paymentMethod?: PaymentMethod;
  customer?: {
    whatsappNumber?: string;
    firstName: string;
    lastName: string;
    email?: string;
    phone: string;
    id?: string;
  };
  address?: Address;
  driverRating?: any;
  rewardPoints?: number;
  coupons?: Partial<Coupon>[];
};

export type ProductsQueryParams = {
  order?: string;
  orderby?: string;
  featured?: boolean;
  on_sale?: boolean;

  saleType?: saleType;
  categoryId?: string | number;
  pageSize?: string;
  pageNumber?: string;
  sortOrder?: string;
  minPrice?: string;
  maxPrice?: string;
  keyword?: string;
};

export type SearchProductsQueryParams = {
  pageNumber: number;
  keyword: string;
};

export type User = {
  id: string;
  token: string;
  firstName: string;
  lastName: string;
  userName?: string;
  email?: string;
  phone?: string;
  discountCardNumber?: string;
};

export type UserWithPassword = User & {
  password?: string;
};

export type LoginVariables = {
  username: string;
  password: string;
};

export type SmsAuthVariables = {
  phone: string;
  verificationCode: string;
  verificationCodeId: string;
  firstName?: string;
  lastName?: string;
};

export type ResetPasswordVariables = {
  email?: string;
  phone?: string;
  newPassword?: string;
  verificationCode?: string;
  verificationCodeId?: string;
};

export type VerifySMSCodeVariables = {
  code: string;
  phoneNumber: string;
  codeID: string;
};

export type RegisterUserCredentials = {
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
  referal?: {
    referralLink: string;
    ownVersionReferral: string;
  };
  verification?: {
    verificationCode: string;
    verificationCodeId: string;
  };
};

export type RegisterResponse = {
  userName: string;
};

export type VerificationCodeResponse = {
  verificationCodeId: string;
  phoneNumber: string;
};

export enum ShippingMethodType {
  flatRate = "flat_rate",
  freeShipping = "free_shipping",
  taxiShipping = "taxi_shipping",
  pickupPoint = "pickup_point",
  courier = "courier",
}

export enum PaymentMethodIdEnum {
  cash = "cash",
  card = "card",
}

export enum saleType {
  featured = 1,
  on_sale = 2,
}

// export type ShippingMethodSettings = {
//   id: string;
//   label?: string;
//   description?: string;
//   type?: string;
//   value: string;
//   default?: string;
//   tip?: string;
//   placeholder?: string;
// };

export enum ShippingMethodDeliveryType {
  default = "DEFAULT",
  nextDay = "NEXT_DAY",
}

export type ShippingMethod = {
  id: string;
  enabled: boolean;
  description: string;
  title: string;
  minAmount?: string;
  cost?: string;
  methodId: string;
  methodDeliveryType: ShippingMethodDeliveryType;
  methodType: ShippingMethodType;
  order?: number;
  minDeliveryDays?: number;
  maxDeliveryDays?: number;
};

export type PaymentMethod = {
  id: string;
  title: string;
  enabled?: boolean;
  type: PaymentMethodIdEnum;
};

export type ShippingZone = {
  id: string;
  name: string;
};

export type ShippingLine = {
  methodId: string;
  methodTitle: string;
  shipId: string | number;
  total: string;
};

export type PickupPoint = {
  id: string;
  name: string;
  address: string;
  hours?: string;
  latitude: string;
  longitude: string;
};

export type Address = {
  id?: string;
  name?: string;
  state?: string;
  city?: string;
  street?: string;
  apartmentNumber?: string;
  fullAddress: string;
  entrance?: string;
  floor?: string;
  house?: string;
  intercom?: string;
  isFlat?: boolean;
  latitude?: string;
  longitude?: string;
};

export type CreateOrderData = Omit<TOrder, "id" | "number" | "paymentMethod" | "shippingLine"> &
  Required<Pick<TOrder, "paymentMethod" | "shippingLine">>;

export interface LineItem {
  id: string;
  quantity: number;
  variationId?: string;

  name?: string;
  image?: ProductImage;
  productId?: number | string;
  total?: string;
  subtotal?: string;
  price?: string;
}

export type CreatePaymentPayload = {
  amount: number;
  orderNumber: string;
  orderId: string;
};

export type PaymentFormData = {
  orderId: string;
  formUrl: string;
};

export interface DeliveryInterval {
  id: string;
  text: string;
}

export enum CouponDiscountType {
  PERCENT = "percent",
  FIXED_CART = "fixed_cart",
  FIXED_PRODUCT = "fixed_product",
}

export type Coupon = {
  id: string;
  code: string;
  discountType: CouponDiscountType;
  discountAmount: number;
  dateExpires?: string;
  minimumAmount?: number;
  maximumAmount?: number;
  individualUse?: boolean;
  productIds?: number[];
  excludedProductIds?: number[];
  freeShipping?: boolean;
  productCategories?: number[];
  excludedProductCategories?: number[];
  excludeSaleItems?: boolean;
};

// YYYY-MM-DD string
export type ISODate = `${number}-${number}-${number}`;

export type ReviewSet = {
  reviewSet: ReviewsSetOrderByIdQuery["customerOrderById"]["reviewSet"]["edges"][number]["node"];
  orderNumber: ReviewsSetOrderByIdQuery["customerOrderById"]["orderNumber"];
};
