import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import WooComAPI from "./providers/WooComAPI/woo-com-api";

const endpoint = createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

const asyncAuthLink = setContext(async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists

  return {
    headers: {
      ...headers,
      Authorization: WooComAPI.getBearerAuthToken() || "",
    },
  };
});

const client = new ApolloClient({
  link: asyncAuthLink.concat(endpoint),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});

export { client };
