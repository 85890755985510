import React from "react";
import { IonAlert, isPlatform } from "@ionic/react";
import { goToSettings } from "../../../utils/utils";
import { Geolocation } from "@capacitor/geolocation";

interface LocationServiceEnableAlertProps {
  isOpen: boolean;
  onDismiss: () => void;
  ts: any;
}

interface LocationPromptAlertProps {
  ts: any;
  message: string;
  showLocationPrompt: boolean;
  setShowLocationPrompt: (boolean: boolean) => void;
}
 
export const LocationServiceEnableAlert = ({ isOpen, onDismiss, ts }: LocationServiceEnableAlertProps) => {
  return (
    <IonAlert
      isOpen={isOpen}
      buttons={[
        {
          role: "cancel",
          text: ts["Cancel"],
          handler: () => {
            onDismiss();
          },
        },
        {
          text: ts["Go to settings"],
          handler: async () => {
            await goToSettings();
          },
        },
      ]}
      onDidDismiss={() => onDismiss()}
      message={ts["Unable to get your location. Please enable location services and try again"]}
    />
  );
};

export const LocationPromptAlert = ({
  showLocationPrompt,
  setShowLocationPrompt,
  message,
  ts,
}: LocationPromptAlertProps) => {
  return (
    <IonAlert
      isOpen={showLocationPrompt}
      header={ts["Location Access"]}
      message={message}
      buttons={[
        {
          text: "OK",
          handler: async () => {
            if (isPlatform("capacitor")) {
              await Geolocation.requestPermissions({ permissions: ['location'] });
            } else {
              return true;
            }
          },
        },
      ]}
      onDidDismiss={() => setShowLocationPrompt(false)}
    />
  );
};
