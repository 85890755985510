import { Category, CreateOrderData, Product, ProductImageTypes, ProductType, TOrder, User } from "../../types/entities";
import {
  IrsKomCategory,
  IrsKomOrderHistory,
  IrsKomOrderHistoryItem,
  IrsKomOrderSubmitRequestBody,
  IrskomUser,
  IrskomProductDetails,
  IrskomProductList,
} from "./types";

const baseUrl = process.env.REACT_APP_IRSKOM_URL;

const mapIconToObject = {
  CatalogIcon1: "images/svg/catalog-1.svg",
  CatalogIcon2: "images/svg/catalog-2.svg",
  CatalogIcon3: "images/svg/catalog-3.svg",
  CatalogIcon4: "images/svg/catalog-4.svg",
  CatalogIcon5: "images/svg/catalog-5.svg",
  CatalogIcon6: "images/svg/catalog-6.svg",
  CatalogIcon7: "images/svg/catalog-7.svg",
  CatalogIcon8: "images/svg/catalog-8.svg",
  CatalogIcon9: "images/svg/catalog-9.svg",
  CatalogIcon10: "images/svg/catalog-10.svg",
  CatalogIcon11: "images/svg/catalog-11.svg",
  CatalogIcon12: "images/svg/catalog-12.svg",
  CatalogIcon13: "images/svg/catalog-13.svg",
  CatalogIcon14: "images/svg/catalog-14.svg",
  CatalogIcon15: "images/svg/catalog-15.svg",
  CatalogIcon16: "images/svg/catalog-16.svg",
  CatalogIcon17: "images/svg/catalog-17.svg",
  CatalogIcon18: "images/svg/catalog-18.svg",
  CatalogIcon19: "images/svg/catalog-19.svg",
  CatalogIcon20: "images/svg/catalog-20.svg",
  CatalogIcon21: "images/svg/catalog-21.svg",
  CatalogIcon22: "images/svg/catalog-22.svg",
  CatalogIcon23: "images/svg/catalog-23.svg",
};

const _convertTreeCategories = (treeCategories: IrsKomCategory[], acc: Category[], parent: string | null = null) => {
  for (let cat of treeCategories) {
    acc.push({
      id: cat.id,
      count: cat.count,
      name: cat.name,
      parent: parent ?? "",
      image: {
        // have to point to the website for the icons
        //https://irskom.ru/images/svg/catalog-1.svg
        src: cat?.icon?.startsWith("http")
          ? cat.icon
          : `${baseUrl}/${mapIconToObject[cat.icon as keyof typeof mapIconToObject]}`,
      },
    });

    if (cat.children?.length) {
      _convertTreeCategories(cat.children, acc, cat.id);
    }
  }

  return acc;
};

export const convertTreeCategories = (treeCategories: IrsKomCategory[]): Category[] => {
  return _convertTreeCategories(treeCategories, []);
};

export const convertOrderHistory = (order: IrsKomOrderHistory): TOrder => {
  return {
    id: order.id,
    dateCreated: order.date,
    number: order.number,
    address: {
      fullAddress: order.address,
    },
    status: order.deliveryStatus,
    paymentStatus: order.paymentStatus,
    total: order.totalAmount,
  };
};

export const convertOrderHistoryDetails = (
  orderItems: IrsKomOrderHistoryItem[],
  orderDetail: IrsKomOrderHistory
): TOrder => {
  return {
    id: orderDetail.id,
    number: orderDetail.number,
    dateCreated: orderDetail.date,
    status: orderDetail.deliveryStatus,
    paymentStatus: orderDetail.paymentStatus,
    total: orderDetail.totalAmount,

    lineItems: orderItems.map((orderItem) => ({
      id: orderItem.id,
      name: orderItem.name,
      image: { src: orderItem.image, type: ProductImageTypes.base64 },
      quantity: orderItem.count,
      total: String(orderItem.amount),
      sku: orderItem.code,
    })),
    address: {
      fullAddress: orderDetail.address,
    },
  };
};

export const convertTORderToIrskomReqBody = (order: CreateOrderData): IrsKomOrderSubmitRequestBody => {
  return {
    comment: `${order.customer?.firstName} ${order.customer?.lastName} ${order.customer?.phone} ${order.customerNote}`,
    deliveryAddress: order.address?.fullAddress ?? "",
    issuePointId: order.address?.id ?? "",
    deliveryType: String(order.shippingLine?.shipId) ?? "0",
    paymentType: order.paymentMethod?.id ?? "",
    orderItems: order.lineItems?.map((item) => ({
      commodityId: String(item.id),
      count: item.quantity,
    })),
  };
};

export const convertIrskomUserToUser = (user: IrskomUser): User => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    discountCardNumber: user.discountCardNumber,
    phone: user.phoneNumber,
    email: "",
    token: "",
    userName: user.phoneNumber,
  };
};

export const converterIrskomProductList = (productItem: IrskomProductList): Product => {
  return {
    id: productItem.id,
    name: productItem.name,
    price: String(productItem.price),
    onSale: productItem.isBestPrice,
    discountPrice: String(productItem.discountPrice),
    stockStatus: "instock",
    description: "",
    attributes: [],
    stockQuantity: productItem.countLeft,
    type: ProductType.SIMPLE,
    purchasable: true,
    images: [{ src: productItem.imageUrl, type: ProductImageTypes.base64 }],
    soldIndividually: false,
    isHit: productItem.isHit,
    isNew: productItem.isNew,
    categories: [],
  };
};

export const converterIrskomProductDetails = (productItem: IrskomProductDetails): Product => {
  return {
    id: productItem.id,
    name: productItem.name,
    price: String(productItem.price),
    onSale: productItem.isBestPrice,
    discountPrice: String(productItem.discountPrice),
    stockStatus: "instock",
    description: "",
    attributes: [],
    stockQuantity: productItem.countLeft,
    type: ProductType.SIMPLE,
    purchasable: true,
    images: productItem.images[0] ? [{ src: productItem.images[0], type: ProductImageTypes.base64 }] : [],
    soldIndividually: false,
    isHit: productItem.isHit,
    isNew: productItem.isNew,
    categories: [{ id: productItem.categoryId, name: "", slug: "" }],
    videoUrl: productItem.videoUrl ?? undefined,
  };
};
