import React, { useContext } from "react";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

// Import Ionic
import { IonCol, IonIcon, IonRow, isPlatform } from "@ionic/react";

// Import Icons
import { bookmark } from "ionicons/icons";

// Import Styles
import classNames from "classnames/bind";
import "./HomeProductsSection.css";
import styles from "./HomeProductsSection.module.scss";

// Import Components
import { Product as TProduct } from "../../data/types/entities";
import { ProductsSlider } from "../ProductsSlider/ProductsSlider";

interface IHomeProductsSection {
  items: TProduct[];
  openProducts: () => void;
  sectionTitle: string;
  buttonTitle: string;
}

const cx = classNames.bind(styles);

const HomeProductsSection: React.FC<IHomeProductsSection> = ({ items, openProducts, sectionTitle, buttonTitle }) => {
  const { state } = useContext(AppContext);
  return (
    <div className={cx("wrapper", "productList")}>
      <IonRow className={cx("top-icon-header", "ion-justify-content-between", "productListWrapper")}>
        <IonCol size="0.5" className={cx("productListBookmark")}>
          <IonIcon icon={bookmark} />
        </IonCol>
        <IonCol className="ion-align-self-center">
          <h5 className={cx("productListTitle")} onClick={openProducts}>
            {sectionTitle}
          </h5>
        </IonCol>
        <span onClick={openProducts} className={cx("ion-hide-md-down ion-text-right", "productListSeeAll")}>
          {state.config.languageJson["Shop More"]}
        </span>
      </IonRow>
      <ProductsSlider
        items={items}
        extraButtonTitle={state.config.languageJson["All"]}
        extraButtonHandler={openProducts}
        isHavePagination={isPlatform("desktop")}
        isHaveExtraButton={true}
      />
    </div>
  );
};

export default HomeProductsSection;
