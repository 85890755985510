import { Component, ErrorInfo } from "react";
import { AppMetricaProvider } from "./AppMetrica";
import { history } from "../Redux/Reducers/history";

class AppMetricaErrorBoundary extends Component {
  constructor(props: Component["props"]) {
    super(props);
    history.listen((loc) => {
      this.historyTrace.push(`${history.action} ${loc.pathname}`);
    });
  }

  historyTrace: string[] = [];

  componentDidCatch(error: Error, info: ErrorInfo) {
    AppMetricaProvider.reportError(error.message, `${this.historyTrace.join("\n")}\n${error.stack}`);
  }

  render() {
    return this.props.children;
  }
}

export { AppMetricaErrorBoundary };
