// Import Ionic Components
import {
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  isPlatform,
  useIonToast,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { Browser } from "@capacitor/browser";
import { App } from "@capacitor/app";
import { PluginListenerHandle } from "@capacitor/core";

// Import React Libraries
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";

import dayjs from "dayjs";

// Import providers
import { AppMetricaProvider } from "../../providers/AppMetrica/AppMetrica";

// Import Css File
import classNames from "classnames/bind";
import styles from "./order.module.scss";

// Import custom components
import ContinueButton from "../../components/ContinueButton/ContinueButton";
import Footer from "../../components/Footer/Footer";
import OrderSummaryCoupons from "../../components/OrderSummary/OrderSummaryCoupons";
import OrderSummaryDetails from "../../components/OrderSummary/OrderSummaryDetails";
import OrderSummaryNote from "../../components/OrderSummary/OrderSummaryNote";
import OrderSummaryProductsList from "../../components/OrderSummary/OrderSummaryProductsList";
import OrderSummaryTotal from "../../components/OrderSummary/OrderSummaryTotal";
import OrderSummaryWhatsapp from "../../components/OrderSummary/OrderSummaryWhatsapp";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import {
  CreateOrderData,
  LineItem,
  PaymentMethodIdEnum,
  ProductType,
  ShippingMethodDeliveryType,
} from "../../data/types/entities";
import { useAuth } from "../../contexts/auth-context";
import { useCreateOrderMutation, useCreatePaymentMutation } from "../../providers/Orders";
import { useOrderData } from "../../providers/orderDataStore";
import { useGetSettingsQuery } from "../../providers/settings";
import { useCartData } from "../../providers/cartDataStore";
import { useGetIncludeProductsQuery } from "../../providers/products";
import { Coupon } from "../../providers/coupons";
import { getCurrentLanguage } from "../../providers/Redux/Reducers/state";

const cx = classNames.bind(styles);
const ts = getCurrentLanguage();

const Order = () => {
  const { user } = useAuth();
  let history = useHistory(); // For Navigation
  const { cartProducts, cartSubtotalValue, cartTotalValue, coupons, resetCart, rewardPoints } = useCartData();

  const [showLoading, setShowLoading] = useState(false); // For Loading
  const [showToast] = useIonToast();

  const { mutate: createOrderMutation } = useCreateOrderMutation();
  const { data: appSettings } = useGetSettingsQuery();
  const { data: products } = useGetIncludeProductsQuery({
    include: cartProducts.map((product) => product.parentId),
    options: {
      enabled: !!cartProducts.length,
    },
  });
  const order = useOrderData();

  const { mutate: createPaymentMutation } = useCreatePaymentMutation();

  const submit = async () => {
    setShowLoading(true);

    const requestData: CreateOrderData = {
      deliveryDate:
        order.shippingMethod?.methodDeliveryType === ShippingMethodDeliveryType.default
          ? order.deliveryDate ?? undefined
          : undefined,
      deliveryTime:
        order.shippingMethod?.methodDeliveryType === ShippingMethodDeliveryType.default
          ? order.deliveryInterval
          : undefined,

      paymentMethod: order.paymentMethod!,

      address: {
        city: order.address?.city ?? "",
        house: order.address?.house ?? "",
        isFlat: order.address?.isFlat,
        apartmentNumber: order.address?.apartmentNumber ?? "",
        street: order.address?.street ?? "",
        entrance: order.address?.entrance ?? "",
        floor: order.address?.floor ?? "",
        fullAddress: order.address?.fullAddress ?? "",
        intercom: order.address?.intercom ?? "",
        id: order.address?.id ?? "",
        longitude: order.address?.longitude ?? "",
        latitude: order.address?.latitude ?? "",
      },

      customer: {
        id: user?.id ?? "",
        firstName: order.firstName ?? "",
        lastName: order.lastName ?? "",
        phone: order.phoneNumber ?? "",
        whatsappNumber: order.whatsAppNumber ?? "",
        email: user?.email,
      },

      shippingLine: {
        methodId: order.shippingMethod!.methodId,
        methodTitle: order.shippingMethod!.title,
        shipId: order.shippingMethod!.id,
        total: order.shippingMethod!.cost ?? "0",
      },
      lineItems: cartProducts.map((cartItem) => {
        const lineItem: LineItem = {
          id: cartItem.parentId,
          quantity: cartItem.quantity,
          price: cartItem.price,
        };

        if (cartItem.type === ProductType.VARIATION) {
          lineItem.variationId = cartItem.id;
        }

        if (coupons.length > 0) {
          let itemTotalPrice = cartItem.quantity * parseFloat(cartItem.price);
          coupons.forEach((coupon) => {
            itemTotalPrice -= Coupon.calculateDiscountForItem(coupon, cartItem, cartSubtotalValue);
          });

          lineItem.total = String(itemTotalPrice);
        }

        return lineItem;
      }),
      customerNote: order.customerNote,
      total: cartTotalValue,
      coupons: coupons,
    };

    if (rewardPoints > 0) {
      requestData.rewardPoints = rewardPoints;
    }

    const finishOrder = (responseData: unknown) => {
      AppMetricaProvider.reportEvent("orderCreate", {
        data_in_request: requestData,
        data_in_response: responseData,
      });

      resetCart();

      setShowLoading(false);
      history.replace("/thank-you");
    };

    createOrderMutation(requestData, {
      onSuccess(orderData) {
        if (
          appSettings?.isOnlinePaymentEnabled &&
          orderData.paymentMethod?.type === PaymentMethodIdEnum.card &&
          orderData.id &&
          orderData.number
        ) {
          const paymentPayload = {
            amount: Math.trunc(requestData.total * 100),
            orderId: String(orderData.id),
            orderNumber: orderData.number,
          };
          createPaymentMutation(paymentPayload, {
            onSuccess(data) {
              if (data.formUrl) {
                Browser.open({ url: data.formUrl });
              }
              finishOrder(orderData);
            },
          });
        } else {
          finishOrder(orderData);
        }
      },
      onError(error) {
        setShowLoading(false);
        if (error && error.response.data.message) {
          const message: string =
            ts[error.response.data.message as keyof typeof ts] ?? ts["An unexpected error occurred"];
          showToast({
            message: message,
            duration: 2000,
            color: "danger",
          });
        }
      },
    });
  };

  const goBackTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const appResumeListenerRef = useRef<PluginListenerHandle | null>(null);

  const goBackIfOrderDataOutdated = () => {
    if (order.updatedAt && dayjs(new Date()).diff(order.updatedAt) > dayjs.duration({ minutes: 10 }).asMilliseconds()) {
      history.goBack();
    }
  };

  useIonViewDidEnter(async () => {
    goBackIfOrderDataOutdated();
    goBackTimeoutRef.current = setTimeout(() => history.goBack(), dayjs.duration({ minutes: 10 }).asMilliseconds());

    if (isPlatform("capacitor")) {
      appResumeListenerRef.current = await App.addListener("resume", goBackIfOrderDataOutdated);
    }
  }, [history]);

  useIonViewDidLeave(() => {
    goBackTimeoutRef.current && clearTimeout(goBackTimeoutRef.current);
    if (isPlatform("capacitor") && appResumeListenerRef.current) {
      appResumeListenerRef.current.remove();
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton />
      </IonHeader>
      <IonContent scrollEvents={true}>
        <ToolbarDesktop />
        <IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={ts["Please wait..."]} />
        <MaxWidthContainer
          classList={`${cx("order")} global-content`}
          smContainer={true}
          mdContainer={true}
          lgContainer={true}
        >
          <IonRow className={cx("orderWrapper")}>
            <IonCol size={"12"} sizeLg={"6"}>
              <div className={cx("ion-hide-lg-up")}>
                {order.address && (
                  <OrderSummaryDetails
                    paymentMethodTitle={order.paymentMethod?.title ?? ""}
                    title={ts["Order Detail"]}
                    languageJson={ts}
                    address={order.address}
                    shippingIsDefine={!!order.address}
                    shippingMethod={order.shippingMethod}
                    deliveryDate={order.deliveryDate}
                    deliveryInterval={order.deliveryInterval}
                  />
                )}
              </div>

              {/* TODO-FIX: THE LIST-PRODUCTS PROP 2-3 AT FIRST RENDERS UNDEFINED AND THEREFORE PRODUCT IMAGES ARE NOT DISPLAYED */}
              <OrderSummaryProductsList title={ts.Products} cartProducts={cartProducts} productsList={products || []} />

              {(!!coupons.length || rewardPoints > 0) && (
                <OrderSummaryCoupons
                  setLoading={() => {
                    setShowLoading(false);
                  }}
                  coupons={coupons}
                  rewardPoints={rewardPoints}
                />
              )}

              <OrderSummaryTotal
                title={ts["Sub Total"]}
                languageJson={ts}
                productTotal={cartSubtotalValue}
                deliveryAmount={order.shippingMethod?.cost || 0}
                discount={cartSubtotalValue - cartTotalValue}
              />

              <div className="ion-hide-lg-up">
                {order.customerNote && <OrderSummaryNote title={ts["Order Notes"]} note={order.customerNote} />}
              </div>

              <div className="ion-hide-lg-up">
                {order.whatsAppNumber && (
                  <OrderSummaryWhatsapp title={ts["WhatsApp number"]} whatsAppNumber={order.whatsAppNumber} />
                )}
              </div>

              <IonCard className={cx("orderToolbarWrapper", "ion-hide-md-down", "ion-hide-lg-up")}>
                <ContinueButton onClick={submit} disabled={showLoading} buttonText={ts.SendOrder} />
              </IonCard>
            </IonCol>
            <IonCol size={"12"} sizeLg={"6"} className={cx("ion-hide-lg-down")}>
              {order?.address && (
                <OrderSummaryDetails
                  paymentMethodTitle={order.paymentMethod?.title ?? ""}
                  title={ts["Order Detail"]}
                  languageJson={ts}
                  address={order.address}
                  shippingIsDefine={!!order.address}
                  shippingMethod={order.shippingMethod}
                  deliveryDate={order.deliveryDate}
                  deliveryInterval={order.deliveryInterval}
                />
              )}
              {order.customerNote && <OrderSummaryNote title={ts["Order Notes"]} note={order.customerNote} />}
              {order.whatsAppNumber && (
                <OrderSummaryWhatsapp title={ts["WhatsApp number"]} whatsAppNumber={order.whatsAppNumber} />
              )}
              <IonCard className={cx("orderToolbarWrapper")}>
                <ContinueButton onClick={() => submit()} disabled={showLoading} buttonText={ts.SendOrder} />
              </IonCard>
            </IonCol>
          </IonRow>
        </MaxWidthContainer>
        <Footer />
      </IonContent>
      <IonFooter className={cx("oneButtonFooter")}>
        <div className={cx("ion-hide-md-up")}>
          <ContinueButton
            onClick={submit}
            disabled={
              showLoading ||
              !(
                order.firstName &&
                order.phoneNumber &&
                order.address &&
                order.shippingMethod &&
                order.paymentMethod &&
                (order.shippingMethod.methodDeliveryType === ShippingMethodDeliveryType.nextDay ||
                  (order.deliveryDate && order.deliveryInterval))
              )
            }
            buttonText={ts.SendOrder}
          />
        </div>
      </IonFooter>
    </IonPage>
  );
};

export default Order;
