import React, { ComponentProps, useState } from "react";

import { IonInput, IonIcon } from "@ionic/react";

import classNames from "classnames/bind";
import styles from "./PasswordInput.module.scss";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";

const cx = classNames.bind(styles);

const PasswordInput: React.FC<ComponentProps<typeof IonInput>> = ({ value, children, className, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={cx("passwordWrapper")}>
      <IonInput
        className={cx("passwordInput", className)}
        type={showPassword ? "text" : "password"}
        maxlength={24}
        value={value}
        {...props}
      >
        {children}
      </IonInput>
      <span className={cx("passwordShowIcon")} onClick={() => setShowPassword((prevState) => !prevState)}>
        <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} />
      </span>
    </div>
  );
};

export { PasswordInput };
