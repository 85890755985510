export const Global = {
  // You Can Add Your New Language
  // For Example
  //a3:{

  //},

  a2: {
    status: "ok",
    "All Products": "All Products",
    "Select Currency": "Select Currency",
    "Cancel order": "Cancel order",
    "continue shopping": "continue shopping",
    Price: "Price",
    PRICE: "PRICE",
    Remove: "Remove",
    by: "By",
    View: "View",
    Quantity: "Quantity",
    QUANTITY: "QUANTITY",
    Proceed: "Proceed",
    Country: "Country",
    "Shipping Address": "Shipping Address",
    Zone: "Zone",
    other: "other",
    "Post code": "Post code",
    City: "City",
    State: "State",
    "Safe Payment": "Safe Payment",
    "Update Address": "Update Address",
    "Login & Register": "login & Register",
    "Save Address": "Save Address",
    "Please login or create an account for free": "Please login or create Account",
    "Log Out": "Log Out",
    "My Wish List": "My Wish List",
    Filters: "Filters",
    "Price Range": "Price Range",
    Close: "Close",
    Clear: "Clear",
    Apply: "Apply",
    Menu: "Menu",
    "Secure Online Payment": "Secure Online Payment",
    Home: "Home",
    "Card Number": "Card Number",
    "Expiration Date": "Expiration Date",
    Expiration: "Expiration",
    "Error: invalid card number!": "Error: invalid card number!",
    "Error: invalid expiry date!": "Error: invalid expiry date!",
    "Error: invalid cvc number!": "Error: invalid cvc number!",
    Continue: "Continue",
    SendOrder: "Send order",
    "My Cart": "My Cart",
    "Your cart is empty": "Your cart is empty",
    "Shipping Method": "Shipping Method",
    "Order Notes": "Order Notes",
    "Shipping Cost": "Shipping Cost",
    Tax: "Tax",
    "Products Price": "Products Price",
    SubTotal: "SubTotal",
    Payment: "Payment",
    Products: "Products",
    Phone: "Phone",
    Address: "Address",
    "Billing Info": "Billing Info",
    "Same as Shipping Address": "Same as Shipping Address",
    Order: "Order",
    Next: "Next",
    "Billing Address": "Billing Address",
    "Already Memeber?": "Already Memeber?",
    "Last Name": "Last Name",
    "Create an Account": "Create an Account",
    "First Name": "First Name",
    "Add new Address": "Add new Address",
    "Please add your new shipping address for the futher processing of the your order":
      "Please add your new shipping address for the futher processing of the your order",
    "Current Password": "Current Password",
    "Order Status": "Order Status",
    "New Password": "New Password",
    "Change Password": "Change Password",
    "Customer Orders": "Customer Orders",
    "Customer Address": "Customer Address",
    Date: "Date",
    "No. of Products": "No. of Products",
    "Product Price": "Product Price",
    "Orders ID": "Orders ID",
    Update: "Update",
    "Date of Birth": "Date of Birth",
    "My Account": "My Account",
    Mobile: "Mobile",
    Likes: "Likes",
    newest: "Newest ",
    "top seller": "Top Seller",
    special: "Special",
    "most liked": "Most Liked",
    Cancel: "Cancel",
    "Sort Products": "Sort Products",
    "Special Products": "Special Products",
    "Price : low - high": "Price : low - high",
    "Price : high - low": "Price : high - low",
    "Z - A": "Z - A",
    "A - Z": "A - Z",
    All: "All",
    "Explore More": "Explore More",
    "Note to the buyer": "Note to the buyer",
    Coupon: "Coupon",
    "coupon code": "coupon code",
    "Coupon Amount": "Coupon Amount",
    "Coupon Code": "Coupon Code",
    "Food Categories": "Food Categories",
    "Recipe of Day": "Recipe of Day",
    "Top Dishes": "Top Dishes",
    Skip: "Skip",
    "Term and Services": "Term and Services",
    "Privacy Policy": "Privacy Policy",
    "Refund Policy": "Refund Policy",
    NEWEST: "NEWEST",
    Newest: "Newest",
    "OUT OF STOCK": "OUT OF STOCK",
    "Select Language": "Select Language",
    Reset: "Reset",
    Shop: "Shop",
    Settings: "Settings",
    "Enter keyword": "Enter keyword",
    News: "News",
    "Top Sellers": "Top Sellers",
    "Go Back": "Go Back",
    "Word Press Post Detail": "Word Press Post Detail",
    Explore: "Explore",
    "Continue Adding": "Continue Adding",
    "Your wish List is empty": "Your wish List is empty",
    Favourite: "Favourite",
    "Continue Shopping": "Continue Shopping",
    "My Orders": "My Orders",
    "Thank you for shopping with us.": "Thank you for shopping with us.",
    "Thank You": "Thank You",
    "Shipping method": "Shipping method",
    "Sub Categories": "Sub Categories",
    "Main Categories": "Main Categories",
    Search: "Search",
    "Reset Filters": "Reset Filters",
    "No Products Found": "No Products Found",
    OFF: "OFF",
    "Techincal details": "Techincal details",
    "Product Description": "Product Description",
    "ADD TO CART": "ADD TO CART",
    "Add to Cart": "Add to Cart",
    "In Stock": "In Stock",
    "Out of Stock": "Out of Stock",
    New: "New",
    "Product Details": "Product Details",
    Shipping: "Shipping",
    "Sub Total": "Sub Total",
    Total: "Total",
    TOTAL: "TOTAL",
    "Price Detail": "Price Detail",
    "Order Detail": "Order Detail",
    "Got It!": "Got It!",
    "Skip Intro": "Skip Intro",
    Intro: "Intro",
    REMOVE: "REMOVE",
    Deals: "Deals",
    DEALS: "DEALS",
    "All Categories": "All Categories",
    "Most Liked": "Most Liked",
    "Special Deals": "Special Deals",
    "Top Seller": "Top Seller",
    "Products are available.": "Products are available.",
    "Recently Viewed": "Recently Viewed",
    "Please connect to the internet": "Please connect to the internet",
    "Contact Us": "Contact Us",
    Name: "Name",
    "Your Message": "Your Message",
    Categories: "Categories",
    "About Us": "About Us",
    Send: "Send",
    "Forgot Password": "Forgot Password",
    Register: "Register",
    Password: "Password",
    Email: "Email",
    or: "or",
    "Login with": "Login with",
    "Creating an account means you\u2019re okay with shopify's Terms of Service, Privacy Policy":
      "Creating an account means you're okay with shopify's Terms of Service, Privacy Policy",
    "Forgotten password?": "Forgotten password?",
    "": null,
    "Creating an account means you\u2019re okay with our": "Creating an account means you\u2019re okay with our",
    Login: "Login",
    "Turn on/off Local Notifications": "Turn on/off Local Notifications",
    "Turn on/off Notifications": "Turn on/off Notifications",
    "Change Language": "Change Language",
    "Official Website": "Official Website",
    "Rate Us": "Rate Us",
    Share: "Share",
    "Edit Profile": "Edit Profile",
    "A percentage discount for the entire cart": "A percentage discount for the entire cart",
    "A fixed total discount for the entire cart": "A fixed total discount for the entire cart",
    "A fixed total discount for selected products only": "A fixed total discount for selected products only",
    "A percentage discount for selected products only": "A percentage discount for selected products only",
    Latest: "Latest",
    "On Sale": "On Sale",
    "ON SALE": "ON SALE",
    Featured: "Featured",
    FEATURED: "FEATURED",
    "View All": "View All",
    SALE: "SALE",
    DETAILS: "DETAILS",
    "Your Price": "Your Price",
    "Please select all product options before adding this product":
      "Please select all product options before adding this product",
    "Please select all product options before adding this product to your cart":
      "Please select all product options before adding this product to your cart",
    "Stock Quantity": "Stock Quantity",
    Select: "Select",
    "Total Sales": "Total Sales",
    Ok: "Ok",
    "No Products Found!": "No Products Found!",
    "Email or Username": "Email or Username",
    and: "and",
    Username: "Username",
    "No Posts Available": "No Posts Available",
    "No Categories Available": "No Categories Available",
    Posts: "Posts",
    "News Details": "News Details",
    "News List": "News List",
    Discount: "Discount",
    "Filters By": "Filters By",
    "A - Z Date": "A - Z Date",
    "Z - A Date": "Z - A Date",
    "Select Zone": "Select Zone",
    "Select Country": "Select Country",
    Compnay: "Compnay",
    "Same as billing Address": "Same as billing Address",
    Loading: "Loading",
    "Place Your Order": "Place Your Order",
    Done: "Done",
    "Payment Method": "Payment Method",
    "Home Page": "Home Page",
    "Category Page": "Category Page",
    "Shop Page": "Shop Page",
    "Cart Page": "Cart Page",
    "Order Page": "Order Page",
    "Releated Items": "Releated Items",
    "Continue as a Guest": "Continue as a Guest",
    "rating and review": "rating and review",
    Reviews: "Reviews",
    "Ratings & Reviews": "Ratings & Reviews",
    rating: "rating",
    "Rate and write a review": "Rate and write a review",
    "Enter a description": "Enter a description",
    "Post Comment": "Post Comment",
    "Write a review": "Write a review",
    "Comment about this product": "Comment about this product",
    "Your Rating:": "Your Rating",
    "Your Order List is Empty": "Your Order List is Empty",
    "Cancel Order": "Cancel Order",
    "Order Cancelled": "Order Cancelled",
    "Server Error": "Server Error",
    "All Categories Loaded!": "All Categories Loaded!",
    "All Posts Loaded!": "All Posts Loaded!",
    pending: "pending",
    refunded: "refunded",
    cancelled: "cancelled",
    failed: "failed",
    processing: "processing",
    completed: "completed",
    "on-hold": "on-hold",
    "Your Rating1": "Sold by",
    "View Store": "View Store",
    "Vendor Info": "Vendor Info",
    Vendors: "Vendors",
    "Downloads remaining": "Downloads remaining",
    Expires: "Expires",
    Downloads: "Downloads",
    Addresses: "Addresses",
    "Update Shipping": "Update Shipping",
    "Update Billing": "Update Billing",
    Store: "Store",
    "Seller Information": "Seller Information",
    Product: "Product",
    "Billing Address Updated": "Billing Address Updated",
    "Shipping Address Updated": "Shipping Address Updated",
    "Error Updating Data!": "Error Updating Data!",
    "Data Updated!": "Data Updated!",
    "Order Cancelation Time Expires!": "Order Cancelation Time Expires!",
    "Please enter something": "Please enter something",
    "No Product found!": "No Product found!",
    "User Created": "User Created",
    "Please Connect to the Internet!": "Please Connect to the Internet!",
    "Network connected Reloading Data": "Network connected Reloading Data",
    "Item not Available!": "Item not Available!",
    "Invalid Coupon Code!": "Invalid Coupon Code!",
    "Error Check Login Status Facebook": "Error Check Login Status Facebook",
    "Server Error while Loading Orders": "Server Error while Loading Orders",
    "Please Add Quantity": "Please Add Quantity",
    "Product Quantity is Limited!": "Product Quantity is Limited!",
    Alert: "Alert",
    "Added To Cart!": "Added To Cart!",
    "Login with facebook": "Login with facebook",
    "Added To Wish List!": "Added To Wish List!",
    "Removed From Wish List!": "Removed From Wish List!",
    "Reloading App!": "Reloading App!",
    "Reloading App Please Wait!": "Reloading App Please Wait!",
    "Reward Points": "Reward Points",
    "Total Points Earned": "Total Points Earned",
    Status: "Status",
    "Coupons Applied": "Coupons Applied",
    "Track Order": "Track Order",
    "Your Rewards List is Empty": "Your Rewards List is Empty",
    Points: "Points",
    Action: "Action",
    Test: "Test",
    "No item found": "No item found",
    "SHOP MORE": "SHOP MORE",
    "Shop More": "Shop More",

    "Your Download List is Empty": "Your Download List is Empty",
    "User Created. Login Using your Username & Password": "User Created. Login Using your Username & Password",

    "Newest Products": "Newest Products",
    "On Sale Products": "On Sale Products",
    "Featured Products": "Featured Products",
    "Customers Choice": "Customers Choice",
    "Related Items": "Related Items",
    "Your Scratch Cards Coupon List is Empty": "Your Scratch Cards Coupon List is Empty",
    "Scratch Cards": "Scratch Cards",
    "Message Send Successfully": "Your message has been send succesfully",
    "Login button": "Login",
    "Please wait...": "Please wait...",
    "Please Wait": "Please Wait",
    Catalog: "Catalog",
    catalog: "Catalog",
    Basket: "Basket",
    Favorite: "Favorite",
    Profile: "Profile",
    "Address Placeholder": "House number, street and city",
    optional: "optional",
    "Delivery date": "Delivery date",
    "Delivery interval": "Delivery interval",
    "Select delivery date": "Select delivery date",
    "Select delivery interval": "Select delivery interval",
    "Order note": "Order note",
    "WhatsApp number": "WhatsApp number",
    "Delivery date and interval": "Delivery date and interval",
    "An unexpected error occurred": "An unexpected error occurred",

    // there are spaces in the key because of specific of backend
    "This Email Already exist": "This Email Already exist",
    "This phone number is already registered": "This phone number is already registered",
    " Email not valid ": "Email not valid",
    "The Email is not Valid": "The Email is not Valid",
    " This Email not exist ": "This Email doesn't exist",
    "Invalid username or password.": "Invalid username or password",
    "No reviews yet": "No reviews yet",
    "Confirm New Password": "Confirm New Password",
    "dont fill to save current password": "(don't fill to save current password)",
    "Save changes": "Save changes",
    "Change account details": "Change account details",
    "Go to My orders": "Go to My orders",
    "Password requirements":
      "The password must contain from 8 to 20 characters and contain upper and lower case letters, numbers",
    "Password mismatch": "Password mismatch",
    "Confirm the password": "Confirm the password",
    "Enter the current password": "Enter the current password",
    "Enter the new password": "Enter the new password",
    "Data successfully updated": "Data successfully updated",
    trash: "Trash",
    assembly: "Assembly",
    packing: "Packing",
    "pending-delivery": "Pending delivery",
    "Total cost": "Total cost",
    "Search in the store": "Search in the store",
    "All products of the category": "All products of the category",
    "Products of this category": "Products of this category",
    "Customers who bought this item also bought": "Customers who bought this item also bought",
    "Failed to request data": "Failed to request data",
    "House number": "House number",
    Street: "Street",
    Floor: "Floor",
    "Apartment number": "Apartment number",
    Entrance: "Entrance",
    "First name input placeholder": "Enter firts name",
    "Last name input placeholder": "Enter last name",
    "Email input placeholder": "Enter your email address",
    "House number input placeholder": "Enter house number",
    "Street input placeholder": "Enter street name",
    "Floor input placeholder": "Enter the floor number",
    "Apartment input number placeholder": "Enter the apartment number",
    "Entrance input placeholder": "Enter the entrance number",
    "Delivery to the apartment": "delivery to the apartment",
    Flat: "flat",
    today: "today",
    tomorrow: "tomorrow",
    "day after tomorrow": "day after tomorrow",
    "Some items in the shopping cart are out of stock or insufficient to order.":
      "Some items in the shopping cart are out of stock or insufficient to order",
    "insufficient stock, the quantity of the product in the cart has been reduced":
      "недостаточно запасов, количество продукта в корзине было уменьшено",
    "the stock of the product has run out, the product has been removed from the basket":
      "запасы товара закончились, товар был убран из корзины",
    Warning: "Warning",
    latest: "latest",
    sale: "sale",
    featured: "featured",
    customersChoice: "Customers Choice",
    on_sale: "sale",
    "Free shipping on first order": "Free shipping on first order",
    "Your comment will be published after moderation": "Your comment will be published after moderation",
    "Total ratings": "Total ratings",
    "No reviews": "No reviews",
    "Where did you hear about us?": "Where did you hear about us?",
    "Television, radio": "Television, radio",
    "Banners, advertising posters": "Banners, advertising posters",
    "Social networks": "Social networks",
    "Newspapers, journals": "Newspapers, journals",
    "Friends, acquaintances": "Friends, acquaintances",
    "Didn't find the product you were looking for? Write to us":
      "Didn't find the product you were looking for? Write to us",
    "Please enter a valid e-mail": "Please enter a valid e-mail",
    "Email placeholder": "my.email@example.com",
    Message: "Message",
    "Enter the names of the products that you did not find": "Enter the names of the products that you did not find",
    "Please enter a valid phone number": "Please enter a valid phone number",
    Locality: "Locality",
    "Select locality": "Select locality",
    "Only Latin letters, numbers and -_ symbols": "Only Latin letters, numbers and -_ symbols",
    "Registration completed successfully!": "Registration completed successfully!",
    "Find address": "Find address",
    "Enter address to find": "Enter address to find",
    "Nothing found": "Nothing found",
    "Please update the app": "Please update the app",
    "A new version has been released...":
      "A new version of the application has been released, please update to the new version so that all new functions are available to you",
    "Who told you about Parhato": "Who told you about Parhato",
    "Sorry Coupon is Expired": "Sorry Coupon is Expired",
    "Sorry your Cart total is low than coupon min limit!": "Sorry your Cart total is low than coupon min limit!",
    "Sorry, this coupon is not valid for this email address!":
      "Sorry, this coupon is not valid for this email address!",
    "Sorry, this coupon is not valid for sale items.": "Sorry, this coupon is not valid for sale items.",
    "Coupon code already applied!": "Coupon code already applied!",
    "Sorry Individual Use Coupon is already applied any other coupon cannot be applied with it !":
      "Sorry Individual Use Coupon is already applied any other coupon cannot be applied with it !",
    "Coupon usage limit has been reached.": "Coupon usage limit has been reached.",
    "Sorry Coupon Cannot be Applied on these Products!": "Sorry Coupon Cannot be Applied on these Products!",
    "Enter a comment for the delivery": "Enter a comment for the delivery",
    Comment: "Comment",
    Driver: "Driver",
    "Rate the driver": "Rate the driver",
    "Rate the delivery": "Rate the delivery",
    "Thanks for your feedback": "Thanks for your feedback",
    "Your rating for the driver": "Your rating for the driver",
    "Please select an address from the list": "Please select an address from the list",
    "Invalid verification code": "Invalid verification code",
    "Enter password": "Enter password",
    "Enter confirmation code": "Enter confirmation code",
    "To verify your phone number, enter the code from SMS": "To verify your phone number, enter the code from SMS",
    "Code must be 6 digits": "Code must be 6 digits",
    "Enter phone number": "Enter phone number",
    "Phone number": "Phone number",
    "Login via email": "Login via email",
    "Username, phone number or email": "Username, phone number or email",
    "Phone number without +, in international format: 79283334455":
      "Phone number without +, in international format: 79283334455",
    "Email or phone number": "Email or phone number",
    "Password reset link sent to your email": "Password reset link sent to your email",
    "This phone number already exists": "This phone number already exists",
    "No user found with this phone number": "No user found with this phone number",
    "Enter street name": "Enter street name",
    "Invalid email, or such email is already in use": "Invalid email, or such email is already in use",
    "Contact number": "Contact number",
    "Copied to clipboard!": "Copied to clipboard!",
    "Your bonus points": "Your bonus points",
    "Apply bonus points": "Apply bonus points",
    "Incorrect input": "Incorrect input",
    "Not enough bonus points": "Not enough bonus points",
    "This Email not exist": "This Email doesn't exist",
    "Delete account": "Delete account",
    "Account deletion": "Account deletion",
    "Referral system": "Referral system",
    "Delete account description":
      "Do you want to delete your account? Deleting your account will delete all data associated with it, as well as the inability to use the service",
    "Address search error, please select a location on the map":
      "Address search error, please select a location on the map",
    "No description": "No description",
    "Pickup point": "Pickup point",
    "Pickup points": "Pickup points",
    "Select pickup point": "Select pickup point",
    AlreadyExistMessage: "An account with this phone number already exists",
    "Payment status": "Payment status",
    "Applied bonus points": "Applied bonus points",
    "Reset bonus points": "Reset bonus points",
    "Bonus points": "Bonus points",
    "Select address in the selected shipping zone": "Select address in the selected shipping zone",
    "Select shipping zone": "Select shipping zone",
    "Select an address in the zone": "Select an address in the zone",
    "or change the delivery zone on the main page": "or change the delivery zone on the main page",
    from: "from",
    "Please, select the shipping method": "Please, select the shipping method",
    "Please, select the payment method": "Please, select the shipping method",
    "The string must be between 8 and 24 characters long": "The string must be between 8 and 24 characters long",
    "There are products left in your shopping cart. Click to complete your order.":
      "There are products left in your shopping cart. Click to complete your order.",
    "Goods in the cart": "Goods in the cart",
    "Repeat order": "Repeat order",
    "Order review": "Order review",
    "Please rate the service and leave any comments or feedback that can help us make it better. The main criteria include the quality of the products, speed of delivery, courtesy of the delivery person and the fact of delivery to the door. Your feedback is very important to us!":
      "Please rate the service and leave any comments or feedback that can help us make it better. The main criteria include the quality of the products, speed of delivery, courtesy of the delivery person and the fact of delivery to the door. Your feedback is very important to us!",
    "Required field": "Required field",
    "Rate the order": "Rate the orde",
    "Needed to evaluate the order": "Required for rate the order",
    "Order comment": "Order comment",
    "The product is out of stock": "The product is out of stock",
    "Send code": "Send code",
    "Enter code": "Enter code",
    "Enter the code from the SMS message": "Enter the code from the SMS message",
    "If the code is not received, check the entered phone number and try again":
      "If the code is not received, check the entered phone number and try again",
    "Send again": "Send again",
  },
  RU: {
    status: "ok",
    Connected: "Соединение восстановлено",
    Disconnected: "Ошибка соединения",
    "All Products": "Все продукты",
    "Select Currency": "Выбрать валюту",
    "Cancel order": "Отменить заявку",
    "continue shopping": "продолжить покупки",
    "Choose Payment": "Выберите способ оплаты",
    "Payment not selected": "Не указан способ оплаты",
    "Select payment": "Указать способ оплаты",
    Price: "Цена",
    PRICE: "Цена",
    "Add to cart": "В корзину",
    Remove: "Удалить",
    by: "к", //has several meanings
    View: "Посмотреть",
    Quantity: "Количество",
    QUANTITY: "КОЛИЧЕСТВО",
    Proceed: "Продолжить",
    Country: "Страна",
    "Shipping Address": "Адрес доставки",
    Zone: "Зона",
    other: "другое",
    "Post code": "Почтовый индекс",
    City: "Город",
    State: "Регион",
    "Safe Payment": "Безопасный платеж",
    "Update Address": "Обновить адрес",
    "Login & Register": "Войти и зарегистрироваться",
    "Save Address": "Сохранить адрес",
    "Back to map": "Вернуться на карту",
    "Change Address": "Изменить адрес",
    "My geolocation": "Мое местоположение",
    "Go to settings": "Перейти в настройки",
    "Please enable location services and try again": "Включите службы определения местоположения и повторите попытку",
    "Unable to get your location. Please enable location services and try again":
      "Не удалось определить ваше местоположение. Пожалуйста, включите службы определения местоположения и повторите попытку",
    Confirm: "Подтвердить",
    "Please login or create an account for free": "Пожалуйста, войдите или создайте учётную запись",
    "Log Out": "Выйти из аккаунта",
    "My Wish List": "Мой список желаний",
    Filters: "Фильтры",
    "Price Range": "Диапазон цен",
    Close: "Закрыть",
    Clear: "Очистить",
    Apply: "Применить",
    Menu: "Меню",
    "Secure Online Payment": "Безопасный онлайн платеж",
    Home: "Главная",
    "Card Number": "Номер карты",
    "Expiration Date": "Дата окончания срока",
    Expiration: "Срок действия",
    "Error: invalid card number!": "Ошибка: неверный номер карты!",
    "Error: invalid expiry date!": "Ошибка: неверная дата действия!",
    "Error: invalid cvc number!": "Ошибка: неверный CVC код!",
    Continue: "Продолжить",
    SendOrder: "Отправить заказ",
    "My Cart": "Моя корзина",
    "Your cart is empty": "Ваша корзина пуста",
    "Shipping Method": "Метод доставки", //repeats
    "Order Notes": "Примечания к заказу",
    "Shipping Cost": "Стоимость доставки",
    Tax: "Налог",
    "Products Price": "Цена товаров",
    SubTotal: "Промежуточная цена",
    Payment: "Оплата",
    Products: "Продукция",
    Phone: "Телефон",
    Address: "Адрес",
    "Billing Info": "Информация о платеже",
    "Same as Shipping Address": "Такой же, что и адрес доставки",
    Order: "Заказ",
    Next: "Далее",
    "Billing Address": "Платежный адрес",
    "Already Memeber?": "Уже участвовали?",
    "Last Name": "Фамилия",
    "Create an Account": "Создать учетную запись",
    "First Name": "Имя",
    "Add new Address": "Добавить новый адрес",
    "Add new": "Добавить новый",
    "Please add your new shipping address for the futher processing of the your order":
      "Пожалуйста, добавьте ваш новый адрес доставки для дальнейшей обработки заказа",
    "Current Password": "Текущий пароль",
    "Order Status": "Статус заказа",
    "New Password": "Новый пароль",
    "Change Password": "Изменить пароль",
    "Customer Orders": "Заказы клиента",
    "Customer Address": "Адрес клиента",
    Date: "Дата",
    "No. of Products": "Кол-во продуктов",
    "Product Price": "Цена продукта",
    "Orders ID": "Номер заказа",
    Update: "Обновить",
    "Date of Birth": "Дата рождения",
    "My Account": "Моя учетная запись",
    Mobile: "Телефон",
    Likes: "Нравится",
    newest: "Новый",
    "top seller": "Лучший продавец",
    special: "Особый", //has several meanings (мб Специальный)
    "most liked": "Больше всего понравилось",
    Cancel: "Отменить",
    "Location Access": "Доступ к местоположению",
    "Please allow access to your location to use this feature. We use your location to improve your experience":
      "Пожалуйста, разрешите доступ к вашему местоположению, чтобы использовать эту функцию. Мы используем ваше местоположение, чтобы улучшить ваш опыт",
    "Sort Products": "Сортировать продукты",
    "Special Products": "Особые продукты",
    "Price : low - high": "Цена : низкая - высокая",
    "Price : high - low": "Цена : высокая - низкая",
    "Z - A": "Я - А",
    "A - Z": "А - Я",
    All: "Все",
    "Explore More": "Узнать больше",
    "Note to the buyer": "Примечание для покупателя",
    Coupon: "Купон",
    "coupon code": "код купона", //repeats
    "Coupon Amount": "Сумма купона",
    "Coupon Code": "Код купона", //repeats
    "Food Categories": "Категория продуктов питания",
    "Recipe of Day": "Рецепт дня",
    "Top Dishes": "Лучшие блюда",
    Skip: "Пропустить",
    "Term and Services": "Условия пользования",
    "Privacy Policy": "Политикой обработки персональных данных",
    "Refund Policy": "Политика возврата",
    NEW: "Новинка",
    Newest: "Новое",
    "OUT OF STOCK": "ОТСУТСТВУЕТ НА СКЛАДЕ",
    "Select Language": "Выбрать язык",
    Reset: "Сброс",
    Shop: "Магазин",
    Settings: "Настройки",
    "Enter keyword": "Введите значение",
    News: "Новости",
    "Top Sellers": "Лидеры продаж", //repeats
    "Go Back": "Вернуться",
    "Word Press Post Detail": "Сведения о публикации Word Press",
    Explore: "Перейти в каталог",
    "Continue Adding": "Продолжить добавление",
    "Your wish List is empty": "Ваш список желаний пуст",
    Favourite: "Любимые",
    "Continue Shopping": "Продолжить покупки",
    "My Orders": "Мои заказы",
    "Thank you for shopping with us.": "Спасибо за покупку у нас",
    "Thank You": "Спасибо",
    "Shipping method": "Метод доставки", //repeats
    "Sub Categories": "Подкатегории",
    "Main Categories": "Основные категории",
    Search: "Поиск",
    "Reset Filters": "Сбросить фильтр",
    "No Products Found": "Товаров не найдено",
    OFF: "ВЫКЛ",
    "Techincal details": "Технические детали",
    "Product Description": "Описание продукта",
    "ADD TO CART": "ДОБАВИТЬ В КОРЗИНУ",
    "Add to Cart": "Добавить в корзину",
    "In Stock": "В наличии",
    "Out of Stock": "Нет в наличии",
    New: "Новый",
    "Product Details": "Сведения о продукте",
    Shipping: "Доставка",
    "Sub Total": "Промежуточный итог",
    Total: "Итого",
    TOTAL: "ИТОГО",
    "Price Detail": "Подробно о цене",
    "Order Detail": "Детали заказа",
    "Got It!": "Понял!",
    "Skip Intro": "Пропустить вступление",
    Intro: "Вступление",
    REMOVE: "УДАЛИТЬ",
    Deals: "«Сделки»",
    DEALS: "«СДЕЛКИ»",
    "All Categories": "Все категории»",
    "Most Liked": "Самые популярные»",
    "Special Deals": "Специальные предложения",
    "Top Seller": "«Лидеры продаж»", //repeats
    "Products are available.": "Товары доступны.",
    "Recently Viewed": "Недавно просмотрено",
    "Please connect to the internet": "Пожалуйста, подключитесь к интернету", //repeats
    "Contact Us": "Связаться с нами",
    Name: "Имя",
    "Your Message": "Ваше сообщение",
    Categories: "Категории",
    "About Us": "О нас",
    "About company": "О компании",
    Contacts: "Контакты",
    Send: "Отправить",
    "Send code": "Отправить код",
    "Enter code": " Ввести код",
    "Forgot Password": "Забыл пароль",
    Register: "Зарегистрироваться",
    Password: "Пароль",
    Email: "Электронная почта",
    or: "или",
    "Login with": "Войти с помощью",
    "Creating an account means you\u2019re okay with shopify's Terms of Service, Privacy Policy":
      "Создание учетной записи означает, что вы согласны с Условиями использования и Политикой конфиденциальности shopify",
    "Forgotten password?": "Забыли пароль?",
    "": null,
    "Creating an account means you\u2019re okay with our": "Создание учетной записи означает, что вы согласны с нашей",
    Login: "Вход",
    "Turn on/off Local Notifications": "Включить/выключить локальные уведомления",
    "Turn on/off Notifications": "Включить/выключить уведомления",
    "Change Language": "Сменить язык",
    "Official Website": "Официальный сайт",
    "Rate Us": "Оцените нас",
    Share: "Поделиться",
    "Edit Profile": "Редактировать профиль",
    "A percentage discount for the entire cart": "Скидка в процентах на всю мою корзину",
    "A fixed total discount for the entire cart": "Фиксированная общая скидка на всю корзину",
    "A fixed total discount for selected products only": "Фиксированная общая скидка только для выбранных товаров",
    "A percentage discount for selected products only": "Процентная скидка только на выбранные товары",
    Latest: "«Последние»",
    "On Sale": "В продаже",
    "ON SALE": "В ПРОДАЖЕ",
    Featured: "Рекомендуемые",
    FEATURED: "РЕКОМЕНДУЕМЫЙ",
    "View All": "Просмотреть все",
    SALE: "РАСПРОДАЖА",
    DETAILS: "ДЕТАЛИ",
    "Your Price": "Ваша цена",
    "Please select all product options before adding this product":
      "Пожалуйста, выберите все свойства продукта перед добавлением этого продукта",
    "Please select all product options before adding this product to your cart":
      "Пожалуйста, выберите все свойства продукта перед добавлением этого продукта в корзину",
    "Stock Quantity": "Количество на складе",
    Select: "Выбрать",
    "Total Sales": "Всего продано",
    Ok: "Ок",
    "No Products Found!": "Товаров не найдено!",
    "Email or Username": "Электронная почта или имя пользователя",
    and: "и",
    Username: "Имя пользователя",
    "No Posts Available": "Нет доступных постов",
    "No Categories Available": "Нет доступных категорий",
    Posts: "Посты",
    "News Details": "Детали новостей",
    "News List": "Список новостей",
    Discount: "Скидка",
    "Filters By": "Фильтровать по",
    "A - Z Date": "Дата от А до Я",
    "Z - A Date": "Дата от Я до А",
    "Select Zone": "Выберите место",
    "Select Country": "Выберите страну",
    Compnay: "Компания", //mistake in word
    "Same as billing Address": "Такой же как и адрес для оплаты",
    Loading: "Загрузка",
    "Loading...": "Загрузка...",
    "Place Your Order": "Разместить заказ",
    Done: "Приступить",
    "Payment Method": "Способ оплаты", //repeats
    "Home Page": "Домашняя страница",
    "Category Page": "Категории",
    "Shop Page": "Товары",
    "Cart Page": "Корзина",
    "Order Page": "Заказы",
    "Releated Items": "Похожие товары",
    "Continue as a Guest": "Продолжить как гость",
    "rating and review": "оценка и отзыв",
    Reviews: "Обзоры",
    "Ratings & Reviews": "Оценки и отзывы",
    rating: "оценка",
    "Rate and write a review": "Оценить и написать отзывы",
    "Enter a description": "Напишите что вы думаете об этом продукте...",
    "Post Comment": "Добавить отзыв",
    "Write a review": "Написать отзыв",
    "Comment about this product": "Написать отзыв",
    "Your Rating": "Ваша оценка",
    "Your Order List is Empty": "Ваш список заказов пуст",
    "Cancel Order": "Отменить заказ",
    "Order Cancelled": "Заказ отменен",
    "Server Error": "Ошибка сервера",
    "All Categories Loaded!": "Все категории загружены!",
    "All Posts Loaded!": "Все посты загружены!",
    "Your Rating1": "Продавец",
    "View Store": "Просмотреть магазин",
    "Vendor Info": "Информация о продавце",
    Vendors: "Продацы",
    "Downloads remaining": "Осталось загрузок",
    Expires: "Истекает",
    Downloads: "Загрузки",
    Addresses: "Адреса",
    "Update Shipping": "Обновить доставка",
    "Update Billing": "Обновить платежную информацию",
    Store: "Магазин",
    "Seller Information": "Информация о продавце",
    Product: "Товар",
    "Billing Address Updated": "Платежный адрес обновлен",
    "Shipping Address Updated": "Адрес доставки обновлен",
    "Error Updating Data!": "Ошибка обновления данных!",
    "Data Updated!": "Данные обновлены!",
    "Order Cancelation Time Expires!": "Срок отмены заказа истекает!",
    "Please enter something": "Пожалуйста введите что-нибудь",
    "No Product found!": "Товар не найден!",
    "User Created": "Пользователь создан",
    "Please Connect to the Internet!": "Пожалуйста, проверьте подключение к интернету!", //repeats
    "Network connected Reloading Data": "Сеть подключена для перезагрузки данных",
    "Item not Available!": "Товар не доступен!",
    "Invalid Coupon Code!": "Недействительный код купона!",
    "Error Check Login Status Facebook": "Ошибка проверки статуса входа в Facebook",
    "Server Error while Loading Orders": "Ошибка сервара для загрузка заказов",
    "Please Add Quantity": "Пожалуйста, добавьте количество",
    "Product Quantity is Limited!": "Количество товара ограничено!",
    Alert: "Ошибка",
    "Added To Cart!": "Добавлен в корзину!",
    "Login with facebook": "Войти через Faceebok",
    "Added To Wish List!": "Добавлен в список желаний!",
    "Removed From Wish List!": "Удален из списка желаний!",
    "Reloading App!": "Перезагрузка приложения!",
    "Reloading App Please Wait!": "Перезагрузка приложения, пожалуйста, подождите!",
    "Earned points": "Заработанные баллы",
    "Referral link": "Реферальная ссылка",
    "Your referral link": "Ваша реферальная ссылка",
    "Total Points Earned": "Всего заработано баллов",
    Status: "Статус",
    "Coupons Applied": "Примененные купоны",
    "Track Order": "Отследить заказ",
    "Your Rewards List is Empty": "Ваш список наград пуст",
    Points: "Баллы",
    Action: "Действие",
    Test: "Тест",
    "No item found": "Товар не найден",
    "SHOP MORE": "ПОКАЗАТЬ ВСЕ",
    "Shop More": "Показать все",
    MORE: "ВСЕ",
    more: "все",

    "Your Download List is Empty": "Ваш список загрузок пуст",
    "User Created. Login Using your Username & Password":
      "Пользователь создан. Войдите, используя своё имя пользователя и пароль",

    "Newest Products": "Новые продукты",
    "Latest Addest": "Новинки",
    "On Sale Products": "Товары со скидкой",
    "Featured Products": "Специальное предложение",
    "Customers Choice": "Выбор покупателей",
    "Related Items": "Похожие товары",
    "Your Scratch Cards Coupon List is Empty": "Список купонов для ваших скретч-карт пуст",
    "Scratch Cards": "Скретч-карты",
    "Message Send Successfully": "Ваше сообщение успешно отправлено",
    "You have been successfully authorized!": "Вы успешно авторизованы!",
    "Send again": "Отправить снова",
    "Enter the code from the SMS message": "Введите код из СМС сообщения",
    "If the code is not received, check the entered phone number and try again":
      "Если код не пришел проверьте введенный номер телефона и повторите попытку",
    "An SMS code will be sent to this number to confirm the order":
      "Для подтверждения заказа на этот номер будет отправлен СМС код",
    "Login button": "Войти",
    "Please wait...": "Пожалуйста подождите...",
    "Please Wait": "Пожалуйста подождите",
    "Show more": "Показать больше",
    Catalog: "Каталог",
    catalog: "Каталог товаров",
    "Catalog of goods": "Каталог товаров",
    Promotions: "Акции",
    Bestsellers: "Хиты продаж",
    "Good price": "Отличная цена",
    Basket: "Корзина",
    Favorite: "Любимые",
    Profile: "Профиль",
    "Address Placeholder": "Номер дома, название улицы и город",
    optional: "необязательно",
    "Delivery date": "Дата доставки",
    "Delivery interval": "Интервал доставки",
    "Select delivery date": "Выберите дату доставки",
    "Select delivery interval": "Выберите интервал доставки",
    "Order note": "Примечания к заказу",
    "WhatsApp number": "Номер WhatsApp",
    "Delivery date and interval": "Дата и интервал доставки",
    "An unexpected error occurred": "Произошла непредвиденная ошибка",

    // there are spaces in the key because of specific of backend
    "This Email Already exist": "Учетная запись с такой почтой уже существует",
    "This phone number is already registered": "Этот номер телефона уже зарегистрирован",
    " Email not valid ": "Электронная почта недействительна",
    "The Email is not Valid": "Адрес электронной почты недействителен",
    "Invalid username or password.": "Неправильное имя пользователя или пароль",
    "No reviews yet": "Пока нет отзывов",
    "Confirm New Password": "Подтвердите новый пароль",
    "dont fill to save current password": "(не заполняйте, чтобы оставить прежний)",
    "Save changes": "Сохранить изменения",
    "Change account details": "Изменить данные аккаунта",
    "Go to My orders": "Перейти к Моим заказам",
    "Password requirements":
      "Пароль должен содержать от 8 до 20 символов латинского алфавита, содержать заглавные и строчные буквы, цифры",
    "Password mismatch": "Пароли не совпадают",
    "Confirm the password": "Подтвердите пароль",
    "Enter the current password": "Введите текущий пароль",
    "Enter the new password": "Введите новый пароль",
    "Data successfully updated": "Данные успешно обновлены",
    pending: "В ожидании оплаты",
    processing: "Обработка",
    "on-hold": "На удержании",
    completed: "Выполнен",
    cancelled: "Отменен",
    refunded: "Возвращен",
    failed: "Не удался",
    trash: "Удален",
    assembly: "Сборка заказа",
    packing: "Касса/упаковка",
    "pending-delivery": "Ожидает отправки",
    "Total cost": "Общая стоимость",
    "Search in the store": "Искать в магазине",
    "All products of the category": "Все товары категории",
    "Products of this category": "Товары данной категории",
    "Customers who bought this item also bought": "С этим товаром покупают",
    "Failed to request data": "Ошибка запроса данных",
    "House number": "Номер дома",
    Street: "Улица",
    Floor: "Этаж",
    "Apartment number": "Квартира",
    Entrance: "Подъезд",
    "First name input placeholder": "Введите имя",
    "Last name input placeholder": "Введите фамилию",
    "Email input placeholder": "Введите адрес электронной почты",
    "House number input placeholder": "Введите номер дома",
    "Street input placeholder": "Введите название улицы",
    "Floor input placeholder": "Введите номер этажа",
    "Intercom input placeholder": "Введите код домофона",
    "Apartment input number placeholder": "Введите номер квартиры",
    "Entrance input placeholder": "Введите номер подъезда",
    "Required field": "Заполните обязательное поле",
    "Delivery to the apartment": "Доставка до квартиры",
    "Enter the address manually": "Ввести адрес вручную",
    "Enter correct data": "Введите корректные данные",
    Flat: "квартира",
    Intercom: "Домофон",
    "Intercom code": "Код домофона",
    today: "сегодня",
    tomorrow: "завтра",
    "day after tomorrow": "послезавтра",
    "Some items in the shopping cart are out of stock or insufficient to order.":
      "Некоторых товаров в корзине нет в наличии или их недостаточно для заказа",
    "insufficient stock, the quantity of the product in the cart has been reduced":
      "недостаточно запасов, количество продукта в корзине было уменьшено",
    "the stock of the product has run out, the product has been removed from the basket":
      "запасы товара закончились, товар был убран из корзины",
    Warning: "Внимание",
    latest: "Новинки",
    sale: "Товары со скидкой",
    featured: "Специальное предложение",
    customersChoice: "Выбор покупателей",
    on_sale: "Товары со скидкой",
    "Delivery address": "Адрес доставки",
    "Add a new shipping address": "Добавить новый адрес доставки",
    "Free shipping on first order": "Бесплатная доставка на первый заказ",
    "Your comment will be published after moderation": "Ваш отзыв будет опубликован после модерации",
    "Total ratings": "Всего оценок",
    "No reviews": "Нет отзывов",
    "Where did you hear about us?": "Откуда вы о нас узнали",
    "Television, radio": "Телевизор, радио",
    "Banners, advertising posters": "Баннеры, рекламные плакаты",
    "Social networks": "Социальные сети",
    "Newspapers, journals": "Газеты, журналы",
    "Friends, acquaintances": "Друзья, знакомые",
    "Didn't find the product you were looking for? Write to us": "Не нашли товар который искали? Напишите нам",
    "Please enter a valid e-mail": "Укажите корректный Email адрес",
    "Email placeholder": "my.email@example.com",
    Message: "Сообщение",
    "Enter the names of the products that you did not find": "Введите названия товаров, которые Вы не нашли",
    "Please enter a valid phone number": "Укажите корректный номер телефона",
    Locality: "Населенный пункт",
    "Select locality": "Выберите населенный пункт",
    "Only Latin letters, numbers and -_ symbols": "Только латинские буквы, цифры и символы . - _",
    "Registration completed successfully!": "Регистрация прошла успешно!",
    "Find address": "Найти адрес",
    "Enter address": "Введите адрес для поиска",
    "Nothing found": "Ничего не найдено",
    "Please update the app": "Пожалуйста обновите приложение",
    "A new version has been released...":
      "Вышла новая версия приложения, пожалуйста обновитесь до новой версии, чтобы вам были доступны все новые функции",
    "Who told you about Parhato": "Кто рассказал вам о Parhato",
    "Sorry Coupon is Expired": "К сожалению, срок действия купона истек",
    "Sorry your Cart total is low than coupon min limit!":
      "К сожалению, общая сумма вашей корзины ниже минимального лимита купона!",
    "Sorry, this coupon is not valid for this email address!":
      "К сожалению, этот купон недействителен для этого адреса электронной почты!",
    "Sorry, this coupon is not valid for sale items.":
      "К сожалению, этот купон не действителен для товаров со скидкой.",
    "Coupon code already applied!": "Этот купон уже применен!",
    "Sorry Individual Use Coupon is already applied any other coupon cannot be applied with it !":
      "К сожалению, купон для индивидуального использования уже применен, любой другой купон не может быть применен вместе с ним!",
    "Coupon usage limit has been reached.": "Достигнут предел использования купона.",
    "Use points": "Использовать баллы",
    "Sorry Coupon Cannot be Applied on these Products!": "К сожалению, купон не может быть применен к этим продуктам!",
    "Enter a comment for the delivery": "Введите комментарий к доставке",
    Comment: "Комментарий",
    Driver: "Водитель",
    "Rate the driver": "Поставить оценку водителю",
    "Rate the delivery": "Оцените доставку",
    "Thanks for your feedback": "Спасибо за ваш отзыв",
    "Your rating for the driver": "Ваша оценка водителю",
    "Please select an address from the list": "Пожалуйста выберите адрес из списка",
    "Invalid verification code": "Неверный код",
    "Enter password": "Введите пароль",
    "Enter confirmation code": "Введите код",
    "To verify your phone number, enter the code from SMS": "Для подтверждения номера телефона введите код из СМС",
    "Code must be 6 digits": "Код должен состоять из 6 цифр",
    "Enter phone number": "Введите номер телефона",
    "Phone number": "Номер телефона",
    "Login via email": "Войти через электронную почту",
    "Username, phone number or email": "Логин, номер телефона или email",
    "Phone number without +, in international format: 79283334455":
      "Номер телефона без +, в международном формате: 79283334455",
    "Email or phone number": "Электронная почта или номер телефона",
    "Password reset link sent to your email": "Ссылка для сброса пароля отправлена на вашу почту",
    "This phone number already exists": "Такой номер телефона уже существует",
    "No user found with this phone number": "Пользователь с таким номером телефона не найден",
    "Enter street name": "Введите название улицы",
    "Invalid email, or such email is already in use": "Неверный email, или такой email уже используется",
    "Contact number": "Контактный номер",
    "Copied to clipboard!": "Успешно скопировано!",
    "Want to delete?": "Хотите удалить?",
    Delete: "Удалить",
    "Your bonus points": "Ваши бонусные баллы",
    "Apply bonus points": "Применить бонусные баллы",
    "Incorrect input": "Некорректный ввод",
    "Not enough bonus points": "Недостаточно бонусных баллов",
    "This Email not exist": "Неверный адрес электронной почты",
    "Delete account": "Удалить аккаунт",
    "Account deletion": "Удаление аккаунта",
    "Referral system": "Реферальная система",
    "The string must be between 8 and 20 characters long": "Строка должна быть длиной от 8 до 24 символов",
    "The string must contain at least one number": "Строка должна содержать как минимум одну цифру",
    "The string must contain at least one lowercase letter": "Строка должна содержать как минимум одну строчную букву",
    "The string must contain at least one capital letter": "Строка должна содержать как минимум одну заглавную букву",

    "Please make sure that the entered address matches the one on the map":
      "Пожалуйста, убедитесь, что адрес совпадает с выбранной точкой на карте",
    "Delete account description":
      "Хотите удалить свой аккаунт? Удаление вашей учетной записи приведет к удалению всех данных связанных с ней, а также к невозможности использования сервиса",
    "Address search error, please select a location on the map":
      "Ошибка поиска адреса, пожалуйста, выберите место на карте",
    "No description": "Нет описания",
    "Purchase is limited in time": "Покупка ограничена по времени",
    AlreadyExistMessage: "Пользователь с данным номером телефона уже существует",
    "Pickup point": "Пункт выдачи",
    "Pickup points": "Пункты выдачи",
    "Select pickup point": "Выбрать пункт выдачи",
    "Payment status": "Статус оплаты",
    "Discount card": "Скидочная карта",
    "Applied bonus points": "Примененные баллы",
    "Reset bonus points": "Сбросить примененные баллы",
    "Bonus points": "Бонусные баллы",
    "Select shipping zone": "Выберите зону доставки",
    "Select address in the selected shipping zone": "Выберите адрес в выбранной зоне доставки",
    "Select an address in the zone": "Выберите адрес в зоне",
    "or change the delivery zone on the main page": "или поменяйте зону доставки на главной странице",
    from: "от",
    "Please, select the shipping method": "Выберите метод доставки",
    "Please, select the payment method": "Выберите способ оплаты",
    "The product is out of stock": "Товара нет в наличии",
    "The string must be between 8 and 24 characters long": "Длина строки должна составлять от 8 до 24 символов",
    "There are products left in your shopping cart. Click to complete your order.":
      "Остались продукты в корзине. Нажмите, чтобы завершить заказ.",
    "Goods in the cart": "Товары в корзине",
    "Repeat order": "Повторить заказ",
    "Order review": "Оценка заказа",
    "Please rate the service and leave any comments or feedback that can help us make it better. The main criteria include the quality of the products, speed of delivery, courtesy of the delivery person and the fact of delivery to the door. Your feedback is very important to us!":
      "Пожалуйста, оцените сервис и оставьте любые замечания или комментарии, которые могут помочь нам сделать его лучше. Основные критерии включают качество продуктов, скорость доставки, вежливость доставщика и факт доставки до двери. Ваш отзыв очень важен для нас!",
    "Rate the order": "Поставьте оценку заказа",
    "Needed to evaluate the order": "Необходимо для оценки заказа",
    "Order comment": "Комментарий к заказу",
  },
};
export default Global;
