import React, { useEffect } from "react";
import { IonSelectOption } from "@ionic/react";
import { CustomerAddress } from "../../../providers/CustomerAddresses/CustomerAddressesTypes";
import { CustomerAddressType } from "../../../generated/graphql";
import { getCustomerFullAddress } from "../../../utils/utils";
import CommonFormElement from "../../UI/CommonFormElements/CommonFormElement";
import Select from "../../Select/Select";

import classNames from "classnames/bind";
import styles from "../ShippingAddressComponent.module.scss";
import { useCustomerAddresses } from "../../../providers/CustomerAddresses/CustomerAddressesDataContext";
import { useCustomerAddressesStateData } from "../../../providers/CustomerAddresses/CustomerAddressesStateContext";

interface ICustomerAddressInput {
  // customerAddresses: CustomerAddress[];
  selectedAddressId?: number | string | null;
  onSelectChange: (address: CustomerAddress) => void;
  ts: any;
  invalidText?: string;
}

const cx = classNames.bind(styles);

const CustomerAddressInput: React.FC<ICustomerAddressInput> = ({
  // customerAddresses,
  selectedAddressId,
  onSelectChange,
  ts,
  invalidText,
}) => {
  const { customerAddresses, customerAddressesError, refetchCustomerAddresses } = useCustomerAddresses();
  const { setIsAddressModalOpen } = useCustomerAddressesStateData();

  useEffect(() => {
    refetchCustomerAddresses();
  }, []);

  useEffect(() => {
    if (customerAddresses) {
      const addr = customerAddresses?.length === 1 && !customerAddressesError ? customerAddresses[0] : undefined;

      if (addr) {
        onSelectChange(addr);
      }
    }
  }, [customerAddresses, customerAddressesError]);

  return (
    <CommonFormElement
      classListItem={cx("shipping__addressSelect")}
      label={ts["Delivery address"]}
      isValid={!invalidText}
      errorMessage={invalidText}
    >
      <Select
        disabled={false}
        value={selectedAddressId}
        isValid={!!selectedAddressId}
        placeholder={ts["Delivery address"]}
        languageJson={ts}
        className={"shippingAddress_deliverySelect"}
        onChange={(e: any) => {
          if (e.detail.value === "ADD_NEW") {
            setIsAddressModalOpen(true);
            return;
          }
          onSelectChange(customerAddresses.find((addr) => addr.id === e.detail.value)!);
        }}
      >
        {customerAddresses.length > 0 &&
          customerAddresses.map((address) => {
            return (
              <IonSelectOption key={address.id} value={address.id}>
                {getCustomerFullAddress(address as CustomerAddressType)}
              </IonSelectOption>
            );
          })}
        <IonSelectOption value="ADD_NEW" className={"shippingAddress_deliverySelectOption"}>
          {ts["Add new"]}
        </IonSelectOption>
      </Select>
    </CommonFormElement>
  );
};

export { CustomerAddressInput };
