import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { deliveryDatesClient } from "../../data/clients";
import { SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { ISODate } from "../../data/types/entities";

interface DeliveryDatesQueryAttrs {
  shippingMethodId?: string;
}

const useGetDeliveryDatesQuery = (
  { shippingMethodId }: DeliveryDatesQueryAttrs = {},
  options: UseQueryOptions<ISODate[]> = {},
) => {
  return useQuery<ISODate[]>(
    ["delivery-dates", shippingMethodId],
    async () => {
      if (deliveryDatesClient) {
        const data = await deliveryDatesClient.getDeliveryDates(
          shippingMethodId,
        );
        return data;
      }

      throw new Error("deliveryDatesClient not implemented");
    },
    {
      cacheTime: SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS,
      staleTime: SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS,
      refetchInterval: SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS,
      refetchIntervalInBackground: true,
      ...options,
    },
  );
};

export default useGetDeliveryDatesQuery;
