// Import React Libraries
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams, withRouter } from "react-router-dom";

import { AppContext } from "../../providers/Redux/Reducers/AppContext";

// Import Ionic Components
import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonList,
  IonLoading,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
  IonSpinner,
} from "@ionic/react";

// Import Css File
import "./product-detail.scss";
import styles from "./product-detail.module.scss";
import classNames from "classnames/bind";

// Import components
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../../components/Footer/Footer";
import ProductDetailSlideImg from "../../components/ProductDetail/ProductDetailSlideImg/ProductDetailSlideImg";
import ProductDetailInStock from "../../components/ProductDetail/ProductDetailInstock/ProductDetailInStock";
import ProductDetailRating from "../../components/ProductDetail/ProductDetailRating/ProductDetailRating";
import ProductDetailSlideBadges from "../../components/ProductDetail/ProductDetailSlideBadges/ProductDetailSlideBadges";
import ProductDetailCounter from "../../components/ProductDetail/ProductDetailCounter/ProductDetailCounter";
import ProductDetailInfo from "../../components/ProductDetail/ProductDetailInfo/ProductDetailInfo";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import { ProductType, Product as TProduct } from "../../data/types/entities";
import useGetProductByIdQuery from "../../providers/products/useGetProductByIdQuery";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import useGetCategoryByIdQuery from "../../providers/categories/useGetCategoryByIdQuery";
import useGetProductsQuery from "../../providers/products/useGetProductsQuery";
import useGetSettingsQuery from "../../providers/settings/useGetSettingsQuery";
import { ProductDescription } from "../../components/UI/ProductDescription/ProductDescription";

import { ProductId, useCartData } from "../../providers/cartDataStore";
import { ProductsSlider } from "../../components/ProductsSlider/ProductsSlider";
import useGetProductVariations from "../../providers/products/useGetProductVariations";
import { SelectVariationAttribute } from "../../fsd/features/SelectVatiationAttribute/SelectVatiationAttribute";

const cx = classNames.bind(styles);

export type ProductDetailParams = {
  id: string;
};

const ProductDetail = () => {
  const { data: settings } = useGetSettingsQuery(); // запрашиваем настройки приложения
  const { state } = useContext(AppContext); // чисто для перевода (eng to rus)
  const ts = useMemo(() => state.config.languageJson, [state.config.languageJson]);
  const { cartProductsMap, addToCart: addToCartStore, removeFromCart } = useCartData();
  const [showLoading, setShowLoading] = useState(false);
  const { search } = useLocation();
  const { id } = useParams<ProductDetailParams>();

  const {
    data: product,
    isLoading: isSingleProductLoading,
    isFetching: isSingleProductFetching,
  } = useGetProductByIdQuery(id);

  const { data: category, isLoading: isCategoryLoading } = useGetCategoryByIdQuery(product?.categories[0]!.id ?? "", {
    enabled: !!product && !!product?.categories?.length,
  });

  const { data: categoryProducts, isFetching: isCategoryProductsLoading } = useGetProductsQuery(
    {
      categoryId: category?.id,
    },
    {
      enabled: !!category?.id,
    }
  );

  const { data: variations, isFetching: isVariationsFetching } = useGetProductVariations(product?.variations ?? [], {
    enabled: !!product?.variations?.length,
  });

  const history = useHistory();
  // For History

  let [selectedVariation, setSelectedVariation] = useState<TProduct | null>(null); // For Selected Variation

  const productId = product?.type === "variable" ? selectedVariation?.id : product?.id;

  const sliderOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
  };

  const ionContent = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    ionContent.current!.scrollToTop(500);
    if (!search.length) {
      selectedVariation = null;
      setSelectedVariation(null);
      // this costyl for temporary fix variables issue
    }
  }, [id]);

  function addToCart(product: TProduct) {
    // DON'T ALLOW ADD TO CART IF PRODUCT UNPURCHASABLE
    if (!product?.purchasable) {
      return;
    }

    const productItem = product.type === ProductType.VARIABLE ? selectedVariation! : product;
    addToCartStore(productItem);
  }

  function handleRemoveProductFromCart(id: ProductId) {
    removeFromCart(id);
  }

  // For New Product Check Badge
  function checkProductNew() {
    if (product && product.dateCreated) {
      const pDate = new Date(product.dateCreated);
      const date = pDate.getTime() + state.config.newProductDuration * 86400000;
      const todayDate = new Date().getTime();

      return date > todayDate;
    }
  }

  const openProducts = (value: string) => {
    history.push(`/${value}`);
  };

  const handleVariationSelect = (variation: TProduct | null) => {
    setSelectedVariation(variation);
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton />
      </IonHeader>
      <IonContent ref={ionContent}>
        <ToolbarDesktop
          breadcrumbs={
            !isSingleProductLoading || !isCategoryLoading || !isCategoryProductsLoading || !isSingleProductFetching
              ? [
                  {
                    title: category ? category.name : ts["Catalog"],
                    handler: () => {
                      return category
                        ? openProducts(`products/${category.id}/${category.name}/popular`)
                        : openProducts("search-main-categories");
                    },
                  },
                  { title: product?.name ?? "" },
                ]
              : []
          }
        />
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={ts["Please wait..."]}
          duration={20000}
        />
        <IonLoading
          isOpen={isSingleProductLoading || isSingleProductFetching}
          message={ts["Please wait..."]}
          duration={20000}
        />
        <div className={`${cx("productDetailWrapper", "product-detail-page", "productDetailPage")} global-content`}>
          <MaxWidthContainer
            smContainer={true}
            mdContainer={true}
            lgContainer={true}
            classList={cx("productDetailContainer")}
          >
            {!!product ? (
              <div>
                <IonRow className={cx("productDetailTop")}>
                  <IonCol sizeMd={"5"} className={cx("productDetailSliderWrapper")}>
                    <IonSlides
                      options={sliderOptions}
                      key={product.images.map((img) => img.src.slice(-10)).join("_")}
                      className={cx("productDetailSlider")}
                      pager={false}
                    >
                      {product.videoUrl ? (
                        <IonSlide className={cx("productDetailSliderItem")}>
                          <video height={"100%"} className={cx("productDetailSlider__video")} controls>
                            <source src={product.videoUrl} type="video/mp4" />
                          </video>
                        </IonSlide>
                      ) : null}
                      {product.images?.length === 0 ? (
                        <IonSlide className={cx("productDetailSliderItem")}>
                          <ProductDetailSlideBadges
                            product={product}
                            selectedVariation={selectedVariation}
                            languageJson={ts}
                            isNewProduct={checkProductNew()!}
                          />
                          <ProductDetailSlideImg image={undefined} />
                        </IonSlide>
                      ) : (
                        product.images?.map((img) => (
                          <IonSlide key={img.src && img.src.slice(-10)}>
                            <ProductDetailSlideBadges
                              product={product}
                              selectedVariation={selectedVariation}
                              languageJson={ts}
                              isNewProduct={checkProductNew()!}
                            />
                            <ProductDetailSlideImg image={img} />
                          </IonSlide>
                        ))
                      )}
                    </IonSlides>
                  </IonCol>
                  <IonCol sizeMd={"7"} className={cx("ion-hide-md-down")}>
                    <IonRow className={cx("productDetailTitleRow")}>
                      <IonCol className="productDetailTitleCol" size="12">
                        <h3 className={cx("productDetailTitle")}>{product?.name}</h3>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol size="6">
                        <ProductDetailInStock
                          label={!product?.purchasable ? ts["Purchase is limited in time"] : ""}
                          isInStock={product.stockStatus === "instock"}
                          languageJson={ts}
                        />
                      </IonCol>
                      <IonCol size="6" className={cx("averageRatingWrapper")}>
                        {product.averageRating && (
                          <ProductDetailRating
                            rating={
                              product.averageRating ? product.averageRating.slice(0, 3).replace(".", ",") : undefined
                            }
                          />
                        )}
                      </IonCol>
                    </IonRow>
                    {isVariationsFetching && (
                      <IonGrid>
                        <IonCol>
                          <IonSpinner />
                        </IonCol>
                      </IonGrid>
                    )}
                    {variations && product.type === ProductType.VARIABLE && !isVariationsFetching && (
                      <SelectVariationAttribute variations={variations} onVariationSelect={handleVariationSelect} />
                    )}
                    {(product.type !== ProductType.VARIABLE ||
                      (product.type === ProductType.VARIABLE && selectedVariation)) &&
                      productId && (
                        <ProductDetailCounter
                          isInStock={product.stockStatus === "instock"}
                          isEmpty={!cartProductsMap[productId]?.quantity}
                          product={product}
                          selectedVariation={selectedVariation}
                          cartQuantity={cartProductsMap[productId]?.quantity ?? 0}
                          onAddToCart={addToCart}
                          onRemoveFromCart={handleRemoveProductFromCart}
                        />
                      )}
                  </IonCol>
                </IonRow>

                {/*  -------------------------- For Badge,Price And Name ---------------------------- */}

                <IonGrid className={cx("ion-hide-md-up", "productDetailHeadInfo")}>
                  <IonRow>
                    <IonCol size="6">
                      <ProductDetailInStock
                        label={!product?.purchasable ? ts["Purchase is limited in time"] : ""}
                        isInStock={product.stockStatus === "instock"}
                        languageJson={ts}
                      />
                    </IonCol>
                    <IonCol size="6" className={cx("averageRatingWrapper")}>
                      {product.averageRating && (
                        <ProductDetailRating
                          rating={
                            product.averageRating ? product.averageRating.slice(0, 3).replace(".", ",") : undefined
                          }
                        />
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                      <h3 className={cx("productDetailItemTitle")}>{product?.name}</h3>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                {/*  -------------------------- For Grouped Products ---------------------------- */}

                {/*<ProductDetailGroupProducts/>*/}

                {/*  -------------------------- For Variable Products ---------------------------- */}

                {isVariationsFetching && (
                  <IonGrid className={cx("ion-hide-md-up")}>
                    <IonCol>
                      <IonSpinner />
                    </IonCol>
                  </IonGrid>
                )}

                {product.type === ProductType.VARIABLE && !isVariationsFetching && (
                  <IonGrid className={cx("productDetailAttributesGrid", "ion-hide-md-up")}>
                    <IonList>
                      {variations && product.type === ProductType.VARIABLE && !isVariationsFetching && (
                        <SelectVariationAttribute variations={variations} onVariationSelect={handleVariationSelect} />
                      )}
                    </IonList>
                  </IonGrid>
                )}
                {settings?.showProductReviews ? (
                  <div className={cx("productDetailInfo")}>
                    <ProductDetailInfo id={id} product={product} />
                  </div>
                ) : (
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <h3 className={"ion-no-margin ion-margin-bottom"}>{ts["Product Description"]}</h3>
                        <ProductDescription props={product} />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}

                <IonGrid class={cx("sameCategorySlider", "productDetailContent animate-product ion-no-padding")}>
                  {isCategoryProductsLoading && (
                    <IonCol>
                      <IonSpinner className="ion-margin-start" />
                    </IonCol>
                  )}
                  {categoryProducts && categoryProducts?.length > 0 && !isCategoryProductsLoading && (
                    <IonRow class="ion-no-padding">
                      <IonCol class="ion-no-padding" size="12">
                        <IonRow class="top-icon-header heading">
                          <p className={cx("relatedItems")}>{ts["Products of this category"]}</p>
                        </IonRow>
                        <div className={cx("sameCategorySliderWrapper")}>
                          <ProductsSlider items={categoryProducts} />
                        </div>
                      </IonCol>
                    </IonRow>
                  )}
                </IonGrid>
              </div>
            ) : null}
          </MaxWidthContainer>
        </div>
        <Footer />
      </IonContent>

      <IonFooter>
        {product && productId && (
          <div className={cx("ion-hide-md-up")}>
            {(product.type !== ProductType.VARIABLE ||
              (product.type === ProductType.VARIABLE && selectedVariation)) && (
              <ProductDetailCounter
                isInStock={product.stockStatus === "instock"}
                isEmpty={!cartProductsMap[productId]?.quantity}
                product={product}
                selectedVariation={selectedVariation}
                cartQuantity={cartProductsMap[productId]?.quantity ?? 0}
                onAddToCart={addToCart}
                onRemoveFromCart={handleRemoveProductFromCart}
              />
            )}
          </div>
        )}
      </IonFooter>
    </IonPage>
  );
};

export default withRouter(ProductDetail);
