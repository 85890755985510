import React, { useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";

// Import Ionic
import { IonCol, IonIcon, IonImg, IonRow } from "@ionic/react";

// Import Icons
import { basketOutline, heartOutline, personOutline } from "ionicons/icons";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ToolbarDesktop.module.scss";

// Import Components
import MaxWidthContainer from "../../UI/MaxWidthContainer/MaxWidthContainer";
import { SearchBox } from "../../SearchBox/SearchBox";
import Breadcrumbs from "../../Breadcrumbs/Breadcrumbs";
import { useAuth } from "../../../contexts/auth-context";
import { images } from "../../../config/images";
import { useGetSettingsQuery } from "../../../providers/settings";
import { applyRegExOnPhoneNumber } from "../../../providers/utils/utils";
import { useCartData } from "../../../providers/cartDataStore";
import { SelectShippingZone } from "../../SelectShippingZone/SelectShippingZone";

const cx = classNames.bind(styles);

interface IToolbarDesktop {
  showShippingZone?: boolean;
  breadcrumbs?: { title: string; handler?: () => void }[];
}

const ToolbarDesktop: React.FC<IToolbarDesktop> = ({ breadcrumbs, showShippingZone }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { cartTotalValue, cartProducts } = useCartData();

  const { user } = useAuth();
  const { state } = useContext(AppContext);
  const { data: appSettings } = useGetSettingsQuery();

  const goToPage = (path: string) => {
    history.push(path);
  };

  const goToMyAccount = () => {
    if (!!user) {
      goToPage("/my-account");
    } else {
      if (!pathname.startsWith("/login")) {
        goToPage("/auth");
      }
    }
  };

  const isDefaultShippingZoneEnabled = useMemo(() => {
    return appSettings?.isShippingZonesEnabled && appSettings?.showDefaultShippingZone;
  }, [appSettings?.isShippingZonesEnabled, appSettings?.showDefaultShippingZone]);

  return (
    <div className={cx("toolbarDesktop", "ion-align-items-center", "ion-hide-md-down")}>
      <MaxWidthContainer>
        <div className={cx("ion-align-items-center", "toolbarDesktopWrapper")}>
          <IonRow className={cx("toolbarTop")}>
            <IonCol size={"auto"} className={cx("toolbarLogoWrapper")}>
              <IonImg
                src={images.logo}
                alt={state.config.languageJson["Home"]}
                className={cx("toolbarLogo")}
                onClick={() => {
                  goToPage("/home3");
                }}
              />
            </IonCol>

            <IonCol className="flex">
              {showShippingZone && isDefaultShippingZoneEnabled && <SelectShippingZone interfaceType="popover" />}
            </IonCol>
            <IonCol size={"auto"}>
              <a
                href={`tel:+${applyRegExOnPhoneNumber(appSettings?.companyPhoneNumber || "")}`}
                className={cx("toolbarPhone")}
              >
                {appSettings?.companyPhoneNumber}
              </a>
            </IonCol>
          </IonRow>
          <IonRow className={cx("toolbarBottom")}>
            <IonCol size={"auto"} className={cx("toolbarCatalog")}>
              <span
                onClick={() => {
                  goToPage("/search-main-categories");
                }}
              >
                {state.config.languageJson["Catalog of goods"]}
              </span>
              {/*<IonIcon icon={chevronDown} size={"small"} />*/}
            </IonCol>
            <IonCol className={cx("toolbarSearch")}>
              <SearchBox />
            </IonCol>
            <IonCol className={cx("toolbarIcons")} size={"auto"}>
              <div
                className={cx("toolbarIcon", "toolbarIconWish")}
                title={state.config.languageJson["Favorite"]}
                onClick={() => {
                  goToPage("/wish-list");
                }}
              >
                <IonIcon icon={heartOutline} />
              </div>
              <div
                className={cx("toolbarIcon", "toolbarIconUser")}
                title={state.config.languageJson["Profile"]}
                onClick={goToMyAccount}
              >
                <IonIcon icon={personOutline} />
              </div>
              <div
                className={cx("toolbarIconBasketWrapper")}
                onClick={() => {
                  goToPage("/cart");
                }}
              >
                <div className={cx("toolbarIcon", "toolbarIconBasket")} title={state.config.languageJson["My Cart"]}>
                  <IonIcon icon={basketOutline} />
                  <div className={cx("toolbarIconBasketBadge")}>
                    <span>{cartProducts.reduce((acc, item) => item.quantity + acc, 0).toFixed(2)}</span>
                  </div>
                </div>
                <span className={cx("toolbarIconSum")}>&#x20bd;{cartTotalValue.toFixed(2)}</span>
              </div>
            </IonCol>
          </IonRow>
        </div>
        {!!breadcrumbs?.length && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      </MaxWidthContainer>
    </div>
  );
};

export default ToolbarDesktop;
