import React, { useCallback, useContext } from "react";
import { IonCol, IonRow, IonSkeletonText } from "@ionic/react";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

import classNames from "classnames/bind";
import styles from "./HomePageCategories.module.scss";
import { useHistory } from "react-router";
import { Image } from "../Image/Image";

import "swiper/scss/mousewheel";
import { useGetAllCategoriesQuery } from "../../providers/categories";
import { icons } from "../../config/Icons";
import { useGetSettingsQuery } from "../../providers/settings";
import { CustomSlider } from "../CustomSlider/CustomSlider";

const cx = classNames.bind(styles);

const categoriesSkeleton = [...Array(10).keys()];

const HomePageCategories = () => {
  const { state } = useContext(AppContext);
  let history = useHistory();
  const { data: appSettings } = useGetSettingsQuery();

  const { data: { allCategories, mainCategories } = {} } = useGetAllCategoriesQuery();

  const openSubcategories = useCallback(
    (id: string | number, category: { [key: string]: any }) => {
      if (allCategories?.filter((x: { [key: string]: any }) => x.parent === id).length) {
        history.push("/categories/" + id + "/subcategories", { parentCategory: category });
      } else {
        history.push("/products/" + id + "/" + category.name + "/popular");
      }
    },
    [allCategories, history]
  );

  return (
    <div className={cx("row")}>
      {mainCategories?.length ? (
        <CustomSlider>
          <div className={"flex"}>
            {appSettings?.showSliderCatalogButton && (
              <div className={cx("catalog__slide")}>
                <div
                  className={cx("catalog")}
                  onClick={() => {
                    history.push("/search-main-categories");
                  }}
                >
                  <div className={cx("catalog__img-wrapper")}>
                    {icons.catalog && (
                      <Image
                        draggable={false}
                        alt={"catalog"}
                        className={cx("catalog__img")}
                        downsize={false}
                        lazyLoad={false}
                        src={icons.catalog}
                      />
                    )}
                    <div className={cx("catalog__title")}>
                      <p>{state.config.languageJson.Catalog}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {mainCategories.map((category) => {
              return (
                !!category.image?.src && (
                  <div key={category.id} className={cx("category__slide")}>
                    <div
                      onClick={() => {
                        openSubcategories(category.id, category);
                      }}
                      className={cx("category")}
                    >
                      <div className={cx("category__img-wrapper")}>
                        <Image
                          draggable={false}
                          className={cx(
                            `${category.image.src.includes("catalog") ? "category__img_transform" : "category__img"}`
                          )}
                          downsize={false}
                          lazyLoad={false}
                          src={category.image.src}
                        />
                      </div>
                      <p className={cx("category__title")}>{category.name}</p>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </CustomSlider>
      ) : (
        <IonCol size={"12"} className={"ion-no-padding ion-no-margin"}>
          <IonRow className={cx("skeleton")}>
            {categoriesSkeleton.map((skeleton) => {
              return (
                <IonCol key={skeleton} className={cx("skeleton__col")} size="3">
                  <IonSkeletonText className={cx("skeleton__text")} animated style={{ height: 70 }}></IonSkeletonText>
                  <IonSkeletonText className={cx("skeleton__text")} animated style={{ height: 10 }}></IonSkeletonText>
                  <IonSkeletonText
                    className={cx("skeleton__text")}
                    animated
                    style={{ height: 10, width: "50%" }}
                  ></IonSkeletonText>
                </IonCol>
              );
            })}
          </IonRow>
        </IonCol>
      )}
    </div>
  );
};

export default HomePageCategories;
