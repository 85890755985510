import React, { useState } from "react";
import styles from "./StarRating.module.scss"
import clns from "classnames";
import { ReactComponent as StarIcon } from '../../../assets/icon/star.svg'

interface StarRatingProps {
  value: number;
  starClassName?: string;
  onChange: (value: number) => void;
}

function StarRating({ value, onChange, starClassName }: StarRatingProps) {
  const [hover, setHover] = useState(0);

  return (
    <div className={styles.starWrapper}>
      {[...Array(5)].map((_, i) => {
        const ratingValue = i + 1;
        return (
          <label key={ratingValue}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => {
                onChange(ratingValue);
              }}
            />
            <StarIcon
              onMouseLeave={() => setHover(0)}
              onMouseEnter={() => setHover(ratingValue)}
              className={
                clns(starClassName,
                  ratingValue <= (hover || value) ? styles.activeStar : styles.star
                )
              }
            />
          </label>
        );
      })}
    </div>
  );
}


export default StarRating;