import React, { useContext, useMemo, useRef } from "react";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";

import { IonButton, IonButtons, IonIcon, IonImg, IonTitle, IonToolbar, isPlatform } from "@ionic/react";

// import components
// import TS interface
import { IToolbarProps } from "../Toolbar";

// import styles
import classNames from "classnames/bind";
import styles from "./ToolbarMobile.module.scss";
import { call, heart, shareSocial } from "ionicons/icons";
import { SocialSharing } from "@ionic-native/social-sharing";
import { useGetSettingsQuery } from "../../../providers/settings";
import { images } from "../../../config/images";
import HomePageSearchBox from "../../HomePageSearchBox/HomePageSearchBox";
import BackButton from "../../BackButton/BackButton";
import { SelectShippingZone } from "../../SelectShippingZone/SelectShippingZone";

const cx = classNames.bind(styles);

interface IToolbarMobileProps extends IToolbarProps {}

const ToolbarMobile = ({ showBackButton, showSearchButton, showShippingZone }: IToolbarMobileProps) => {
  const { state } = useContext(AppContext);
  const { data: appSettings } = useGetSettingsQuery();
  const shareButton = useRef<HTMLIonButtonElement>(null);

  const shareApp = () => {
    const shareData: ShareData = {
      title: appSettings?.sharedData.title,
      text: appSettings?.sharedData.text,
      url: isPlatform("ios") ? appSettings?.sharedData.iosUrl : appSettings?.sharedData.androidUrl,
    };

    if (typeof window !== "undefined" && navigator.share && navigator.canShare && navigator.canShare(shareData)) {
      shareButton.current?.addEventListener("click", async () => {
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.error("Share failed:", err);
        }
      });
    } else {
      SocialSharing.share(
        `${shareData.text}ios: ${appSettings?.sharedData.iosUrl}\n\nandroid: ${appSettings?.sharedData.androidUrl}`
      );
    }
  };

  const isDefaultShippingZoneEnabled = useMemo(() => {
    return appSettings?.isShippingZonesEnabled && appSettings?.showDefaultShippingZone;
  }, [appSettings?.isShippingZonesEnabled, appSettings?.showDefaultShippingZone]);

  return (
    <div>
      <IonToolbar className={cx("toolbar", "ion-hide-md-up")}>
        <IonButtons slot="start">
          {showShippingZone && isDefaultShippingZoneEnabled && <SelectShippingZone />}
          {showBackButton && <BackButton />}
          <IonTitle>
            <IonImg className={cx("title-img")} src={images.roundLogo} />
          </IonTitle>
        </IonButtons>
        {false && (
          <IonButtons slot="start" style={{ width: "100%" }}>
            {showBackButton ? (
              <BackButton />
            ) : (
              <>
                <IonButton
                  fill={"clear"}
                  className={`ion-no-padding ion-no-margin ${cx("logo-button")}`}
                  routerDirection="forward"
                  routerLink="/home3"
                >
                  <IonImg className={cx("title-img")} src={images.roundLogo} />
                </IonButton>
                {showSearchButton && !appSettings?.wishListIcons && (
                  <div style={{ width: "100%", color: "#222" }}>
                    <HomePageSearchBox placeholder={state.config.languageJson["Search in the store"]} />
                  </div>
                )}
              </>
            )}
          </IonButtons>
        )}
        <IonTitle>
          <IonImg className={cx("logo-centered", "title-img")} src={images.roundLogo} />
        </IonTitle>
        {/*<IonButtons className={cx("buttons-centered")}>*/}
        {/*  <IonButton*/}
        {/*    fill={"clear"}*/}
        {/*    className={`ion-no-padding ion-no-margin ${cx("logo-button")}`}*/}
        {/*    routerDirection="forward"*/}
        {/*    routerLink="/home3"*/}
        {/*  >*/}
        {/*  </IonButton>*/}
        {/*</IonButtons>*/}
        {(appSettings?.wishListIcons || !showSearchButton) && (
          <IonButtons slot="end" className={cx("testclass")}>
            <IonButton
              fill="clear"
              className={cx(isDefaultShippingZoneEnabled ? "button-sm" : "button")}
              routerLink="/wish-list"
              routerDirection="forward"
            >
              <IonIcon className={cx("icon")} icon={heart} />
            </IonButton>
            <IonButton
              fill="clear"
              className={cx(isDefaultShippingZoneEnabled ? "button-sm" : "button")}
              routerLink="/contact-us"
              routerDirection="forward"
            >
              <IonIcon className={cx("icon")} icon={call} />
            </IonButton>
            <IonButton
              fill="clear"
              className={cx(isDefaultShippingZoneEnabled ? "button-sm" : "button")}
              routerDirection="forward"
              onClick={shareApp}
              ref={shareButton}
            >
              <IonIcon className={cx("icon", "icon-inf")} icon={shareSocial} />
            </IonButton>
          </IonButtons>
        )}
      </IonToolbar>
    </div>
  );
};

export default ToolbarMobile;
