interface RouteParams {
  id: string;
  name: string;
  type: string;
}

const getQueryParamsFromPath = ({ id, name, type }: RouteParams) => {
  let query = {};
  if (type === "popular") {
    query = { ...query, order: "desc", sortOrder: "popularity" };
  }

  if (type === "featured") {
    query = { ...query, featured: true };
  }

  if (type === "sale" || type === "on_sale") {
    query = { ...query, saleType: true };
  }

  if (id !== "0") {
    query = { ...query, categoryId: id };
  }

  return query;
};

const replaceEmbed = (str: string) => {
  return str.replace(/\[embed\](.+?)\[\/embed\]/gimu, `<embed src="$1"/>`);
};

export { getQueryParamsFromPath, replaceEmbed };
