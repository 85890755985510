// Import Ionic Components
import { IonList, IonListHeader, IonLoading, IonItem, IonLabel, useIonViewWillLeave } from "@ionic/react";

// Import React Libraries
import React, { useContext, useState, useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useHistory } from "react-router";

// Import Css File
import styles from "./subCategories.module.scss";
import classNames from "classnames/bind";

// Import custom providers and components
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { ProductsSearchPageLayout } from "../../components/ProductsSearchPageLayout/ProductsSearchPageLayout";
import { WCProduct } from "../../providers/WooComAPI/woo-com-api";
import {
  useGetAllCategoriesQuery,
  useGetCategoryByIdQuery,
  useGetSubCategoriesQuery,
} from "../../providers/categories";

const cx = classNames.bind(styles);

const SubCategories: React.FC<RouteComponentProps> = (props: any) => {
  let history = useHistory(); // For Push page
  const { data: { allCategories } = {}, isLoading } = useGetAllCategoriesQuery();

  const { data: subCategories } = useGetSubCategoriesQuery(props.match.params.parent, { enabled: !!allCategories });
  const { data: parentCategory } = useGetCategoryByIdQuery(props.match.params.parent, { enabled: !!allCategories });

  const { state } = useContext(AppContext);
  const [searchResult, setSearchResult] = useState<WCProduct[]>([]);

  const isHaveSubCategories = useCallback(
    (id: string) => {
      return !!allCategories?.some((item) => String(item.parent) === id);
    },
    [allCategories]
  );

  const openShop = useCallback(
    (id: string, name: string) => {
      if (isHaveSubCategories(id)) {
        history.push("/categories/" + id + "/subcategories");
      } else {
        history.push("/products/" + id + "/" + name + "/popular");
      }
    },
    [history, isHaveSubCategories]
  );

  useIonViewWillLeave(() => {
    setSearchResult([]);
  }, [props.match.params]);

  return (
    <>
      <IonLoading isOpen={isLoading} message={state.config.languageJson["Please wait..."]} />
      {!isLoading && (
        <ProductsSearchPageLayout pageTitle={parentCategory?.name || ""} breadcrumbsTitle={parentCategory?.name || ""}>
          <IonList mode="ios" className={searchResult.length ? cx("hide") : cx("categoriesList", "ion-no-padding")}>
            <IonListHeader className={"ion-hide-md-up"}>{parentCategory?.name}</IonListHeader>
            {subCategories?.map((subCategory) => (
              <IonItem
                key={subCategory.id}
                onClick={() => openShop(String(subCategory.id), subCategory.name)}
                button
                type="button"
                className={cx("ion-no-padding")}
              >
                <IonLabel>{subCategory.name}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </ProductsSearchPageLayout>
      )}
    </>
  );
};

export default SubCategories;
