import { IProductsClient } from "../../types/clients";
import BaseClient from "../base-client";
import { Product, ProductsQueryParams, SearchProductsQueryParams } from "../../types/entities";
import { ParhatoProduct, ParhatoProductReview } from "./types";
import { converParhatoReview, convertParhatoProduct, getCurrentShippingZoneId } from "./utils";

const DEFAULT_PRODUCTS_PARAMS = {
  status: "publish",
  stock_status: "instock",
  min_price: 0.1,
  per_page: 21,
};

function convertProductsProps(props: ProductsQueryParams) {
  return {
    on_sale: props.saleType,
    page: props.pageNumber,
    per_page: props.pageSize,
    category: props.categoryId,
    orderby: props.sortOrder,
    min_price: props.minPrice,
    max_price: props.maxPrice,
    keyword: props.keyword,
    featured: props.featured,
  };
}

class ProductsClient extends BaseClient implements IProductsClient {
  async getProducts(props: ProductsQueryParams) {
    const response = await this.instance.get<ParhatoProduct[]>("/wp-json/wc/v3/products?", {
      params: {
        ...DEFAULT_PRODUCTS_PARAMS,
        shippingZone: getCurrentShippingZoneId(),
        ...convertProductsProps(props),
      },
    });

    return response.data.map(convertParhatoProduct);
  }

  async searchProducts(params: SearchProductsQueryParams) {
    const response = await this.instance.get<ParhatoProduct[]>("/wp-json/parhato-api/v1/search-products?", {
      params: {
        q: params.keyword,
        page: params.pageNumber,
        shippingZone: getCurrentShippingZoneId(),
      },
    });

    return response.data.map(convertParhatoProduct);
  }

  async getCutomersChoiceProducts(props: ProductsQueryParams) {
    const response = await this.instance.get<ParhatoProduct[]>("wp-json/wc/v3/products", {
      params: {
        ...DEFAULT_PRODUCTS_PARAMS,
        shippingZone: getCurrentShippingZoneId(),
        orderby: "popularity",
        order: "desc",
      },
    });

    return response.data.map(convertParhatoProduct);
  }

  async getNewestProducts(props: ProductsQueryParams) {
    const response = await this.instance.get<ParhatoProduct[]>("/wp-json/wc/v3/products", {
      params: {
        ...DEFAULT_PRODUCTS_PARAMS,
        shippingZone: getCurrentShippingZoneId(),
      },
    });
    return response.data.map(convertParhatoProduct);
  }

  async getOnSaleProducts(props: ProductsQueryParams) {
    const response = await this.instance.get<ParhatoProduct[]>("/wp-json/wc/v3/products", {
      params: {
        ...DEFAULT_PRODUCTS_PARAMS,
        shippingZone: getCurrentShippingZoneId(),
        on_sale: true,
      },
    });

    return response.data.map(convertParhatoProduct);
  }

  async getFeaturedProducts(props: ProductsQueryParams) {
    const response = await this.instance.get<ParhatoProduct[]>("/wp-json/wc/v3/products", {
      params: {
        ...DEFAULT_PRODUCTS_PARAMS,
        shippingZone: getCurrentShippingZoneId(),
        featured: true,
      },
    });

    return response.data.map(convertParhatoProduct);
  }

  async getProductById(id: string): Promise<Product> {
    const response = await this.instance.get<ParhatoProduct>(`/wp-json/wc/v3/products/${id}`, {
      params: {
        ...DEFAULT_PRODUCTS_PARAMS,
        shippingZone: getCurrentShippingZoneId(),
      },
    });

    return convertParhatoProduct(response.data);
  }

  async getIncludeProducts(include: string[], params?: { per_page?: string }) {
    const response = await this.instance.get<ParhatoProduct[]>("/wp-json/wc/v3/products", {
      params: {
        include: include.join(","),
        per_page: params && params.per_page ? params.per_page : include.length + 1,
        shippingZone: getCurrentShippingZoneId(),
      },
    });

    return response.data.map(convertParhatoProduct);
  }

  async getProductReviews(id: Product["id"]) {
    const response = await this.instance.get<ParhatoProductReview[]>("/wp-json/wc/v3/products/reviews?product=" + id);

    return response.data.map(converParhatoReview);
  }
}

export default ProductsClient;
