import React from 'react';
import {IonRow, IonSkeletonText} from "@ionic/react";
import classNames from "classnames/bind";
import styles from "./MyAccountComponent.module.scss";

const cx = classNames.bind(styles);

const CustomerAddressesSkeleton = () => {
  return (
    <div>
      <IonSkeletonText className={cx('skeleton_item')} animated={true} style={{height: 32}}>
      </IonSkeletonText>
      <IonSkeletonText className={cx('skeleton_item')} animated={true} style={{height: 32}}>
      </IonSkeletonText>
      <IonSkeletonText className={cx('skeleton_item')} animated={true} style={{height: 32}}>
      </IonSkeletonText>
      <IonRow className={'ion-no-margin ion-no-padding ion-align-items-center ion-justify-content-center'}>
        <IonSkeletonText className={cx('skeleton_item')} animated={true} style={{height: 32, width: '30%'}}>
        </IonSkeletonText>
      </IonRow>
    </div>
  );
};

export {CustomerAddressesSkeleton};