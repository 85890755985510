import React from "react";
import SkeletonItem from "../../components/SkeletonItem/SkeletonItem";
import { IonSkeletonText, IonSlide, IonSlides, isPlatform } from "@ionic/react";
import classNames from "classnames/bind";
import styles from "./HomeProductsWidgetSkeleton.module.scss";

const skeletonItems = [...Array(21).keys()];
const cx = classNames.bind(styles);

const sliderConfig = {
  slidesPerView: 2,
  spaceBetween: 16,
  freeMode: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    1140: {
      speed: 1200,
      slidesPerView: 7,
      slidesPerGroup: 7,
    },
    1024: {
      slidesPerView: 6,
      slidesPerGroup: 6,
    },
    768: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
    640: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    320: {
      slidesPerView: 2.5,
    },
  },
};

// I DON'T KNOW WHY IT IS WORK LIKE THAT
const desktopSliderConfig = {
  slidesPerView: 2,
  spaceBetween: 15,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  breakpoints: {
    412: {
      // from 515px
      slidesPerView: 3,
    },
    515: {
      // from 640px
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    615: {
      // from 768px
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
    820: {
      // from 1024px
      slidesPerView: 5,
      slidesPerGroup: 5,
    },
    960: {
      // from 1200px
      speed: 1200,
      slidesPerView: 7,
      slidesPerGroup: 7,
    },
  },
};
const HomeProductsWidgetSkeleton = () => {
  return (
    <div className={cx("wrapper")}>
      <IonSkeletonText animated style={{ height: 20, width: 120 }} className={cx("title")} />
      <IonSlides
        className={cx("slider", "sliderWrapper")}
        pager={true}
        options={isPlatform("desktop") ? desktopSliderConfig : sliderConfig}
      >
        {skeletonItems.map((el) => {
          return (
            <IonSlide key={el} className={cx("ion-slide-max-width")}>
              <SkeletonItem />
            </IonSlide>
          );
        })}
      </IonSlides>
    </div>
  );
};

export { HomeProductsWidgetSkeleton };
