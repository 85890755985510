import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import styles from "./HomeProductsWidget.module.scss";
import classNames from "classnames/bind";

import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import HomeProductsSection from "../../components/HomeProductsSection/HomeProductsSection";
import {
  useGetFeaturedProductsQuery,
  useGetNewestProductsQuery,
  useGetOnSaleProductsQuery,
  useGetCustomersChoiceProductsQuery,
} from "../../providers/products";
import { useGetSettingsQuery } from "../../providers/settings";
import { HomeProductsWidgetSkeleton } from "../HomeProductsWidgetSkeleton/HomeProductsWidgetSkeleton";
import { AppSettings } from "../../data/types/entities";

const cx = classNames.bind(styles);

const HomeProductsWidget = () => {
  const {
    data: newestProductList,
    isFetching: isNewestProductsFetching,
    isRefetching: isNewestProductRefetching,
  } = useGetNewestProductsQuery();

  const {
    data: onSaleProductList,
    isFetching: isOnSaleProductsFetching,
    isRefetching: isOnSaleProductRefetching,
  } = useGetOnSaleProductsQuery();

  const {
    data: featuredProductList,
    isFetching: isFeatureProductsFetching,
    isRefetching: isFeatureProductRefetching,
  } = useGetFeaturedProductsQuery();

  const {
    data: customersChoiceProducts,
    isFetching: isCustomersChoiceProductsFetching,
    isRefetching: isCustomersChoiceProductsRefetching,
  } = useGetCustomersChoiceProductsQuery();

  const { state } = useContext(AppContext);
  const { data: appSettings } = useGetSettingsQuery();

  const { homePageCategories: { featured, latest, sale, customersChoice } = {} } = appSettings ?? ({} as AppSettings);

  let history = useHistory();

  const openProducts = (value: any) => {
    history.push("/products/0/0/" + value);
  };

  return (
    <div className={cx("homePage")}>
      {isFeatureProductsFetching || isFeatureProductRefetching ? (
        <HomeProductsWidgetSkeleton />
      ) : (
        featured?.isEnabled &&
        featuredProductList?.length && (
          <HomeProductsSection
            sectionTitle={state.config.languageJson[`${featured?.title}`]}
            buttonTitle={state.config.languageJson["more"]}
            items={featuredProductList}
            openProducts={() => {
              openProducts("featured");
            }}
          />
        )
      )}

      {isCustomersChoiceProductsFetching || isCustomersChoiceProductsRefetching ? (
        <HomeProductsWidgetSkeleton />
      ) : (
        customersChoice?.isEnabled &&
        customersChoiceProducts?.length && (
          <HomeProductsSection
            sectionTitle={state.config.languageJson[`${customersChoice?.title}`]}
            buttonTitle={state.config.languageJson["more"]}
            items={customersChoiceProducts}
            openProducts={() => {
              openProducts("popular");
            }}
          />
        )
      )}

      {isOnSaleProductsFetching || isOnSaleProductRefetching ? (
        <HomeProductsWidgetSkeleton />
      ) : (
        sale?.isEnabled &&
        onSaleProductList?.length && (
          <HomeProductsSection
            sectionTitle={state.config.languageJson[`${sale?.title}`]}
            buttonTitle={state.config.languageJson["more"]}
            items={onSaleProductList}
            openProducts={() => {
              openProducts("sale");
            }}
          />
        )
      )}

      {isNewestProductsFetching || isNewestProductRefetching ? (
        <HomeProductsWidgetSkeleton />
      ) : (
        latest?.isEnabled &&
        !!newestProductList?.length && (
          <HomeProductsSection
            sectionTitle={state.config.languageJson[`${latest?.title}`]}
            buttonTitle={state.config.languageJson["more"]}
            items={newestProductList}
            openProducts={() => {
              openProducts("latest");
            }}
          />
        )
      )}
    </div>
  );
};

export default HomeProductsWidget;
