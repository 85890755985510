import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useGetSettingsQuery } from "../../providers/settings";

const AppUrlListener = () => {
  const history = useHistory();
  const { data: { siteDomain } = {} } = useGetSettingsQuery();

  useEffect(() => {
    if (siteDomain) {
      App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(siteDomain).pop();
        if (slug) {
          history.push(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }
  }, [history, siteDomain]);

  return null;
};

export default AppUrlListener;
