import { Preferences } from "@capacitor/preferences";
import { AppSettings, User } from "../types/entities";

export class LocalStorageOperations {
  static saveUserInfo(user: User) {
    // replace any
    Preferences.set({ key: "user", value: JSON.stringify(user) });
  }

  static updateUserInfo(user: Partial<User>) {
    //replace any
    const userInfo = this.getUserData() || {};
    Preferences.set({ key: "user", value: JSON.stringify({ ...userInfo, ...user }) });
  }

  static async getUserData(): Promise<User | undefined> {
    const { value } = await Preferences.get({ key: "user" });
    if (value) {
      return JSON.parse(value);
    }
  }

  static removeUserInfo(): void {
    Preferences.remove({ key: "user" });
  }

  static setAppSettings(settings: AppSettings): void {
    Preferences.set({ key: "appSettings", value: JSON.stringify(settings) });
  }

  static async getAppSettings(): Promise<AppSettings | undefined> {
    const { value } = await Preferences.get({ key: "appSettings" });
    if (value) {
      return JSON.parse(value);
    }
  }
}
