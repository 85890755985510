import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { paymentMethodsClient } from "../../data/clients";
import { PAYMENT_METHODS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { PaymentMethod } from "../../data/types/entities";

const useGetPaymentMethodsQuery = (options: UseQueryOptions<PaymentMethod[]> = {}) => {
  return useQuery<PaymentMethod[], any>(
    ["payment-methods"],
    async () => {
      return await paymentMethodsClient.getPaymentMethods();
    },
    {
      cacheTime: PAYMENT_METHODS_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: PAYMENT_METHODS_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );
};

export default useGetPaymentMethodsQuery;
