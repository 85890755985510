import React from 'react';

// Import Ionic
import {IonSelect, isPlatform} from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import commonFormStyles from "../UI/CommonFormElements/CommonFormElements.module.scss";

interface ISelect {
    value: any,
    placeholder: string,
    languageJson: any,
    okText?: string,
    cancelText?: string,
    disabled: boolean,
    onChange: any,
    isValid?: boolean,
    className?: string,
}

const commonForm = classNames.bind(commonFormStyles)

const Select:React.FC<ISelect> = ({
    value,
    placeholder= '',
    disabled = false,
    onChange,
    languageJson,
    okText,
    cancelText,
    isValid = true,
    className = '',
    ...props}) => {
    return (
        <IonSelect
            className={commonForm("commonElement", "commonElementSelect", {'commonInvalid': !isValid })}
            value={value}
            placeholder={placeholder}
            onIonChange={onChange}
            interfaceOptions={{
                cssClass: className,
                mode: isPlatform("desktop") ? "md" : "ios" ,
                showBackdrop: false,
            }}
            interface={isPlatform("desktop") ? "popover" : "action-sheet"}
            disabled={disabled}
            cancelText={cancelText || languageJson.Cancel}
            okText={okText || languageJson.Ok}
        >
            {props.children}
        </IonSelect>
    );
};

export default Select;