import React from 'react';

// Import Ionic
import {IonBadge} from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ProductDetailSlideBadges.module.scss";

interface IProductDetailSlideBadges {
    product: any,
    isNewProduct: boolean,
    selectedVariation: any,
    languageJson: any,
}

const cx = classNames.bind(styles);

const getOnSaleProductPrice = (product: any) => {
    return Math.round(((product.regular_price - product.sale_price) / product.regular_price) * 100)
}

const getVariableProductPrice = (selectedVariation: any) => {
    return Math.round(((selectedVariation.regular_price - selectedVariation.sale_price) / selectedVariation.regular_price) * 100)
}


const ProductDetailSlideBadges:React.FC<IProductDetailSlideBadges> = ({
    product,
    isNewProduct= false,
    selectedVariation,
    languageJson,
    }) => {
    return (
        <div className={cx("badges")}>
            {
                isNewProduct && (
                    <IonBadge className={cx("badge")} color="danger">
                        {languageJson.New}
                    </IonBadge>
                )
            }
            {
                product.on_sale && product.type !== 'variable' && (
                    <IonBadge className={cx("badgeSalePercent", "badge")}>
                        {getOnSaleProductPrice(product)}
                    </IonBadge>
                )
            }
            {
                product.type === 'variable' && selectedVariation?.on_sale && (
                    <IonBadge className={cx("badgeSalePercent", "badge")}>
                        {getVariableProductPrice(selectedVariation)}
                    </IonBadge>
                )
            }
        </div>
    );
};

export default ProductDetailSlideBadges;