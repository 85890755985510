// Import React Libraries
import React, { useCallback, useState } from "react";

// Import Css File
import "./thank-you.scss";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import styles from "./thank-you.module.scss";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../../components/Footer/Footer";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import { useAuth } from "../../contexts/auth-context";
import { useOrderData } from "../../providers/orderDataStore";
import { useGetSettingsQuery } from "../../providers/settings";
import { applyRegExOnPhoneNumber } from "../../providers/utils/utils";
import classNames from "classnames/bind";
import { getCurrentLanguage } from "../../providers/Redux/Reducers/state";

const cx = classNames.bind(styles);
const ts = getCurrentLanguage();

const ThankYou: React.FC = () => {
  const { user } = useAuth();
  const order = useOrderData();
  const { data: appSettings } = useGetSettingsQuery();
  const [dayOfWeekDeliveryDate, setDayOfWeekDeliveryDate] = useState("");

  const listOfDays = useCallback(() => {
    const deliveryDate = order?.deliveryDate?.toLocaleDateString("ru-RU") ?? "";
    const tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const dayAfterTomorrowDate = new Date();
    dayAfterTomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const today = new Date().toLocaleDateString("ru-RU");
    const tomorrow = tomorrowDate.toLocaleDateString("ru-RU");
    const dayAfterTomorrow = dayAfterTomorrowDate.toLocaleDateString("ru-RU");

    switch (deliveryDate) {
      case today:
        setDayOfWeekDeliveryDate(` ${deliveryDate} (${ts.today})`);
        break;
      case tomorrow:
        setDayOfWeekDeliveryDate(` ${deliveryDate} (${ts.tomorrow})`);
        break;
      case dayAfterTomorrow:
        setDayOfWeekDeliveryDate(` ${deliveryDate} (${ts["day after tomorrow"]})`);
        break;
      default:
        setDayOfWeekDeliveryDate(deliveryDate);
    }
  }, [order]);

  useIonViewWillEnter(() => {
    listOfDays();
  }, [order]);

  useIonViewDidLeave(() => {
    order.reset();
  }, [order]);

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton showSearchButton />
      </IonHeader>

      <IonContent>
        <ToolbarDesktop />
        <MaxWidthContainer classList={"thank-you global-content"} maxWidthBreakpoint={"Md"}>
          <IonCard className="ion-text-center">
            <IonCardContent>
              <IonIcon color="secondary" icon={checkmarkCircle} />
              <IonCardTitle>{ts["Thank You"]}</IonCardTitle>
              <div>
                <h2 className={styles.title}>Ваш заказ получен:</h2>
                <p className={styles.info}>
                  {appSettings?.isDeliveryDateEnabled ? (
                    <span>
                      {ts["Delivery date"]}:<span className={styles.deliveryDate}>{dayOfWeekDeliveryDate}</span>
                      <br />
                      {ts["Delivery interval"]}:<span>&nbsp;{order?.deliveryInterval}</span>
                      <br />
                    </span>
                  ) : (
                    ""
                  )}
                  Вы можете связаться с нами по{" "}
                  <a
                    className={cx("contact")}
                    href={`https://api.whatsapp.com/send/?phone=${applyRegExOnPhoneNumber(
                      appSettings?.companyPhoneNumber || ""
                    )}`}
                  >
                    WhatsApp{" "}
                  </a>
                  или позвонить, по номеру{" "}
                  <a
                    className={cx("contact")}
                    href={`tel:+${applyRegExOnPhoneNumber(appSettings?.companyPhoneNumber || "")}`}
                  >
                    {" "}
                    {appSettings?.companyPhoneNumber}
                  </a>
                </p>
              </div>
              {!!user?.id && (
                <IonButton
                  routerDirection="forward"
                  routerLink="/my-orders"
                  expand="block"
                  color="secondary"
                  className="ion-margin-top"
                >
                  {ts["My Orders"]}
                </IonButton>
              )}
              <IonButton
                routerDirection="forward"
                routerLink="/products/0/0/newest"
                expand="block"
                fill="clear"
                color="secondary"
              >
                {ts["Continue Shopping"]}
              </IonButton>
            </IonCardContent>
          </IonCard>
        </MaxWidthContainer>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default ThankYou;
