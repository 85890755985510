import { APP_SETTINGS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { settingsClient } from "../../data/clients";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AppSettings } from "../../data/types/entities";
import { useEffect } from "react";
import { LocalStorageOperations } from "../../data/storage/LocalStorageOperations";

const useGetSettingsQuery = (options: UseQueryOptions<AppSettings> = {}) => {
  const settingsQuery = useQuery<AppSettings, any>(
    ["app-settings"],
    async () => {
      return await settingsClient.getSettings();
    },
    {
      cacheTime: APP_SETTINGS_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: APP_SETTINGS_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );

  useEffect(() => {
    if (settingsQuery.data) {
      LocalStorageOperations.setAppSettings(settingsQuery.data);
    }
  }, [settingsQuery.data]);

  return settingsQuery;
};

export default useGetSettingsQuery;
