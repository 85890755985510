import React from "react";

// Import Ionic
import { IonPage, IonHeader, IonContent } from "@ionic/react";

// Import Styles
import "../contacts/ContactUs.scss";
import classNames from "classnames/bind";
// import styles from "../modals/privacy-policy/PrivacyPolicy.module.scss";
import styles from "./ContactUs.module.scss";

// Import Components
import Footer from "../../components/Footer/Footer";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import { useGetSettingsQuery } from "../../providers/settings";
import { codeBaseVersion } from "../../App";

const cx = classNames.bind(styles);

const contacts = [
  { title: "Основной телефон:", number: "8 928 888 10 10" },
  { title: "Сайт:", number: "8 928 888 10 10" },
  { title: "Для юридических лиц:", number: "8 928 085 08 88" },
  { title: "Для поставщиков:", number: "8 938 990 71 17" },
  { title: "Трудоустройство:", number: "8 929 888 28 22" },
  { title: "Реклама и сотрудничество:", number: "8 938 990 72 27" },
];

const requisites = [
  "ИП Мусаев Алихан Алиевич",
  "ИНН 201600591641 / ОГРНИП 309203103400036",
  "Юридический адрес: 364024 Чеченская Республика г.Грозный ул. Р. Люксембург д.30",
];

const ContactUs: React.FC = () => {
  const { data: appSettings } = useGetSettingsQuery();
  // const history = useHistory();

  const createMarkup = () => {
    if (appSettings?.contactUsPage) {
      return { __html: appSettings.contactUsPage };
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton showSearchButton />
      </IonHeader>
      <IonContent>
        <ToolbarDesktop />
        <MaxWidthContainer classList={"global-content"}>
          {!!appSettings?.contactUsPage ? (
            <p
              className={cx("ion-no-margin ion-margin-start ion-margin-end", "contacts")}
              dangerouslySetInnerHTML={createMarkup()}
            ></p>
          ) : (
            <div className="p-4 gap-y-10 sm:p-10 m-2 border-2 border-gray-200 rounded-xl flex flex-col lg:flex-row gap-y-4">
              <div className="md:mr-20">
                <h1 className="text-3xl mb-4 font-bold">Контакты</h1>
                <div className="flex flex-col gap-y-2">
                  {contacts.map((contact, index) => (
                    <div key={index} className="xs:flex xs:justify-between xs:gap-x-4 max-w-[600px]">
                      <div className="text-gray-400">{contact.title}</div>
                      <a href={`tel:${contact.number}`} className="hover:text-irsRed">
                        {contact.number}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="md:max-w-[50%]">
                <h1 className="text-3xl mb-4 font-bold">Реквизиты:</h1>
                <div className="flex flex-col gap-y-2">
                  {requisites.map((requisite, index) => (
                    <div key={index}>{requisite}</div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </MaxWidthContainer>
        <Footer />
        <p className={cx("appVersionLabel")}>{process.env.REACT_APP_CLIENT} v{codeBaseVersion}</p>
      </IonContent>
    </IonPage>
  );
};

export default ContactUs;
