import React from "react";

// Import Ionic
import { IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonText } from "@ionic/react";

// Import Icons
import { chevronBackOutline } from "ionicons/icons";

// Import Styles
import classNames from "classnames/bind";
import styles from "./CommonModal.module.scss";

interface ICommonModal {
  children: React.ReactNode;
  onDismiss: () => void;
  title: string;
  classNameHeader?: string;
  classNameContent?: string;
}

const cx = classNames.bind(styles);

const CommonModal: React.FC<ICommonModal> = ({
  onDismiss,
  children,
  title,
  classNameHeader = "",
  classNameContent = "",
}) => {
  return (
    <>
      <IonHeader className={cx(`${classNameHeader}`)}>
        <IonItem color={"primary"} lines="none" className={cx("modalHeader")}>
          <span onClick={onDismiss} className={cx("modalHeaderIcon")}>
            <IonIcon icon={chevronBackOutline} />
          </span>
          <IonLabel className={cx("modalHeaderLabel")}>
            <IonText className={cx("modalHeaderTitle")}>{title}</IonText>
          </IonLabel>
        </IonItem>
      </IonHeader>
      <IonContent className={cx("modalIonContent", `${classNameContent}`)}>{children}</IonContent>
    </>
  );
};

export default CommonModal;
