import { IAuthClient } from "../../types/clients";
import {
  ResetPasswordVariables,
  LoginVariables,
  User,
  VerifySMSCodeVariables,
  RegisterUserCredentials,
  RegisterResponse,
  VerificationCodeResponse,
  SmsAuthVariables,
} from "../../types/entities";
import BaseClient from "../base-client";
import { Customer } from "./types";

class AuthClient extends BaseClient implements IAuthClient {
  async login({ username, password }: LoginVariables): Promise<User> {
    const { data: userData } = await this.instance.post<any>("/wp-json/jwt-auth/v1/token", { username, password }); // #TODO: types

    this.setToken(userData.data.token);

    const { data: profileData } = await this.instance.get<Customer>(`/wp-json/wc/v3/customers/${userData.data.id}`);

    const user: User = {
      id: profileData.id.toString(),
      userName: profileData.username,
      firstName: profileData.first_name,
      lastName: profileData.last_name,
      email: profileData.email,
      phone: profileData.billing.phone,
      token: userData.data.token,
    };

    return user;
  }

  setToken(token: string) {
    this.instance.token = token;
  }

  async sendVerificationCode(phone: string): Promise<VerificationCodeResponse> {
    const { data } = await this.instance.post<any>("/wp-json/parhato-api/v1/reset-password", { phone });

    return { verificationCodeId: data.code_id, phoneNumber: data.phone_number };
  }

  async resetPassword(params: ResetPasswordVariables) {
    const res = await this.instance.post<any>("/wp-json/parhato-api/v1/reset-password", {
      email: params.email,
      phone: params.phone,
      new_password: params.newPassword,
      verification_code: params.verificationCode,
      verification_code_id: params.verificationCodeId,
    });

    return res.status;
  }

  async verifyCode(params: VerifySMSCodeVariables) {
    const res = await this.instance.post<any>("/wp-json/parhato-api/v1/verify-code", params);

    return res.status;
  }

  async register(params: RegisterUserCredentials): Promise<RegisterResponse> {
    const credentials = {
      first_name: params.firstName,
      last_name: params.lastName,
      phone: params.phone,
      password: params.password,
      referral_link: params.referal?.referralLink ?? "",
      own_version_referral: params.referal?.ownVersionReferral ?? "",
      verification_code: params.verification?.verificationCode ?? "",
      verification_code_id: params.verification?.verificationCodeId ?? "",
    };

    const { data } = await this.instance.post<any>("/wp-json/parhato-api/v1/register", credentials);

    const user: RegisterResponse = {
      userName: data.user_login,
    };

    return user;
  }

  async getSmsCode(phone: string): Promise<VerificationCodeResponse> {
    const { data } = await this.instance.post<any>("/wp-json/parhato-api/v1/auth", { phone });

    return {
      verificationCodeId: data.code_id,
      phoneNumber: data.phone_number,
    };
  }

  async authBySmsCode(params: SmsAuthVariables) {
    const { data: authInfo } = await this.instance.post<any>("/wp-json/parhato-api/v1/auth", {
      first_name: params.firstName,
      last_name: params.lastName,
      phone: params.phone,
      verification_code: params.verificationCode,
      verification_code_id: params.verificationCodeId,
    });

    this.setToken(authInfo.data.token);

    const { data: profileData } = await this.instance.get<Customer>(`/wp-json/wc/v3/customers/${authInfo.data.id}`);

    const user: User = {
      id: profileData.id.toString(),
      userName: profileData.username,
      firstName: profileData.first_name,
      lastName: profileData.last_name,
      email: profileData.email,
      phone: profileData.billing.phone,
      token: authInfo.data.token,
    };

    return user;
  }

  async getRegisterVerificationCode(phone: string): Promise<VerificationCodeResponse> {
    const { data } = await this.instance.post<any>("/wp-json/parhato-api/v1/register", { phone });
    return { verificationCodeId: data.code_id, phoneNumber: data.phone_number };
  }
}

export default AuthClient;
