import { initialState } from "./auth-context";
import { ActionType, StateType } from "./types";

export const authReducer = (
  state: StateType,
  action: ActionType,
): StateType => {
  switch (action.type) {
    case "LOGIN": {
      const { user } = action.payload!;
      return {
        ...state,
        isUserAuthenticated: true,
        user,
      };
    }

    case "LOGOUT": {
      return { ...initialState };
    }

    case "UPDATE_USER_DATA": {
      const { user = {} } = action.payload!;
      return {
        ...state,
        user: {
          ...state.user!,
          ...user,
        },
      };
    }
    default:
      return state;
  }
};
