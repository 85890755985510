import { httpClient } from "./http-client";
import { DataClients } from "..";

import UsersClient from "./users-client";
import BannersClient from "./banners-client";
import CategoriesClient from "./categories-client";
import ProductsClient from "./products-client";
import SettingsClient from "./settings-client";
import AuthClient from "./auth-client";
import OrdersClient from "./orders-client";
import PaymentMethodsClient from "./payment-methods-client";
import ShippingMethodsClient from "./shipping-methods-client";
import ShippingZonesClient from "./shipping-zones-client";
import CouponsClient from "./coupons-client";
import DeliveryDatesClient from "./delivery-dates-client";

export default {
  bannersClient: new BannersClient(httpClient),
  categoriesClient: new CategoriesClient(httpClient),
  productsClient: new ProductsClient(httpClient),
  settingsClient: new SettingsClient(httpClient),
  authClient: new AuthClient(httpClient),
  usersClient: new UsersClient(httpClient),
  ordersClient: new OrdersClient(httpClient),
  paymentMethodsClient: new PaymentMethodsClient(httpClient),
  shippingMethodsClient: new ShippingMethodsClient(httpClient),
  shippingZonesClient: new ShippingZonesClient(httpClient),
  couponsClient: new CouponsClient(httpClient),
  deliveryDatesClient: new DeliveryDatesClient(httpClient),
} as DataClients;
