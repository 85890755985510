// Import React Libraries
import React, { useContext } from "react";

// Import Ionic Components
import { IonCardSubtitle, IonCol, IonItem, IonLabel, IonRow, IonThumbnail } from "@ionic/react";

// Import providers
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

// Import styles
import classNames from "classnames/bind";
import styles from "./MyOrderProduct.module.scss";
import { Image } from "../Image/Image";
import { LineItem } from "../../data/types/entities";

const cx = classNames.bind(styles);

const MyOrderProduct: React.FC<{ item: LineItem; currencySymbol: string; onClick?: Function }> = ({
  item,
  currencySymbol,
  onClick,
}) => {
  const { state } = useContext(AppContext); // For Store

  return (
    //====================================  list view for shop page ====================================
    <div>
      <IonRow>
        <IonCol>
          <IonCardSubtitle className={cx("product__title")}>{item.name}</IonCardSubtitle>
        </IonCol>
      </IonRow>
      <IonItem className={cx("product__item")} lines="full">
        <IonThumbnail slot="start">
          <Image className={cx("product__img")} onClick={() => onClick && onClick()} image={item.image} />
        </IonThumbnail>
        <IonLabel>
          {item.price && (
            <IonRow>
              <IonCol className="ion-text-left" size="6">
                {state.config.languageJson.Price}&nbsp;:&nbsp;
              </IonCol>
              <IonCol className="ion-text-right" size="6">{`${Number(item.price).toFixed()} ${currencySymbol}`}</IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol className="ion-text-left" size="6">
              {state.config.languageJson.Quantity}&nbsp;:&nbsp;
            </IonCol>
            <IonCol className="ion-text-right" size="6">
              {item.quantity}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-left" size="6">
              <strong>{state.config.languageJson.Total}</strong>&nbsp;:&nbsp;
            </IonCol>
            <IonCol className="ion-text-right" size="6">
              <strong>{`${item.total} ${currencySymbol}`} </strong>
            </IonCol>
          </IonRow>
        </IonLabel>
      </IonItem>
    </div>
  );
};
export { MyOrderProduct };
