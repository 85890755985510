// Import React Libraries
import React, { useState, useContext, useMemo } from "react";

import { IonPage, IonHeader, IonButton, IonContent, IonLoading, useIonToast } from "@ionic/react";

import { AppContext } from "../../../providers/Redux/Reducers/AppContext";
import { useHistory, useLocation } from "react-router";
import { PasswordInput } from "../../../components/PasswordInput/PasswordInput";

// Import Css File
import styles from "./ResetPassword.module.scss";
import classNames from "classnames/bind";
import MaxWidthContainer from "../../../components/UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../../../components/Footer/Footer";
import ToolbarMobile from "../../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import ToolbarDesktop from "../../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import CommonFormElement from "../../../components/UI/CommonFormElements/CommonFormElement";
import commonFormStyles from "../../../components/UI/CommonFormElements/CommonFormElements.module.scss";
import { ValidatePasswordInput } from "../../../components/ValidatePasswordInput/ValidatePasswordInput";
import { useResetPasswordMutation } from "../../../providers/Auth";

const cx = classNames.bind(styles);
const commonForm = classNames.bind(commonFormStyles);

const ResetPassword = () => {
  const { state } = useContext(AppContext);
  const { mutate: resetPasswordMutation } = useResetPasswordMutation();
  const [repeatPassword, setRepeatPassword] = useState("");
  const [password, setPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false); // For Loading
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const ts = useMemo(() => state.config.languageJson, []);

  const location = useLocation<{
    verificationCodeData: {
      code: string;
      code_id: number;
      phone_number: string;
    };
  }>();
  const history = useHistory();

  const [presentToast] = useIonToast();

  const showErrorToast = () => {
    presentToast({
      message: ts["An unexpected error occurred"],
      color: "danger",
      duration: 2000,
    });
  };

  const resetPassword = () => {
    setShowLoading(true);
    const verificationCodeData = location.state.verificationCodeData;

    resetPasswordMutation(
      {
        phone: verificationCodeData.phone_number,
        newPassword: password,
        verificationCode: verificationCodeData.code,
        verificationCodeId: verificationCodeData.code_id.toString(),
      },
      {
        onError: () => {
          showErrorToast();
          setShowLoading(false);
        },
        onSuccess: () => {
          setShowLoading(false);
          history.goBack();
        },
      }
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton />
      </IonHeader>

      <IonContent className="about-us">
        <ToolbarDesktop />
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={ts["Please wait..."]}
          duration={20000}
        />
        <div className={`${cx("resetPassword__content")} global-content`}>
          <MaxWidthContainer maxWidthBreakpoint={"Sm"} classList={cx("resetPassword__container")}>
            <form
              onSubmit={(e) => {
                resetPassword();
                e.preventDefault();
              }}
            >
              <CommonFormElement
                label={ts["New Password"]}
                errorMessage={ts[passwordError]}
                isValid={!!(password.length && isPasswordValid)}
              >
                <ValidatePasswordInput
                  name="c_d"
                  required={true}
                  value={password}
                  setPassword={(value) => setPassword(value)}
                  setPasswordError={(error) => setPasswordError(error)}
                  setIsPasswordValid={(isValid) => setIsPasswordValid(isValid)}
                  className={commonForm("commonElement", { commonInvalid: !(password.length && isPasswordValid) })}
                />
              </CommonFormElement>
              <CommonFormElement
                label={ts["Confirm the password"]}
                errorMessage={ts["Password mismatch"]}
                isValid={password === repeatPassword}
              >
                <PasswordInput
                  required
                  className={commonForm("commonElement", { commonInvalid: password !== repeatPassword })}
                  value={repeatPassword}
                  onIonChange={(e: any) => setRepeatPassword(e.detail.value!)}
                />
              </CommonFormElement>

              <IonButton
                color="secondary"
                className="ion-text-capitalize ion-margin-start ion-margin-end"
                expand="block"
                type="submit"
                disabled={!isPasswordValid || password !== repeatPassword}
              >
                {ts["Send"]}
              </IonButton>
            </form>
          </MaxWidthContainer>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export { ResetPassword };
