import { BANNERS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { bannersClient } from "../../data/clients";
import { Banner } from "../../data/types/entities";

type Config = Omit<UseQueryOptions<Banner[], unknown, any, any>, "queryFn" | "queryKey">;

const useGetBannersQuery = (
  config: Config = { cacheTime: BANNERS_QUERY_CACHE_EXPIRE_TIME_MS, staleTime: BANNERS_QUERY_CACHE_EXPIRE_TIME_MS }
) => {
  return useQuery<Banner[], any>(
    ["banners"],
    async () => {
      return await bannersClient.getBanners();
    },
    config
  );
};

export default useGetBannersQuery;
