import React from "react";
import { AuthContextType, StateType } from "./types";

export const initialState: StateType = {
  token: null,
  user: undefined,
  isUserAuthenticated: false,
};

export const AuthContext = React.createContext<AuthContextType>({
  ...initialState,

  login: () => {
    return;
  },

  register: () => {
    return;
  },

  logout: () => {
    return;
  },
  updateUserData: () => {
    return;
  },
});
