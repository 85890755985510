import React, { createContext, useContext, useState } from "react";
import { CustomerAddressType } from "../../generated/graphql";

const CustomerAddressesStateContext = createContext<{
  token: string;
  setToken: (token: string) => void;
  customerAddress: CustomerAddressType | undefined;
  setCustomerAddress: (value: CustomerAddressType | undefined) => void;
  isAddressModalOpen: boolean;
  setIsAddressModalOpen: (value: boolean) => void;
}>(undefined!);

export const CustomerAddressesStateProvider = ({ children }: { children: React.ReactChild }) => {
  const [token, setToken] = useState("");

  const [customerAddress, setCustomerAddress] = useState<CustomerAddressType>();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  return (
    <CustomerAddressesStateContext.Provider
      value={{
        token,
        setToken,
        customerAddress,
        setCustomerAddress,
        isAddressModalOpen,
        setIsAddressModalOpen,
      }}
    >
      {children}
    </CustomerAddressesStateContext.Provider>
  );
};

export const useCustomerAddressesStateData = () => useContext(CustomerAddressesStateContext);
