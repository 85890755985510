// Import React Libraries
import React, { useState, useContext, useRef, useMemo } from "react";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";
import { useHistory } from "react-router";

// Import Ionic
import {
  IonPage,
  IonHeader,
  IonButton,
  IonContent,
  IonLoading,
  useIonToast,
  useIonAlert,
  IonInput,
} from "@ionic/react";

// Import Style
import classNames from "classnames/bind";
import styles from "./ForgotPassword.module.scss";

// Import Components
import MaxWidthContainer from "../../../components/UI/MaxWidthContainer/MaxWidthContainer";
import CommonFormElement from "../../../components/UI/CommonFormElements/CommonFormElement";
import Footer from "../../../components/Footer/Footer";
import ToolbarMobile from "../../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import ToolbarDesktop from "../../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import commonFormStyles from "../../../components/UI/CommonFormElements/CommonFormElements.module.scss";
import { isEmailValid, isPhoneNumberValid } from "../../../utils/utils";
import { useResetPasswordMutation, useSendVerificationCode, useVerifyCodeMutation } from "../../../providers/Auth";

const cx = classNames.bind(styles);
const commonForm = classNames.bind(commonFormStyles);

const ForgotPassword: React.FC = () => {
  const { state } = useContext(AppContext);

  const { mutate: sendVerificationCode } = useSendVerificationCode();
  const { mutate: verifyCodeMutation } = useVerifyCodeMutation();
  const { mutate: resetPassword } = useResetPasswordMutation();
  const [showLoading, setShowLoading] = useState(false);
  const [login, setLogin] = useState("");

  const verificationCodeData = useRef<{ code_id: string; phone_number: string } | null>(null);

  const history = useHistory();

  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();

  const ts = useMemo(() => {
    return state.config.languageJson;
  }, [state.config.languageJson]);

  const showErrorToast = (message = "") => {
    presentToast({
      message: message || ts["An unexpected error occurred"],
      color: "danger",
      duration: 2000,
    });
  };

  const forgetPassword = async () => {
    setShowLoading(true);

    if (/^\+?[0-9]+$/.test(login)) {
      let phoneNumber = login.replace("+", "");

      if (phoneNumber.length === 11 && phoneNumber.startsWith("8")) {
        phoneNumber = "7" + phoneNumber.slice(1);
      }

      sendVerificationCode(phoneNumber, {
        onSuccess: (data) => {
          if (!!data) {
            verificationCodeData.current = { phone_number: data.phoneNumber, code_id: data.verificationCodeId };
          } else {
            verificationCodeData.current = { phone_number: phoneNumber, code_id: "0" };
          }
          openEnterCodeAlert();
          setShowLoading(false);
        },
        onError: (error) => {
          if (error.response.data.code === "invalid_verification_code_data") {
            openEnterCodeAlert();
            presentToast({
              message: ts["Invalid verification code"],
              color: "danger",
              duration: 2000,
            });
          } else {
            showErrorToast(ts[error.response.data.message]);
          }
          setShowLoading(false);
        },
      });
    } else {
      resetPassword(
        { email: login },
        {
          onError: (e) => {
            showErrorToast(ts[e.response.data.message]);
            setShowLoading(false);
          },
          onSuccess: () => {
            presentToast({
              message: ts["Password reset link sent to your email"],
              color: "success",
              duration: 4000,
            });
            setShowLoading(false);
            history.goBack();
          },
        }
      );
    }
  };

  const verifyCode = (code: string) => {
    setShowLoading(true);
    const phoneNumber = verificationCodeData.current!.phone_number;
    const codeID = verificationCodeData.current?.code_id?.toString();
    verifyCodeMutation(
      { code, phoneNumber, codeID },
      {
        onSuccess: () => {
          history.replace("/reset-password", {
            verificationCodeData: {
              ...verificationCodeData.current,
              code,
            },
          });
          setShowLoading(false);
        },
        onError: (error) => {
          if (error && error.error.code === "invalid_code") {
            openEnterCodeAlert();
            showErrorToast(ts["Invalid verification code"]);
          } else {
            showErrorToast();
          }
          setShowLoading(false);
        },
      }
    );
  };

  const openEnterCodeAlert = () => {
    presentAlert({
      header: ts["Enter confirmation code"],
      message: ts["To verify your phone number, enter the code from SMS"],
      backdropDismiss: false,
      keyboardClose: false,
      inputs: [
        {
          type: "number",
          name: "code",
          placeholder: ts["Enter confirmation code"],
          attributes: {
            maxlength: 6,
            minlength: 6,
            inputmode: "numeric",
            pattern: "[0-9]*",
          },
        },
      ],
      buttons: [
        {
          text: ts["Cancel"],
          role: "cancel",
        },
        {
          text: ts["Ok"],
          handler: (alertData) => {
            if (!/^\d+$/.test(alertData.code)) {
              presentToast({
                message: ts["Code must be 6 digits"],
                color: "danger",
                duration: 2000,
              });

              return false;
            }

            verifyCode(alertData.code);
          },
        },
      ],
    });
  };

  const isLoginValid = () => {
    return isPhoneNumberValid(login) || isEmailValid(login);
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton />
      </IonHeader>

      <IonContent>
        <ToolbarDesktop />
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={ts["Please wait..."]}
          duration={20000}
        />
        <div className={`${cx("forgotPasswordContent")} global-content`}>
          <MaxWidthContainer maxWidthBreakpoint={"Sm"} classList={cx("forgotPasswordForm")}>
            <form
              onSubmit={(e) => {
                forgetPassword();
                e.preventDefault();
              }}
            >
              <CommonFormElement
                label={ts["Email or phone number"]}
                isValid={isLoginValid()}
                errorMessage={ts["Enter correct data"]}
              >
                <IonInput
                  type="text"
                  name="login"
                  className={commonForm("commonElement", { commonInvalid: !isLoginValid() })}
                  placeholder={ts["Email or phone number"]}
                  required
                  onIonChange={(e) => {
                    setLogin(e.detail.value!);
                  }}
                />
              </CommonFormElement>
              <IonButton
                color="secondary"
                disabled={!isLoginValid()}
                className="ion-no-margin"
                expand="block"
                type="submit"
              >
                {ts.Send}
              </IonButton>
            </form>
          </MaxWidthContainer>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
