import { CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { categoriesClient } from "../../data/clients";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { CategoriesMapObj } from "../../data/types/entities";

const useGetAllCategoriesQuery = (options: UseQueryOptions<CategoriesMapObj> = {}) => {
  return useQuery<CategoriesMapObj, any>(
    ["all-categories"],
    async () => {
      return await categoriesClient.getAllCategories();
    },
    {
      cacheTime: CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );
};

export default useGetAllCategoriesQuery;
