import React, { useContext, useMemo } from "react";
import { Product as TProduct } from "../../../data/types/entities";
import classNames from "classnames/bind";
import styles from "./ProductDescription.module.scss";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";

interface IProductDescriptionProps {
  props: Pick<TProduct, "description">;
}

const cx = classNames.bind(styles);

function createMarkupDesc(description: IProductDescriptionProps["props"]["description"]) {
  return { __html: String(description) };
}

const ProductDescription = ({ props }: IProductDescriptionProps) => {
  const { state } = useContext(AppContext);
  const ts = useMemo(() => state.config.languageJson, [state.config.languageJson]);

  return !!props?.description ? (
    <div className={cx("detailDescription")} dangerouslySetInnerHTML={createMarkupDesc(props?.description)}></div>
  ) : (
    <div className={cx("detailDescription")}>{ts["No description"]}</div>
  );
};

export { ProductDescription };
