import { create } from "zustand";
import { RESEND_SMS_CODE_DELAY_MS } from "../../config/constansts";

type SmsVerificationDataState = {
  timer: null | ReturnType<typeof setTimeout>;
  waitingRestTime: number;
  canGetNewCode: boolean;
  phoneNumber: string | null;
};

type SmsVerificationDataActions = {
  setWaitingTimer: (newPhoneNumber: string) => void;
  sentSmsAgain: () => void;
  _setTimer: () => void;
  reset: () => void;
};

const initialState: SmsVerificationDataState = {
  phoneNumber: null,
  timer: null,
  canGetNewCode: true,
  waitingRestTime: RESEND_SMS_CODE_DELAY_MS / 1000, // CONVERT FROM MILLISECONDS TO SECONDS,
};

let interval: ReturnType<typeof setInterval> | null = null;

export const useSmsVerificationState = create<SmsVerificationDataState & SmsVerificationDataActions>()((set, get) => ({
  ...initialState,

  _setTimer: () => {
    const { reset, timer } = get();

    if (timer) {
      return;
    }

    interval = setInterval(() => {
      set((state) => {
        return {
          ...state,
          waitingRestTime: state.waitingRestTime - 1,
        };
      });
    }, 1000);

    set((state) => {
      return {
        ...state,
        canGetNewCode: false,
        timer: setTimeout(() => {
          reset();
        }, RESEND_SMS_CODE_DELAY_MS),
      };
    });
  },

  setWaitingTimer: (newPhoneNumber) => {
    const { _setTimer } = get();

    set((state) => {
      return {
        ...state,
        phoneNumber: newPhoneNumber,
      };
    });
    _setTimer();
  },

  sentSmsAgain: () => {
    const { _setTimer } = get();

    _setTimer();
  },

  reset: () => {
    const { timer } = get();

    if (timer) {
      clearTimeout(timer);
    }

    if (interval) {
      clearInterval(interval);
    }

    set((state) => {
      return {
        ...initialState,
        phoneNumber: state.phoneNumber ? state.phoneNumber : null,
      };
    });
  },
}));

const useSmsVerificationData = () => {
  return useSmsVerificationState((state) => ({
    ...state,
  }));
};

export default useSmsVerificationData;
