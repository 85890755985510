import React, { useRef } from "react";
import {
  DatetimeChangeEventDetail,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonPopover,
  isPlatform,
} from "@ionic/react";
import CommonFormElement from "../../UI/CommonFormElements/CommonFormElement";

import classNames from "classnames/bind";
import styles from "./SelectDeliveryDate.module.scss";
import { getCurrentLanguage } from "../../../providers/Redux/Reducers/state";

interface ISelectDeliveryDate {
  isValid: boolean;
  value: string | null;
  onChange: (e: CustomEvent<DatetimeChangeEventDetail>) => void;
  deliveryDateList: string[];
  className?: string;
}

const cx = classNames.bind(styles);
const ts = getCurrentLanguage();

const SelectDeliveryDate: React.FC<ISelectDeliveryDate> = ({
  isValid,
  deliveryDateList,
  value,
  onChange,
  className,
}) => {
  const modalRef = useRef<HTMLIonModalElement>(null);

  return (
    <CommonFormElement label={ts["Delivery date"]} isValid={isValid}>
      <div className={className + " flex w-full py-[14px] px-[17px]"}>
        <IonDatetimeButton datetime="delivery_date" className={cx("dateTimeButton")} />
        {isPlatform("desktop") ? (
          <IonPopover keepContentsMounted={true} dismissOnSelect>
            <IonDatetime
              id="delivery_date"
              isDateEnabled={(curr) => {
                return deliveryDateList.some((el) => el === curr);
              }}
              presentation="date"
              name="delivery-date"
              value={value}
              onIonChange={onChange}
              doneText={ts.Ok}
            />
          </IonPopover>
        ) : (
          <IonModal ref={modalRef} keepContentsMounted={true}>
            <IonDatetime
              id="delivery_date"
              isDateEnabled={(curr) => {
                return deliveryDateList.some((el) => el === curr);
              }}
              presentation="date"
              name="delivery-date"
              value={value}
              onIonChange={(e) => {
                onChange(e);
                modalRef.current?.dismiss();
              }}
              doneText={ts.Ok}
            />
          </IonModal>
        )}
      </div>
    </CommonFormElement>
  );
};

export { SelectDeliveryDate };
