// Import React Libraries
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

// Import Ionic
import {
  IonHeader,
  IonContent,
  IonPage,
  useIonViewWillEnter,
  IonToolbar,
  IonInput,
  IonIcon,
  IonRow,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonGrid,
  IonButtons,
  IonLoading,
  useIonToast,
  useIonViewDidEnter,
} from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ProductsSearchPageLayout.module.scss";

// Import Components
import { SendUserRequestFormFab } from "../SendUserRequestFormFab/SendUserRequestFormFab";
import MaxWidthContainer from "../UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../Footer/Footer";
import ToolbarDesktop from "../Toolbar/ToolbarDesktop/ToolbarDesktop";
import PageTitle from "../PageTitle/PageTitle";
import { useHistory, useLocation } from "react-router";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { searchIcon } from "../assets/icons/icons";
import Product from "../Product/Product";
import { closeOutline, search } from "ionicons/icons";
import BackButton from "../BackButton/BackButton";
import useSearchProductsInfiniteQuery from "../../providers/products/useSearchProductsInfiniteQuery";
import { useGetSettingsQuery } from "../../providers/settings";

interface IProductsSearchPageLayout {
  pageTitle: string;
  breadcrumbsTitle: string;
}

interface ILocationState {
  isSearchInputFocused: boolean;
}

const cx = classNames.bind(styles);

const ProductsSearchPageLayout: React.FC<IProductsSearchPageLayout> = ({ children, pageTitle, breadcrumbsTitle }) => {
  const [presentToast] = useIonToast();

  const { state } = useContext(AppContext);
  const ts = useMemo(() => state.config.languageJson, []);
  const history = useHistory();
  const [searchText, setSearchText] = useState("");

  const {
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    data: searchResult,
  } = useSearchProductsInfiniteQuery({
    params: {
      keyword: searchText,
    },
  });
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const [hideBackButton, setHideBackButton] = useState(false);
  const location = useLocation<ILocationState>();

  const inputRef = useRef(null as any);
  const { data: appSettings } = useGetSettingsQuery();

  const cleanSearch = useCallback(() => {
    setSearchText("");
    ionContentRef.current?.scrollToTop();
  }, [setSearchText]);

  useIonViewWillEnter(() => {
    if (location.pathname === "/search-main-categories") {
      setHideBackButton(true);
    }
  }, [location]);

  useIonViewWillEnter(() => {
    if (history.action !== "POP") {
      cleanSearch();
    }
  }, []);

  useIonViewDidEnter(() => {
    if (history.action === "PUSH" && location?.state?.isSearchInputFocused) {
      inputRef.current?.setFocus();
    }
  }, [location?.state?.isSearchInputFocused, history.action, inputRef.current]);

  useEffect(() => {
    if (isFetched && searchText.length > 3 && !searchResult?.pages[0].length) {
      presentToast({
        message: ts["Nothing found"],
        duration: 2000,
      });
    }
  }, [isFetched, searchText, searchResult]);

  return (
    <IonPage>
      <IonLoading isOpen={isFetching && !isFetched} message={ts["Please wait..."]} />
      <IonHeader mode="ios">
        <div className={cx("")}>
          <IonToolbar className={cx("toolbar", "ion-hide-md-up")}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className={cx("searchBox__form")}
            >
              <div className={cx("searchBox", { searchBox__backButton: !hideBackButton })}>
                <IonButtons slot="start" className={cx("searchBox__buttons", { "ion-hide": hideBackButton })}>
                  <BackButton />
                </IonButtons>
                <IonInput
                  ref={inputRef}
                  value={searchText}
                  type="search"
                  placeholder={ts["Search in the store"]}
                  onIonChange={(e) => {
                    if (!e.detail.value?.length) {
                      cleanSearch();
                    }
                    setSearchText(e.detail.value || "");
                  }}
                  debounce={2000}
                  className={cx("searchBox__input", "ion-no-padding")}
                />
                {!!searchText.length && (
                  <div onClick={cleanSearch}>
                    <div className={cx("cleanSearch", "cleanSearch--sm", "ion-hide-md-up")}>
                      <IonIcon className={cx("cleanSearch__icon", "cleanSearch__icon--sm")} icon={closeOutline} />
                    </div>
                    <div className={cx("cleanSearch", "cleanSearch--md", "ion-hide-md-down")}>
                      <IonIcon className={cx("cleanSearch__icon", "cleanSearch__icon--md")} icon={closeOutline} />
                    </div>
                  </div>
                )}
                <button type="submit" className={cx("submit-button")}>
                  <div className={cx("searchIcon", "searchIcon--sm", "ion-hide-md-up")}>
                    <IonIcon className={cx("searchIcon__icon", "searchIcon__icon--sm")} icon={searchIcon} />
                  </div>
                  <div className={cx("searchIcon", "searchIcon--md", "ion-hide-md-down")}>
                    <IonIcon className={cx("searchIcon__icon", "searchIcon__icon--md")} icon={search} />
                  </div>
                </button>
                {/*<button type="submit" className={cx("searchBar__button")}>*/}
                {/*    <IonIcon className={cx("search-icon")} icon={searchIcon} />*/}
                {/*</button>*/}
              </div>
            </form>
          </IonToolbar>
        </div>
      </IonHeader>
      <IonContent ref={ionContentRef}>
        <ToolbarDesktop
          breadcrumbs={[
            {
              title: breadcrumbsTitle,
            },
          ]}
        />
        <div className={`${cx("productsContainer")} global-content`}>
          <MaxWidthContainer breakPoints={true}>
            <PageTitle hideTitleBreakpoint={"ion-hide-md-down"} title={pageTitle} />
            <div className={"ion-hide-md-up"}>
              {!!searchText.length && searchResult?.pages[0].length ? (
                <MaxWidthContainer maxWidth={570}>
                  <IonGrid className="ion-no-padding">
                    <IonRow className={cx("productsRow")}>
                      {searchResult.pages
                        .flatMap((el) => el)
                        .map((p: any) => (
                          <Product key={p.id} product={p} type={"newList"} />
                        ))}
                    </IonRow>
                    {/* Infinite Scroll  */}
                    <IonInfiniteScroll
                      threshold="100px"
                      ref={infiniteScrollRef}
                      disabled={!hasNextPage}
                      onIonInfinite={async () => {
                        if (hasNextPage) {
                          await fetchNextPage();
                        }
                        infiniteScrollRef.current?.complete();
                      }}
                    >
                      <IonInfiniteScrollContent
                        loadingText={ts["Please Wait"]}
                        loadingSpinner="bubbles"
                      ></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                  </IonGrid>
                </MaxWidthContainer>
              ) : (
                children
              )}
            </div>
            <div className={"ion-hide-md-down"}>{children}</div>
          </MaxWidthContainer>
        </div>
        {appSettings?.showSendCustomerRequestButton && <SendUserRequestFormFab />}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export { ProductsSearchPageLayout };
