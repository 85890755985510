import React from "react";

// Import Ionic
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonRow } from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./OrderSummary.module.scss";

import { currencyTransform } from "../../providers/Redux/Reducers/reducers2";

interface IOrderSummaryTotal {
  languageJson: any;
  productTotal: string | number;
  deliveryAmount: string | number;
  discount: string | number;
  title: string;
}

const cx = classNames.bind(styles);

const OrderSummaryTotal: React.FC<IOrderSummaryTotal> = ({
  languageJson,
  productTotal,
  discount,
  deliveryAmount,
  title,
}) => {
  return (
    <IonCard className={cx("orderSummary")}>
      <IonCardHeader className={cx("orderSummaryHeader")} color="primary">
        <IonCardTitle className={cx("orderSummaryTitle")}>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className={cx("ion-padding ion-margin-top", "orderSummaryDescription")}>
        <IonRow>
          <IonCol size="6">{languageJson["Products Price"]}</IonCol>
          <IonCol className="ion-text-right" size="6">
            {currencyTransform(productTotal)}
          </IonCol>
        </IonRow>

        {!!deliveryAmount && (
          <IonRow>
            <IonCol size="6">{languageJson["Shipping Cost"]}</IonCol>
            <IonCol className="ion-text-right" size="6">
              {currencyTransform(deliveryAmount)}
            </IonCol>
          </IonRow>
        )}

        {!!discount && (
          <IonRow>
            <IonCol size="6">{languageJson.Discount}</IonCol>
            <IonCol className="ion-text-right" size="6">
              {currencyTransform(discount)}
            </IonCol>
          </IonRow>
        )}

        <IonRow>
          <IonCol size="6">{languageJson.Total}</IonCol>
          <IonCol className="ion-text-right" size="6">
            <strong>{currencyTransform(Number(productTotal) - Number(discount) + Number(deliveryAmount))}</strong>
          </IonCol>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default OrderSummaryTotal;
