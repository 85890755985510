import React, { useContext, useMemo, useState } from "react";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";

// Import Ionic
import { IonCol, IonGrid, IonLabel, IonRow, IonSegment, IonSegmentButton } from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./ProductDetailInfo.module.scss";
import Reviews from "../../Reviews/Reviews";
import { Product as TProduct } from "../../../data/types/entities";
import { ProductDescription } from "../../UI/ProductDescription/ProductDescription";

interface IProductDetailInfo {
  id: TProduct["id"];
  product: TProduct;
}

const cx = classNames.bind(styles);

const ProductDetailInfo: React.FC<IProductDetailInfo> = ({ id, product }) => {
  const [productInfoSegment, setProductInfoSegment] = useState<string | undefined>("description");

  // reviewsVisible IS NECESSARY SO THAT REVIEWS ARE LOADED ONLY WHEN THE USER SWITCHES THE IonSegment
  const [reviewsVisible, setReviewsVisible] = useState(false);
  const { state } = useContext(AppContext);

  const ts = useMemo(() => state.config.languageJson, [state.config.languageJson]);

  return (
    <div className={cx("productInfoSegment")}>
      <IonSegment
        className={cx("productInfoSegmentButtons")}
        value={productInfoSegment}
        onIonChange={(e) => {
          if (!reviewsVisible) {
            setReviewsVisible(true);
          }
          setProductInfoSegment(e.detail.value);
        }}
      >
        <IonSegmentButton value="description">
          <IonLabel>{ts["Product Description"]}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="ratings">
          <IonLabel>{ts["Ratings & Reviews"]}</IonLabel>
        </IonSegmentButton>
      </IonSegment>

      <div className={cx('productInfoDesc', { "ion-hide": productInfoSegment !== "description" })}>
        <IonGrid className={cx("productDetailContent ion-no-padding", styles.productDescription)}>
          <IonRow>
            <IonCol className="ion-padding-horizontal" size="12">
              <ProductDescription props={product} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>

      {reviewsVisible && (
        <div className={cx({ "ion-hide": productInfoSegment !== "ratings" })}>
          <Reviews productId={id} />
        </div>
      )}
    </div>
  );
};

export default ProductDetailInfo;
