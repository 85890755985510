import "react-app-polyfill/stable";
import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { queryClient } from "./config/query-client";
import { LocalStorageOperations } from "./data/storage/LocalStorageOperations";

const appWithStorageData = async (): Promise<ReactElement> => {
  const settings = await LocalStorageOperations.getAppSettings();
  if (settings) {
    queryClient.setQueryData(["app-settings"], settings);
    queryClient.invalidateQueries(["app-settings"]);
  }

  return <App />;
};

appWithStorageData().then((app) => ReactDOM.render(app, document.getElementById("root")));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
