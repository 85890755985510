import { useQuery } from "@tanstack/react-query";
import { productsClient } from "../../data/clients";
import { Product as TProduct, ProductReview as TProductReview } from "../../data/types/entities";

const useGetProductReviewsQuery = (id: TProduct["id"]) => {
  return useQuery<TProductReview[]>(["product-reviews", id], async () => {
    return await productsClient.getProductReviews(id);
  });
};

export default useGetProductReviewsQuery;
