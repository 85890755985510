import axios from "axios";
import { PAxiosInstance } from "../base-client";

const headers = {
  "Content-Type": "application/json",
};

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_PARHATO_URL!,
  headers,
}) as PAxiosInstance;

httpClient.interceptors.request.use((config) => {
  if (httpClient.token) {
    config.headers!.Authorization = `Bearer ${httpClient.token}`;
  }
  return config;
});
