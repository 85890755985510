import React, {useState} from 'react';

import {TextareaChangeEventDetail} from "@ionic/core";
import WooComAPI from "../../providers/WooComAPI/woo-com-api";

// Import Ionic
import {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonRow,
    IonTextarea, useIonLoading, useIonToast, useIonViewWillEnter
} from "@ionic/react";

// Import Icons
import {star, starOutline} from "ionicons/icons";

// Import Styles
import classNames from "classnames/bind";
import styles from "./AddReviewModal.module.scss";

// Import Components
import CommonModal from "../UI/CommonModal/CommonModal";

const cx = classNames.bind(styles);

const AddReviewModal = (props: any) => {

    const {languageJson, firstName, lastName, id, email, onDismiss} = props;

    const [presentLoading, dismissLoading] = useIonLoading();
    const [presentToast] = useIonToast();


    const [rating, setRating] = useState(0);
    const [description, setDescription] = useState('');

    useIonViewWillEnter(() => {
        setRating(0);
        setDescription("");
    });

    const addComment = () => {
        presentLoading(languageJson["Please wait..."]);
        const data = {
            product_id: id.id,
            review: description,
            reviewer:  `${firstName} ${lastName}`,
            reviewer_email: email,
            rating: rating
        };

        WooComAPI.postAsync("products/reviews", data)
            .then(() => {
                presentToast({
                    message: languageJson['Your comment will be published after moderation'],
                    duration: 2000,
                    color: 'success',
                });
            })
            .catch(() => {
                presentToast({
                    message: languageJson['An unexpected error occurred'],
                    duration: 2000,
                    color: 'danger',
                });
            })
            .finally(() => {
                dismissLoading();
                props.onDismiss()
            });
    }

    const isButtonDisabled = () => {
        return rating === 0 || !description;
    }

    const generateStars = () => {
        const stars = [];
        for(let i = 1; i <= 5; i++) {
            stars.push(
                <span className={cx('addReviewStarWrapper')} onClick={() => setRating(i)}>
                    <IonIcon
                        className={cx("addReviewStar")}
                        color="warning"
                        icon={i > rating ? starOutline : star}
                        key={i}
                    />
                </span>
            );
        }

        return stars;
    }

    return (
        <>
            <CommonModal
                onDismiss={onDismiss}
                title={languageJson['Comment about this product']}
                classNameContent={cx("addReview")}
            >
                <span className={"commonModalSupTitle"}>{languageJson['rating']}</span>
                <IonGrid className="ion-no-padding ion-margin-bottom">
                    <IonRow className={cx("addReviewStarsRow", "ion-no-padding")}>
                        <IonCol className={cx("addReviewStarsCol", "ion-no-padding")}>
                            {
                                generateStars()
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <span className={"commonModalSupTitle"}>Комментарий</span>
                <form>
                    <IonItem className={cx("addReviewDescriptionItem", "ion-no-padding")} lines="none">
                        <IonTextarea
                            className={cx("addReviewDescription")}
                            rows={10}
                            value={description}
                            placeholder={languageJson['Enter a description']}
                            name="desc"
                            required
                            onIonChange={(e: CustomEvent<TextareaChangeEventDetail>) => setDescription(e.detail.value!)}
                        />
                    </IonItem>
                </form>
                <IonButton
                    className={cx("addReviewButton")}
                    expand="block"
                    color="primary"
                    onClick={() => addComment()}
                    disabled={isButtonDisabled()}>
                    {languageJson['Post Comment']}
                </IonButton>
            </CommonModal>
        </>
    );
};

export default AddReviewModal;