import * as ionicIcons from "ionicons/icons";
import * as irskomIcons from "../assets/irskomIcons";
import * as parhatoIcons from "../components/assets/icons/icons"

const themes = {
  defaultTheme: "default",
  firstTheme: "firstTheme",
  secondTheme: "secondTheme",
};

let icons: { [key: string]: string } = {};

if (process.env.REACT_APP_THEME === themes.secondTheme) {
  icons = {
    ...icons,
    ...irskomIcons,
  };
} else if (process.env.REACT_APP_THEME === themes.firstTheme) {
  icons = {
    ...icons,
    ...ionicIcons,
    ...parhatoIcons
  };
} else {
  icons = { ...icons, ...ionicIcons };
}

export { icons };
