import BaseClient from "../base-client";
import { IShippingZonesClient } from "../../types/clients";

class ShippingZonesClient extends BaseClient implements IShippingZonesClient {
  async getShippingZones() {
    return [];
  }
}

export default ShippingZonesClient;
