import { history } from "../Redux/Reducers/history";
import { store } from "../Redux/Reducers/AppContext";
import { AppMetricaProvider } from "../AppMetrica/AppMetrica";

// For Configuration
if (localStorage.languageCode == undefined) {
  // Please visit this link to get your html code  https://html-css-js.com/html/character-codes/currency/
  localStorage.currencyCode = "RUB"; // Default Currency Code
  localStorage.currencyPos = "left"; // Default Currency Position
  localStorage.decimals = 2; // Default Currecny Decimal
  localStorage.currencyRate = 1; // Default Curren Rate
  localStorage.currency = "&#8381;"; // Default currecny html code to show in app.

  // Default Language Code
  localStorage.languageCode = "ru"; // For Language Arabic (ar)

  // Default Language Direction Of App
  localStorage.languageDirection = "ltr"; // For Right Direction (rtl)

  // Default Mode Theme Of App
  localStorage.modeTheme = "light"; // For Dark Mode (dark)

  // Default Side Menu Navigation
  localStorage.tabsNavigation = false; // For Tabs Navigation (true)
}

export type WCCustomer = {
  avatar_url: string;
  email: string;
  first_name: string;
  id: number;
  is_paying_customer: boolean;
  last_name: string;
  role: string;
  username: string;
  billing: WCBillingData;
  shipping: WCShippingData;
  meta_data: WCMetaData;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;

  [k: string]: any;
};

export type WCProduct = {
  id: number;
  name: string;
  slug: string;
  permalink: string;
  type: string;
  status: string;
  featured: boolean;
  description: string;
  short_description: string;
  sku: string;
  price: string;
  regular_price: string;
  sale_price: string;
  on_sale: boolean;
  stock_quantity: number;
  average_rating: string;
  rating_count: number;
  upsell_ids: number[];
  cross_sell_ids: number[];
  related_ids: number[];
  parent_id: number;
  purchase_note: string;
  categories: WCProductCategory[];
  images: WCProductImage[];
  stock_status: string;
  [k: string]: any;
};

export type WCLineItem = {
  id: number;
  name: string;
  parent_name: string;
  price: string;
  product_id: number;
  sku: string;
  quantity: number;
  variation_id: number;
  subtotal: string;
  subtotal_tax: string;
  total: string;
  total_tax: string;
  taxes: any[];
  tax_class: string;
  meta_data: WCMetaData[];
  // TODO: CHECK
  image?: {
    id: string;
    src: string;
  };
  product?: WCProduct;
};

export type WCMetaData = {
  id?: number;
  key: string;
  value: string;
  display_key?: string;
  display_value?: string;
};

type WCProductCategory = {
  id: number;
  name: string;
  slug: string;
};

type WCProductImage = {
  id: number;
  src: string;
  name: string;
  alt: string;
};

export type WCShippingZone = {
  id: number;
  name: string;
  order: number;
};

export type WCBillingData = {
  address_1: string;
  address_2: string;
  city?: string;
  company?: string;
  country?: string;
  email?: string;
  first_name: string;
  last_name: string;
  phone: string;
  postcode?: string;
  state?: string;
};

export type WCShippingData = {
  address_1: string;
  address_2: string;
  city?: string;
  company?: string;
  country?: string;
  first_name: string;
  last_name: string;
  phone: string;
  postcode?: string;
  state?: string;
};

export const WooComAPI = {
  url: process.env.REACT_APP_PARHATO_URL!,
  accessToken: "",
  version: "wc/v3", // WooCommerce WP REST API version

  productsArguments: "stock_status=instock&min_price=0.1",

  getWoo(url: string) {
    url = getUrl("get", url);
    return getDataHttp(url);
  },
  getWithUrl(url: string) {
    return getDataHttp(url);
  },
  postAsync(url: string, dataToPost: any) {
    url = getUrl("post", url);
    return postDataHttp(url, dataToPost);
  },
  putAsync(url: string, dataToPost: any) {
    url = getUrl("put", url);
    return putDataHttp(url, dataToPost);
  },

  setAccessToken(token: string) {
    this.accessToken = token;
  },

  getBearerAuthToken(): string {
    return `Bearer ${WooComAPI.accessToken}`;
  },
};

const getUrl = (type: string, endpoint: string) => {
  return `${WooComAPI.url}/wp-json/${WooComAPI.version}/${endpoint}`;
};

const getRequestHeaders = () => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Content-Type", "application/json");

  if (WooComAPI.accessToken) {
    requestHeaders.set("Authorization", WooComAPI.getBearerAuthToken());
  }

  return requestHeaders;
};

const getDataHttp = (url: string) => {
  const makeRequest = () =>
    fetch(url, {
      method: "GET",
      headers: getRequestHeaders(),
    });

  return makeRequest().then((res) => catchInvalidTokenError(res, makeRequest));
};

const putDataHttp = (url: string, dataToPost: any) => {
  const makeRequest = () =>
    fetch(url, {
      method: "PUT",
      body: JSON.stringify(dataToPost),
      headers: getRequestHeaders(),
    });

  return makeRequest().then((res) => catchInvalidTokenError(res, makeRequest));
};

const postDataHttp = (url: string, dataToPost: any) => {
  const makeRequest = () =>
    fetch(url, {
      method: "POST",
      body: JSON.stringify(dataToPost),
      headers: getRequestHeaders(),
    });

  return makeRequest().then((res) => catchInvalidTokenError(res, makeRequest));
};

const catchInvalidTokenError = async (response: Response, makeRequest: () => Promise<Response>): Promise<any> => {
  if (!response.ok) {
    const data = await response.json();

    AppMetricaProvider.reportError(response.statusText, JSON.stringify(data));

    const jwtErrors = ["jwt_auth_invalid_token", "jwt_auth_user_not_found", "jwt_auth_obsolete_token"];

    if (jwtErrors.includes(data.code)) {
      store.dispatch({
        type: "REMOVE_CUSTOMER_DATA",
      });
      history.replace("/home3");

      const res = await makeRequest();
      return res.json();
    } else {
      return Promise.reject({ message: data.message, error: data });
    }
  }

  return response.json();
};

export default WooComAPI;
