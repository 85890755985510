import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { authClient } from "../../data/clients";
import { ResetPasswordVariables } from "../../data/types/entities";

const useResetPasswordMutation = (options?: UseMutationOptions<any, any, ResetPasswordVariables>) =>
  useMutation(async ({ email, phone, newPassword, verificationCode, verificationCodeId }) => {
    const data = await authClient.resetPassword({ email, phone, newPassword, verificationCode, verificationCodeId });

    return data;
  }, options);

export default useResetPasswordMutation;
