import { alertController } from "@ionic/core/components";
import { CouponDiscountType, Coupon as TCoupon } from "../../data/types/entities";
import { CartDataProductsItem } from "../cartDataStore";
import { getCurrentLanguage } from "../Redux/Reducers/state";

export const Coupon = {
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< All below services are used for coupon >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  //========================================================================================================
  //=============================== service to calculate line items total ==============================
  lineItemTotalService(lineItems: any) {
    var total: any = 0;
    for (let value of lineItems) {
      var subtotal = parseFloat(value.total);
      total = total + subtotal;
    }
    return total;
  },

  //========================================================================================================
  //=============================== service to calculate line items total ==============================
  checkOnSaleService(lineItems: CartDataProductsItem[]) {
    return lineItems.some((product) => product.onSale);
  },

  //========================================================================================================
  //=============================== service to calculate line items total ==============================

  checkCategoriesService(value: any, coupon: any) {
    if (coupon.product_categories.length == 0 && coupon.excluded_product_categories.length == 0) return true;
    var found = 0;
    for (let y of coupon.product_categories) {
      for (let z of value.categories) {
        if (z.id == y) {
          found++;
        }
      }
    }

    if (coupon.product_categories.length == 0) {
      found++;
    }

    var found2 = 0;
    //for excluded categries
    for (let y of coupon.excluded_product_categories) {
      for (let z of value.categories) {
        if (z.id == y) {
          found2++;
        }
      }
    }

    if (found != 0 && found2 == 0) return true;
    else return false;
  },

  //========================================================================================================
  //=============================== service to calculate line items total ==============================

  couponApplyOnProductService(value: any, coupon: any) {
    if (coupon.product_ids.length == 0 && coupon.excluded_product_ids.length == 0) return true;

    var id = value.product_id;
    var found = 0;
    //checking in allowed products
    for (let value of coupon.product_ids) {
      if (id == value) {
        found++;
        return true;
      }
    }
    if (coupon.product_ids.length == 0) {
      found++;
    }

    var found2 = 0;
    //checking in excluded products
    for (let value of coupon.excluded_product_ids) {
      if (id == value) {
        found2++;
        return true;
      }
    }
    if (found != 0 && found2 == 0) {
      return true;
    } else return false;
  },

  //========================================================================================================
  //=============================== service to calculate line items total ==============================

  checkAlreadyAppliedService(coupon: TCoupon, couponLines: TCoupon[]) {
    return couponLines.some((_coupon) => _coupon.code === coupon.code);
  },

  //========================================================================================================
  //=============================== service to check coupon will apply on cart or not  ==============================

  checkCouponApplyOrNotOnCurrentProducts(coupon: TCoupon, lineItems: CartDataProductsItem[]) {
    let found = 0;
    if (!coupon.productCategories?.length) {
      found++;
    }
    for (let y of coupon.productCategories ?? []) {
      for (let z of lineItems) {
        if (z.categories[0]?.id === String(y)) {
          found++;
        }
      }
    }

    let found2 = 0;
    //for excluded categries
    for (let y of coupon.excludedProductCategories ?? []) {
      for (let z of lineItems) {
        if (z.categories[0]?.id === String(y)) {
          found2++;
        }
      }
    }

    if (found2 !== 0) return false;
    else if (found === 0) return false;
    else return true;
  },

  //========================================================================================================
  //=============================== service to check the validity of coupon  ==============================
  async validateCouponService(
    coupon: TCoupon,
    lineItems: CartDataProductsItem[],
    couponLines: TCoupon[],
    cartTotal: number
  ) {
    const translate = getCurrentLanguage()!;
    var expDate = new Date(coupon.dateExpires ?? "");
    var todayDate = new Date();
    //checking coupon expire or not
    if (coupon.dateExpires && expDate.getTime() <= todayDate.getTime()) {
      let alert = await alertController.create({
        header: translate["Alert"],
        message: translate["Sorry Coupon is Expired"],
        buttons: [translate["Ok"]],
      });
      await alert.present();
      return false;
    }
    // if cart amount is lower than the coupon minimum limit
    else if (coupon.minimumAmount && cartTotal <= coupon.minimumAmount) {
      let alert = await alertController.create({
        header: translate["Alert"],
        message: translate["Sorry your Cart total is low than coupon min limit!"],
        buttons: [translate["Ok"]],
      });
      await alert.present();
      return false;
    }
    // if cart amount is higher than the coupon minimum limit
    else if (coupon.maximumAmount && cartTotal >= coupon.maximumAmount) {
      let alert = await alertController.create({
        header: translate["Alert"],
        message: translate["Sorry your Cart total is low than coupon min limit!"],
        buttons: [translate["Ok"]],
      });
      await alert.present();
      // this.shared.showAlert("Sorry your Cart total is Higher than coupon Max limit!");
      return false;
    }
    //============================================================== further checking
    else if (coupon.excludeSaleItems && this.checkOnSaleService(lineItems)) {
      let alert = await alertController.create({
        header: translate["Alert"],
        message: translate["Sorry, this coupon is not valid for sale items."],
        buttons: [translate["Ok"]],
      });
      await alert.present();
      // this.shared.showAlert("Sorry, this coupon is not valid for sale items.");
      return false;
    } else if (this.checkAlreadyAppliedService(coupon, couponLines)) {
      let alert = await alertController.create({
        header: translate["Alert"],
        message: translate["Coupon code already applied!"],
        buttons: [translate["Ok"]],
      });
      await alert.present();
      return false;
    } else if (couponLines.length > 0 && (coupon.individualUse || couponLines[0].individualUse)) {
      let alert = await alertController.create({
        header: translate["Alert"],
        message:
          translate["Sorry Individual Use Coupon is already applied any other coupon cannot be applied with it !"],
        buttons: [translate["Ok"]],
      });
      await alert.present();
      return false;
    } else if (!this.checkCouponApplyOrNotOnCurrentProducts(coupon, lineItems)) {
      let alert = await alertController.create({
        header: translate["Alert"],
        message: translate["Sorry Coupon Cannot be Applied on these Products!"],
        buttons: [translate["Ok"]],
      });
      await alert.present();
      return false;
    }
    // else if (checkNoItemInCartService(lineItems, coupon) == false) {
    //   this.shared.showAlert('Sorry, this coupon is not applicable to your cart contents.');
    //   return false;
    // }
    else return true;
  },

  //========================================================================================================
  //=============================== service to apply check coupon ==============================

  apply(coupon: any, lineItems: any) {
    var productLimit = coupon.limit_usage_to_x_items;
    // if (productLimit == 0) productLimit = null;
    var product_qty_flag = 0;
    //fixed cart applying on line items
    if (coupon.discount_type == "fixed_cart") {
      var cartTotal = parseFloat(Coupon.lineItemTotalService(lineItems));
      var discount = parseFloat((coupon.amount / cartTotal).toString());
      lineItems.forEach((value: any, index: any) => {
        if (this.couponApplyOnProductService(value, coupon) && this.checkCategoriesService(value, coupon)) {
          var result = value.total - parseFloat((discount * value.total).toString());
          if (result < 0) result = 0;
          value.total = result;
        }
      });
      return lineItems;
    }
    //percent cart applying on line items
    else if (coupon.discount_type == "percent_old") {
      lineItems.forEach((value: any, index: any) => {
        var amount = parseFloat(coupon.amount);
        var subtotal = parseFloat(value.subtotal);
        var total = parseFloat(value.total);
        var discount = (subtotal / 100) * amount;
        value.total = parseFloat((total - discount).toString());
        if (value.total < 0) value.total = 0;
      });
      return lineItems;
    }
    //fixed product applying on specific line items
    else if (coupon.discount_type == "fixed_product") {
      var amount = parseFloat(coupon.amount);
      lineItems.forEach((value: any, index: any) => {
        if (this.couponApplyOnProductService(value, coupon) && this.checkCategoriesService(value, coupon)) {
          var quantity = value.quantity;
          var total = parseFloat(value.total);
          if (productLimit > 0) {
            for (var l = 1; l <= quantity; l++) {
              if (product_qty_flag < productLimit) {
                total = parseFloat((total - amount).toString());
                product_qty_flag = product_qty_flag + 1;
              }
            }
            value.total = total;
          } else {
            value.total = parseFloat((total - amount * quantity).toString());
          }
          if (value.total < 0) {
            value.total = 0;
          }
        }
      });
      return lineItems;
    }
    //percent product applying on specific line items
    else if (coupon.discount_type == "percent") {
      let amount = parseFloat(coupon.amount);
      lineItems.forEach((value: any, index: any) => {
        if (this.couponApplyOnProductService(value, coupon) && this.checkCategoriesService(value, coupon)) {
          var total = parseFloat(value.total);
          if (productLimit > 0) {
            for (var l = 1; l <= value.quantity; l++) {
              var discount = parseFloat(((value.price / 100) * amount).toString());
              if (product_qty_flag < productLimit) {
                total = parseFloat((total - discount).toString());
                product_qty_flag = product_qty_flag + 1;
              }
            }
            value.total = total;
          } else {
            value.total = parseFloat((total - (total / 100) * amount).toString());
          }

          if (value.total < 0) value.total = 0;
        }
      });

      return lineItems;
    }
    // else return lineItems;
  },

  calculateDiscountForItem(coupon: TCoupon, item: CartDataProductsItem, cartTotal: number): number {
    let itemTotalPrice = item.quantity * parseFloat(item.price);
    if (coupon.discountType === CouponDiscountType.FIXED_CART) {
      return (coupon.discountAmount / cartTotal) * itemTotalPrice;
    } else if (coupon.discountType === CouponDiscountType.FIXED_PRODUCT) {
      return coupon.discountAmount * item.quantity;
    } else if (coupon.discountType === CouponDiscountType.PERCENT) {
      return (itemTotalPrice / 100) * coupon.discountAmount;
    }

    return 0;
  },
};

export default Coupon;
