import React, { useContext, useMemo } from "react";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { useHistory } from "react-router-dom";

// Import Ionic Components
import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLoading, IonPage, IonRow } from "@ionic/react";

// Import Icons
import { heart } from "ionicons/icons";

// Import Styles
import classNames from "classnames/bind";
import styles from "./Wishlist.module.scss";
import "./Wishlist.scss";

// Import Components
import Product from "../../components/Product/Product";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../../components/Footer/Footer";
import PageTitle from "../../components/PageTitle/PageTitle";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import { useWishListData } from "../../providers/wishListStore";
import { useGetIncludeProductsQuery } from "../../providers/products";
import useViewVisible from "../../hooks/useViewVisibleHook";

const cx = classNames.bind(styles);

const Wishlist: React.FC = () => {
  const { state } = useContext(AppContext);
  const ts = useMemo(() => state.config.languageJson, []);
  const { wishListProducts: wishListProductsIds } = useWishListData();
  let history = useHistory();

  const isVisible = useViewVisible();

  const { data: wishlistProducts, isFetching: isWishListFetching } = useGetIncludeProductsQuery({
    include: wishListProductsIds,
    options: {
      enabled: !!wishListProductsIds.length && isVisible,
    },
  });

  const openProductsPage = () => {
    history.push("search-main-categories");
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showSearchButton={true} />
      </IonHeader>

      <IonContent>
        <ToolbarDesktop breadcrumbs={[{ title: ts["Favourite"] }]} />
        <MaxWidthContainer
          classList={`global-content ${cx({ "empty-list": state.config.wishListProducts.length === 0 })}`}
        >
          <PageTitle hideTitleBreakpoint={"ion-hide-md-down"} title={ts["Favourite"]} />
          {wishListProductsIds.length === 0 ? (
            <IonGrid className="ion-no-padding grid-wish-list">
              <IonRow>
                <IonCol size="12">
                  <IonIcon className="no-margin-icon" icon={heart} />
                </IonCol>
                <IonCol size="12">
                  <h4>{ts["Your wish List is empty"]}</h4>
                </IonCol>
                <IonCol size="12">
                  <IonButton onClick={() => openProductsPage()}>{ts.Explore}</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid className={cx("wishList__container")}>
              <IonRow>
                <IonLoading isOpen={isWishListFetching} message={ts["Please wait..."]} duration={20000} />
                {wishlistProducts?.map((p: any, i: number) => (
                  <IonCol key={p.id} size={"12"} sizeSm={"3"} sizeMd={"2.3"} sizeLg={"2"} sizeXl={"1.5"}>
                    <Product product={p} type={"list-for-wish"} />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          )}
        </MaxWidthContainer>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Wishlist;
