import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { ordersClient } from "../../data/clients";
import { REVIEW_ORDER_SET_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { ReviewSet } from "../../data/types/entities";

const useReviewSetOrderById = (orderNumber: string, options: UseQueryOptions<ReviewSet> = {}) => {
  return useQuery<ReviewSet>(
    ["review", orderNumber],
    async () => {
      const res = await ordersClient.getReviewSetOrderById(orderNumber);

      return res;
    },
    {
      cacheTime: REVIEW_ORDER_SET_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: REVIEW_ORDER_SET_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );
};

export default useReviewSetOrderById;
