import React from "react";
import { IonSkeletonText } from "@ionic/react";
import classNames from "classnames/bind";
import styles from "./CartItemSkeleton.module.scss";

const cx = classNames.bind(styles);

const CartItemSkeleton = () => {
  return (
    <div className={cx("wrapper")}>
      <div className={cx("wrapper-img")}>
        <IonSkeletonText className={cx("ion-no-margin", "img")} animated />
      </div>
      <div className={cx("wrapper-info")}>
        <div className={cx("wrapper-descr")}>
          <IonSkeletonText className={cx("ion-no-margin", "descr")} animated style={{ height: 17 }} />
          <IonSkeletonText className={cx("ion-no-margin", "descr", "descr-second")} animated style={{ height: 17 }} />
        </div>
        <div className={cx("wrapper-counter")}>
          <IonSkeletonText className={cx("ion-no-margin", "counter")} animated />
        </div>
      </div>
    </div>
  );
};

export { CartItemSkeleton };
