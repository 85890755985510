import { IonSkeletonText } from "@ionic/react";

import React, { useRef } from "react";
import classNames from "classnames/bind";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Menu Css File
import styles from "./Banner.module.scss";

import { useHistory } from "react-router";
import { Image } from "../Image/Image";
import { useGetAllCategoriesQuery } from "../../providers/categories";
import { useGetBannersQuery } from "../../providers/banners";
import { Banner as TBanner } from "../../data/types/entities";

import { Autoplay } from "swiper/modules";

const cx = classNames.bind(styles);

const Banner: React.FC = () => {
  const history = useHistory();
  const sliderRef = useRef<any>(null);
  const { data: { allCategories } = {} } = useGetAllCategoriesQuery();
  const { data: bannersData, isFetching: isBannersFetching } = useGetBannersQuery();
  function processBannerUrl(url: string) {
    return window.innerWidth >= 768 ? url.replace(new RegExp("/app-"), "/desktop-") : url;
  }

  const navigateToSpecialOffers = (banner: TBanner) => {
    switch (banner.type) {
      case "on_sale":
        history.push("/products/0/0/on_sale");
        break;
      case "featured":
        history.push("/products/0/0/featured");
        break;
      case "latest":
        history.push("/products/0/0/latest");
        break;
      case "category":
        const cat = allCategories?.find((cat) => cat.id === banner.link);
        if (cat) {
          history.push(`products/${cat.id}/${cat.name}/newest`);
        }
        break;
      case "product":
        history.push(`product-detail/${banner.link}`);
        break;
      case "custom":
        banner?.link !== undefined && history.push(banner.link);
        break;
      default:
        history.push("/");
    }
  };

  return (
    <div className={`${cx("bannerWrapper")} bannerWrapper`}>
      {isBannersFetching && <IonSkeletonText animated style={{ height: 150 }}></IonSkeletonText>}

      {bannersData ? (
        <Swiper
          ref={sliderRef}
          spaceBetween={"2.5%"}
          centeredSlides={true}
          className={cx("sliderWrapper")}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay]}
          slidesPerView={1.1}
          loop
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
        >
          {bannersData.map((banner) => (
            <SwiperSlide
              onClick={() => navigateToSpecialOffers(banner)}
              key={banner.id}
              className={cx("h-auto max-h-72")}
            >
              <Image
                src={processBannerUrl(banner.imageSrc)}
                downsize={false}
                lazyLoad={false}
                className={"object-cover rounded-xl"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
    </div>
  );
};

export default Banner;
