// Import React Libraries
import React, { useContext, useMemo, useRef } from "react";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { useHistory } from "react-router-dom";

// Import Ionic Components
import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonPage,
  IonRow,
  useIonViewWillEnter,
} from "@ionic/react";

// Import Icons
import { briefcase } from "ionicons/icons";

// Import Styles
import "./my-orders.scss";
import styles from "./my-orders.module.scss";
import classNames from "classnames/bind";

// Import Components
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../../components/Footer/Footer";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import { useAuth } from "../../contexts/auth-context";
import { TOrder } from "../../data/types/entities";
import { useInfiniteGetOrdersQuery } from "../../providers/Orders";
import { useGetSettingsQuery } from "../../providers/settings";

const cx = classNames.bind(styles);

const MyOrders: React.FC = () => {
  let history = useHistory();
  let infinite = useRef(null as any);
  const page = useRef<number>(1);
  const { state } = useContext(AppContext);
  const { user } = useAuth();
  const ionContentRef = useRef<HTMLIonContentElement>(null);
  const { data: appSettings } = useGetSettingsQuery();
  const ts = useMemo(() => state.config.languageJson, []);

  const {
    data: orders,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isRefetching,
  } = useInfiniteGetOrdersQuery({
    params: { page: page.current, customer: user?.id ?? "", per_page: 10 },
    options: {
      enabled: !!user?.id,
    },
  });

  useIonViewWillEnter(() => {
    if (history.action === "PUSH") {
      ionContentRef.current?.scrollToTop();
      page.current = 1;
    }
  }, [state, history, orders, isLoading]);

  const showOrderDetail = (order: TOrder) => {
    history.push("/my-order-detail/" + order.id);
  };

  const openShop = () => {
    history.push("search-main-categories");
  };

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton showSearchButton />
      </IonHeader>
      <IonContent>
        <ToolbarDesktop />
        <div className={`global-content ${cx({ myOrders__emptyContent: !orders?.pages.flat().length })}`}>
          <MaxWidthContainer
            maxWidthBreakpoint={"Md"}
            classList={cx({ myOrders__emptyContent: !orders?.pages.flat().length })}
          >
            <IonLoading isOpen={isRefetching || isLoading} message={ts["Please wait..."]} duration={8000} />

            {!orders?.pages.flat().length && (
              <IonGrid class="ion-no-padding grid-my-orders">
                <IonRow>
                  <IonCol className="ion-text-center" size="12">
                    <IonIcon icon={briefcase} />
                  </IonCol>
                  <IonCol className="ion-text-center" size="12">
                    <h4>{ts["Your Order List is Empty"]}</h4>
                  </IonCol>
                  <IonCol className="ion-text-center" size="12">
                    <h5>{ts["Continue Shopping"]}</h5>
                  </IonCol>
                  <IonCol className="ion-text-center" size="12">
                    <IonButton onClick={() => openShop()}>{ts.Explore}</IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            )}

            {!!orders?.pages.length && !isRefetching && (
              <IonGrid>
                {orders?.pages.flat().map((order: TOrder) => {
                  return (
                    <IonCard key={order.id} onClick={() => showOrderDetail(order)}>
                      <IonCardContent className={cx("myOrders__cardContent")}>
                        {order.status === "completed" && !order.driverRating && (
                          <IonBadge color="primary">{ts["Rate the delivery"]}</IonBadge>
                        )}
                        <IonRow>
                          <IonCol size="6">{ts["Orders ID"]}:</IonCol>
                          <IonCol class="ion-text-right" size="6">
                            {"#" + order.number}
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="6">{ts.Date}:</IonCol>
                          <IonCol class="ion-text-right" size="6">
                            {order.dateCreated?.split("T")[0].split("-").reverse().join(".")}
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="6">
                            <strong>{ts["Total cost"]}:</strong>
                          </IonCol>
                          <IonCol class="ion-text-right" size="6">
                            <strong>{order.total + " ₽"}</strong>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol size="6">{ts.Status}:</IonCol>
                          <IonCol className={cx("flex", "justify-end")} size="6">
                            <strong
                              className={cx("orderStatusBar", "complete", {
                                cancel: order.status === "cancelled" || order.status === "failed",
                                inProcess: order.status === "processing",
                              })}
                            >
                              {ts[order.status!] || order.status}
                            </strong>
                          </IonCol>
                        </IonRow>

                        {order.paymentStatus && (
                          <IonRow className={cx("myOrders__statusRow")}>
                            <IonCol size="6">{ts["Payment status"]}:</IonCol>
                            <IonCol className={cx("flex", "justify-end")} size="6">
                              <strong className={cx("orderStatusBar", "complete")}>{order.paymentStatus}</strong>
                            </IonCol>
                          </IonRow>
                        )}
                      </IonCardContent>
                    </IonCard>
                  );
                })}
              </IonGrid>
            )}
            {appSettings?.orderHistoryPagination && (
              <IonInfiniteScroll
                ref={infinite}
                threshold="100px"
                onIonInfinite={async () => {
                  if (hasNextPage) {
                    await fetchNextPage();
                  }
                  infinite.current?.complete();
                }}
              >
                <IonInfiniteScrollContent
                  loadingText={ts["Please Wait"]}
                  loadingSpinner="bubbles"
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            )}
          </MaxWidthContainer>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};
export default MyOrders;
