import React, { useContext, useEffect, useMemo, useState } from "react";
import { IonButton, IonCard, IonCardContent, IonCol, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import classNames from "classnames/bind";
import styles from "./BonusPoints.module.scss";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";
import { ParhatoRESTApi } from "../../../providers/ParhatoRESTApi/ParhatoRESTApi";
import { useCartData } from "../../../providers/cartDataStore";

const cx = classNames.bind(styles);

const BonusPoints: React.FC = () => {
  const { state } = useContext(AppContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ts = useMemo(() => state.config.languageJson, []);
  const { addRewardPoints, rewardPoints, cartTotalValue, resetRewardPoints } = useCartData();

  const [userRewardPoints, setUserRewardPoints] = useState(0);
  const [pointsToUse, setPointsToUse] = useState("");
  const [pointsErrorMessage, setPointsErrorMessage] = useState("");

  const handleUseRewardPoints = () => {
    const points = parseInt(pointsToUse, 10);
    if (isNaN(points) || points < 0 || points > cartTotalValue) {
      setPointsErrorMessage(ts["Incorrect input"]);
      return;
    } else {
      setPointsErrorMessage("");
    }

    if (points + rewardPoints > userRewardPoints) {
      setPointsErrorMessage(ts["Not enough bonus points"]);
      return;
    } else {
      setPointsErrorMessage("");
    }

    addRewardPoints(points);
  };

  useEffect(() => {
    ParhatoRESTApi.getBonusPointsAmount().then((data) => {
      setUserRewardPoints(data.points);
    });
  }, []);

  return (
    <IonCard>
      <IonCardContent>
        <IonRow className={cx("ion-margin-top ion-margin-start")}>
          <IonCol className={cx("ion-no-padding")} size="7">
            {`${ts["Your bonus points"]}:`}
          </IonCol>
          <IonCol size="5" className={cx("flex ion-align-items-center ion-justify-content-end")}>
            {userRewardPoints}
          </IonCol>
        </IonRow>
        <IonRow className={cx("ion-margin-top ion-margin-start")}>
          <IonCol className={cx("ion-no-padding")} size="7">
            {`${ts["Applied bonus points"]}:`}
          </IonCol>
          <IonCol size="5" className={cx("flex ion-align-items-center ion-justify-content-end")}>
            {rewardPoints}
          </IonCol>
        </IonRow>
        {rewardPoints > 0 ? (
          <IonRow>
            <IonCol className={cx("ion-no-padding")}>
              <IonButton color="primary" expand="block" onClick={resetRewardPoints}>
                {ts["Reset bonus points"]}
              </IonButton>
            </IonCol>
          </IonRow>
        ) : (
          <IonRow>
            <IonCol className={cx("ion-no-padding")}>
              <IonItem className={cx("ion-no-padding ion-margin-start")}>
                <IonInput
                  type="number"
                  min="0"
                  max={String(userRewardPoints)}
                  pattern="[0-9]*"
                  placeholder={ts["Use points"]}
                  onIonChange={(e) => setPointsToUse(e.detail.value ?? "")}
                />
              </IonItem>
              {pointsErrorMessage && (
                <IonItem lines="none" className={cx("ion-no-padding ion-margin-start")}>
                  <IonLabel className="ion-text-wrap" color="danger">
                    {pointsErrorMessage}
                  </IonLabel>
                </IonItem>
              )}
              <IonButton color="primary" expand="block" onClick={handleUseRewardPoints}>
                {ts["Apply bonus points"]}
              </IonButton>
            </IonCol>
          </IonRow>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export { BonusPoints };
