import React from "react";

import PhoneInput, { CountryData, PhoneInputProps } from "react-phone-input-2";
import phoneInputRuLocal from "react-phone-input-2/lang/ru.json";

import classNames from "classnames/bind";
import styles from "./CustomPhoneInput.module.scss";

const cx = classNames.bind(styles);

export interface CustomPhoneInputProps extends PhoneInputProps {
  className?: string;
  onCustomChange?: (value: string, isValid: boolean, country: {} | CountryData, formattedValue: string) => void;
}

const CustomPhoneInput = ({ className, onChange, onCustomChange, ...props }: CustomPhoneInputProps) => {
  return (
    <PhoneInput
      country={"ru"}
      localization={phoneInputRuLocal}
      placeholder={"+7 (999) 333-44-55"}
      disableDropdown={true}
      containerClass={cx("phoneInput", className)}
      inputClass={cx("phoneInput_input", { disabled: props.disabled })}
      onChange={(val, country: CountryData, e, formattedVal) => {
        const isValid = !!country.format && val.length === country.format!.split(".").length - 1;
        onCustomChange && onCustomChange(val, isValid, country, formattedVal);
        onChange && onChange(val, country, e, formattedVal);
      }}
      {...props}
    />
  );
};

export { CustomPhoneInput };
