import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { shippingMethodsClient } from "../../data/clients";
import { SHIPPING_METHODS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { PickupPoint } from "../../data/types/entities";

const useGetPickupPoints = (options: UseQueryOptions<PickupPoint[]> = {}) => {
  return useQuery<PickupPoint[], any>(
    ["pickup-points"],
    async () => {
      return await shippingMethodsClient.getPickupPoints();
    },
    {
      cacheTime: SHIPPING_METHODS_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: SHIPPING_METHODS_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );
};

export default useGetPickupPoints;
