// Import React Libraries
import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames/bind";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { hideSplashScreen } from "../../providers/Redux/Reducers/reducers2";
import { ScrollDetail, BackButtonEvent } from "@ionic/core/components";

// Import Ionic Components
import { IonContent, IonHeader, IonPage, isPlatform, useIonRouter } from "@ionic/react";

// Import Styles
import "./Home3.scss";
import styles from "./Home3.module.scss";

// import components
import Banner from "../../components/Banner/Banner";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../../components/Footer/Footer";
import AboutCompanySEO from "../../components/SEO/AboutCompanySEO";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import { App } from "@capacitor/app";
import HomePageCategories from "../../components/HomPageCategories/HomePageCategories";
import HomePageSearchBox from "../../components/HomePageSearchBox/HomePageSearchBox";
import HomeProductsWidget from "../../widgets/HomeProductsWidget/HomeProductsWidget";
import { useGetSettingsQuery } from "../../providers/settings";

const cx = classNames.bind(styles);
const RELATIVE_INPUT_THRESHOLD = 100;

// Parent Component
const Home3: React.FC = () => {
  const ionRouter = useIonRouter();
  const { state } = useContext(AppContext);
  const [showSearch, setShowSearch] = useState(false);
  const { data: appSettings } = useGetSettingsQuery();

  useEffect(() => {
    hideSplashScreen();
    if (isPlatform("mobile")) {
      document.addEventListener("ionBackButton", (ev) => {
        (ev as BackButtonEvent).detail.register(-1, () => {
          if (!ionRouter.canGoBack()) {
            App.exitApp();
          }
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  function handleScroll(event: CustomEvent<ScrollDetail>) {
    if (event.detail.scrollTop !== 0 && event.detail.scrollTop > RELATIVE_INPUT_THRESHOLD) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }

  return (
    <IonPage>
      <IonHeader className={cx("header")}>
        <ToolbarMobile showSearchButton={showSearch} showShippingZone />
      </IonHeader>
      <IonContent className={cx("ion-padding-bottom")} scrollEvents onIonScroll={handleScroll}>
        <ToolbarDesktop showShippingZone />
        <div className={"global-content"}>
          <MaxWidthContainer>
            <div className={"ion-hide-md-up"}>
              <HomePageSearchBox placeholder={state.config.languageJson["Search in the store"]} />
            </div>
            {!appSettings?.wishListIcons ? (
              <>
                <HomePageCategories />
                <Banner />
              </>
            ) : (
              <>
                <Banner />
                <HomePageCategories />
              </>
            )}
            <div style={{ overflow: "hidden" }}>
              <HomeProductsWidget />
            </div>
          </MaxWidthContainer>
          {appSettings?.isSeoOnHomePageEnabled && (
            <MaxWidthContainer>
              <AboutCompanySEO />
            </MaxWidthContainer>
          )}
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export { Home3 };
