import React, { useCallback, useState } from "react";
import { SearchAddressInput, SearchAddressInputTypes } from "../SearchAddressInput/SearchAddressInput";
import { HEADQUARTERS, YandexMap } from "../MapComponent/YandexMap";
import { ParhatoAddressObject } from "../../providers/ParhatoRESTApi/ParhatoRESTApi";
import { useCustomerAddressesStateData } from "../../providers/CustomerAddresses/CustomerAddressesStateContext";
import { CustomerAddressType } from "../../generated/graphql";
import { getCustomerFullAddress } from "../../utils/utils";

interface ShippingAddressManuallyMapProps {
  setShowAddressForm: (newState: boolean) => void;
}

const AddressSearchMap = ({ setShowAddressForm }: ShippingAddressManuallyMapProps) => {
  const { customerAddress, setCustomerAddress } = useCustomerAddressesStateData();

  const onSetCustomerAddress = useCallback(
    (searchAddressResult: Partial<ParhatoAddressObject> | undefined) => {
      setCustomerAddress({
        id: customerAddress?.id || "",
        city: searchAddressResult?.locality || "",
        house: searchAddressResult?.house || "",
        street: searchAddressResult?.street || "",
        location: {
          coordinates: searchAddressResult?.location || HEADQUARTERS
        }
      } as CustomerAddressType);
    },
    [setCustomerAddress]
  );

  const showAddressForm = useCallback(() => {
    setShowAddressForm(true);
  }, [setShowAddressForm]);

  // When the "check" button is pressed, the address from the input disappears, and therefore it is done like this
  const [address, setAddress] = useState(
    customerAddress?.street ? getCustomerFullAddress(customerAddress, "for_YMap") : ""
  );

  const [userDraggingMap, setUserDraggingMap] = useState(false);

  return (
    <>
      <SearchAddressInput
        address={address}
        setUserDraggingMap={setUserDraggingMap}
        onOptionSelect={({ address }: SearchAddressInputTypes.SelectedOption) => {
          onSetCustomerAddress(address);
        }}
      />
      <div style={{ width: "100%", height: "88%" }}>
        <YandexMap
          userDraggingMap={userDraggingMap}
          setUserDraggingMap={setUserDraggingMap}
          setAddress={setAddress}
          mapState={{
            center: customerAddress?.location?.coordinates || HEADQUARTERS,
            zoom: 17
          }}
          showAddressForm={showAddressForm}
          onSetCustomerAddress={(address) => onSetCustomerAddress(address)}
        />
      </div>
    </>
  );
};

export { AddressSearchMap };
