import BaseClient from "../base-client";
import { IShippingZonesClient } from "../../types/clients";
import { ShippingZone } from "../../types/entities";
import { ShippingZonesDocument, ShippingZonesQuery } from "../../../generated/graphql";
import { client } from "../../../apollo";

type ZONE_TYPE = ShippingZonesQuery["customerShippingZones"]["edges"][number]["node"];

class ShippingZonesClient extends BaseClient implements IShippingZonesClient {
  async getShippingZones() {
    const zones: ZONE_TYPE[] = await client
      .query<ShippingZonesQuery>({
        query: ShippingZonesDocument,
      })
      .then((res) => {
        if (res.data) {
          return res.data.customerShippingZones.edges.map((item) => item.node);
        }

        return [];
      });

    const data: ShippingZone[] = zones.map(({ code, title }) => ({ id: code, name: title }));
    return data;
  }
}

export default ShippingZonesClient;
