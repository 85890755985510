import React from 'react';

// Import Redux
import { currencyTransform } from "../../../providers/Redux/Reducers/reducers2";

// Import Ionic
import { IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react";

interface ITotalPrice {
  languageJson: { [key: string]: any },
  subtotal: number,
  total: number,
  discount: number
}
// calculation result
const TotalPrice: React.FC<ITotalPrice> = ({
  languageJson,
  subtotal,
  total,
  discount,
}) => {
  return <IonCard>
    <IonCardContent className="ion-padding">
      <IonRow>
        <IonCol size="6" sizeLg={"7"}>
          {languageJson.SubTotal}
        </IonCol>
        <IonCol size="6" sizeLg={"5"} class="ion-text-right">
          {currencyTransform(subtotal)}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6">
          {languageJson.Discount}
        </IonCol>
        <IonCol size="6" class="ion-text-right">
          {currencyTransform((discount))}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6">
          {languageJson.Total}
        </IonCol>
        <IonCol size="6" class="ion-text-right">
          {currencyTransform(total)}
        </IonCol>
      </IonRow>
    </IonCardContent>
  </IonCard>
};

export default TotalPrice;