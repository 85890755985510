import React, { useCallback, useEffect, useRef, useState } from "react";

import { IonIcon, IonSelect, IonSelectOption, useIonToast, IonPopover } from "@ionic/react";
import { chevronDown } from "ionicons/icons";

import classNames from "classnames/bind";
import styles from "./SelectShippingZone.module.scss";

import { useShippingZonesData } from "../../providers/shippingZones";
import { ShippingZonesModalWidget } from "../../widgets/ShippingZonesModalWidget/ShippingZonesModalWidget";
import { useGetSettingsQuery } from "../../providers/settings";
import { getCurrentLanguage } from "../../providers/Redux/Reducers/state";
import { useGetShippingZonesQuery } from "../../providers/shippingAndPayment";

const cx = classNames.bind(styles);
const ts = getCurrentLanguage();

interface SelectShippingZoneProps {
  interfaceType?: "action-sheet" | "popover";
}

const SelectShippingZone = ({ interfaceType = "action-sheet" }: SelectShippingZoneProps) => {
  const [presentToast] = useIonToast();
  const selectRef = useRef<HTMLIonSelectElement>(null);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const { shippingZone, setShippingZone } = useShippingZonesData();

  const openModal = () => {
    setIsModalOpened(true);
    selectRef!.current?.click()
  };

  const { data: appSettings } = useGetSettingsQuery();
  const presentErrorToast = useCallback(() => {
    presentToast({
      message: ts["An unexpected error occurred"],
      duration: 2000,
      color: "danger",
    });
  }, [presentToast]);

  const { data: shippingZones } = useGetShippingZonesQuery({
    onError: presentErrorToast,
    enabled: appSettings?.isShippingZonesEnabled && appSettings?.showDefaultShippingZone,
  });

  useEffect(() => {
    if (interfaceType === "popover" && shippingZones?.length && !shippingZone) {
      setShippingZone(shippingZones[0]);
    }
  }, [shippingZones]);

  return (
    <>
      <span onClick={openModal} className={cx("shippingZone flex items-center justify-center cursor-pointer")}>
        {interfaceType === "action-sheet" && (
          <>
            <span className={cx("shippingZone__name")}>{shippingZone?.name}</span>
            <IonIcon className={cx("icon-12")} icon={chevronDown} />
            {isModalOpened ? <ShippingZonesModalWidget setIsModalOpened={setIsModalOpened} /> : null}
          </>
        )}
        {interfaceType === "popover" && !!shippingZones && (
          <>
            <IonSelect
              ref={selectRef}
              value={shippingZone?.id}
              className={cx("shippingZone__select")}
              onIonChange={(e) => {
                const zone = shippingZones!.find((_zone) => _zone.id === e.detail.value);
                setShippingZone(zone);
              }}
              interface={"popover"}
            >
              {shippingZones?.map((zone) => (
                <IonSelectOption key={zone.id} value={zone.id}>
                  {zone.name}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonIcon className={cx("icon-10")} icon={chevronDown} />
          </>
        )}
      </span>
    </>
  );
};

export { SelectShippingZone };
