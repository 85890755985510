import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle } from "@ionic/react";
import { Product as TProduct } from "../../../data/types/entities";
import styles from "../../../components/ProductDetail/ProductDetail.module.scss";
import { getLowestPriceVariation } from "../../shared/utils";
import { AttributeBadge } from "../../shared/AttributeBadge/AttributeBadge";

type TAttributes = { [k: string]: Set<string> };

interface ISelectVariationAttributeProps {
  variations: TProduct[];
  onVariationSelect: (variation: TProduct | null) => void;
}

const getAttributesFromSearchParams = (urlParams: string): { [key: string]: string } | undefined => {
  if (urlParams && urlParams.startsWith("?")) {
    const attrs = urlParams
      .substring(1)
      .split("&")
      .reduce((acc, attr) => {
        const keyAndValueOfAttr = attr.split("=").map((el) => decodeURI(el));

        if (keyAndValueOfAttr.every((el) => !!el.length)) {
          return { ...acc, [keyAndValueOfAttr[0]]: keyAndValueOfAttr[1] ?? "" };
        } else {
          return acc;
        }
      }, {});

    return attrs;
  }

  return undefined;
};

const cx = classNames.bind(styles);

const SelectVariationAttribute: React.FC<ISelectVariationAttributeProps> = ({ variations, onVariationSelect }) => {
  const { search } = useLocation();
  const [chosenAttributes, setChosenAttributes] = useState<{ [key: string]: string } | undefined>(
    getAttributesFromSearchParams(search)
  );
  const history = useHistory();

  const { attributes, instockVariations } = useMemo(() => {
    const attributes: TAttributes = {};

    variations?.forEach((variation) => {
      variation.attributes.forEach((attribute) => {
        if (!attributes[attribute.name]) {
          attributes[attribute.name] = new Set();
        }

        attributes[attribute.name].add(attribute.option ?? "");
      });
    });

    const instockVariations = variations.filter((variation) => variation.stockStatus === "instock");
    return { attributes, instockVariations };
  }, [variations]);

  const handleClick = (attributeName: string, value: string) => {
    if (
      chosenAttributes &&
      chosenAttributes.hasOwnProperty(attributeName) &&
      chosenAttributes[attributeName] === value
    ) {
      const newAttrs = Object.assign({}, chosenAttributes);
      delete newAttrs[attributeName];
      setChosenAttributes(newAttrs);
    } else {
      setChosenAttributes({ ...chosenAttributes, [attributeName]: value });
    }
  };

  const isAttributeChosen = (name: string, value: string) => {
    if (chosenAttributes && chosenAttributes.hasOwnProperty(name)) {
      return chosenAttributes[name] === value;
    }
  };

  const availableVariationAttributes = useMemo(() => {
    return instockVariations.map((variation) => {
      const attrs = variation.attributes.map(({ name, option }) => {
        return { [name]: option };
      });

      return attrs.reduce((acc, attr) => {
        return { ...acc, ...attr };
      }, {});
    });
  }, [instockVariations]);

  const isAttributeAvailable = (name: string, value: string) => {
    const newAttributes = { ...chosenAttributes, [name]: value };
    return availableVariationAttributes.some((variationAttributes) => {
      return Object.keys(newAttributes).every((newAttributeName) => {
        return variationAttributes[newAttributeName] === newAttributes[newAttributeName];
      });
    });
  };

  const getParamsFromChosenAttributes = (chs: typeof chosenAttributes) => {
    if (chs) {
      if (!Object.keys(chs).length) {
        return undefined;
      }

      return `?${Object.keys(chs)
        .map((attr) => {
          return `${encodeURI(attr)}=${encodeURI(chs[attr])}`;
        })
        .join("&")}`;
    }
  };

  const setAttributesFromSearchParams = (urlParams: string): void => {
    const attrs = getAttributesFromSearchParams(urlParams);
    if (attrs) {
      setChosenAttributes(attrs);
    }
  };

  const changeVariaion = () => {
    const newSelectVariation = instockVariations.find((variation) => {
      return variation.attributes.every((variationAttribute) => {
        if (chosenAttributes) {
          // only for ts skipping, it is always true
          return variationAttribute.option === chosenAttributes[variationAttribute.name];
        }
      });
    });

    if (newSelectVariation) {
      onVariationSelect(newSelectVariation);
    }
  };

  useEffect(() => {
    setAttributesFromSearchParams(search);
    if (!chosenAttributes && !Object.keys(chosenAttributes ?? {}).length && !search) {
      const lowestPriceVariationAttributes = getLowestPriceVariation(variations)?.attributes.reduce((acc, attr) => {
        return { ...acc, [attr.name]: attr.option ?? "" };
      }, {});
      if (lowestPriceVariationAttributes && Object.keys(lowestPriceVariationAttributes).length) {
        setChosenAttributes(lowestPriceVariationAttributes); // use state on use effect
      }
    }
  }, [search, variations]);

  useEffect(() => {
    if (getParamsFromChosenAttributes(chosenAttributes) !== search) {
      history.replace({ pathname: history.location.pathname, search: getParamsFromChosenAttributes(chosenAttributes) });
    }
    changeVariaion();
  }, [chosenAttributes]);

  return (
    <>
      {attributes &&
        Object.keys(attributes).map((attributeName) => (
          <IonCard className={cx("attributesCard")} key={attributeName}>
            <IonCardHeader className={cx("attributesCardHeader")}>
              <IonCardSubtitle>{attributeName}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent className={cx("attributesCardContent")}>
              {[...attributes[attributeName]].map((value) => {
                return (
                  <span
                    key={value}
                    className={cx("attributesCardSpan", {
                      attributeIsDisabled: !isAttributeAvailable(attributeName, value),
                    })}
                  >
                    <AttributeBadge
                      isColorPrimary={!!isAttributeChosen(attributeName, value)}
                      isDisabled={!isAttributeAvailable(attributeName, value)}
                      onClick={() => {
                        if (isAttributeAvailable(attributeName, value)) {
                          handleClick(attributeName, value);
                        }
                      }}
                      value={value}
                    />
                  </span>
                );
              })}
            </IonCardContent>
          </IonCard>
        ))}
    </>
  );
};

export { SelectVariationAttribute };
