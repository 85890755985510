import BaseClient from "../base-client";
import { IShippingMethodsClient } from "../../types/clients";
import { PickupPoint, ShippingMethod, ShippingMethodDeliveryType, ShippingMethodType } from "../../types/entities";
import { IrsKomPickupPoint } from "./types";

class ShippingMethodsClient extends BaseClient implements IShippingMethodsClient {
  async getShippingMethods(): Promise<ShippingMethod[]> {
    const methodDefaultFields = {
      minAmount: "0",
      cost: "0",
      enabled: true,
    };

    const pickupPointMethod = {
      id: "1",
      title: "ПВЗ Ирском",
    };

    const deliveryMethod = {
      id: "2",
      title: "Курьерская доставка",
    };

    const data: ShippingMethod[] = [
      {
        id: pickupPointMethod.id,
        description: pickupPointMethod.title,
        methodType: ShippingMethodType.pickupPoint,
        title: pickupPointMethod.title,
        methodDeliveryType: ShippingMethodDeliveryType.default,
        methodId: pickupPointMethod.id,
        ...methodDefaultFields,
      },
      {
        id: deliveryMethod.id,
        description: deliveryMethod.title,
        methodType: ShippingMethodType.courier,
        title: deliveryMethod.title,
        methodDeliveryType: ShippingMethodDeliveryType.default,
        methodId: deliveryMethod.id,
        ...methodDefaultFields,
      },
    ];
    return data;
  }

  async getPickupPoints(): Promise<PickupPoint[]> {
    const response = await this.instance.get<IrsKomPickupPoint[]>("/order/points");
    const data: PickupPoint[] = response.data.map((point) => ({
      ...point,
      latitude: String(point.latitude),
      longitude: String(point.longitude),
    }));

    return data;
  }
}

export default ShippingMethodsClient;
