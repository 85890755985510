import React from 'react';

// Import Ionic
import {IonCheckbox, IonItem, IonLabel} from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./Checkbox.module.scss";

const cx = classNames.bind(styles);

const Checkbox = ({label = '', checked = false, itemClassName = '', checkboxClassName = '' , ...props}) => {
    return (
        <IonItem className={cx("checkboxItem", itemClassName)} lines="none">
            <IonCheckbox
                {...props}
                className={cx("checkboxItem_checkbox", checkboxClassName)}
                mode="md"
                color="primary"
                checked={checked}
            />
            <IonLabel className={cx("checkboxItem_label")}>{label}</IonLabel>
        </IonItem>
    );
};

export default Checkbox;