import React, { useContext, useEffect, useState } from "react";
import { IonAlert, IonApp, IonRouterOutlet, setupIonicReact, useIonAlert } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/secondTheme.scss";
import "./global.scss";

/* Application Pages And Components */
import { Home3 } from "./pages/home-pages/Home3";
import Products from "./pages/products/Products";
import Wishlist from "./pages/wish-list/Wishlist";
import Cart from "./pages/cart/cart";
import ContactUs from "./pages/contacts/ContactUs";
import { MyAccountPage } from "./pages/my-account/my-account";
import ThankYou from "./pages/thank-you/thank-you";
import ForgotPassword from "./pages/modals/forgot-password/ForgotPassword";
import Login from "./pages/modals/login/Login";
import SignUp from "./pages/modals/sign-up/SignUp";
import Order from "./pages/order/order";
import MyOrders from "./pages/my-orders/my-orders";
import MyOrderDetail from "./pages/my-order-detail/my-order-detail";
import ProductDetail from "./pages/product-detail/product-detail";
import WooComAPI from "./providers/WooComAPI/woo-com-api";
import SubCategories from "./pages/subCategories/subCategories";
import { ResetPassword } from "./pages/modals/ResetPassword/ResetPassword";

/* Application Providers And Events For State Management And Communication Between Components */
import { AppContext, AppContextProvider } from "./providers/Redux/Reducers/AppContext";
import { history } from "./providers/Redux/Reducers/history";
import { AppMetricaConfigInterface, AppMetricaProvider } from "./providers/AppMetrica/AppMetrica";
import { AppMetricaErrorBoundary } from "./providers/AppMetrica/AppMetricaErrorBoundary";

/* Native Plugins */
import { Network } from "@ionic-native/network";
import { Capacitor } from "@capacitor/core";
import { App as AppPlugin } from "@capacitor/app";
import { AppLauncher } from "@capacitor/app-launcher";

import Tabs from "./components/MyTabs/Tabs";
import SearchMainCategories from "./pages/searchMainCategories/searchMainCategories";

import { Preferences } from "@capacitor/preferences";
import { isPlatform } from "@ionic/core";
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";
import { useRegisterCustomerAndDeviceMutation } from "./generated/graphql";
import { ShippingAddressPage } from "./pages/address-pages/shipping-address/ShippingAddressPage";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/query-client";
import { useGetSettingsQuery } from "./providers/settings";
import { AppSettings } from "./data/types/entities";
import { AuthProvider, useAuth } from "./contexts/auth-context";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { CustomerAddressesStateProvider } from "./providers/CustomerAddresses/CustomerAddressesStateContext";
import { CustomerAddressesDataProvider } from "./providers/CustomerAddresses/CustomerAddressesDataContext";
import { PushNotificationData } from "./types";
import AppUrlListener from "./utils/components/AppUrlListener";
import { useCartData } from "./providers/cartDataStore";
import { LocalNotificationSchema, LocalNotifications } from "@capacitor/local-notifications";
import {
  addLocalNotifications,
  cancelAllLocalNotifications,
  clearLocalNotifications,
} from "./utils/local-notifications";
import { AuthBySms } from "./pages/authBySms/AuthBySms";
dayjs.extend(duration);

setupIonicReact({
  mode: "ios",
  animated: !isPlatform("desktop"),
});

export const codeBaseVersion = process.env.REACT_APP_PARHATO_CODE_VERSION!;

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppContextProvider>
            <AppMetricaErrorBoundary>
              <CustomerAddressesDataProvider>
                <CustomerAddressesStateProvider>
                  <IonicApp />
                </CustomerAddressesStateProvider>
              </CustomerAddressesDataProvider>
            </AppMetricaErrorBoundary>
          </AppContextProvider>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ApolloProvider>
  );
};

const IonicApp: React.FC = () => {
  const { state, dispatch } = useContext(AppContext); // For Store
  // eslint-disable-next-line
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const { user } = useAuth();
  const [registerCustomerAndDevice] = useRegisterCustomerAndDeviceMutation({
    notifyOnNetworkStatusChange: true,
  });

  const [presentAlert] = useIonAlert();
  const [pushToken, setPushToken] = useState("");
  const { data: appSettings } = useGetSettingsQuery();

  const { getCartTotal } = useCartData();

  useEffect(() => {
    if (WooComAPI.accessToken && pushToken && !!user) {
      registerCustomerAndDevice({
        variables: {
          customer: {
            email: user.email!,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phone!,
            username: user.userName!,
          },
          device: {
            pushToken: pushToken,
            appVersion: Number(codeBaseVersion),
          },
        },
      });
    }
  }, [pushToken, registerCustomerAndDevice, user]);

  useEffect(() => {
    const showAlert = async (settings: AppSettings) => {
      const appInfo = await AppPlugin.getInfo();
      const isIOS = isPlatform("ios");

      let minVersion = isIOS ? Number(settings.minVersionIOS) : Number(settings.minVersionAndroid);

      if (Number(codeBaseVersion) < minVersion) {
        const toMarketUrl = isIOS
          ? `itms-apps://itunes.apple.com/app/id${appInfo.id}`
          : `market://details?id=${appInfo.id}`;
        presentAlert({
          header: settings.newVersionAlertTitle ?? state.config.languageJson["Please update the app"],
          message: settings.newVersionAlertMessage ?? state.config.languageJson["A new version has been released..."],
          backdropDismiss: false,
          keyboardClose: false,
          buttons: [
            {
              text: settings.newVersionAlertButtonText ?? state.config.languageJson["Update"],
              handler: () => {
                AppLauncher.openUrl({ url: toMarketUrl });
                return false;
              },
            },
          ],
        });
      }
    };

    if (appSettings && appSettings.showNewVersionAlert && Capacitor.isNativePlatform()) {
      showAlert(appSettings);
    }
  }, [appSettings, presentAlert, state.config.languageJson]);

  const initAppMetrica = () => {
    if (process.env.REACT_APP_APPMETRICA_API_KEY) {
      const configuration: AppMetricaConfigInterface = {
        sessionTimeout: 15,
        locationTracking: false,
      };

      AppMetricaProvider.initialize(configuration);

      history.listen((location) => {
        AppMetricaProvider.reportEvent(location.pathname);
      });
    }
  };

  const initPushNotifications = async () => {
    try {
      const permission = await FirebaseMessaging.requestPermissions();
      if (permission.receive === "granted") {
        const tokenRequest = await FirebaseMessaging.getToken();
        setPushToken(tokenRequest.token);

        FirebaseMessaging.subscribeToTopic({ topic: "all" });
        FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
          if (event.notification.data) {
            const data = event.notification.data as PushNotificationData;
            if (data.type === "OPEN_PAGE") {
              history.push(data.path);
            }
            if (data.type === "OPEN_LINK") {
              window.open(data.path);
            }
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const initLocalPushes = async (delayMinutes: number | undefined) => {
    const notificationPermissions = (await LocalNotifications.checkPermissions()).display;

    if (delayMinutes) {
      AppPlugin.addListener("pause", async () => {
        const getCartTotalValue = getCartTotal();

        if (getCartTotalValue > 0) {
          const notifications: LocalNotificationSchema[] = [
            {
              id: dayjs().unix(),
              schedule: {
                at: dayjs().add(delayMinutes, "m").toDate(),
                allowWhileIdle: true,
              },
              title: state.config.languageJson["Goods in the cart"],
              body: state.config.languageJson[
                "There are products left in your shopping cart. Click to complete your order."
              ],
              extra: {
                type: "OPEN_CART",
              },
            },
          ];
          addLocalNotifications(notifications);
        }
      });

      AppPlugin.addListener("resume", async () => {
        if ((await notificationPermissions) === "granted") {
          cancelAllLocalNotifications();
          clearLocalNotifications();
        }
      });
    }

    LocalNotifications.addListener("localNotificationActionPerformed", (actionPerformed) => {
      if (actionPerformed.notification.extra.type === "OPEN_CART") {
        history.push("/cart");
      }
    });
  };

  useEffect(() => {
    if (appSettings) {
      if (Capacitor.isNativePlatform() && process.env.REACT_APP_CLIENT === "PARHATO") {
        const delayMinutes = appSettings?.notifications?.cartRemindDelayMinutes;

        initLocalPushes(delayMinutes);

        // Set up firebase push notifications
        // Request permission to use push notifications if platform is native
        initPushNotifications();
      }

      saveDefaultCurrency();
      getLocalStorageData();
    }
  }, [getCartTotal, appSettings]);

  useEffect(() => {
    document.body.classList.add(process.env.REACT_APP_THEME || "");

    // For Internet Check Connected Or Disconnected
    let connectedToInternet = true;
    Network.onDisconnect().subscribe(() => {
      connectedToInternet = false;
      setShowAlert1(true);
    });
    Network.onConnect().subscribe(() => {
      if (!connectedToInternet) {
        window.location.reload();
        setShowAlert2(true);
      }
    });

    if (Capacitor.isNativePlatform() && process.env.REACT_APP_CLIENT === "PARHATO") {
      initAppMetrica();
    }
  }, []);

  // For Getting Local Storage Data
  const getLocalStorageData = async () => {
    //getting wishList items from local storage
    const val4: any = await Preferences.get({ key: "wishListProducts" });
    if (val4.value != null) {
      dispatch({
        type: "ADD_WISHLIST_PRODUCTS",
        wishListProducts: JSON.parse(val4.value),
        localStorage: true,
      });
    }
    //---------------- end -----------------
  };

  // For Save Default Currency
  const saveDefaultCurrency = () => {
    if (localStorage.appStartFirstTime === undefined) {
      localStorage.currencyDefault = localStorage.currency; //default currency symbol to show in app
      localStorage.currencyCodeDefault = localStorage.currencyCode; //default currency code
      localStorage.currencyPosDefault = localStorage.currencyPos; //default currency position
      localStorage.decimalsDefault = localStorage.decimals; //default currency decimal
      localStorage.appStartFirstTime = "started";
    }
  };

  const theme =
    process.env.REACT_APP_THEME === "secondTheme"
      ? process.env.REACT_APP_THEME
      : `secondary-primary
  ${state.config.colorTheme === "primary-theme" ? "primary-theme" : ""}
  ${state.config.colorTheme === "white" ? "white-theme" : ""}
  ${state.config.colorTheme === "black" ? "black-theme" : ""}
  ${state.config.colorTheme === "green" ? "green-theme" : ""}
  ${state.config.colorTheme === "plum" ? "plum-theme" : ""}
  ${state.config.colorTheme === "magnesium" ? "magnesium-theme" : ""}
  ${state.config.colorTheme === "salmon" ? "salmon-theme" : ""}
  ${state.config.colorTheme === "blue" ? "blue-theme" : ""}
  ${state.config.colorTheme === "pink" ? "pink-theme" : ""}
  ${state.config.colorTheme === "orange" ? "orange-theme" : ""}
  ${state.config.colorTheme === "maroon" ? "maroon-theme" : ""}
  ${state.config.colorTheme === "cayanne" ? "cayanne-theme" : ""}
  ${state.config.colorTheme === "red" ? "red-theme" : ""}
  ${state.config.colorTheme === "theme15" ? "theme15-theme" : ""}
  ${state.config.colorTheme === "theme16" ? "theme16-theme" : ""}
  ${state.config.colorTheme === "theme17" ? "theme17-theme" : ""}
  ${state.config.colorTheme === "theme18" ? "theme18-theme" : ""}
  ${state.config.colorTheme === "sea" ? "sea-theme" : ""}
  ${localStorage.modeTheme === "dark" ? "dark-theme" : ""}
  ${localStorage.modeTheme === "light" ? "light-theme" : ""}`;

  return (
    <IonApp className={`${theme}`}>
      <IonAlert
        isOpen={showAlert1}
        onDidDismiss={() => setShowAlert1(false)}
        header={state.config.languageJson["Disconnected"]}
        message={state.config.languageJson["Please Connect to the Internet!"]}
        buttons={["OK"]}
      />
      <IonAlert
        isOpen={showAlert2}
        onDidDismiss={() => setShowAlert2(false)}
        header={state.config.languageJson["Connected"]}
        message={state.config.languageJson["Network connected Reloading Data"]}
        buttons={["OK"]}
      />
      <IonReactRouter history={history}>
        <AppUrlListener />
        <Tabs />
        <IonRouterOutlet id="main">
          <Redirect exact from="/" to="/home3" />
          <Redirect exact from="/home" to="/home3" />

          {/* For Side Menu Routes */}
          <Route exact={true} path="/cart" component={Cart} />
          <Route exact={true} path="/product-detail/:id" component={ProductDetail} />

          <Route exact={true} path="/home3" component={Home3} />

          <Route exact={true} path="/categories/:parent/subcategories" component={SubCategories} />

          <Route exact={true} path="/products/:id/:name/:type" component={Products} />
          <Route exact={true} path="/wish-list" component={Wishlist} />

          <Route exact={true} path="/contact-us" component={ContactUs} />

          <Route exact={true} path="/my-account" component={MyAccountPage} />
          <Route exact={true} path="/thank-you" component={ThankYou} />

          <Route exact={true} path="/shipping-address" component={ShippingAddressPage} />

          {/* TODO: auth compnents */}
          <Route exact={true} path="/forgot-password" component={ForgotPassword} />
          <Route exact={true} path="/reset-password" component={ResetPassword} />
          {/*<Route exact={true} path="/sign-up" component={SignUp} />*/}
          <Route exact={true} path="/auth" component={AuthBySms} />
          <Route exact={true} path="/login/:name" component={Login} />

          {/* TODO: Setting Search SearchMainCategories RewardPoints useless*/}
          <Route exact={true} path="/search-main-categories" component={SearchMainCategories} />

          <Route exact={true} path="/order" component={Order} />
          <Route exact={true} path="/my-orders" component={MyOrders} />
          <Route exact={true} path="/my-order-detail/:id" component={MyOrderDetail} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
