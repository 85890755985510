import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { usersClient } from "../../data/clients";
import { User } from "../../data/types/entities";

type TParams = {
  options?: UseQueryOptions<User, any, User, any>;
  userId?: string;
};

const useGetProfileQuery = (
  config: TParams = {},
): UseQueryResult<User, any> => {
  return useQuery(
    ["get-profile", config],
    async () => {
      const data = await usersClient.getProfile(config.userId ?? "");

      return data;
    },
    {
      ...config?.options,
    },
  );
};

export default useGetProfileQuery;
