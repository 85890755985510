import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

// Import Ionic
import {
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";

// Import Styles
import styles from "./SearchBox.module.scss";
import classNames from "classnames/bind";

// Import Components
import { searchIcon } from "../assets/icons/icons";
import { closeOutline, search } from "ionicons/icons";
import { Image } from "../Image/Image";
import useSearchProductsInfiniteQuery from "../../providers/products/useSearchProductsInfiniteQuery";
import { getCurrentCurrencySymbol } from "../../utils";

const cx = classNames.bind(styles);

const SearchBox: React.FC = () => {
  const { state } = useContext(AppContext);

  const history = useHistory();

  const [presentToast] = useIonToast();
  const [queryText, setQueryText] = useState("");
  const [searchProduct, setSearchProduct] = useState<string>("");

  const [showProducts, setShowProducts] = useState<boolean>(false);

  const inputRef = useRef<HTMLIonInputElement>(null);

  const {
    fetchNextPage,
    hasNextPage,
    isFetching,
    data: searchResult,
  } = useSearchProductsInfiniteQuery({
    options: {
      enabled: queryText.length > 3,
    },
    params: {
      keyword: queryText,
    },
  });

  useIonViewWillEnter(() => {
    setShowProducts(false);
    setSearchProduct("");
  }, []);

  function getMoreProducts() {
    fetchNextPage();
  }

  const cleanSearchInput = () => {
    setSearchProduct("");
    setShowProducts(false);
  };

  function showProductDetail(id: string) {
    history.push("/product-detail/" + id);
  }

  function handleSearchInput(value: string) {
    if (value === queryText) {
      setShowProducts(true);
    }

    if (value.length > 3) {
      setQueryText(value);
    }
  }

  useEffect(() => {
    if (!queryText.length) return;

    if (!!searchResult?.pages?.flat().length) {
      setShowProducts(true);
      inputRef.current!.setFocus();
    } else {
      setShowProducts(false);
    }

    if (!searchResult?.pages?.flat().length && searchProduct.length) {
      presentToast({
        message: state.config.languageJson["No Products Found"],
        duration: 2000,
        position: "top",
        color: "warning",
        cssClass: cx("presentWarning"),
      });
    }
  }, [searchResult]);

  return (
    <IonCol className={cx("ion-hide-md-down", "ion-no-padding", "searchBox__common")}>
      <div
        className={cx("searchBox", "searchBox__desktop")}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowProducts(false);
          }
        }}
      >
        <form
          className={cx("searchBox__wrapper")}
          onSubmit={(e) => {
            inputRef.current!.setFocus();
            e.preventDefault();
            handleSearchInput(searchProduct);
          }}
        >
          <IonInput
            autofocus
            ref={inputRef}
            value={searchProduct}
            type="search"
            placeholder={state.config.languageJson["Search in the store"]}
            debounce={2000}
            className={cx("searchBox__input", "ion-no-padding")}
            onIonInput={(e: any) => {
              if (!(e.target as HTMLInputElement).value.length) {
                cleanSearchInput();
              }
              setSearchProduct((e.target as HTMLInputElement).value);
            }}
            onIonChange={() => {
              handleSearchInput(searchProduct);
            }}
            onIonFocus={() => {
              if (searchProduct.length && searchResult?.pages.flatMap((el) => el).length) {
                setShowProducts(true);
              }
            }}
          />
          {searchProduct && (
            <div onClick={cleanSearchInput}>
              <div className={cx("cleanSearch", "cleanSearch--sm", "ion-hide-md-up")}>
                <IonIcon className={cx("cleanSearch__icon", "cleanSearch__icon--sm")} icon={closeOutline} />
              </div>
              <div className={cx("cleanSearch", "cleanSearch--md", "ion-hide-md-down")}>
                <IonIcon className={cx("cleanSearch__icon", "cleanSearch__icon--md")} icon={closeOutline} />
              </div>
            </div>
          )}
          {isFetching ? (
            <div className={cx("searchSpinner", "searchSpinner--md")}>
              <IonSpinner name={"crescent"} />
            </div>
          ) : (
            <button type="submit">
              <div className={cx("searchIcon", "searchIcon--sm", "ion-hide-md-up")}>
                <IonIcon className={cx("searchIcon__icon", "searchIcon__icon--sm")} icon={searchIcon} />
              </div>
              <div className={cx("searchIcon", "searchIcon--md", "ion-hide-md-down")}>
                <IonIcon className={cx("searchIcon__icon", "searchIcon__icon--md")} icon={search} />
              </div>
            </button>
          )}
        </form>

        {showProducts && (
          <div className={cx("searchBox__result")}>
            <IonList className={cx("searchBox__list")}>
              {searchResult?.pages
                ?.flatMap((el) => el)
                .map((product, key) => (
                  <IonItem
                    key={product.id}
                    onMouseDown={() => {
                      showProductDetail(product.id);
                    }}
                    className={cx("searchBox__item")}
                  >
                    <IonCol size={"3"} sizeSm={"1.7"} sizeMd={"1.5"}>
                      <Image image={product.images[0]} />
                    </IonCol>
                    <IonCol>
                      <IonItem lines={"none"}>
                        <IonLabel className={cx("searchBox__label")}>{product.name}</IonLabel>
                      </IonItem>
                      {product.stockStatus === "instock" ? (
                        <div className={cx("product__info")}>{getCurrentCurrencySymbol() + product.price}</div>
                      ) : (
                        <div className={cx("product__info", "product__info--error")}>
                          {state.config.languageJson["Out of Stock"]}
                        </div>
                      )}
                    </IonCol>
                  </IonItem>
                ))}
            </IonList>
            {hasNextPage && (
              <button
                disabled={isFetching}
                onClick={() => {
                  getMoreProducts();
                }}
                className={cx("searchBox__showMoreProducts")}
              >
                {state.config.languageJson[isFetching ? "Loading..." : "Show more"]}
              </button>
            )}
          </div>
        )}
      </div>
    </IonCol>
  );
};

export { SearchBox };
