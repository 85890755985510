import React, { useContext, useEffect, useMemo } from "react";

import { Controller, useForm } from "react-hook-form";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";

import CommonFormElement from "../UI/CommonFormElements/CommonFormElement";
import Select from "../Select/Select";
import ContinueButton from "../ContinueButton/ContinueButton";

import { IonInput, IonSelectOption } from "@ionic/react";

import classNames from "classnames/bind";
import styles from "./AddressForm.module.scss";
import commonFormStyles from "../UI/CommonFormElements/CommonFormElements.module.scss";
import componentStyles from "./AddressForm.module.scss";

import { useCustomerAddressesStateData } from "../../providers/CustomerAddresses/CustomerAddressesStateContext";
import { CustomerAddressInput } from "../../generated/graphql";
import { City } from "../../providers/CustomerAddresses/CustomerAddressesTypes";
import Checkbox from "../Checkbox/Checkbox";

const cx = classNames.bind(styles);
const cn = classNames.bind(componentStyles);

const commonForm = classNames.bind(commonFormStyles);

interface AddressFormProps {
  onSubmit: (data: any) => void;
  cities: City[];
}

const AddressForm = ({ onSubmit, cities }: AddressFormProps) => {
  const { customerAddress } = useCustomerAddressesStateData();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
    setValue,
  } = useForm<CustomerAddressInput>({
    defaultValues: { ...customerAddress, isFlat: true },
    mode: "onChange",
    shouldUnregister: true,
  });

  const { state } = useContext(AppContext);

  useEffect(() => {
    if (cities && customerAddress) {
      const city = cities.find((_city) => _city.name.includes(customerAddress.city));
      setValue("city", city?.name ?? null);
    }
  }, [cities, customerAddress]);

  const ts = useMemo(() => {
    return state.config.languageJson;
  }, [state.config.languageJson]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn("form")}>
      <Controller
        name="city"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <CommonFormElement
                label={ts["Locality"]}
                isValid={cities.some((city) => city.name === value)}
                errorMessage={cities.some((city) => city.name !== value) ? ts["Select locality"] : ""}
              >
                <Select
                  value={value}
                  isValid={cities.some((city) => city.name === value)}
                  disabled={cities.length < 1}
                  placeholder={ts["Select locality"]}
                  languageJson={state.config.languageJson}
                  onChange={(e: any) => {
                    onChange(e.detail.value);
                  }}
                >
                  {cities.map((city) => {
                    return (
                      <IonSelectOption key={city.id} value={city.name}>
                        {city.name}
                      </IonSelectOption>
                    );
                  })}
                </Select>
              </CommonFormElement>
            </>
          );
        }}
      />

      <Controller
        control={control}
        rules={{
          required: ts["Required field"],
        }}
        name="street"
        render={({ field: { onChange, value } }) => (
          <>
            <CommonFormElement label={ts.Street} isValid={!!value} errorMessage={errors.street?.message}>
              <IonInput
                type="text"
                value={value}
                onIonChange={(e) => {
                  onChange(e.detail.value!);
                }}
                placeholder={ts["Street input placeholder"]}
                className={commonForm("commonElement", { commonInvalid: !value?.length })}
              />
            </CommonFormElement>
          </>
        )}
      />

      <Controller
        control={control}
        name="house"
        rules={{
          required: ts["Required field"],
        }}
        render={({ field: { onChange, value } }) => (
          <>
            <CommonFormElement label={ts["House number"]} errorMessage={errors.house?.message} isValid={!!value}>
              <IonInput
                type="text"
                value={value}
                onIonChange={(e) => onChange(e.detail.value!)}
                placeholder={ts["House number input placeholder"]}
                className={commonForm("commonElement", { commonInvalid: !watch("house")?.length })}
              />
            </CommonFormElement>
          </>
        )}
      />
      <Controller
        control={control}
        name="isFlat"
        render={({ field: { onChange, value } }) => (
          <Checkbox
            label={ts["Delivery to the apartment"]}
            itemClassName={cx("checkboxItem")}
            checked={!!value}
            onIonChange={(e: any) => onChange(e.detail.checked)}
          />
        )}
      />

      <div className={cx("dropdown", { dropdown__active: !!watch("isFlat") })}>
        {!!watch("isFlat") && (
          <>
            <Controller
              control={control}
              name="entrance"
              rules={{
                required: ts["Required field"],
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <CommonFormElement label={ts["Entrance"]} isValid={!!value} errorMessage={errors.entrance?.message}>
                    <IonInput
                      type="text"
                      value={value}
                      onIonChange={(e) => onChange(e.detail.value!)}
                      placeholder={ts["Entrance input placeholder"]}
                      className={commonForm("commonElement", { commonInvalid: !watch("entrance")?.length })}
                    />
                  </CommonFormElement>
                </>
              )}
            />
            <Controller
              control={control}
              name="intercom"
              render={({ field: { onChange, value } }) => (
                <>
                  <CommonFormElement label={`${ts["Intercom code"]}  (${ts.optional})`}>
                    <IonInput
                      type="text"
                      value={value}
                      onIonChange={(e) => onChange(e.detail.value!)}
                      placeholder={ts["Intercom input placeholder"]}
                      className={commonForm("commonElement")}
                    />
                  </CommonFormElement>
                </>
              )}
            />
            <Controller
              control={control}
              name="floor"
              rules={{
                required: ts["Required field"],
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <CommonFormElement label={ts["Floor"]} errorMessage={errors.floor?.message} isValid={!!value}>
                    <IonInput
                      type="text"
                      value={value}
                      onIonChange={(e) => onChange(e.detail.value!)}
                      placeholder={ts["Floor input placeholder"]}
                      className={commonForm("commonElement", { commonInvalid: !watch("floor")?.length })}
                    />
                  </CommonFormElement>
                </>
              )}
            />
            <Controller
              control={control}
              name="apartmentNumber"
              rules={{
                required: ts["Required field"],
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <CommonFormElement
                    label={ts["Apartment number"]}
                    isValid={!!value}
                    errorMessage={errors.apartmentNumber?.message}
                  >
                    <IonInput
                      type="text"
                      value={value}
                      onIonChange={(e) => onChange(e.detail.value!)}
                      placeholder={ts["Apartment input number placeholder"]}
                      className={commonForm("commonElement", { commonInvalid: !watch("apartmentNumber")?.length })}
                    />
                  </CommonFormElement>
                </>
              )}
            />
          </>
        )}
      </div>
      <ContinueButton
        className={cx("modal__button")}
        onClick={() => false}
        type="submit"
        disabled={!isValid}
        buttonText={ts["Save Address"]}
      />
    </form>
  );
};

export { AddressForm };
