import React from "react";
import classNames from "classnames/bind";
import styles from "../../../components/ProductDetail/ProductDetail.module.scss";
import { IonBadge } from "@ionic/react";

const cx = classNames.bind(styles);

interface AttributeBadgeProps {
  value: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isColorPrimary?: boolean;
}

const AttributeBadge = ({ value, isDisabled = false, isColorPrimary = false, onClick }: AttributeBadgeProps) => {
  return (
    <IonBadge
      className={cx("attributesCardBadge", { disabled: isDisabled })}
      color={isColorPrimary ? "primary" : "medium"}
      mode="md"
      onClick={onClick}
    >
      {value}
    </IonBadge>
  );
};

export { AttributeBadge };
