import React from 'react';

// Import Ionic
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./OrderSummary.module.scss";

interface IOrderSummaryWhatsapp {
    title: string,
    whatsAppNumber: string,
}

const cx = classNames.bind(styles);

const OrderSummaryWhatsapp:React.FC<IOrderSummaryWhatsapp> = ({
    title,
    whatsAppNumber
    }) => {
    return (
        <IonCard className={cx('orderSummary')}>
            <IonCardHeader className={cx("orderSummaryHeader")} color="primary">
                <IonCardTitle className={cx('orderSummaryTitle')}>
                    {title}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className={cx("ion-padding ion-margin-top card-disable", "orderSummaryDescription")}>
                {whatsAppNumber}
            </IonCardContent>
        </IonCard>
    );
};

export default OrderSummaryWhatsapp;