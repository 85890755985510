import { ISettingsClient } from "../../types/clients";
import { AppSettings } from "../../types/entities";
import BaseClient from "../base-client";

class SettingsClient extends BaseClient implements ISettingsClient {
  async getSettings(): Promise<AppSettings> {
    return {
      aboutPageId: 6,
      privacyPageId: 3,
      termsPageId: 0,
      refundPageId: 0,

      homePage: 3,
      categoryPage: 4,
      introPage: 0,
      myOrdersPage: 0,
      newsPage: 0,
      wishListPage: 0,
      shippingAddressPage: 0,
      contactUsPage: undefined,
      editProfilePage: 0,
      settingPage: 0,
      addressPage: true,
      wishListIcons: false,
      downloadPage: false,
      cardProduct: true,

      multiLanguage: false,
      multiCurrency: false,

      showVendorInfo: false,
      showWcVendorInfo: false,

      enableGeoFencing: false,
      enableDeliveryTracking: false,
      enableWpPointReward: false,

      rateApp: 0,
      sharedData: {
        title: "Ирском",
        text: "Ирском - сеть магазинов и пунктов выдачи заказов\n\n",
        androidUrl: "https://play.google.com/store/apps/details?id=ru.irskom.app",
        iosUrl: "https://apps.apple.com/us/app/irskom/id6451446579",
      },
      shareApp: 0,
      defaultIcons: false,
      currentSettings: 19,
      checkOutPage: 2,

      email: "",
      latitude: "",
      longitude: "",
      newProductDuration: "20",

      siteUrl: "https://irskom.ru",
      orderCancelButton: false,
      cancelOrderTime: 1,
      minimumOrderAmount: 0,
      addToCart: "Add to cart",
      showNewVersionAlert: true,
      newVersionAlertTitle: "Пожалуйста обновите приложение",
      newVersionAlertMessage:
        "Вышла новая версия приложения, пожалуйста обновитесь до новой версии, чтобы вам были доступны все новые функции",
      newVersionAlertButtonText: "Обновить",
      minVersionAndroid: "24",
      minVersionIOS: "26",
      shouldCheckItemsAvailability: false,
      showProductReviews: false,
      showCouponsInCart: false,

      isBonusPointsEnabled: false,
      isShippingZonesEnabled: false,
      isDeliveryDateEnabled: false,
      orderHistoryPagination: false,
      isCustomerAdressesEnabled: false,
      isReferralEnabled: false,

      deleteAccountLink: "",
      showSendCustomerRequestButton: false,
      updateUserForm: {
        isUpdatePasswordEnabled: false,
        showPhoneNumberInput: false,
      },
      isOnlinePaymentEnabled: true,
      privacyPolicyPageLink: "https://irskom.ru/privacy.html",
      isSeoOnHomePageEnabled: false,
      companyPhoneNumber: "+7 (928) 888-10-10",
      isContentInFooterShown: false,
      hasCardLoyalty: true,
      showSliderCatalogButton: false,
      addressSettings: {
        showCustomerAddresses: false,
        showFlatDelivery: false,
        addressInputDependsOnShippingMethod: true,
      },
      homePageCategories: {
        customersChoice: { title: "Customers Choice", isEnabled: false },
        featured: { title: "Bestsellers", isEnabled: true },
        sale: { title: "Good price", isEnabled: true },
        latest: { title: "Latest Addest", isEnabled: true },
      },
      showDefaultShippingZone: false,
      showOrderPageProductCardCounter: true,
      cartMinAmountFromMethods: false,
      siteDomain: "irskom.ru",

      signUpPage: {
        nameFieldEnabled: true,
        lastNameFieldEnabled: true,
      },

      loginPage: {
        phoneOrEmailFieldEnabled: false,
      },

      orderDetailPage: {
        repeatOrderEnabled: false,
        reviewOrderEnabled: false,
      }
    };
  }
}

export default SettingsClient;
