import { IonButton, IonIcon } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router-dom";

const BackButton: React.FC = () => {
  let history = useHistory();
  return (
    <IonButton
      onClick={() => {
        history.goBack();
      }}
    >
      <IonIcon className="ion-no-margin" icon={chevronBack} />
    </IonButton>
  );
};

export default BackButton;
