import React, { useState } from "react";

// Import Ionic
import { IonButton, IonCard, IonCardContent, IonInput, IonItem, IonSpinner } from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./CouponInput.module.scss";

interface ICouponInput {
  languageJson: any;
  getCoupon: () => void;
  onInputCouponCode: (e: string) => void;
  setShowActionSheet: any;
  isCouponLoading?: boolean;
}

const cx = classNames.bind(styles);

//Блок ввода кода купона
const CouponInput: React.FC<ICouponInput> = ({
  languageJson,
  getCoupon,
  onInputCouponCode,
  isCouponLoading = false,
}) => {
  const [couponCode, setCouponCode] = useState("");

  const handleCodeInput = (value: string) => {
    setCouponCode(value);
    onInputCouponCode(value);
  };

  const handleApplyButtonClick = () => {
    getCoupon();
    setCouponCode("");
  };

  return (
    <IonCard className={cx("coupon", "ion-padding")}>
      <IonCardContent className="ion-no-padding">
        <IonItem>
          <IonInput
            placeholder={`${languageJson["coupon code"]}`}
            value={couponCode}
            onIonChange={(e) => handleCodeInput(e.detail.value ?? "")}
          />
          <IonButton color="secondary" slot="end" onClick={handleApplyButtonClick}>
            {isCouponLoading ? <IonSpinner name="crescent" className="w-4 h-4" /> : languageJson.Apply}
          </IonButton>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default CouponInput;
