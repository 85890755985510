import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { authClient } from "../../data/clients";

const useGetSmsCode = (options?: UseMutationOptions<any, any, { phone: string }>) =>
  useMutation(
    ["get-sms-code"],
    async ({ phone }) => {
      const data = await authClient.getSmsCode(phone);

      return data;
    },
    options
  );

export default useGetSmsCode;
