import React from "react";
import { IonButton, IonModal } from "@ionic/react";
import { useSmsVerificationData } from "../../providers/smsVerifivation";
import styles from "./AuthByCode.module.scss";
import classNames from "classnames/bind";
import { SubmitHandler, useForm } from "react-hook-form";
import { getCurrentLanguage } from "../../providers/Redux/Reducers/state";

type AuthByCodeProps = {
  isOpen: boolean;
  toggleIsOpen: () => void;
  auth: (code: string) => void;
  requestNewCode: () => void;
};

const cx = classNames.bind(styles);
const ts = getCurrentLanguage();

const AuthByCode = ({ isOpen, toggleIsOpen, auth, requestNewCode }: AuthByCodeProps) => {
  const { canGetNewCode, waitingRestTime } = useSmsVerificationData();
  const { handleSubmit, getValues, register } = useForm<{ code: string }>({
    mode: "onSubmit",
  });

  const cancelModal = () => {
    toggleIsOpen();
  };

  const getNewCode = () => {
    requestNewCode();
  };

  const handleForm: SubmitHandler<{ code: string }> = () => {
    const code = getValues("code");
    if (!code) return;
    auth(code);
  };

  return (
    <IonModal id="auth-code-modal" backdropDismiss={false} className={cx("auth-code-modal")} isOpen={isOpen}>
      <div className={cx("head")}>
        <h2 className={cx("head__title")}>{ts["Enter the code from the SMS message"]}</h2>
      </div>
      <div className={cx("message")}>
        {ts["If the code is not received, check the entered phone number and try again"]}
      </div>
      <form onSubmit={handleSubmit(handleForm)}>
        <div className={cx("input__wrapper")}>
          <input
            placeholder={ts["Enter confirmation code"]}
            type="text"
            minLength={4}
            maxLength={6}
            className={cx("input")}
            {...register("code", {
              required: true,
              minLength: 4,
              maxLength: 6,
              pattern: /[0-9]{4,6}/,
              validate: (value) => {
                return value.length <= 6;
              },
            })}
          />
        </div>
        <div className={cx("buttons")}>
          <IonButton type="button" className={cx("buttons__item", "cancel")} onClick={cancelModal}>
            <span className="alert-button-inner sc-ion-alert-ios">{ts["Close"]}</span>
          </IonButton>
          <IonButton
            onClick={getNewCode}
            disabled={!canGetNewCode}
            type="button"
            className={cx("buttons__item", "try-again", { disabled: !canGetNewCode })}
          >
            <span className={cx("try-again__span")}>
              {ts["Send again"]}
              <span className={cx("try-again__timer")}>{!canGetNewCode ? `(${waitingRestTime})` : ""}</span>
            </span>
          </IonButton>
          <input type="submit" hidden />
          <IonButton type="submit" className={cx("buttons__item", "continue")}>
            <span className="alert-button-inner sc-ion-alert-ios">{ts["Continue"]}</span>
          </IonButton>
        </div>
      </form>
    </IonModal>
  );
};

export { AuthByCode };
