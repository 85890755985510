import {
  LocalNotifications,
  LocalNotificationSchema,
  ScheduleResult,
} from "@capacitor/local-notifications";

export const addLocalNotifications = async (
  notifications: LocalNotificationSchema[],
): Promise<
  ScheduleResult | undefined
> => {
  const notificationPermissions = await LocalNotifications.checkPermissions();

  if (notificationPermissions.display === "granted") {
    return await LocalNotifications.schedule({ notifications });
  }
};

export const getPendingLocalNotifications = async () => {
  const getPendingNotifications = await LocalNotifications.getPending();

  const pendingNotifications = getPendingNotifications.notifications;

  return pendingNotifications && pendingNotifications.length > 0
    ? pendingNotifications
    : [];
};

export const cancelAllLocalNotifications = async () => {
  const pendgingNotis = await getPendingLocalNotifications();

  if (!pendgingNotis || pendgingNotis.length <= 0) {
    return;
  }

  await LocalNotifications.cancel({
    notifications: pendgingNotis.map((pendingNotification) => ({
      id: pendingNotification.id,
    })),
  });
};

export const clearLocalNotifications = async () => {
  const deliveredNotifications =
    (await LocalNotifications.getDeliveredNotifications()).notifications;

  if (!deliveredNotifications || deliveredNotifications.length <= 0) {
    return;
  }

  await LocalNotifications.removeDeliveredNotifications({
    notifications: deliveredNotifications,
  });
};
