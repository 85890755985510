import React from 'react';

// Import Ionic
import {IonButton, IonCol, IonGrid, IonIcon, IonRow} from "@ionic/react";

// Import Icons
import {basket} from "ionicons/icons";

interface IIsEmptyCart {
    openProductsPage: () => void,
    languageJsonCartIsEmpty: any,
    languageJsonContinueShopping: any,
    languageJsonExplore: any,
}

const IsEmptyCart: React.FC<IIsEmptyCart> = ({
        languageJsonCartIsEmpty,
        languageJsonContinueShopping,
        languageJsonExplore,
        openProductsPage
    }) => {
    return <IonGrid class="ion-no-padding grid-cart">
            <IonRow>
                <IonCol className="ion-text-center" size="12">
                    <IonIcon className="no-margin-icon" icon={basket}/>
                </IonCol>
                <IonCol className="ion-text-center" size="12">
                    <h4>
                        {languageJsonCartIsEmpty}
                    </h4>
                </IonCol>
                <IonCol className="ion-text-center" size="12">
                    <h5>
                        {languageJsonContinueShopping}
                    </h5>
                </IonCol>
                <IonCol className="ion-text-center" size="12">
                    <IonButton onClick={() => openProductsPage()}>
                        {languageJsonExplore}
                    </IonButton>
                </IonCol>
            </IonRow>
        </IonGrid>
};

export default IsEmptyCart;