import { IBannersClient } from "../../types/clients";
import { Banner } from "../../types/entities";
import BaseClient from "../base-client";

class BannersClient extends BaseClient implements IBannersClient {
  async getBanners() {
    const response = await this.instance.get<any>(`banner/list/active?position=1`);

    const banners: Banner[] = response.data.map((banner: any) => {
      return {
        id: banner.id,
        imageSrc: banner.imageUrl,
      } as Banner
    });

    return banners;
  }
}

export default BannersClient;
