import React, { useEffect } from "react";

// Import Ionic
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonRow } from "@ionic/react";

import { currencyTransform } from "../../providers/Redux/Reducers/reducers2";

// Import Styles
import classNames from "classnames/bind";
import styles from "./OrderSummary.module.scss";
import { Coupon, CouponDiscountType } from "../../data/types/entities";
import { getCurrentLanguage } from "../../providers/Redux/Reducers/state";

interface IOrderSummaryCoupons {
  coupons: Coupon[];
  rewardPoints: number;
  setLoading?: () => void;
}

const cx = classNames.bind(styles);

const OrderSummaryCoupons: React.FC<IOrderSummaryCoupons> = ({ rewardPoints, coupons, setLoading }) => {
  const ts = getCurrentLanguage()!;

  useEffect(() => {
    if (setLoading) {
      setLoading();
    }
  }, []);

  return (
    <IonCard className={cx("orderSummary")}>
      <IonCardHeader className={cx("orderSummaryHeader")} color="primary">
        <IonCardTitle className={cx("orderSummaryTitle")}>{ts["Coupons Applied"]}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent className={cx("ion-padding ion-margin-top", "orderSummaryDescription")}>
        {rewardPoints > 0 && (
          <IonRow>
            <IonCol size="6">{ts["Bonus points"]}</IonCol>
            <IonCol size="6" className="ion-text-right">
              {rewardPoints}
            </IonCol>
          </IonRow>
        )}
        {coupons.map((coupon) => (
          <IonRow key={coupon.id}>
            <IonCol size="6">{coupon.code}</IonCol>
            <IonCol size="6" className="ion-text-right">
              {coupon.discountType === CouponDiscountType.PERCENT
                ? `%${parseFloat(String(coupon.discountAmount)).toFixed(2)}`
                : currencyTransform(coupon.discountAmount)}
            </IonCol>
          </IonRow>
        ))}
      </IonCardContent>
    </IonCard>
  );
};

export default OrderSummaryCoupons;
