import { AxiosResponse, AxiosInstance } from "axios";

export type PAxiosInstance = AxiosInstance & {
  token?: string;
};

export type BaseClientResponse<T = unknown, D = any> = AxiosResponse<T, D>;

class BaseClient {
  private readonly _instance: PAxiosInstance;

  constructor(instance: PAxiosInstance) {
    this._instance = instance;
  }

  get instance() {
    return this._instance;
  }
}

export default BaseClient;
