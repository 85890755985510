/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  Geometry: any;
  JSONString: any;
  Time: any;
  Upload: any;
};

export type AddChangeRequestToOrder = {
  __typename?: 'AddChangeRequestToOrder';
  order: Maybe<OrderType>;
};

export type AddDriverToBag = {
  __typename?: 'AddDriverToBag';
  bag: Maybe<BagType>;
};

export type AddDriverToOrdersBag = {
  __typename?: 'AddDriverToOrdersBag';
  ordersBag: Maybe<OrdersBagType>;
};

export type AddDriverToOrdersBagType = {
  bagCode: Scalars['String'];
};

export type AddOrderToBag = {
  __typename?: 'AddOrderToBag';
  bag: Maybe<BagType>;
  order: Maybe<OrderType>;
};

export type AddReviewToOrder = {
  __typename?: 'AddReviewToOrder';
  review: Maybe<ReviewType>;
};

export type AddressSuggestionResultType = {
  __typename?: 'AddressSuggestionResultType';
  fullAddress: Maybe<Scalars['String']>;
  location: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type AddressType = {
  __typename?: 'AddressType';
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  entrance: Scalars['String'];
  flat: Scalars['String'];
  floor: Scalars['String'];
  fullAddress: Scalars['String'];
  house: Scalars['String'];
  id: Scalars['ID'];
  location: Maybe<GeometryObjectType>;
  orderSet: OrderTypeConnection;
  street: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type AddressTypeOrderSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type AppointPickerForOrder = {
  __typename?: 'AppointPickerForOrder';
  order: Maybe<OrderType>;
};

export type AttachDriverToInterval = {
  __typename?: 'AttachDriverToInterval';
  interval: Maybe<IntervalType>;
};

/** An enumeration. */
export enum BagStatus {
  /** EMPTY */
  A_1 = 'A_1',
  /** WAITING_DELIVERY */
  A_2 = 'A_2',
  /** ON_DELIVERY */
  A_3 = 'A_3'
}

export type BagType = {
  __typename?: 'BagType';
  activeOrdersBag: Maybe<OrdersBagType>;
  code: Scalars['String'];
  id: Scalars['ID'];
  ordersbagSet: OrdersBagTypeConnection;
  status: BagStatus;
};


export type BagTypeOrdersbagSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type BagTypeConnection = {
  __typename?: 'BagTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BagTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BagType` and its cursor. */
export type BagTypeEdge = {
  __typename?: 'BagTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<BagType>;
};

export type ChangeOrderDeliveryStatus = {
  __typename?: 'ChangeOrderDeliveryStatus';
  order: Maybe<OrderType>;
};

export type ChangeOrderDeliveryStatusType = {
  deliveryStatus: OrderDeliveryStatus;
  orderId: Scalars['ID'];
};

export type CreateCustomerAddress = {
  __typename?: 'CreateCustomerAddress';
  address: Maybe<CustomerAddressType>;
};

export type CreateDriver = {
  __typename?: 'CreateDriver';
  driver: Maybe<DriverType>;
};

export type CreateOperator = {
  __typename?: 'CreateOperator';
  user: Maybe<UserType>;
};

export type CreatePicker = {
  __typename?: 'CreatePicker';
  picker: Maybe<PickerType>;
};

/**
 * Mutation that receives 'phone' and 'security_code'
 * and returns a jwt token
 */
export type CreateToken = {
  __typename?: 'CreateToken';
  token: Maybe<Scalars['String']>;
  user: Maybe<UserType>;
};

export type CustomerAddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  entrance?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['String']>;
  house?: InputMaybe<Scalars['String']>;
  intercom?: InputMaybe<Scalars['String']>;
  isFlat?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type CustomerAddressType = {
  __typename?: 'CustomerAddressType';
  apartmentNumber: Scalars['String'];
  city: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerId: Scalars['Int'];
  entrance: Scalars['String'];
  floor: Scalars['String'];
  house: Scalars['String'];
  id: Scalars['ID'];
  intercom: Scalars['String'];
  isFlat: Scalars['Boolean'];
  location: Maybe<GeometryObjectType>;
  state: Scalars['String'];
  street: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CustomerAddressTypeConnection = {
  __typename?: 'CustomerAddressTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerAddressTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomerAddressType` and its cursor. */
export type CustomerAddressTypeEdge = {
  __typename?: 'CustomerAddressTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CustomerAddressType>;
};

export type CustomerDeviceInput = {
  appVersion?: InputMaybe<Scalars['Int']>;
  pushToken: Scalars['String'];
};

export type CustomerInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type CustomerShippingMethodType = {
  __typename?: 'CustomerShippingMethodType';
  code: ShippingMethodCode;
  cost: Scalars['String'];
  deliveryintervalSet: DeliveryIntervalTypeConnection;
  description: Scalars['String'];
  id: Scalars['ID'];
  isEnabled: Scalars['Boolean'];
  maxDeliveryDays: Maybe<Scalars['Int']>;
  methodType: ShippingMethodMethodType;
  minAmount: Scalars['String'];
  minDeliveryDays: Maybe<Scalars['Int']>;
  shippingzoneshippingmethodSet: ShippingZoneShippingMethodTypeConnection;
  title: Scalars['String'];
};


export type CustomerShippingMethodTypeDeliveryintervalSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CustomerShippingMethodTypeShippingzoneshippingmethodSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CustomerShippingMethodTypeConnection = {
  __typename?: 'CustomerShippingMethodTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerShippingMethodTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomerShippingMethodType` and its cursor. */
export type CustomerShippingMethodTypeEdge = {
  __typename?: 'CustomerShippingMethodTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CustomerShippingMethodType>;
};

export type CustomerShippingZoneType = {
  __typename?: 'CustomerShippingZoneType';
  code: Scalars['String'];
  id: Scalars['ID'];
  shippingzoneshippingmethodSet: ShippingZoneShippingMethodTypeConnection;
  title: Scalars['String'];
};


export type CustomerShippingZoneTypeShippingzoneshippingmethodSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CustomerShippingZoneTypeConnection = {
  __typename?: 'CustomerShippingZoneTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerShippingZoneTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomerShippingZoneType` and its cursor. */
export type CustomerShippingZoneTypeEdge = {
  __typename?: 'CustomerShippingZoneTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CustomerShippingZoneType>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  createdAt: Scalars['DateTime'];
  customersdeviceSet: CustomersDeviceTypeConnection;
  devices: Maybe<Array<Maybe<CustomersDeviceType>>>;
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};


export type CustomerTypeCustomersdeviceSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type CustomersDeviceType = {
  __typename?: 'CustomersDeviceType';
  appVersion: Maybe<Scalars['Int']>;
  customer: CustomerType;
  device: DeviceType;
  id: Scalars['ID'];
};

export type CustomersDeviceTypeConnection = {
  __typename?: 'CustomersDeviceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomersDeviceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomersDeviceType` and its cursor. */
export type CustomersDeviceTypeEdge = {
  __typename?: 'CustomersDeviceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<CustomersDeviceType>;
};

export type DeleteCustomerAddress = {
  __typename?: 'DeleteCustomerAddress';
  addressId: Maybe<Scalars['ID']>;
};

export type DeleteDriver = {
  __typename?: 'DeleteDriver';
  driverId: Maybe<Scalars['ID']>;
};

export type DeleteOperator = {
  __typename?: 'DeleteOperator';
  operatorId: Maybe<Scalars['ID']>;
};

export type DeletePicker = {
  __typename?: 'DeletePicker';
  pickerId: Maybe<Scalars['ID']>;
};

export type DeliveryIntervalType = {
  __typename?: 'DeliveryIntervalType';
  id: Scalars['ID'];
  shippingMethod: Maybe<CustomerShippingMethodType>;
  timeEnd: Scalars['Time'];
  timeStart: Scalars['Time'];
};

export type DeliveryIntervalTypeConnection = {
  __typename?: 'DeliveryIntervalTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeliveryIntervalTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DeliveryIntervalType` and its cursor. */
export type DeliveryIntervalTypeEdge = {
  __typename?: 'DeliveryIntervalTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DeliveryIntervalType>;
};

export type DeviceType = {
  __typename?: 'DeviceType';
  createdAt: Scalars['DateTime'];
  customersdeviceSet: CustomersDeviceTypeConnection;
  id: Scalars['ID'];
  pushToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type DeviceTypeCustomersdeviceSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type DriverStatisticsType = {
  __typename?: 'DriverStatisticsType';
  bagsAmount: Maybe<Scalars['Int']>;
  driverEarnings: Maybe<Scalars['Float']>;
  ordersAmount: Maybe<Scalars['Int']>;
  ordersTotalCost: Maybe<Scalars['Float']>;
  totalDistance: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export enum DriverStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** ARCHIVED */
  Archived = 'ARCHIVED',
  /** BANNED */
  Banned = 'BANNED',
  /** PENDING */
  Pending = 'PENDING'
}

export type DriverType = {
  __typename?: 'DriverType';
  driverIntervals: Array<IntervalDriverType>;
  id: Scalars['ID'];
  ordersbagSet: OrdersBagTypeConnection;
  status: DriverStatus;
  user: UserType;
  vehicle: Maybe<VehicleType>;
};


export type DriverTypeOrdersbagSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type DriverTypeConnection = {
  __typename?: 'DriverTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DriverTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DriverType` and its cursor. */
export type DriverTypeEdge = {
  __typename?: 'DriverTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DriverType>;
};

export type EvaluatePicker = {
  __typename?: 'EvaluatePicker';
  order: Maybe<OrderType>;
};

export type FinishOrderWithChangeRequest = {
  __typename?: 'FinishOrderWithChangeRequest';
  order: Maybe<OrderType>;
};

export type ForeignKeyInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/**
 *
 * `GeometryObjectType` represents a pair of values:
 * - Geometry `type`
 * - Geometry `coordinates`
 *
 */
export type GeometryObjectType = {
  __typename?: 'GeometryObjectType';
  coordinates: Maybe<Scalars['GenericScalar']>;
  type: Maybe<Scalars['String']>;
};

export type GetSberPaymentsFormLink = {
  __typename?: 'GetSberPaymentsFormLink';
  payment: Maybe<OrderPaymentType>;
};

export type ImageType = {
  __typename?: 'ImageType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Maybe<Scalars['String']>;
  userSet: UserTypeConnection;
};


export type ImageTypeUserSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type IntervalDriverType = {
  __typename?: 'IntervalDriverType';
  createdAt: Scalars['DateTime'];
  date: Scalars['Date'];
  driver: DriverType;
  id: Scalars['ID'];
  interval: IntervalType;
  updatedAt: Scalars['DateTime'];
};

export type IntervalType = {
  __typename?: 'IntervalType';
  added: Maybe<Scalars['Boolean']>;
  capacity: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  date: Scalars['Date'];
  end: Scalars['Time'];
  id: Scalars['ID'];
  intervalDrivers: Array<IntervalDriverType>;
  occupancy: Maybe<Scalars['Int']>;
  start: Scalars['Time'];
  updatedAt: Scalars['DateTime'];
};

export type IntervalTypeConnection = {
  __typename?: 'IntervalTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntervalTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `IntervalType` and its cursor. */
export type IntervalTypeEdge = {
  __typename?: 'IntervalTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<IntervalType>;
};

export type LoginInputType = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addChangeRequestToOrder: Maybe<AddChangeRequestToOrder>;
  addDriverToBag: Maybe<AddDriverToBag>;
  addDriverToOrdersBag: Maybe<AddDriverToOrdersBag>;
  addOrderToBag: Maybe<AddOrderToBag>;
  addReviewToOrder: Maybe<AddReviewToOrder>;
  appointPickerForOrder: Maybe<AppointPickerForOrder>;
  attachDriverToInterval: Maybe<AttachDriverToInterval>;
  changeOrderDeliveryStatus: Maybe<ChangeOrderDeliveryStatus>;
  createCustomerAddress: Maybe<CreateCustomerAddress>;
  createDriver: Maybe<CreateDriver>;
  createOperator: Maybe<CreateOperator>;
  createPicker: Maybe<CreatePicker>;
  deleteCustomerAddress: Maybe<DeleteCustomerAddress>;
  deleteDriver: Maybe<DeleteDriver>;
  deleteOperator: Maybe<DeleteOperator>;
  deletePicker: Maybe<DeletePicker>;
  evaulatePicker: Maybe<EvaluatePicker>;
  finishOrderWithChangeRequest: Maybe<FinishOrderWithChangeRequest>;
  getSberPaymentsFormLink: Maybe<GetSberPaymentsFormLink>;
  refreshToken: Maybe<Refresh>;
  registerCustomerAndDevice: Maybe<RegisterCustomerAndDevice>;
  removeOrderFromBag: Maybe<RemoveOrderFromBag>;
  removeUser: Maybe<RemoveUser>;
  removeUserAvatar: Maybe<RemoveUserAvatar>;
  sendNotificationToAllCustomers: Maybe<SendNotificationToAllCustomers>;
  sendNotificationToCustomer: Maybe<SendNotificationToCustomer>;
  swapOrderPriority: Maybe<SwapOrderPriority>;
  /**
   * Mutation that receives 'phone' and 'security_code'
   * and returns a jwt token
   */
  tokenAuth: Maybe<CreateToken>;
  updateBag: Maybe<UpdateBag>;
  updateCustomerAddress: Maybe<UpdateCustomerAddress>;
  updateDriver: Maybe<UpdateDriver>;
  updateOperator: Maybe<UpdateOperator>;
  updateOrder: Maybe<UpdateOrder>;
  updateOrderPaymentStatus: Maybe<UpdateOrderPaymentStatus>;
  updateOrdersBag: Maybe<UpdateOrdersBag>;
  updatePicker: Maybe<UpdatePicker>;
  updateReviewOrder: Maybe<UpdateReviewOrder>;
  uploadUserAvatar: Maybe<UploadUserAvatar>;
  userDataSet: Maybe<UserData>;
  /**
   * Mutation that receives 'phone', 'email' and possibly 'role', creates a user (if doesn't exist)
   * with provided data and send security code through sms to the provided phone number
   */
  userRegister: Maybe<UserRegister>;
  verifyToken: Maybe<Verify>;
};


export type MutationAddChangeRequestToOrderArgs = {
  changePaymentInfo: Scalars['String'];
  customerPaidAmount: Scalars['Float'];
  orderId: Scalars['ID'];
};


export type MutationAddDriverToBagArgs = {
  bagId: Scalars['ID'];
  driverId?: InputMaybe<Scalars['ID']>;
};


export type MutationAddDriverToOrdersBagArgs = {
  input: AddDriverToOrdersBagType;
};


export type MutationAddOrderToBagArgs = {
  bagId: Scalars['ID'];
  orderId: Scalars['ID'];
};


export type MutationAddReviewToOrderArgs = {
  orderNumber: Scalars['ID'];
  reviewData: ReviewInputType;
};


export type MutationAppointPickerForOrderArgs = {
  orderNumber: Scalars['ID'];
  pickerId?: InputMaybe<Scalars['ID']>;
};


export type MutationAttachDriverToIntervalArgs = {
  date: Scalars['Date'];
  intervalId: Scalars['ID'];
};


export type MutationChangeOrderDeliveryStatusArgs = {
  input: ChangeOrderDeliveryStatusType;
};


export type MutationCreateCustomerAddressArgs = {
  address: CustomerAddressInput;
};


export type MutationCreateDriverArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  secondName: Scalars['String'];
  vehicle?: InputMaybe<VehicleInputType>;
};


export type MutationCreateOperatorArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  secondName: Scalars['String'];
};


export type MutationCreatePickerArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  password: Scalars['String'];
  secondName: Scalars['String'];
};


export type MutationDeleteCustomerAddressArgs = {
  addressId: Scalars['ID'];
};


export type MutationDeleteDriverArgs = {
  driverId: Scalars['ID'];
};


export type MutationDeleteOperatorArgs = {
  operatorId: Scalars['ID'];
};


export type MutationDeletePickerArgs = {
  pickerId: Scalars['ID'];
};


export type MutationEvaulatePickerArgs = {
  orderId: Scalars['ID'];
  rating: Scalars['Int'];
};


export type MutationFinishOrderWithChangeRequestArgs = {
  orderId: Scalars['ID'];
};


export type MutationGetSberPaymentsFormLinkArgs = {
  orderNumber: Scalars['String'];
  paymentAmount: Scalars['Float'];
};


export type MutationRefreshTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterCustomerAndDeviceArgs = {
  customer: CustomerInput;
  device?: InputMaybe<CustomerDeviceInput>;
};


export type MutationRemoveOrderFromBagArgs = {
  orderId: Scalars['ID'];
};


export type MutationRemoveUserArgs = {
  input: RemoveUserType;
};


export type MutationRemoveUserAvatarArgs = {
  input: RemoveUserAvatarType;
};


export type MutationSendNotificationToAllCustomersArgs = {
  body: Scalars['String'];
  notificationData?: InputMaybe<Scalars['GenericScalar']>;
  title: Scalars['String'];
};


export type MutationSendNotificationToCustomerArgs = {
  body: Scalars['String'];
  customerId: Scalars['ID'];
  notificationData?: InputMaybe<Scalars['GenericScalar']>;
  title: Scalars['String'];
};


export type MutationSwapOrderPriorityArgs = {
  order1: Scalars['ID'];
  order2: Scalars['ID'];
};


export type MutationTokenAuthArgs = {
  input: LoginInputType;
};


export type MutationUpdateBagArgs = {
  input: UpdateBagType;
};


export type MutationUpdateCustomerAddressArgs = {
  address: CustomerAddressInput;
  addressId: Scalars['ID'];
};


export type MutationUpdateDriverArgs = {
  driverId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  secondName?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<VehicleInputType>;
};


export type MutationUpdateOperatorArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  operatorId: Scalars['ID'];
  password?: InputMaybe<Scalars['String']>;
  secondName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderType;
};


export type MutationUpdateOrderPaymentStatusArgs = {
  paymentId: Scalars['ID'];
  status: OrderPaymentStatus;
};


export type MutationUpdateOrdersBagArgs = {
  input: UpdateOrdersBagType;
};


export type MutationUpdatePickerArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  pickerId: Scalars['ID'];
  secondName?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateReviewOrderArgs = {
  orderNumber: Scalars['ID'];
  reviewData: ReviewInputType;
};


export type MutationUploadUserAvatarArgs = {
  input: UploadUserAvatarType;
};


export type MutationUserDataSetArgs = {
  input?: InputMaybe<UserDataInputType>;
};


export type MutationUserRegisterArgs = {
  input: UserRegisterInputType;
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum OrderDeliveryStatus {
  /** PENDING */
  A_1 = 'A_1',
  /** PROCESSING */
  A_2 = 'A_2',
  /** COMPLETED */
  A_3 = 'A_3',
  /** FAILED */
  A_4 = 'A_4',
  /** WAITING_FOR_CHANGE */
  A_5 = 'A_5'
}

export type OrderInBagType = {
  __typename?: 'OrderInBagType';
  id: Scalars['ID'];
  order: OrderType;
  ordersBag: OrdersBagType;
  priority: Scalars['Int'];
};

export type OrderInBagTypeConnection = {
  __typename?: 'OrderInBagTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderInBagTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrderInBagType` and its cursor. */
export type OrderInBagTypeEdge = {
  __typename?: 'OrderInBagTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OrderInBagType>;
};

export type OrderLineItemType = {
  __typename?: 'OrderLineItemType';
  id: Scalars['ID'];
  imageSrc: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: OrderType;
  price: Scalars['Float'];
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
  sku: Scalars['String'];
  subtotalCost: Scalars['Float'];
  totalCost: Scalars['Float'];
  variationId: Maybe<Scalars['Int']>;
};

export type OrderLineItemTypeConnection = {
  __typename?: 'OrderLineItemTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderLineItemTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrderLineItemType` and its cursor. */
export type OrderLineItemTypeEdge = {
  __typename?: 'OrderLineItemTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OrderLineItemType>;
};

/** An enumeration. */
export enum OrderOrderStatus {
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** FAILED */
  Failed = 'FAILED',
  /** ON_HOLD */
  OnHold = 'ON_HOLD',
  /** PENDING */
  Pending = 'PENDING',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** REFUNDED */
  Refunded = 'REFUNDED',
  /** TRASH */
  Trash = 'TRASH'
}

/** An enumeration. */
export enum OrderPaymentStatus {
  /** FAILED */
  Failed = 'FAILED',
  /** PAYED */
  Payed = 'PAYED',
  /** WAITING_PAYMENT */
  WaitingPayment = 'WAITING_PAYMENT'
}

export type OrderPaymentType = {
  __typename?: 'OrderPaymentType';
  additionalData: Scalars['JSONString'];
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  formUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order: OrderType;
  payedAt: Maybe<Scalars['DateTime']>;
  status: OrderPaymentStatus;
  updatedAt: Scalars['DateTime'];
};

export type OrderPaymentTypeConnection = {
  __typename?: 'OrderPaymentTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderPaymentTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrderPaymentType` and its cursor. */
export type OrderPaymentTypeEdge = {
  __typename?: 'OrderPaymentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OrderPaymentType>;
};

export type OrderType = {
  __typename?: 'OrderType';
  activeAssembly: Maybe<PickerOrderAssemblyType>;
  address: AddressType;
  assemblies: PickerOrderAssemblyTypeConnection;
  changeAmount: Scalars['Float'];
  changePaymentInfo: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerFullname: Scalars['String'];
  customerId: Scalars['Int'];
  customerNote: Scalars['String'];
  customerPhone: Scalars['String'];
  deliveryDate: Scalars['Date'];
  deliveryIntervalEnd: Scalars['Time'];
  deliveryIntervalStart: Scalars['Time'];
  deliveryStatus: OrderDeliveryStatus;
  id: Scalars['ID'];
  lineItems: OrderLineItemTypeConnection;
  orderNumber: Scalars['Int'];
  orderStatus: OrderOrderStatus;
  orderinbag: Maybe<OrderInBagType>;
  orderinbagSet: OrderInBagTypeConnection;
  orderpaymentSet: OrderPaymentTypeConnection;
  ordersBagDeprecated: Maybe<OrdersBagType>;
  paymentMethod: PaymentMethodType;
  placedAt: Scalars['DateTime'];
  reviewSet: ReviewTypeConnection;
  shippingCost: Scalars['Float'];
  shippingMethod: Maybe<ShippingMethodType>;
  totalCost: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};


export type OrderTypeAssembliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type OrderTypeLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type OrderTypeOrderinbagSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type OrderTypeOrderpaymentSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type OrderTypeReviewSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OrderTypeConnection = {
  __typename?: 'OrderTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `OrderType` and its cursor. */
export type OrderTypeEdge = {
  __typename?: 'OrderTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OrderType>;
};

/** An enumeration. */
export enum OrdersBagStatus {
  /** FINISHED */
  Finished = 'FINISHED',
  /** IN_PROGRESS */
  InProgress = 'IN_PROGRESS',
  /** PENDING */
  Pending = 'PENDING'
}

export type OrdersBagType = {
  __typename?: 'OrdersBagType';
  bag: Maybe<BagType>;
  createdAt: Scalars['DateTime'];
  driver: Maybe<DriverType>;
  finishedAt: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  orderSet: OrderTypeConnection;
  orderinbagSet: OrderInBagTypeConnection;
  orders: Maybe<Array<Maybe<OrderType>>>;
  status: OrdersBagStatus;
  takenAt: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  validDrivers: Maybe<Array<Maybe<DriverType>>>;
  weight: Scalars['Float'];
};


export type OrdersBagTypeOrderSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type OrdersBagTypeOrderinbagSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OrdersBagTypeConnection = {
  __typename?: 'OrdersBagTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrdersBagTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OrdersBagType` and its cursor. */
export type OrdersBagTypeEdge = {
  __typename?: 'OrdersBagTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<OrdersBagType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  code: Scalars['String'];
  id: Scalars['ID'];
  orderSet: OrderTypeConnection;
  title: Scalars['String'];
};


export type PaymentMethodTypeOrderSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PaymentMethodTypeConnection = {
  __typename?: 'PaymentMethodTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PaymentMethodTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PaymentMethodType` and its cursor. */
export type PaymentMethodTypeEdge = {
  __typename?: 'PaymentMethodTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PaymentMethodType>;
};

/** An enumeration. */
export enum PickerOrderAssemblyStatus {
  /** FINISHED */
  Finished = 'FINISHED',
  /** IN_PROGRESS */
  InProgress = 'IN_PROGRESS',
  /** PENDING */
  Pending = 'PENDING'
}

export type PickerOrderAssemblyType = {
  __typename?: 'PickerOrderAssemblyType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  order: OrderType;
  picker: Maybe<PickerType>;
  rating: Maybe<Scalars['Int']>;
  status: PickerOrderAssemblyStatus;
  updatedAt: Scalars['DateTime'];
};

export type PickerOrderAssemblyTypeConnection = {
  __typename?: 'PickerOrderAssemblyTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickerOrderAssemblyTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PickerOrderAssemblyType` and its cursor. */
export type PickerOrderAssemblyTypeEdge = {
  __typename?: 'PickerOrderAssemblyTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PickerOrderAssemblyType>;
};

export type PickerType = {
  __typename?: 'PickerType';
  assemblies: PickerOrderAssemblyTypeConnection;
  id: Scalars['ID'];
  user: UserType;
};


export type PickerTypeAssembliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type PickerTypeConnection = {
  __typename?: 'PickerTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PickerTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PickerType` and its cursor. */
export type PickerTypeEdge = {
  __typename?: 'PickerTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PickerType>;
};

export type PickupPointType = {
  __typename?: 'PickupPointType';
  address: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  latitude: Maybe<Scalars['String']>;
  longitude: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PushNotificationNotifyType {
  /** anytype */
  Anytype = 'ANYTYPE'
}

export type PushNotificationType = {
  __typename?: 'PushNotificationType';
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  data: Maybe<Scalars['JSONString']>;
  id: Scalars['ID'];
  notifyType: PushNotificationNotifyType;
  title: Scalars['String'];
  topic: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  addressSuggestions: Maybe<Array<Maybe<AddressSuggestionResultType>>>;
  bag: Maybe<BagType>;
  bags: Maybe<BagTypeConnection>;
  boardOrders: Maybe<OrderTypeConnection>;
  customerAddresses: Maybe<CustomerAddressTypeConnection>;
  customerOrderById: Maybe<OrderType>;
  customerShippingMethods: Maybe<CustomerShippingMethodTypeConnection>;
  customerShippingMethodsByZoneId: Maybe<CustomerShippingMethodTypeConnection>;
  customerShippingZones: Maybe<CustomerShippingZoneTypeConnection>;
  dayIntervals: Maybe<IntervalTypeConnection>;
  deliveryDates: Maybe<Array<Maybe<Scalars['String']>>>;
  deliveryIntervals: Maybe<Array<Maybe<DeliveryIntervalType>>>;
  driver: Maybe<DriverType>;
  driverActiveOrders: Maybe<OrderTypeConnection>;
  driverByOrderNumber: Maybe<DriverType>;
  driverHistoryOrders: Maybe<OrderTypeConnection>;
  driverStatistics: Maybe<DriverStatisticsType>;
  drivers: Maybe<DriverTypeConnection>;
  geocode: Maybe<AddressType>;
  me: Maybe<UserType>;
  operatorUsers: Maybe<UserTypeConnection>;
  order: Maybe<OrderType>;
  orderPayment: Maybe<OrderPaymentType>;
  orderPayments: Maybe<OrderPaymentTypeConnection>;
  orders: Maybe<OrderTypeConnection>;
  ordersBag: Maybe<OrdersBagType>;
  ordersBags: Maybe<OrdersBagTypeConnection>;
  paymentChangeHistory: Maybe<OrderTypeConnection>;
  paymentMethod: Maybe<PaymentMethodType>;
  paymentMethods: Maybe<PaymentMethodTypeConnection>;
  picker: Maybe<PickerType>;
  pickerOrderAssemblies: Maybe<PickerOrderAssemblyTypeConnection>;
  pickerOrderAssembly: Maybe<PickerOrderAssemblyType>;
  pickers: Maybe<PickerTypeConnection>;
  pickupPointsByZoneId: Maybe<Array<Maybe<PickupPointType>>>;
  setting: Maybe<SettingType>;
  shippingMethod: Maybe<ShippingMethodType>;
  shippingMethods: Maybe<ShippingMethodTypeConnection>;
  unreviewedOrders: Maybe<OrderTypeConnection>;
  user: Maybe<UserType>;
  users: Maybe<UserTypeConnection>;
  version: Maybe<VersionType>;
};


export type QueryAddressSuggestionsArgs = {
  addressStr: Scalars['String'];
};


export type QueryBagArgs = {
  id: Scalars['ID'];
};


export type QueryBagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryBoardOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerOrderByIdArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerShippingMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryCustomerShippingMethodsByZoneIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  productsWeight?: InputMaybe<Scalars['Decimal']>;
  zoneId?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerShippingZonesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryDayIntervalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryDeliveryDatesArgs = {
  shippingMethodId?: InputMaybe<Scalars['ID']>;
};


export type QueryDeliveryIntervalsArgs = {
  date?: InputMaybe<Scalars['Date']>;
  shippingMethodId?: InputMaybe<Scalars['ID']>;
};


export type QueryDriverArgs = {
  id: Scalars['ID'];
};


export type QueryDriverActiveOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  ordersBag?: InputMaybe<Scalars['ID']>;
};


export type QueryDriverByOrderNumberArgs = {
  orderNumber: Scalars['String'];
};


export type QueryDriverHistoryOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  ordersBag?: InputMaybe<Scalars['ID']>;
};


export type QueryDriverStatisticsArgs = {
  driverId?: InputMaybe<Scalars['ID']>;
  period?: InputMaybe<StatisticsPeriodEnum>;
};


export type QueryDriversArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<DriverStatus>>>;
};


export type QueryGeocodeArgs = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};


export type QueryOperatorUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryOrderPaymentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  ordersBag?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Array<InputMaybe<OrderDeliveryStatus>>>;
};


export type QueryOrdersBagArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersBagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Array<InputMaybe<OrdersBagStatus>>>;
};


export type QueryPaymentChangeHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryPaymentMethodArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryPickerArgs = {
  id: Scalars['ID'];
};


export type QueryPickerOrderAssembliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderNumber?: InputMaybe<Scalars['ID']>;
};


export type QueryPickerOrderAssemblyArgs = {
  id: Scalars['ID'];
};


export type QueryPickersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryPickupPointsByZoneIdArgs = {
  zoneId: Scalars['ID'];
};


export type QuerySettingArgs = {
  name: Scalars['String'];
};


export type QueryShippingMethodArgs = {
  id: Scalars['ID'];
};


export type QueryShippingMethodsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryUnreviewedOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RegisterCustomerAndDevice = {
  __typename?: 'RegisterCustomerAndDevice';
  customer: Maybe<CustomerType>;
};

export type RemoveOrderFromBag = {
  __typename?: 'RemoveOrderFromBag';
  bag: Maybe<BagType>;
  order: Maybe<OrderType>;
};

export type RemoveUser = {
  __typename?: 'RemoveUser';
  success: Maybe<Scalars['Boolean']>;
};

export type RemoveUserAvatar = {
  __typename?: 'RemoveUserAvatar';
  success: Maybe<Scalars['Boolean']>;
};

export type RemoveUserAvatarType = {
  avatarId: Scalars['ID'];
};

export type RemoveUserType = {
  userId: Scalars['ID'];
};

export type ReviewInputType = {
  assemblyQuality: Scalars['Int'];
  customerNote?: InputMaybe<Scalars['String']>;
  deliverySpeed: Scalars['Int'];
  driverCourtesy: Scalars['Int'];
  productQuality: Scalars['Int'];
};

export type ReviewType = {
  __typename?: 'ReviewType';
  assemblyQuality: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  customerNote: Scalars['String'];
  deliverySpeed: Scalars['Int'];
  driverCourtesy: Scalars['Int'];
  id: Scalars['ID'];
  order: OrderType;
  productQuality: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ReviewTypeConnection = {
  __typename?: 'ReviewTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReviewType` and its cursor. */
export type ReviewTypeEdge = {
  __typename?: 'ReviewTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReviewType>;
};

export type SendNotificationToAllCustomers = {
  __typename?: 'SendNotificationToAllCustomers';
  push: Maybe<PushNotificationType>;
};

export type SendNotificationToCustomer = {
  __typename?: 'SendNotificationToCustomer';
  push: Maybe<PushNotificationType>;
};

export type SettingType = {
  __typename?: 'SettingType';
  createdAt: Scalars['DateTime'];
  data: Scalars['JSONString'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum ShippingMethodCode {
  /** CDEK_COURIER */
  CdekCourier = 'CDEK_COURIER',
  /** CDEK_POINT */
  CdekPoint = 'CDEK_POINT',
  /** FLAT_RATE */
  FlatRate = 'FLAT_RATE',
  /** FREE_SHIPPING */
  FreeShipping = 'FREE_SHIPPING',
  /** PICKUP_POINT */
  PickupPoint = 'PICKUP_POINT',
  /** TAXI_SHIPPING */
  TaxiShipping = 'TAXI_SHIPPING'
}

/** An enumeration. */
export enum ShippingMethodMethodType {
  /** DEFAULT */
  Default = 'DEFAULT',
  /** NEXT_DAY */
  NextDay = 'NEXT_DAY'
}

export type ShippingMethodType = {
  __typename?: 'ShippingMethodType';
  code: Scalars['String'];
  id: Scalars['ID'];
  orderSet: OrderTypeConnection;
  title: Scalars['String'];
};


export type ShippingMethodTypeOrderSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ShippingMethodTypeConnection = {
  __typename?: 'ShippingMethodTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ShippingMethodTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ShippingMethodType` and its cursor. */
export type ShippingMethodTypeEdge = {
  __typename?: 'ShippingMethodTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ShippingMethodType>;
};

export type ShippingZoneShippingMethodType = {
  __typename?: 'ShippingZoneShippingMethodType';
  id: Scalars['ID'];
  maxDeliveryDays: Maybe<Scalars['Int']>;
  minDeliveryDays: Maybe<Scalars['Int']>;
  shippingMethod: CustomerShippingMethodType;
  shippingZone: CustomerShippingZoneType;
};

export type ShippingZoneShippingMethodTypeConnection = {
  __typename?: 'ShippingZoneShippingMethodTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ShippingZoneShippingMethodTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ShippingZoneShippingMethodType` and its cursor. */
export type ShippingZoneShippingMethodTypeEdge = {
  __typename?: 'ShippingZoneShippingMethodTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ShippingZoneShippingMethodType>;
};

export enum StatisticsPeriodEnum {
  LastMonth = 'last_month',
  Month = 'month',
  Today = 'today',
  Week = 'week'
}

export type SwapOrderPriority = {
  __typename?: 'SwapOrderPriority';
  bag: Maybe<BagType>;
};

export type UpdateBag = {
  __typename?: 'UpdateBag';
  bag: Maybe<BagType>;
};

export type UpdateBagType = {
  bagId: Scalars['ID'];
  code?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerAddress = {
  __typename?: 'UpdateCustomerAddress';
  address: Maybe<CustomerAddressType>;
};

export type UpdateDriver = {
  __typename?: 'UpdateDriver';
  driver: Maybe<DriverType>;
};

export type UpdateOperator = {
  __typename?: 'UpdateOperator';
  user: Maybe<UserType>;
};

export type UpdateOrder = {
  __typename?: 'UpdateOrder';
  order: Maybe<OrderType>;
};

export type UpdateOrderPaymentStatus = {
  __typename?: 'UpdateOrderPaymentStatus';
  payment: Maybe<OrderPaymentType>;
};

export type UpdateOrderType = {
  customerFullname?: InputMaybe<Scalars['String']>;
  customerPhone?: InputMaybe<Scalars['String']>;
  deliveryDate?: InputMaybe<Scalars['String']>;
  deliveryIntervalEnd?: InputMaybe<Scalars['String']>;
  deliveryIntervalStart?: InputMaybe<Scalars['String']>;
  deliveryStatus?: InputMaybe<OrderDeliveryStatus>;
  id?: InputMaybe<Scalars['ID']>;
  orderStatus?: InputMaybe<OrderOrderStatus>;
  paymentMethod?: InputMaybe<ForeignKeyInput>;
  shippingCost?: InputMaybe<Scalars['Float']>;
  shippingMethod?: InputMaybe<ForeignKeyInput>;
  totalCost?: InputMaybe<Scalars['Float']>;
};

export type UpdateOrdersBag = {
  __typename?: 'UpdateOrdersBag';
  ordersBag: Maybe<OrdersBagType>;
};

export type UpdateOrdersBagType = {
  bagId?: InputMaybe<Scalars['ID']>;
  driverId?: InputMaybe<Scalars['ID']>;
  finishedAt?: InputMaybe<Scalars['DateTime']>;
  ordersBagId: Scalars['ID'];
  takenAt?: InputMaybe<Scalars['DateTime']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdatePicker = {
  __typename?: 'UpdatePicker';
  picker: Maybe<PickerType>;
};

export type UpdateReviewOrder = {
  __typename?: 'UpdateReviewOrder';
  review: Maybe<ReviewType>;
};

export type UploadUserAvatar = {
  __typename?: 'UploadUserAvatar';
  image: Maybe<ImageType>;
};

export type UploadUserAvatarType = {
  file: Scalars['Upload'];
  userId: Scalars['ID'];
};

export type UserData = {
  __typename?: 'UserData';
  user: Maybe<UserType>;
};

export type UserDataInputType = {
  address: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  diagnosis: Scalars['String'];
  firstName: Scalars['String'];
  location: Scalars['Geometry'];
  menstrualCorrelation: Scalars['Boolean'];
  secondName: Scalars['String'];
  thirdName: Scalars['String'];
};

/**
 * Mutation that receives 'phone', 'email' and possibly 'role', creates a user (if doesn't exist)
 * with provided data and send security code through sms to the provided phone number
 */
export type UserRegister = {
  __typename?: 'UserRegister';
  user: Maybe<UserType>;
};

export type UserRegisterInputType = {
  email: Scalars['String'];
  phone: Scalars['String'];
  role?: InputMaybe<UserRole>;
};

/** An enumeration. */
export enum UserRole {
  /** admin */
  A_1 = 'A_1',
  /** operator */
  A_2 = 'A_2',
  /** driver */
  A_3 = 'A_3',
  /** client */
  A_4 = 'A_4',
  /** picker */
  A_5 = 'A_5'
}

export type UserType = {
  __typename?: 'UserType';
  avatar: Maybe<ImageType>;
  createdAt: Scalars['DateTime'];
  dateJoined: Scalars['DateTime'];
  driverSet: DriverTypeConnection;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  /** Отметьте, если пользователь должен считаться активным. Уберите эту отметку вместо удаления учётной записи. */
  isActive: Scalars['Boolean'];
  /** Отметьте, если пользователь может входить в административную часть сайта. */
  isStaff: Scalars['Boolean'];
  /** Указывает, что пользователь имеет все права без явного их назначения. */
  isSuperuser: Scalars['Boolean'];
  lastLogin: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  pickerSet: PickerTypeConnection;
  role: UserRole;
  roleName: Maybe<Scalars['String']>;
  secondName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type UserTypeDriverSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UserTypePickerSetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<UserType>;
};

export type VehicleInputType = {
  licensePlate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  vehicleType?: InputMaybe<VehicleVehicleType>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  drivers: DriverTypeConnection;
  id: Scalars['ID'];
  licensePlate: Maybe<Scalars['String']>;
  name: Scalars['String'];
  vehicleType: VehicleVehicleType;
};


export type VehicleTypeDriversArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum VehicleVehicleType {
  /** CAR */
  A_1 = 'A_1',
  /** BICYCLE */
  A_2 = 'A_2',
  /** MOPED */
  A_3 = 'A_3',
  /** MOTORCYCLE */
  A_4 = 'A_4',
  /** KICK_SCOOTER */
  A_5 = 'A_5'
}

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type VersionType = {
  __typename?: 'VersionType';
  versionAndroid: Maybe<Scalars['String']>;
  versionIos: Maybe<Scalars['String']>;
};

export type AddressDetailsFragment = { __typename?: 'CustomerAddressType', id: string, customerId: number, state: string, city: string, entrance: string, street: string, house: string, isFlat: boolean, floor: string, apartmentNumber: string, intercom: string, location: { __typename?: 'GeometryObjectType', type: string, coordinates: any } };

export type CustomerAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerAddressesQuery = { __typename?: 'Query', customerAddresses: { __typename?: 'CustomerAddressTypeConnection', edges: Array<{ __typename?: 'CustomerAddressTypeEdge', node: { __typename?: 'CustomerAddressType', id: string, customerId: number, state: string, city: string, entrance: string, street: string, house: string, isFlat: boolean, floor: string, apartmentNumber: string, intercom: string, location: { __typename?: 'GeometryObjectType', type: string, coordinates: any } } }> } };

export type CreateCustomerAddressMutationVariables = Exact<{
  address: CustomerAddressInput;
}>;


export type CreateCustomerAddressMutation = { __typename?: 'Mutation', createCustomerAddress: { __typename?: 'CreateCustomerAddress', address: { __typename?: 'CustomerAddressType', id: string, customerId: number, state: string, city: string, entrance: string, street: string, house: string, isFlat: boolean, floor: string, apartmentNumber: string, intercom: string, location: { __typename?: 'GeometryObjectType', type: string, coordinates: any } } } };

export type UpdateCustomerAddressMutationVariables = Exact<{
  addressId: Scalars['ID'];
  address: CustomerAddressInput;
}>;


export type UpdateCustomerAddressMutation = { __typename?: 'Mutation', updateCustomerAddress: { __typename?: 'UpdateCustomerAddress', address: { __typename?: 'CustomerAddressType', id: string, customerId: number, state: string, city: string, entrance: string, street: string, house: string, isFlat: boolean, floor: string, apartmentNumber: string, intercom: string, location: { __typename?: 'GeometryObjectType', type: string, coordinates: any } } } };

export type DeleteCustomerAddressMutationVariables = Exact<{
  addressId: Scalars['ID'];
}>;


export type DeleteCustomerAddressMutation = { __typename?: 'Mutation', deleteCustomerAddress: { __typename?: 'DeleteCustomerAddress', addressId: string } };

export type AddReviewOrderMutationVariables = Exact<{
  orderNumber: Scalars['ID'];
  reviewData: ReviewInputType;
}>;


export type AddReviewOrderMutation = { __typename?: 'Mutation', addReviewToOrder: { __typename: 'AddReviewToOrder', review: { __typename: 'ReviewType', id: string, assemblyQuality: number, customerNote: string, deliverySpeed: number, driverCourtesy: number, productQuality: number, order: { __typename?: 'OrderType', id: string, orderNumber: number } } } };

export type ReviewsSetOrderByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReviewsSetOrderByIdQuery = { __typename?: 'Query', customerOrderById: { __typename?: 'OrderType', orderNumber: number, reviewSet: { __typename?: 'ReviewTypeConnection', edges: Array<{ __typename?: 'ReviewTypeEdge', node: { __typename?: 'ReviewType', productQuality: number, customerNote: string, assemblyQuality: number, deliverySpeed: number, driverCourtesy: number, order: { __typename?: 'OrderType', orderNumber: number } } }> } } };

export type CustomerFragment = { __typename?: 'CustomerType', id: number, email: string, firstName: string, lastName: string, username: string, phoneNumber: string, devices: Array<{ __typename?: 'CustomersDeviceType', id: string, appVersion: number, device: { __typename?: 'DeviceType', pushToken: string } }> };

export type RegisterCustomerAndDeviceMutationVariables = Exact<{
  customer: CustomerInput;
  device?: InputMaybe<CustomerDeviceInput>;
}>;


export type RegisterCustomerAndDeviceMutation = { __typename?: 'Mutation', registerCustomerAndDevice: { __typename?: 'RegisterCustomerAndDevice', customer: { __typename?: 'CustomerType', id: number, email: string, firstName: string, lastName: string, username: string, phoneNumber: string, devices: Array<{ __typename?: 'CustomersDeviceType', id: string, appVersion: number, device: { __typename?: 'DeviceType', pushToken: string } }> } } };

export type ShippingMethodFragment = { __typename?: 'CustomerShippingMethodType', id: string, isEnabled: boolean, description: string, title: string, minAmount: string, cost: string, code: ShippingMethodCode, methodType: ShippingMethodMethodType, minDeliveryDays: number, maxDeliveryDays: number };

export type ShippingMethodsByIdQueryVariables = Exact<{
  zoneId?: InputMaybe<Scalars['ID']>;
  productsWeight?: InputMaybe<Scalars['Decimal']>;
}>;


export type ShippingMethodsByIdQuery = { __typename?: 'Query', customerShippingMethodsByZoneId: { __typename?: 'CustomerShippingMethodTypeConnection', edges: Array<{ __typename?: 'CustomerShippingMethodTypeEdge', node: { __typename?: 'CustomerShippingMethodType', id: string, isEnabled: boolean, description: string, title: string, minAmount: string, cost: string, code: ShippingMethodCode, methodType: ShippingMethodMethodType, minDeliveryDays: number, maxDeliveryDays: number } }> } };

export type ShippingZonesQueryVariables = Exact<{ [key: string]: never; }>;


export type ShippingZonesQuery = { __typename?: 'Query', customerShippingZones: { __typename?: 'CustomerShippingZoneTypeConnection', edges: Array<{ __typename?: 'CustomerShippingZoneTypeEdge', node: { __typename?: 'CustomerShippingZoneType', id: string, code: string, title: string } }> } };

export type PickupPointsByZoneIdQueryVariables = Exact<{
  zoneId: Scalars['ID'];
}>;


export type PickupPointsByZoneIdQuery = { __typename?: 'Query', pickupPointsByZoneId: Array<{ __typename?: 'PickupPointType', id: string, name: string, address: string, longitude: string, latitude: string }> };

export type DeliveryDatesQueryVariables = Exact<{
  shippingMethodId?: InputMaybe<Scalars['ID']>;
}>;


export type DeliveryDatesQuery = { __typename?: 'Query', deliveryDates: Array<string> };

export type DeliveryIntervalsQueryVariables = Exact<{
  shippingMethodId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
}>;


export type DeliveryIntervalsQuery = { __typename?: 'Query', deliveryIntervals: Array<{ __typename?: 'DeliveryIntervalType', id: string, timeStart: any, timeEnd: any }> };

export const AddressDetailsFragmentDoc = gql`
    fragment AddressDetails on CustomerAddressType {
  id
  customerId
  state
  city
  entrance
  street
  house
  isFlat
  floor
  apartmentNumber
  intercom
  location {
    type
    coordinates
  }
}
    `;
export const CustomerFragmentDoc = gql`
    fragment Customer on CustomerType {
  id
  email
  firstName
  lastName
  username
  phoneNumber
  devices {
    id
    device {
      pushToken
    }
    appVersion
  }
}
    `;
export const ShippingMethodFragmentDoc = gql`
    fragment ShippingMethod on CustomerShippingMethodType {
  id
  isEnabled
  description
  title
  minAmount
  cost
  code
  methodType
  minDeliveryDays
  maxDeliveryDays
}
    `;
export const CustomerAddressesDocument = gql`
    query CustomerAddresses {
  customerAddresses {
    edges {
      node {
        ...AddressDetails
      }
    }
  }
}
    ${AddressDetailsFragmentDoc}`;

/**
 * __useCustomerAddressesQuery__
 *
 * To run a query within a React component, call `useCustomerAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerAddressesQuery(baseOptions?: Apollo.QueryHookOptions<CustomerAddressesQuery, CustomerAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerAddressesQuery, CustomerAddressesQueryVariables>(CustomerAddressesDocument, options);
      }
export function useCustomerAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerAddressesQuery, CustomerAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerAddressesQuery, CustomerAddressesQueryVariables>(CustomerAddressesDocument, options);
        }
export type CustomerAddressesQueryHookResult = ReturnType<typeof useCustomerAddressesQuery>;
export type CustomerAddressesLazyQueryHookResult = ReturnType<typeof useCustomerAddressesLazyQuery>;
export const CreateCustomerAddressDocument = gql`
    mutation CreateCustomerAddress($address: CustomerAddressInput!) {
  createCustomerAddress(address: $address) {
    address {
      ...AddressDetails
    }
  }
}
    ${AddressDetailsFragmentDoc}`;

/**
 * __useCreateCustomerAddressMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAddressMutation, { data, loading, error }] = useCreateCustomerAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCreateCustomerAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>(CreateCustomerAddressDocument, options);
      }
export type CreateCustomerAddressMutationHookResult = ReturnType<typeof useCreateCustomerAddressMutation>;
export type CreateCustomerAddressMutationOptions = Apollo.BaseMutationOptions<CreateCustomerAddressMutation, CreateCustomerAddressMutationVariables>;
export const UpdateCustomerAddressDocument = gql`
    mutation UpdateCustomerAddress($addressId: ID!, $address: CustomerAddressInput!) {
  updateCustomerAddress(address: $address, addressId: $addressId) {
    address {
      ...AddressDetails
    }
  }
}
    ${AddressDetailsFragmentDoc}`;

/**
 * __useUpdateCustomerAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAddressMutation, { data, loading, error }] = useUpdateCustomerAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useUpdateCustomerAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>(UpdateCustomerAddressDocument, options);
      }
export type UpdateCustomerAddressMutationHookResult = ReturnType<typeof useUpdateCustomerAddressMutation>;
export type UpdateCustomerAddressMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerAddressMutation, UpdateCustomerAddressMutationVariables>;
export const DeleteCustomerAddressDocument = gql`
    mutation DeleteCustomerAddress($addressId: ID!) {
  deleteCustomerAddress(addressId: $addressId) {
    addressId
  }
}
    `;

/**
 * __useDeleteCustomerAddressMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAddressMutation, { data, loading, error }] = useDeleteCustomerAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteCustomerAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerAddressMutation, DeleteCustomerAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerAddressMutation, DeleteCustomerAddressMutationVariables>(DeleteCustomerAddressDocument, options);
      }
export type DeleteCustomerAddressMutationHookResult = ReturnType<typeof useDeleteCustomerAddressMutation>;
export type DeleteCustomerAddressMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerAddressMutation, DeleteCustomerAddressMutationVariables>;
export const AddReviewOrderDocument = gql`
    mutation AddReviewOrder($orderNumber: ID!, $reviewData: ReviewInputType!) {
  addReviewToOrder(orderNumber: $orderNumber, reviewData: $reviewData) {
    review {
      id
      assemblyQuality
      customerNote
      order {
        id
        orderNumber
      }
      deliverySpeed
      driverCourtesy
      productQuality
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useAddReviewOrderMutation__
 *
 * To run a mutation, you first call `useAddReviewOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReviewOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReviewOrderMutation, { data, loading, error }] = useAddReviewOrderMutation({
 *   variables: {
 *      orderNumber: // value for 'orderNumber'
 *      reviewData: // value for 'reviewData'
 *   },
 * });
 */
export function useAddReviewOrderMutation(baseOptions?: Apollo.MutationHookOptions<AddReviewOrderMutation, AddReviewOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReviewOrderMutation, AddReviewOrderMutationVariables>(AddReviewOrderDocument, options);
      }
export type AddReviewOrderMutationHookResult = ReturnType<typeof useAddReviewOrderMutation>;
export type AddReviewOrderMutationOptions = Apollo.BaseMutationOptions<AddReviewOrderMutation, AddReviewOrderMutationVariables>;
export const ReviewsSetOrderByIdDocument = gql`
    query ReviewsSetOrderById($id: ID!) {
  customerOrderById(id: $id) {
    orderNumber
    reviewSet {
      edges {
        node {
          productQuality
          customerNote
          assemblyQuality
          deliverySpeed
          driverCourtesy
          order {
            orderNumber
          }
        }
      }
    }
  }
}
    `;

/**
 * __useReviewsSetOrderByIdQuery__
 *
 * To run a query within a React component, call `useReviewsSetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsSetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsSetOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewsSetOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<ReviewsSetOrderByIdQuery, ReviewsSetOrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsSetOrderByIdQuery, ReviewsSetOrderByIdQueryVariables>(ReviewsSetOrderByIdDocument, options);
      }
export function useReviewsSetOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsSetOrderByIdQuery, ReviewsSetOrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsSetOrderByIdQuery, ReviewsSetOrderByIdQueryVariables>(ReviewsSetOrderByIdDocument, options);
        }
export type ReviewsSetOrderByIdQueryHookResult = ReturnType<typeof useReviewsSetOrderByIdQuery>;
export type ReviewsSetOrderByIdLazyQueryHookResult = ReturnType<typeof useReviewsSetOrderByIdLazyQuery>;
export const RegisterCustomerAndDeviceDocument = gql`
    mutation RegisterCustomerAndDevice($customer: CustomerInput!, $device: CustomerDeviceInput) {
  registerCustomerAndDevice(customer: $customer, device: $device) {
    customer {
      ...Customer
    }
  }
}
    ${CustomerFragmentDoc}`;

/**
 * __useRegisterCustomerAndDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterCustomerAndDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCustomerAndDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCustomerAndDeviceMutation, { data, loading, error }] = useRegisterCustomerAndDeviceMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      device: // value for 'device'
 *   },
 * });
 */
export function useRegisterCustomerAndDeviceMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCustomerAndDeviceMutation, RegisterCustomerAndDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCustomerAndDeviceMutation, RegisterCustomerAndDeviceMutationVariables>(RegisterCustomerAndDeviceDocument, options);
      }
export type RegisterCustomerAndDeviceMutationHookResult = ReturnType<typeof useRegisterCustomerAndDeviceMutation>;
export type RegisterCustomerAndDeviceMutationOptions = Apollo.BaseMutationOptions<RegisterCustomerAndDeviceMutation, RegisterCustomerAndDeviceMutationVariables>;
export const ShippingMethodsByIdDocument = gql`
    query ShippingMethodsById($zoneId: ID, $productsWeight: Decimal) {
  customerShippingMethodsByZoneId(
    zoneId: $zoneId
    productsWeight: $productsWeight
  ) {
    edges {
      node {
        ...ShippingMethod
      }
    }
  }
}
    ${ShippingMethodFragmentDoc}`;

/**
 * __useShippingMethodsByIdQuery__
 *
 * To run a query within a React component, call `useShippingMethodsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingMethodsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingMethodsByIdQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *      productsWeight: // value for 'productsWeight'
 *   },
 * });
 */
export function useShippingMethodsByIdQuery(baseOptions?: Apollo.QueryHookOptions<ShippingMethodsByIdQuery, ShippingMethodsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShippingMethodsByIdQuery, ShippingMethodsByIdQueryVariables>(ShippingMethodsByIdDocument, options);
      }
export function useShippingMethodsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShippingMethodsByIdQuery, ShippingMethodsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShippingMethodsByIdQuery, ShippingMethodsByIdQueryVariables>(ShippingMethodsByIdDocument, options);
        }
export type ShippingMethodsByIdQueryHookResult = ReturnType<typeof useShippingMethodsByIdQuery>;
export type ShippingMethodsByIdLazyQueryHookResult = ReturnType<typeof useShippingMethodsByIdLazyQuery>;
export const ShippingZonesDocument = gql`
    query ShippingZones {
  customerShippingZones {
    edges {
      node {
        id
        code
        title
      }
    }
  }
}
    `;

/**
 * __useShippingZonesQuery__
 *
 * To run a query within a React component, call `useShippingZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingZonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useShippingZonesQuery(baseOptions?: Apollo.QueryHookOptions<ShippingZonesQuery, ShippingZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShippingZonesQuery, ShippingZonesQueryVariables>(ShippingZonesDocument, options);
      }
export function useShippingZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShippingZonesQuery, ShippingZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShippingZonesQuery, ShippingZonesQueryVariables>(ShippingZonesDocument, options);
        }
export type ShippingZonesQueryHookResult = ReturnType<typeof useShippingZonesQuery>;
export type ShippingZonesLazyQueryHookResult = ReturnType<typeof useShippingZonesLazyQuery>;
export const PickupPointsByZoneIdDocument = gql`
    query PickupPointsByZoneId($zoneId: ID!) {
  pickupPointsByZoneId(zoneId: $zoneId) {
    id
    name
    address
    longitude
    latitude
  }
}
    `;

/**
 * __usePickupPointsByZoneIdQuery__
 *
 * To run a query within a React component, call `usePickupPointsByZoneIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupPointsByZoneIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupPointsByZoneIdQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function usePickupPointsByZoneIdQuery(baseOptions: Apollo.QueryHookOptions<PickupPointsByZoneIdQuery, PickupPointsByZoneIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupPointsByZoneIdQuery, PickupPointsByZoneIdQueryVariables>(PickupPointsByZoneIdDocument, options);
      }
export function usePickupPointsByZoneIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupPointsByZoneIdQuery, PickupPointsByZoneIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupPointsByZoneIdQuery, PickupPointsByZoneIdQueryVariables>(PickupPointsByZoneIdDocument, options);
        }
export type PickupPointsByZoneIdQueryHookResult = ReturnType<typeof usePickupPointsByZoneIdQuery>;
export type PickupPointsByZoneIdLazyQueryHookResult = ReturnType<typeof usePickupPointsByZoneIdLazyQuery>;
export const DeliveryDatesDocument = gql`
    query DeliveryDates($shippingMethodId: ID) {
  deliveryDates(shippingMethodId: $shippingMethodId)
}
    `;

/**
 * __useDeliveryDatesQuery__
 *
 * To run a query within a React component, call `useDeliveryDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryDatesQuery({
 *   variables: {
 *      shippingMethodId: // value for 'shippingMethodId'
 *   },
 * });
 */
export function useDeliveryDatesQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryDatesQuery, DeliveryDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryDatesQuery, DeliveryDatesQueryVariables>(DeliveryDatesDocument, options);
      }
export function useDeliveryDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryDatesQuery, DeliveryDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryDatesQuery, DeliveryDatesQueryVariables>(DeliveryDatesDocument, options);
        }
export type DeliveryDatesQueryHookResult = ReturnType<typeof useDeliveryDatesQuery>;
export type DeliveryDatesLazyQueryHookResult = ReturnType<typeof useDeliveryDatesLazyQuery>;
export const DeliveryIntervalsDocument = gql`
    query DeliveryIntervals($shippingMethodId: ID, $date: Date) {
  deliveryIntervals(shippingMethodId: $shippingMethodId, date: $date) {
    id
    timeStart
    timeEnd
  }
}
    `;

/**
 * __useDeliveryIntervalsQuery__
 *
 * To run a query within a React component, call `useDeliveryIntervalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryIntervalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryIntervalsQuery({
 *   variables: {
 *      shippingMethodId: // value for 'shippingMethodId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDeliveryIntervalsQuery(baseOptions?: Apollo.QueryHookOptions<DeliveryIntervalsQuery, DeliveryIntervalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryIntervalsQuery, DeliveryIntervalsQueryVariables>(DeliveryIntervalsDocument, options);
      }
export function useDeliveryIntervalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryIntervalsQuery, DeliveryIntervalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryIntervalsQuery, DeliveryIntervalsQueryVariables>(DeliveryIntervalsDocument, options);
        }
export type DeliveryIntervalsQueryHookResult = ReturnType<typeof useDeliveryIntervalsQuery>;
export type DeliveryIntervalsLazyQueryHookResult = ReturnType<typeof useDeliveryIntervalsLazyQuery>;