import * as irskomImages from "../assets/images/irskomImages";
import * as parhatoImages from "../components/assets/images/images"

let images: { [key: string]: string } = {};

if (process.env.REACT_APP_CLIENT === "IRSKOM") {
  images = {
    ...images,
    ...irskomImages,
  };
} else{
  images = {
    ...images,
    ...parhatoImages,
  };}

export { images };
