import React, { HTMLAttributes, useCallback, useEffect, useState } from "react";

import { IonImg } from "@ionic/react";

import classNames from "classnames/bind";
import styles from "./Image.module.scss";
import { images } from "../../config/images";
import { ProductImage, ProductImageTypes } from "../../data/types/entities";

type ProductThumbnailImageProps = {
  src?: HTMLIonImgElement["src"];
  image?: ProductImage;
  alt?: HTMLIonImgElement["alt"];
  downsize?: boolean;
  lazyLoad?: boolean;
} & HTMLAttributes<HTMLImageElement> &
  HTMLAttributes<HTMLIonImgElement>;

const cx = classNames.bind(styles);
const CDN_DOMAIN = process.env.REACT_APP_CDN_DOMAIN!;

const changeHost = (src: string): string => {
  try {
    const url = new URL(src);
    url.host = CDN_DOMAIN;
    return url.href;
  } catch {
    return src;
  }
};

const processImgSrc = (src: string, resize: boolean = true): string => {
  const dotSymbolIndex = src.lastIndexOf(".");
  const resized = resize ? src.substring(0, dotSymbolIndex) + "-300x300" + src.substring(dotSymbolIndex) : src;
  return changeHost(resized);
};

const defaultPlaceholder = images.placeholderLogo;

const Image = ({
  image,
  src = image?.src ?? "",
  className,
  downsize = true,
  lazyLoad = true,
  placeholder = defaultPlaceholder,
  ...imgProps
}: ProductThumbnailImageProps) => {
  const handleImgError = useCallback(
    (e: any) => {
      e.currentTarget.src = placeholder;
    },
    [src, image]
  );

  const [imageUrl, setImageUrl] = useState(processImgSrc(placeholder!, false));

  const imageType = image ? image.type : ProductImageTypes.img;

  useEffect(() => {
    if (imageType === ProductImageTypes.base64) {
      return;
    }

    const img = new window.Image();
    img.onload = function () {
      setImageUrl(img.src);
    };
    img.src = src ? processImgSrc(src, downsize) : placeholder;
  }, [src, image, imageType, downsize, placeholder]);

  const getImgSrc = useCallback(() => {
    return src?.length && imageType === ProductImageTypes.base64 ? `data:image/jpeg;base64, ${src}` : imageUrl;
  }, [src, imageType, imageUrl]);

  return lazyLoad ? (
    <IonImg src={getImgSrc()} className={cx(className, "Image")} onError={handleImgError} {...imgProps} />
  ) : (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img src={getImgSrc()} className={cx(className, "Image")} onError={handleImgError} {...imgProps} />
  );
};

export { Image };
