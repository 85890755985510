import { codeBaseVersion } from "../../../App";
import {
  Category,
  Coupon,
  CreateOrderData,
  PaymentMethodIdEnum,
  Product,
  ProductImageTypes,
  ProductReview,
  ProductType,
  ShippingMethod,
  ShippingMethodDeliveryType,
  ShippingMethodType,
  ShippingZone,
  TOrder,
  User,
} from "../../types/entities";
import {
  CreateParhatoOrder,
  Customer,
  ParhatoCategory,
  ParhatoCoupon,
  ParhatoOrder,
  ParhatoProduct,
  ParhatoProductReview,
} from "./types";
import {
  ShippingMethodCode,
  ShippingMethodMethodType,
  ShippingMethodsByIdQuery,
} from "../../../generated/graphql";
import { useShippingZonesState } from "../../../providers/shippingZones/useShippingZones";
import { getPlatforms } from "@ionic/react";

/**
 *
 * @param {string}  htmlEntites

 * @returns string return value without entities
 */
export function removeHtmlEntites(value: string): string {
  let htmlSymbolCodesMap = {
    "&nbsp;": " ",
    "&lt;": "<",
    "&gt;": ">",
    "&amp;": "&",
    "&quot;": '"',
    "&apos;": "'",
    "&cent;": "¢",
    "&pound;": "£",
    "&yen;": "¥",
    "&euro;": "€",
    "&copy;": "©",
    "&reg;": "®",
    "&#160;": " ",
    "&#60;": "<",
    "&#62;": ">",
    "&#38;": "&",
    "&#34;": '"',
    "&#39;": "'",
    "&#162;": "¢",
    "&#163;": "£",
    "&#165;": "¥",
    "&#8364;": "€",
    "&#169;": "©",
    "&#174;": "®",
  };

  let symbol: keyof typeof htmlSymbolCodesMap;
  for (symbol in htmlSymbolCodesMap) {
    const after = htmlSymbolCodesMap[symbol];
    const pattern = new RegExp(symbol, "g");
    value = value.replace(pattern, after);
  }
  return value;
}

/**
 * @param order take a parhato order and convert to base order
 */
export function convertParhatoOrderToBaseOrder(order: ParhatoOrder): TOrder {
  const metaDataObject: any = {};

  order?.meta_data.forEach((meta_data) => {
    metaDataObject[meta_data.key] = meta_data.value;
  });

  return {
    id: order.id,
    dateCreated: order.date_created,
    number: order.number,
    lineItems: order.line_items.map((item) => ({
      ...item,
      id: String(item.id),
      productId: String(item.product_id),
      variationId: String(item.variation_id),
      price: String(item.price),
      image: { src: item.image.src, type: ProductImageTypes.img },
    })),
    shippingLine: order.shipping_lines[0]
      ? {
        shipId: String(order.shipping_lines[0].id),
        methodId: order.shipping_lines[0].method_id! as ShippingMethodCode,
        methodTitle: order.shipping_lines[0].method_title,
        total: String(order.shipping_lines[0].total),
      }
      : undefined,
    currencySymbol: order.currency_symbol,
    total: parseFloat(order.total),
    discountTotal: order.discount_total,
    shippingTotal: order.shipping_total,
    customerNote: order.customer_note,
    status: order.status,
    paymentMethod: {
      id: order.payment_method,
      title: order.payment_method_title,
      type: order.payment_method === "bacs"
        ? PaymentMethodIdEnum.card
        : PaymentMethodIdEnum.cash,
    },
    deliveryTime: metaDataObject["pi_delivery_time"],
    deliveryDate: metaDataObject["pi_delivery_date"],
    customer: {
      id: String(order.customer_id),
      email: order.billing.email,
      phone: order.billing.phone,
      lastName: order.billing.last_name,
      firstName: order.billing.first_name,
      whatsappNumber: metaDataObject["_order_whatsapp"],
    },
    address: {
      city: order.shipping.city,
      house: order.shipping.address_2,
      street: order.shipping.address_1,
      // postcode: order.billing.postcode,
      latitude: metaDataObject["latitude"],
      longitude: metaDataObject["longitude"],
      intercom: metaDataObject["domofon"],
      floor: metaDataObject["billing_address_5"],
      isFlat: metaDataObject["kv"] === "Квартира",
      entrance: metaDataObject["billing_address_4"],
      fullAddress: metaDataObject["search_address"],
      apartmentNumber: metaDataObject["billing_address_3"],
    },
  };
}

/**
 * Сhange the created order to the parhato type
 * @param order take a base order without id and convert to parhato order
 */
export function convertCreateOrderToParhato(
  order: CreateOrderData,
): Partial<CreateParhatoOrder> {
  const metaData = [
    {
      key: "_order_whatsapp",
      value: order.customer?.whatsappNumber,
    },
    {
      key: "app_info",
      value: `from parhato app v${codeBaseVersion}; ${getPlatforms()}`,
    },
    {
      key: "kv",
      value: order.address?.isFlat ? "Квартира" : "Частный дом",
    },
    {
      key: "domofon",
      value: order.address?.intercom || "",
    },
    {
      key: "billing_address_3",
      value: order.address?.apartmentNumber || "",
    },
    {
      key: "billing_address_4",
      value: order?.address?.entrance || "",
    },
    {
      key: "search_address",
      value: order.address?.fullAddress || "",
    },
    {
      key: "billing_address_5",
      value: order.address?.floor || "",
    },
    {
      key: "longitude",
      value: order.address?.longitude || "",
    },
    {
      key: "latitude",
      value: order.address?.latitude || "",
    },
  ];

  if (order.deliveryDate) {
    metaData.push(
      {
        key: "pi_system_delivery_date",
        value: order.deliveryDate!.getFullYear() +
          "/" +
          String(order.deliveryDate!.getMonth() + 1).padStart(2, "0") +
          "/" +
          String(order.deliveryDate!.getDate()).padStart(2, "0"),
      },
      {
        key: "pi_delivery_date",
        value: String(order.deliveryDate!.getDate()).padStart(2, "0") +
          "." +
          String(order.deliveryDate!.getMonth() + 1).padStart(2, "0") +
          "." +
          order.deliveryDate!.getFullYear(),
      },
      {
        key: "pi_delivery_type",
        value: "delivery",
      },
      {
        key: "pi_delivery_time",
        value: order.deliveryTime,
      },
    );
  }

  return {
    customer_id: Number(order.customer?.id!),

    line_items: order.lineItems?.map((item) => {
      return {
        price: item.price,
        quantity: item.quantity,
        product_id: Number(item.id),
        variation_id: item.variationId ? Number(item.variationId) : 0,
        total: item.total,
      };
    }),

    status: order.status,
    customer_note: order.customerNote,
    shipping_lines: [
      {
        method_id: order.shippingLine.methodId!,
        method_title: order.shippingLine.methodTitle!,
        total: order.shippingLine.total ?? "0.00",
      },
    ],
    payment_method: order.paymentMethod.id,
    payment_method_title: order.paymentMethod.title,

    meta_data: metaData,

    billing: {
      notice: "",
      company: "",
      country: "",
      billing_country: "",

      phone: order?.customer?.phone ?? "",
      email: order?.customer?.email ?? "",

      last_name: order?.customer?.lastName ?? "",
      first_name: order?.customer?.firstName ?? "",

      billing_city: order?.address?.city ?? "",
      billing_address_2: order?.address?.house ?? "",
      billing_address_1: order?.address?.street ?? "",
      billing_address_5: order?.address?.floor ?? "",
      billing_address_4: order?.address?.entrance ?? "",
      billing_address_3: order?.address?.apartmentNumber ?? "",

      address_1: order?.address?.street ?? "",
      address_2: order?.address?.house ?? "",

      city: order?.address?.city ?? "",
      kv: order?.address?.apartmentNumber ?? "",
      postcode: "",
      domofon: order?.address?.intercom ?? "",

      state: order?.address?.city ?? "",
      latitude: order?.address?.latitude ?? "",
      longitude: order?.address?.longitude ?? "",
      search_address: order?.address?.fullAddress ?? "",
    },
    shipping: {
      company: "",
      country: "",

      address_1: order?.address?.street ?? "",
      address_2: order?.address?.house ?? "",

      city: order?.address?.city ?? "",

      shipping_phone: order?.customer?.whatsappNumber ?? "",
      first_name: order?.customer?.firstName ?? "",
      last_name: order?.customer?.lastName ?? "",
      phone: order?.customer?.phone ?? "",

      postcode: "",
      state: order?.address?.city ?? "",
    },
    coupon_lines: order.coupons?.map(({ code }) => ({ code })),
    reward_points: order.rewardPoints,
    cart_total: order.total,
  };
}

export function convertShippingMethod(
  shippingMethod:
    ShippingMethodsByIdQuery["customerShippingMethodsByZoneId"]["edges"][
      number
    ]["node"],
): ShippingMethod {
  const shippingMethodDeliveryTypeMap: Record<
    ShippingMethodMethodType,
    ShippingMethodDeliveryType
  > = {
    [ShippingMethodMethodType.Default]: ShippingMethodDeliveryType.default,
    [ShippingMethodMethodType.NextDay]: ShippingMethodDeliveryType.nextDay,
  };

  const shippingMethodTypeMap: Record<ShippingMethodCode, ShippingMethodType> =
    {
      [ShippingMethodCode.CdekCourier]: ShippingMethodType.courier,
      [ShippingMethodCode.CdekPoint]: ShippingMethodType.pickupPoint,
      [ShippingMethodCode.FlatRate]: ShippingMethodType.flatRate,
      [ShippingMethodCode.FreeShipping]: ShippingMethodType.freeShipping,
      [ShippingMethodCode.PickupPoint]: ShippingMethodType.pickupPoint,
      [ShippingMethodCode.TaxiShipping]: ShippingMethodType.taxiShipping,
    };

  return {
    id: shippingMethod.id,
    description: shippingMethod.description,
    enabled: shippingMethod.isEnabled,
    methodDeliveryType:
      shippingMethodDeliveryTypeMap[shippingMethod.methodType],
    methodId: shippingMethod.code,
    methodType: shippingMethodTypeMap[shippingMethod.code],
    title: shippingMethod.title,
    cost: shippingMethod.cost,
    minAmount: shippingMethod.minAmount,
    minDeliveryDays: shippingMethod.minDeliveryDays,
    maxDeliveryDays: shippingMethod.maxDeliveryDays,
  };
}

export function converParhatoUserToUser(user: Customer): User {
  return {
    id: String(user.id),
    firstName: user.first_name,
    lastName: user.last_name,
    phone: user.billing.phone,
    email: user.email,
    userName: user.username,
    token: "",
  };
}

export function converParhatoCoupon(coupon: ParhatoCoupon): Coupon {
  return {
    id: String(coupon.id),
    code: coupon.code,
    discountAmount: parseFloat(coupon.amount),
    discountType: coupon.discount_type,
    minimumAmount: parseFloat(coupon.minimum_amount),
    maximumAmount: parseFloat(coupon.maximum_amount),
    dateExpires: coupon.date_expires,
    individualUse: coupon.individual_use,
    freeShipping: coupon.free_shipping,
    excludeSaleItems: coupon.exclude_sale_items,
    productIds: coupon.product_ids,
    excludedProductIds: coupon.excluded_product_ids,
    productCategories: coupon.product_categories,
    excludedProductCategories: coupon.excluded_product_categories,
  };
}

export const convertParhatoProduct = (
  parhatoProduct: ParhatoProduct,
): Product => {
  return {
    id: String(parhatoProduct.id),
    name: parhatoProduct.name,
    price: parhatoProduct.price,
    onSale: parhatoProduct.on_sale,
    stockStatus: parhatoProduct.stock_status,
    description: parhatoProduct.description,
    attributes: parhatoProduct.attributes?.map((attr) => ({
      ...attr,
      id: String(attr.id),
    })),
    categories: parhatoProduct.categories?.map((category) => ({
      ...category,
      id: String(category.id),
    })),
    stockQuantity: parhatoProduct.stock_quantity,
    type: parhatoProduct.type as ProductType,
    purchasable: parhatoProduct.purchasable,
    images: parhatoProduct.images.map((img) => ({
      src: img.src,
      type: ProductImageTypes.img,
    })),
    soldIndividually: parhatoProduct.sold_individually,
    groupedProducts: parhatoProduct.grouped_products?.map((id) => String(id)),
    priceHtml: parhatoProduct.price_html,
    variations: parhatoProduct.variations?.map((id) => String(id)),
    averageRating: parhatoProduct.average_rating,
    dateCreated: parhatoProduct.date_created,
    defaultAttributes: parhatoProduct.default_attributes,
    parentId: String(parhatoProduct.parent_id),
    discountPrice: null,
    weight: parhatoProduct.weight || "0",
  };
};

export const convertParhatoCategory = (
  parhatoCategory: ParhatoCategory,
): Category => {
  return {
    id: String(parhatoCategory.id),
    name: removeHtmlEntites(parhatoCategory.name),
    description: parhatoCategory.description,
    image: parhatoCategory.image
      ? {
        src: parhatoCategory.image.src,
        id: parhatoCategory.image.id,
        name: parhatoCategory.image.name,
        alt: parhatoCategory.image.alt,
      }
      : undefined,
    parent: String(parhatoCategory.parent),
  };
};

export const getCurrentShippingZoneId = (): ShippingZone["id"] | undefined => {
  return useShippingZonesState.getState().shippingZone?.id;
};

export const converParhatoReview = (
  review: ParhatoProductReview,
): ProductReview => {
  return {
    id: String(review.id),
    review: review.review,
    dateCreated: review.date_created,
    fullName: review.reviewer,
    rating: review.rating,
  };
};
