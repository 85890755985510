import { productsClient } from "../../data/clients";
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import { PRODUCTS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { Product, ProductsQueryParams } from "../../data/types/entities";

type QueryParams = ProductsQueryParams | undefined;

type QueryKey = ["products", QueryParams];
type Options = UseInfiniteQueryOptions<
  Product[] | undefined,
  any,
  Product[] | undefined,
  Product[] | undefined,
  QueryKey
>;

const useGetProductsInfiniteQuery = (config: { options?: Options; params?: QueryParams } = {}) => {
  return useInfiniteQuery<Product[] | undefined, any, Product[] | undefined, QueryKey>({
    queryKey: ["products", config.params],
    queryFn: async (queryContext: QueryFunctionContext<QueryKey, QueryParams>) => {
      const {
        pageParam = {
          pageNumber: config.params?.pageNumber,
          categoryId: config.params?.categoryId,
          saleType: config.params?.saleType,
        },
        queryKey,
      } = queryContext;
      const configParams = queryKey[1];
      return await productsClient.getProducts({
        ...configParams,
        pageNumber: String(pageParam!.pageNumber),
        categoryId: pageParam!.categoryId,
        saleType: pageParam!.saleType,
      });
    },
    getNextPageParam: (lastRes, allData) => {
      const perPage = config.params?.pageSize ? Number(config.params.pageSize) : 10;
      const itemsAmount = allData.reduce((a, b) => a + (b?.length ?? 0), 0);

      if (lastRes?.length === perPage) {
        return {
          ...config.params,
          pageNumber: Math.ceil(itemsAmount / perPage) + 1,
        };
      }

      // Writing this explicitly to indicate that if we
      // return 'undefined', then no query will be made
      return undefined;
    },
    cacheTime: PRODUCTS_QUERY_CACHE_EXPIRE_TIME_MS,
    staleTime: PRODUCTS_QUERY_CACHE_EXPIRE_TIME_MS,
    ...config.options,
  });
};

export default useGetProductsInfiniteQuery;
