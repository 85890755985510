import React from 'react';

// Import Ionic
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./OrderSummary.module.scss";

interface IOrderSummaryNote {
    title: string,
    note: string,
}

const cx = classNames.bind(styles);

const OrderSummaryNote:React.FC<IOrderSummaryNote> = ({
    title,
    note,
    }) => {
    return (
        <IonCard className={cx('orderSummary')}>
            <IonCardHeader className={cx("orderSummaryHeader")} color="primary">
                <IonCardTitle className={cx('orderSummaryTitle')}>
                    {title}
                </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className={cx("ion-padding ion-margin-top card-disable", "orderSummaryDescription")}>
                {note}
            </IonCardContent>
        </IonCard>
    );
};

export default OrderSummaryNote;