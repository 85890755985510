import { ordersClient } from "../../data/clients";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../../config/query-client";
import { TOrder } from "../../data/types/entities";
import { ORDERS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";

const useGetOrderByIdQuery = (id: string) => {
  return useQuery(
    ["order", id],
    async () => {
      return await ordersClient.getOrderById(id);
    },
    {
      cacheTime: ORDERS_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: ORDERS_QUERY_CACHE_EXPIRE_TIME_MS,
      initialData: () => {
        return queryClient.getQueryData<TOrder[]>(["orders"])?.find((order: TOrder) => order.id === id);
      },
    }
  );
};
export default useGetOrderByIdQuery;
