import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { ShippingZonesActions, ShippingZonesState } from "./index";
import { queryClient } from "../../config/query-client";
import { useCartDataState } from "../cartDataStore";

const initialState: ShippingZonesState = {
  shippingZone: undefined,
};

export const useShippingZonesState = create<ShippingZonesState & ShippingZonesActions>()(
  persist<ShippingZonesState & ShippingZonesActions>(
    (set, get) => ({
      ...initialState,

      resetShippingZone: () => {
        set((state): ShippingZonesState => {
          return {
            ...state,
            shippingZone: undefined,
          };
        });
      },

      setShippingZone: (zone) => {
        const { shippingZone } = get();
        set((state): ShippingZonesState => {
          return {
            ...state,
            shippingZone: zone,
          };
        });

        if (shippingZone?.id !== zone?.id) {
          queryClient.invalidateQueries(["products"]);
          queryClient.invalidateQueries(["pickup-points"]);
          useCartDataState.getState().resetCart();
        }
      },
    }),
    {
      name: "shipping-zones-data",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

const useShippingZonesData = () => {
  return useShippingZonesState((state) => ({
    ...state,
  }));
};

export { useShippingZonesData };
