import React from "react";
import { PasswordInput } from "../PasswordInput/PasswordInput";
import { validatePassword } from "../../utils/utils";

interface ValidatePasswordInputProps {
  name?: string;
  value: string;
  className: string;

  required: boolean;

  setPassword: (value: string) => void;
  setPasswordError: (passErr: string) => void;
  setIsPasswordValid: (isPassErr: boolean) => void;
}

const ValidatePasswordInput = ({
  className,
  name,
  value,
  required,
  setPassword,
  setPasswordError,
  setIsPasswordValid,
}: ValidatePasswordInputProps) => {

  function handlePasswordInput(value: string) {
    setPassword(value);
    
    const error = validatePassword(value);

    setPasswordError(error);
    setIsPasswordValid(!error);
  }

  return (
    <PasswordInput
      onIonChange={(e: any) => handlePasswordInput(e.detail.value!)}
      className={className}
      required={required}
      value={value}
      name={name}
    />
  );
};

export { ValidatePasswordInput };
