import { ICouponsClient } from "../../types/clients";
import { Coupon } from "../../types/entities";
import BaseClient from "../base-client";
import { ParhatoCoupon } from "./types";
import { converParhatoCoupon } from "./utils";

class CouponsClient extends BaseClient implements ICouponsClient {
  async getCoupon(code: string): Promise<Coupon | null> {
    const response = await this.instance.get<ParhatoCoupon[]>("/wp-json/wc/v3/coupons", {
      params: {
        code,
      },
    });

    if (response.data[0]) {
      return converParhatoCoupon(response.data[0]);
    }

    return null;
  }
}

export default CouponsClient;
