import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { ordersClient } from "../../data/clients";
import { AddReviewOrderMutationHookResult, AddReviewOrderMutationVariables } from "../../generated/graphql";
import { queryClient } from "../../config/query-client";

const useAddReviewSetOrder = (
  orderNumber: string,
  options: UseMutationOptions<AddReviewOrderMutationHookResult, any, AddReviewOrderMutationVariables> = {}
) => {
  return useMutation(
    ["review", "add"],
    async (orderReqData) => {
      return ordersClient.addReviewOrder(orderReqData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["review", orderNumber]);
      },
      ...options,
    }
  );
};

export default useAddReviewSetOrder;
