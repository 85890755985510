import React, { ReactNode, useRef, useState } from "react";
import styles from "./CustomSlider.module.scss";

import classNames from "classnames/bind";
const cx = classNames.bind(styles);

const CustomSlider = ({ children }: { children: ReactNode }) => {
  const slider = useRef<HTMLDivElement | null>(null);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const velX = useRef(0);
  let momentumID = useRef(0);

  const beginMomentumTracking = () => {
    cancelMomentumTracking();
    momentumID.current = requestAnimationFrame(momentumLoop);
  };
  const cancelMomentumTracking = () => {
    cancelAnimationFrame(momentumID.current);
  };
  const momentumLoop = () => {
    if (!slider.current) return;
    slider.current.scrollLeft += velX.current;
    velX.current = velX.current * 0.95;
    if (Math.abs(velX.current) > 0.5) {
      momentumID.current = requestAnimationFrame(momentumLoop);
    }
  };

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    velX.current = 0;
    if (!slider.current) return;
    setIsDown(true);
    slider.current.classList.add(cx("active-custom-slider"));
    setStartX(e.pageX - slider.current.offsetLeft);
    setScrollLeft(slider.current.scrollLeft);
    cancelMomentumTracking();
  };

  const onMouseUp = () => {
    setIsDown(false);
    slider?.current?.classList.remove(cx("active-custom-slider"));
    beginMomentumTracking();
  };

  const onMouseLeave = () => {
    setIsDown(false);
    slider?.current?.classList.remove(cx("active-custom-slider"));
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDown || !slider.current) return;
    e.preventDefault();
    const x = e.pageX - slider.current.offsetLeft;
    const walk = (x - startX) * 2.5; //scroll-fast
    const prevScrollLeft = slider.current.scrollLeft;
    slider.current.scrollLeft = scrollLeft - walk;
    velX.current = slider.current.scrollLeft - prevScrollLeft;
  };

  const onWheel = () => {
    cancelMomentumTracking();
  };

  return (
    <div className="flex flex-col bg-white m-auto p-auto">
      <div className="flex hide-scroll-bar">
        <div
          ref={slider}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onWheel={onWheel}
          className={`${cx("custom-products-slider")} flex flex-nowrap`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export { CustomSlider };
