import React, { useContext, useMemo } from "react";

// Import Ionic
import { IonItem, IonLabel } from "@ionic/react";

// Import Styles
import classNames from "classnames/bind";
import styles from "./CommonFormElements.module.scss";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

const cx = classNames.bind(styles);

interface ICommonFormElement {
  label?: string | number;
  isValid?: boolean;
  errorMessage?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  classListItem?: string;
  classListError?: string;
  commonLabelClassName?: string;
}

const CommonFormElement: React.FC<ICommonFormElement> = ({
  label,
  errorMessage,
  classListItem = "",
  classListError = "",
  isValid = true,
  commonLabelClassName,
  ...props
}) => {
  const { state } = useContext(AppContext);

  const ts = useMemo(() => state.config.languageJson, []);

   return (
    <IonItem lines="none" className={cx("commonItem", "ion-no-padding", classListItem)}>
      <IonLabel position={"stacked"} className={cx("commonLabel", commonLabelClassName)}>
        {label}
      </IonLabel>
      {props.children}
      <span
        className={cx(
          "commonErrorMessage",
          { commonErrorMessage__collapsed: !errorMessage || isValid },
          classListError
        )}
      >
        {errorMessage ?? ts["Required field"]}
      </span>
    </IonItem>
  );
};

export default CommonFormElement;
