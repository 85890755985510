import BaseClient from "../base-client";
import { IPaymentMethodsClient } from "../../types/clients";
import { PaymentMethod, PaymentMethodIdEnum } from "../../types/entities";

class PaymentMethodsClient extends BaseClient implements IPaymentMethodsClient {
  async getPaymentMethods() {
    const data: PaymentMethod[] = [
      {
        id: "1",
        title: "Наличными при получении",
        type: PaymentMethodIdEnum.cash,
        enabled: true,
      },
      {
        id: "2",
        title: "Онлайн",
        type: PaymentMethodIdEnum.card,
        enabled: true,
      },
    ];
    return data;
  }
}

export default PaymentMethodsClient;
