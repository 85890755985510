import { WooComAPI, WCProduct, WCMetaData, WCBillingData, WCShippingData } from "../WooComAPI/woo-com-api";

export type ParhatoAddressObject = {
  text: string;
  country: string;
  province: string;
  area: string;
  locality: string;
  district: string;
  street: string;
  house: string;
  point: string;
  location?: number[];
  postcode: string;
};

export type ParhatoDriver = {
  id: number;
  display_name: string;
};

export type CustomerRegisterCredentials = {
  first_name: string;
  last_name: string;
  phone: string;
  password: string;
  email?: string;
  referral_link?: string;
  referral?: string;
  own_version_referral?: string;
  verification_code: string;
  verification_code_id: number | string;
};

export type ResetPasswordCredentials =
  | {
      phone: string;
      new_password: string;
      verification_code: string;
      verification_code_id: number;
    }
  | { email: string };

export type WPApiErrorData = {
  code: string;
  message: string;
  data?: {
    status: number;
    [k: string]: any;
  };
};

export type CreateOrderData = {
  payment_method: number | string;
  payment_method_title: string;
  billing: WCBillingData;
  shipping: WCShippingData;
  customer_id: number | string;
  line_items: {
    price: string;
    product_id: number;
    quantity: number;
    total: string;
  }[];
  shipping_lines: {
    method_id: string;
    method_title: string;
    ship_id: number | string;
    total: string;
  }[];
  status?: string;
  meta_data: WCMetaData[];
  customer_note?: string;
  reward_points?: number;
  cart_total?: number;
};

interface ParhatoRESTApiInterface {
  baseUrl: string;

  searchWCProducts(keyword: string, page: number): Promise<WCProduct[]>;

  searchAddress(keyword: string, abortSignal?: AbortSignal): Promise<ParhatoAddressObject[]>;

  getDriverById(id: number | string): Promise<ParhatoDriver>;

  // sendVerificationCode(phoneNumber: string): Promise<{ code_id: number, phone_number: string }>,
  verifyCode(code: string, phoneNumber: string, codeID: number | string): Promise<any>;

  register<T extends CustomerRegisterCredentials | { phone: string }>(
    credentials: T
  ): Promise<
    T extends CustomerRegisterCredentials
      ? {
          first_name: string;
          last_name: string;
          user_login: string;
          user_email: string;
          display_name: string;
          nickname: string;
          role: string;
        }
      : {
          code_id: number;
          phone_number: string;
        }
  >;

  resetPassword<T extends ResetPasswordCredentials | { phone: string }>(
    credentials: T
  ): Promise<
    T extends ResetPasswordCredentials
      ? {
          success: boolean;
          login: string;
        }
      : {
          code_id: number;
          phone_number: string;
        }
  >;

  getBonusPointsAmount(): Promise<{
    user_id: number;
    points: number;
  }>;

  createOrder(data: CreateOrderData): Promise<any>;
}

class WPApiError extends Error {
  error: WPApiErrorData;

  constructor(error: WPApiErrorData) {
    super(error.message);
    this.name = "WPApiError";
    this.error = error;
  }
}

const ParhatoRESTApi: ParhatoRESTApiInterface = {
  baseUrl: WooComAPI.url + "/wp-json/parhato-api/v1",

  // Searches products by keyword and page
  searchWCProducts(keyword, page = 1) {
    const params = new URLSearchParams({
      q: keyword,
      page: String(page),
    });

    const url = this.baseUrl + "/search-products?" + params;

    return fetch(url, { method: "GET" }).then((res) => res.json());
  },

  // Returns suggestions for address by keyword
  searchAddress(keyword, abortSignal) {
    const url = this.baseUrl + "/search-address?search=" + keyword;
    if (abortSignal) {
      return fetch(url, {
        method: "GET",
        signal: abortSignal,
      }).then((res) => res.json());
    }
    return fetch(url, { method: "GET" }).then((res) => res.json());
  },

  getDriverById(id) {
    const url = this.baseUrl + "/drivers/" + id;

    return fetch(url, { method: "GET" }).then((res) => {
      if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`);
      }

      return res.json();
    });
  },

  // sendVerificationCode(phoneNumber) {
  //     const url = this.baseUrl + '/send-verification-code';

  //     return fetch(url, {
  //             method: 'POST',
  //             headers: {
  //                 "Content-Type": "application/json"
  //             },
  //             body: JSON.stringify({
  //                 phoneNumber,
  //             }),
  //         })
  //         .then(res => {
  //             if (!res.ok) {
  //                 throw new Error(`${res.status} ${res.statusText}`);
  //             }

  //             return res.json()
  //         });
  // },

  verifyCode(code, phoneNumber, codeID) {
    const url = this.baseUrl + "/verify-code";

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        phoneNumber,
        codeID,
      }),
    }).then((res) => {
      if (!res.ok) {
        return throwWPApiError(res);
      }

      return res.json();
    });
  },

  register(credentials) {
    const url = this.baseUrl + "/register";

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    }).then((res) => {
      if (!res.ok) {
        return throwWPApiError(res);
      }

      return res.json();
    });
  },

  resetPassword(credentials) {
    const url = this.baseUrl + "/reset-password";

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    }).then((res) => {
      if (!res.ok) {
        return throwWPApiError(res);
      }

      return res.json();
    });
  },

  getBonusPointsAmount() {
    const url = this.baseUrl + "/rewards/get-points";
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: WooComAPI.getBearerAuthToken(),
      },
    }).then((res) => {
      if (!res.ok) {
        return throwWPApiError(res);
      }

      return res.json();
    });
  },

  createOrder(data) {
    const url = this.baseUrl + "/orders";

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: WooComAPI.getBearerAuthToken(),
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (!res.ok) {
        return throwWPApiError(res);
      }

      return res.json();
    });
  },
};

const throwWPApiError = async (res: Response) => {
  const errorData: WPApiErrorData = await res.json();

  throw new WPApiError(errorData);
};

const getRequestHeaders = () => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Content-Type", "application/x-www-form-urlencoded");
  requestHeaders.set("Accept", "application/json");

  return requestHeaders;
};

export { ParhatoRESTApi, WPApiError };
