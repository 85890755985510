import ParhatoClients from "./Parhato";
import IrskomClients from "./IrsKom/";
import {
  IAuthClient,
  IBannersClient,
  ICategoriesClient,
  ICouponsClient,
  IDeliveryDatesClient,
  IOrdersClient,
  IPaymentMethodsClient,
  IProductsClient,
  ISettingsClient,
  IShippingMethodsClient,
  IShippingZonesClient,
  IUsersClient,
} from "../types/clients";

export type DataClients = {
  authClient: IAuthClient;
  usersClient: IUsersClient;
  bannersClient: IBannersClient;
  categoriesClient: ICategoriesClient;
  productsClient: IProductsClient;
  settingsClient: ISettingsClient;
  ordersClient: IOrdersClient;
  shippingZonesClient: IShippingZonesClient;
  paymentMethodsClient: IPaymentMethodsClient;
  shippingMethodsClient: IShippingMethodsClient;
  couponsClient?: ICouponsClient;
  deliveryDatesClient?: IDeliveryDatesClient;
};

const ClientsMap = {
  IRSKOM: IrskomClients as DataClients,
  PARHATO: ParhatoClients as DataClients,
};

const clientName = process.env.REACT_APP_CLIENT! as keyof typeof ClientsMap;

if (!ClientsMap[clientName]) {
  throw new Error(`Missing client for ${clientName}`);
}

// clients = {...ParhatoClients, ...IrskomClients};

const {
  authClient,
  categoriesClient,
  usersClient,
  settingsClient,
  bannersClient,
  ordersClient,
  paymentMethodsClient,
  shippingMethodsClient,
  productsClient,
  shippingZonesClient,
  couponsClient,
  deliveryDatesClient,
} = ClientsMap[clientName];

// TODO:: this is a temporary override until all irskom clients work
// const { productsClient, bannersClient } = ParhatoClients;

export {
  authClient,
  categoriesClient,
  usersClient,
  settingsClient,
  bannersClient,
  ordersClient,
  paymentMethodsClient,
  shippingMethodsClient,
  productsClient,
  shippingZonesClient,
  couponsClient,
  deliveryDatesClient,
};
