import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { RouteComponentProps, useParams } from "react-router-dom";
// import { menuController } from '@ionic/core';
// Import Ionic Components
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  IonRow,
} from "@ionic/react";

// Import Icons
import { logoDropbox } from "ionicons/icons";

// Import Css File
import "./Products.scss";
import styles from "./Products.module.scss";
import classNames from "classnames/bind";

// Import Components
import Product from "../../components/Product/Product";
import { SendUserRequestFormFab } from "../../components/SendUserRequestFormFab/SendUserRequestFormFab";
import MaxWidthContainer from "../../components/UI/MaxWidthContainer/MaxWidthContainer";
import Footer from "../../components/Footer/Footer";
import PageTitle from "../../components/PageTitle/PageTitle";
import ToolbarDesktop from "../../components/Toolbar/ToolbarDesktop/ToolbarDesktop";
import ToolbarMobile from "../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import { generateObjectsWithId } from "../../utils/utils";
import { getQueryParamsFromPath, replaceEmbed } from "./utils";
import { useGetCategoryByIdQuery } from "../../providers/categories";
import ProductSkeleton from "../../components/UI/ProductSkeleton/ProductSkeleton";
import { useGetProductsInfiniteQuery } from "../../providers/products";
import { useGetSettingsQuery } from "../../providers/settings";

const cx = classNames.bind(styles);

const skeletonProducts = generateObjectsWithId(12);

const defaultParams = {
  min_price: 0.1,
};

interface RouteParams {
  id: string;
  name: string;
  type: string;
}

const Products: React.FC<RouteComponentProps<RouteParams>> = () => {
  const params = useParams<RouteParams>();

  const queryParams = useMemo(() => getQueryParamsFromPath(params), [params]);

  const productsSkeleton = useMemo(
    () =>
      skeletonProducts.map((product) => (
        <IonCol
          key={product.id}
          size={"12"}
          sizeSm={"4"}
          sizeMd={"3"}
          sizeLg={"2.4"}
          sizeXl={"2"}
          className={cx("productsRow__col")}
        >
          <ProductSkeleton />
        </IonCol>
      )),
    []
  );

  const { state } = useContext(AppContext);
  const {
    data: products,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isRefetching,
  } = useGetProductsInfiniteQuery({
    options: {
      enabled: !!queryParams,
    },
    params: {
      ...defaultParams,
      ...queryParams,
      pageNumber: "1",
      pageSize: "12",
    },
  });

  const infinite = useRef<HTMLIonInfiniteScrollElement | null>(null); // For Infinite Scrool Reference

  const [categoryDescription, setCategoryDescription] = useState("");
  const { data: appSettings } = useGetSettingsQuery();
  const { data: category } = useGetCategoryByIdQuery(params.id!);

  useEffect(() => {
    if (category?.description) {
      setCategoryDescription(replaceEmbed(category.description));
    } else {
      setCategoryDescription("");
    }
  }, [category]);

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton />
      </IonHeader>
      <IonContent scrollEvents={true} className="ion-content" id="productContent">
        <ToolbarDesktop
          breadcrumbs={[
            {
              title: params.id !== "0" ? params.name : state.config.languageJson[params.type],
            },
          ]}
        />

        <MaxWidthContainer classList={`global-content ${cx({ products__isEmpty: !products?.pages.length })}`}>
          <PageTitle title={params.id !== "0" ? params.name : state.config.languageJson[params.type]} />

          {categoryDescription && (
            <div>
              <div
                className={cx("categoryDescription__html")}
                dangerouslySetInnerHTML={{ __html: `${categoryDescription}` }}
              ></div>
            </div>
          )}

          {!!products?.pages.flat().some(Boolean) && !isRefetching && (
            <>
              <IonGrid>
                <IonRow className={cx("productsRow")}>
                  {products?.pages.flat().map((p) => {
                    return p ? (
                      <IonCol
                        key={p.id}
                        size={"12"}
                        sizeSm={"4"}
                        sizeMd={"3"}
                        sizeLg={"2.4"}
                        sizeXl={"2"}
                        className={cx("productsRow__col")}
                      >
                        <Product product={p} type="newList" />
                      </IonCol>
                    ) : null;
                  })}
                </IonRow>
              </IonGrid>
            </>
          )}

          {(isLoading || isRefetching) && (
            <IonGrid className={cx("width-100")}>
              <IonRow className={cx("productsRow")}>{productsSkeleton}</IonRow>
            </IonGrid>
          )}

          {!products?.pages?.flat().some(Boolean) && !isLoading && !isRefetching && (
            <IonGrid className={cx("productsNotFound")}>
              <IonRow>
                <IonCol size="12" className="ion-text-center animated fadeIn">
                  <IonIcon icon={logoDropbox} />
                  <h6>{state.config.languageJson["No Products Found!"]}</h6>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}

          <IonInfiniteScroll
            className={cx("infiniteScroll", "infiniteScroll--mobile")}
            // disabled={!hasNextPage && !isFetchingNextPage}
            threshold="100px"
            ref={infinite}
            onIonInfinite={async () => {
              if (hasNextPage) {
                await fetchNextPage();
              }
              infinite.current?.complete();
            }}
          >
            <IonInfiniteScrollContent loadingText={state.config.languageJson["Please Wait"]} loadingSpinner="bubbles" />
          </IonInfiniteScroll>
        </MaxWidthContainer>
        {appSettings?.showSendCustomerRequestButton && <SendUserRequestFormFab />}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Products;
