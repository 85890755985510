import { create } from "zustand";
import { persist } from "zustand/middleware";
import { OrderDataActions, OrderDataState } from ".";

const initialState: OrderDataState = {
  firstName: "",
  lastName: "",
  shippingMethod: null,
  paymentMethod: null,
  address: null,
  phoneNumber: "",
  deliveryDate: null,
  deliveryInterval: "",
  whatsAppNumber: "",
  customerNote: "",
  isPristine: true,
  updatedAt: null,
};

export const useOrderState = create<OrderDataState & OrderDataActions>()(
  persist<OrderDataState & OrderDataActions>(
    (set, get) => ({
      ...initialState,

      updateOrderData: (data: Partial<OrderDataState>) => {
        set({ ...data, isPristine: false, updatedAt: new Date() });
      },

      reset: () => {
        set(initialState);
      },
    }),
    {
      name: "order-data",
      storage: {
        getItem: (name) => {
          const data = JSON.parse(localStorage.getItem(name) ?? "");
          return {
            state: {
              ...data.state,
              deliveryDate: data.state.deliveryDate
                ? new Date(data.state.deliveryDate)
                : null,
              updatedAt: data.state.updatedAt
                ? new Date(data.state.updatedAt)
                : null,
            },
          };
        },
        setItem: (name, newValue) => {
          const str = JSON.stringify(newValue);
          localStorage.setItem(name, str);
        },
        removeItem: (name) => localStorage.removeItem(name),
      },
    },
  ),
);

const useOrderData = () => {
  return useOrderState((state) => ({
    ...state,
  }));
};

export default useOrderData;
