import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { authClient } from "../../data/clients";

const useVerifyCodeMutation = (options?: UseMutationOptions<any, any, any>) =>
  useMutation(async ({ code, phoneNumber, codeID }) => {
    const data = await authClient.verifyCode({ code, phoneNumber, codeID });

    return data;
  }, options);

export default useVerifyCodeMutation;
