import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IonContent, IonFooter, IonHeader, IonPage, useIonToast, useIonViewWillEnter } from "@ionic/react";
import ToolbarMobile from "../../../components/Toolbar/ToolbarMobile/ToolbarMobile";
import { ShippingAddressComponent } from "../../../components/ShippingAddressComponent/ShippingAddressComponent";
import ContinueButton from "../../../components/ContinueButton/ContinueButton";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";
import { useHistory } from "react-router";
import { DatetimeChangeEventDetail } from "@ionic/core/components";
import { getCurrentCurrencySymbol, isPhoneNumberValid } from "../../../utils";
import { PaymentTabButton, ShippingTabButton } from "../../../types";
import { getPaymentTypeIcon, getShippingTypeIcon } from "../../../providers/utils/utils";
import { useGetSettingsQuery } from "../../../providers/settings";
import {
  PaymentMethod,
  PaymentMethodIdEnum,
  ShippingMethod,
  ShippingMethodDeliveryType,
  ShippingMethodType,
} from "../../../data/types/entities";
import {
  useGetPaymentMethodsQuery,
  useGetPickupPoints,
  useGetShippingMethodsQuery,
} from "../../../providers/shippingAndPayment";
import { useAuth } from "../../../contexts/auth-context";
import { OrderDataState, useOrderData } from "../../../providers/orderDataStore";
import { useCartData } from "../../../providers/cartDataStore";
import { useShippingZonesData } from "../../../providers/shippingZones";
import { useUpdateProfileMutation } from "../../../providers/profile";
import { useAuthBySms, useGetSmsCode } from "../../../providers/Auth";
import { useSmsVerificationData } from "../../../providers/smsVerifivation";
import { AuthByCode } from "../../../components/AuthByCode/AuthByCode";

const ALLOW_GUEST_ORDER = process.env.REACT_APP_ALLOW_GUEST_ORDER === "yes";

const ShippingAddressPage = () => {
  let history = useHistory();

  const { user, updateUserData, login, isUserAuthenticated } = useAuth();
  const { state } = useContext(AppContext);
  const ts = useMemo(() => state.config.languageJson, [state.config.languageJson]);
  const { data: appSettings } = useGetSettingsQuery();
  const { shippingZone } = useShippingZonesData();

  const [presentToast] = useIonToast();
  const [shippingTabsButtons, setShippingTabsButtons] = useState<ShippingTabButton[]>([]);
  const [paymentTabsButtons, setPaymentTabsButtons] = useState<PaymentTabButton[]>([]);

  const presentErrorToast = useCallback(() => {
    presentToast({
      message: ts["An unexpected error occurred"],
      duration: 2000,
      color: "danger",
    });
    history.goBack();
  }, [presentToast]);
  const [present] = useIonToast();

  const [isSmsAlertOpened, setIsAlertSmsOpened] = useState(false);
  const verificationCodeData = useRef<{ code_id: string; phone_number: string } | null>(null);

  const { mutate, isLoading: isGettingSmsCodeLoading } = useGetSmsCode();
  const { mutate: mutateAuth, isLoading: isAuthLoading } = useAuthBySms();
  const { setWaitingTimer, canGetNewCode, sentSmsAgain, phoneNumber: prevPhoneNumber } = useSmsVerificationData();

  const {
    shippingMethod,
    firstName,
    lastName,
    phoneNumber,
    updateOrderData,
    address,
    paymentMethod,
    deliveryDate,
    deliveryInterval,
  } = useOrderData();

  const { getProductsTotalWeight, cartSubtotalValue } = useCartData();

  const handlePaymentMethods = (paymentMethods?: PaymentMethod[]) => {
    if (paymentMethods) {
      const enabledPaymentMethods = paymentMethods.filter((payment: PaymentMethod) => payment.enabled);
      const enabledTabsButtons = enabledPaymentMethods.map((payment) => {
        return {
          id: payment.id,
          icon: getPaymentTypeIcon(payment.type as PaymentMethodIdEnum),
          title: payment.title,
        };
      });

      setPaymentTabsButtons(enabledTabsButtons);
    }
  };

  const { data: paymentMethods, isFetching: isPaymentMethodsLoading } = useGetPaymentMethodsQuery({
    onError: presentErrorToast,
    onSuccess: handlePaymentMethods,
  });

  useEffect(() => {
    handlePaymentMethods(paymentMethods);
  }, [paymentMethods]);

  const handleShipingMethods = (shippingMethods?: ShippingMethod[]) => {
    if (shippingMethods) {
      setShippingTabsButtons(getShippingButtons(shippingMethods));
    }
  };

  const { data: shippingMethods, isFetching: isShippingMethodsLoading } = useGetShippingMethodsQuery(
    String(shippingZone?.id || ""),
    getProductsTotalWeight(),
    {
      enabled: (appSettings && !appSettings.isShippingZonesEnabled) || !!shippingZone?.id,
      onError: presentErrorToast,
      onSuccess: handleShipingMethods,
    }
  );

  useEffect(() => {
    handleShipingMethods(shippingMethods);
  }, [shippingMethods, cartSubtotalValue]);

  const { data: pickupPoints } = useGetPickupPoints({
    enabled: shippingMethod?.methodType === ShippingMethodType.pickupPoint,
  });

  const [isPhoneNumberInputValid, setIsPhoneNumberInputValid] = useState(false);
  const [isWhatsappPhoneNumberValid, setIsWhatsappPhoneNumberValid] = useState(true);

  const updateState = async () => {
    if (user) {
      const orderData: Partial<OrderDataState> = {
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phone,
        whatsAppNumber: user.phone,
        shippingMethod: null,
        paymentMethod: null,
        deliveryInterval: "",
      };

      updateOrderData(orderData);
    }

    if (user?.phone) {
      setIsPhoneNumberInputValid(isPhoneNumberValid(user.phone));
    }
  };

  const toggleIsSmsAlertOpened = useCallback(() => {
    setIsAlertSmsOpened((prev) => !prev);
  }, [isSmsAlertOpened]);

  const authUserBySms = (code: string) => {
    if (!verificationCodeData.current) return;

    mutateAuth(
      {
        firstName: firstName,
        lastName: lastName,
        phone: verificationCodeData.current?.phone_number,
        verificationCode: code,
        verificationCodeId: verificationCodeData.current?.code_id,
      },
      {
        onError: (error) => {
          if (error?.response?.data?.message === "Verification code data is not valid") {
            present({
              color: "danger",
              message: ts["Invalid verification code"],
              duration: 1500,
              position: "bottom",
            });
          }

          console.log(error, "error");
        },
        onSuccess: (data) => {
          login(data);
          toggleIsSmsAlertOpened();
          present({
            color: "success",
            message: ts["You have been successfully authorized!"],
            duration: 1500,
            position: "bottom",
          });
          let timer = setTimeout(() => {
            clearTimeout(timer);
            proceedOrder();
          }, 1500);
        },
      }
    );
  };

  const handleAuth = () => {
    if (isUserAuthenticated) {
      proceedOrder();
      return;
    }

    if (!canGetNewCode) {
      toggleIsSmsAlertOpened();
      return;
    }

    if (prevPhoneNumber !== phoneNumber) {
      getCode(phoneNumber, () => {
        setWaitingTimer(phoneNumber);
        toggleIsSmsAlertOpened();
      });
    } else {
      toggleIsSmsAlertOpened();
    }
  };

  const requestNewCode = () => {
    if (!canGetNewCode) return;

    getCode(phoneNumber, sentSmsAgain);
  };

  const getCode = (phone: string, onSucceedCb: () => void) => {
    mutate(
      { phone },
      {
        onError: (error) => {
          console.log(error, "on error");
        },
        onSuccess: (data) => {
          verificationCodeData.current = {
            code_id: data.verificationCodeId,
            phone_number: data.phoneNumber,
          };
          onSucceedCb();
          // AppMetricaProvider.reportEvent("login", { ...data, token: undefined });
          return data;
        },
      }
    );
  };

  const handleSubmit = () => {
    if (ALLOW_GUEST_ORDER) {
      proceedOrder();
    } else {
      handleAuth();
    }
  };

  useIonViewWillEnter(() => {
    setIsPhoneNumberInputValid(isPhoneNumberValid(phoneNumber));
    updateState();
    handleShipingMethods(shippingMethods);
  }, [
    setIsPhoneNumberInputValid,
    isPhoneNumberValid,
    updateState,
    handleShipingMethods,
    phoneNumber,
    cartSubtotalValue,
    user,
    shippingMethods,
  ]);

  const getEnabledShippingMethods = (methods: ShippingMethod[]): ShippingMethod[] => {
    const result = methods.filter((method) => method.enabled);
    const freeShipping = methods.find((method) => method.methodType === ShippingMethodType.freeShipping);

    if (freeShipping && parseFloat(freeShipping.minAmount ?? "") <= cartSubtotalValue) {
      return [freeShipping];
    }

    return result;
  };

  const getShippingButtons = (shippingMethods: ShippingMethod[]) => {
    const activeMethods = getEnabledShippingMethods(shippingMethods);
    const buttons = activeMethods.map((method: ShippingMethod): ShippingTabButton => {
      return {
        id: method.methodId,
        icon: getShippingTypeIcon(method.methodType),
        title:
          parseFloat(method.cost ?? "") > 0
            ? `${method.title} ${method.cost}${getCurrentCurrencySymbol()}`
            : method.title,
        description:
          parseFloat(method.minAmount ?? "") > 0 ? `${ts.from} ${method.minAmount}${getCurrentCurrencySymbol()}` : "",
        disabled: parseFloat(method.minAmount ?? "") > cartSubtotalValue,
      };
    });

    const activeButtonsAmount = buttons.filter((b) => !b.disabled).length;
    if (
      activeButtonsAmount === 1 ||
      (appSettings?.addressSettings.addressInputDependsOnShippingMethod && activeButtonsAmount > 0)
    ) {
      const method = activeMethods[0];
      updateOrderData({
        shippingMethod: method,
      });
    }

    return buttons;
  };

  const setActiveShippingMethod = (methodId: string) => {
    const method: ShippingMethod = shippingMethods?.find((method) => method?.methodId === methodId)!;
    updateOrderData({ shippingMethod: method || null });
  };

  const setPayment = useCallback(
    (methodId: string) => {
      updateOrderData({
        paymentMethod: paymentMethods?.find((payment) => {
          return payment.id === methodId;
        }),
      });
    },
    [paymentMethods, updateOrderData]
  );
  const { mutate: updateProfile } = useUpdateProfileMutation();

  const proceedOrder = () => {
    if (user && firstName !== user.firstName) {
      const updatePayload = { firstName: firstName, lastName: lastName };
      updateProfile(
        { userId: user.id, userPayload: updatePayload },
        { onSuccess: () => updateUserData(updatePayload) }
      );
    }

    history.replace("/order");
  };

  function isFormValid() {
    return !!(
      firstName &&
      phoneNumber &&
      address &&
      shippingMethod &&
      paymentMethod &&
      (shippingMethod.methodDeliveryType === ShippingMethodDeliveryType.nextDay ||
        (deliveryDate && deliveryInterval)) &&
      address.fullAddress.includes(shippingZone?.name ?? "")
    );
  }

  async function handleDeliveryDate(e: CustomEvent<DatetimeChangeEventDetail>) {
    updateOrderData({
      deliveryDate: e.detail.value ? new Date(String(e.detail.value)) : null,
      deliveryInterval: "",
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <ToolbarMobile showBackButton={true} />
      </IonHeader>
      <IonContent>
        <ShippingAddressComponent
          pickupPoints={pickupPoints}
          setPaymentMethod={setPayment}
          setShippingMethod={setActiveShippingMethod}
          shippingTabsButtons={shippingTabsButtons}
          paymentTabsButtons={paymentTabsButtons}
          submit={handleSubmit}
          handleDeliveryDate={handleDeliveryDate}
          isFormValid={isFormValid}
          isPhoneNumberInputValid={isPhoneNumberInputValid}
          isWhatsappPhoneNumberValid={isWhatsappPhoneNumberValid}
          setIsPhoneNumberInputValid={setIsPhoneNumberInputValid}
          setIsWhatsappPhoneNumberValid={setIsWhatsappPhoneNumberValid}
          isGettingSmsCodeLoading={isGettingSmsCodeLoading}
          showLoading={isPaymentMethodsLoading || isShippingMethodsLoading || isAuthLoading}
        />
        <AuthByCode
          isOpen={isSmsAlertOpened}
          toggleIsOpen={toggleIsSmsAlertOpened}
          auth={authUserBySms}
          requestNewCode={requestNewCode}
        />
      </IonContent>
      <IonFooter className={"oneButtonFooter"}>
        <div className={"ion-hide-md-up"}>
          <ContinueButton onClick={handleAuth} disabled={!isFormValid()} buttonText={ts.Next} />
        </div>
      </IonFooter>
    </IonPage>
  );
};

export { ShippingAddressPage };
