import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { shippingMethodsClient } from "../../data/clients";
import { SHIPPING_METHODS_QUERY_CACHE_EXPIRE_TIME_MS } from "../../config/constansts";
import { ShippingMethod } from "../../data/types/entities";

const useGetShippingMethodsQuery = (
  zoneId: string,
  productsWeight = 0,
  options: UseQueryOptions<ShippingMethod[]> = {}
) => {
  return useQuery<ShippingMethod[], any>(
    ["shipping-methods", zoneId, productsWeight],
    async () => {
      return await shippingMethodsClient.getShippingMethods(zoneId, productsWeight);
    },
    {
      cacheTime: SHIPPING_METHODS_QUERY_CACHE_EXPIRE_TIME_MS,
      staleTime: SHIPPING_METHODS_QUERY_CACHE_EXPIRE_TIME_MS,
      ...options,
    }
  );
};

export default useGetShippingMethodsQuery;
