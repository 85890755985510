import { cardOutline, cashOutline, cubeOutline, walletOutline } from "ionicons/icons";
import { car, taxiShipping } from "../../assets/icon/exportSVG";
import { PaymentMethodIdEnum, ShippingMethodType } from "../../data/types/entities";

interface fetchWithTimeoutOptions extends RequestInit {
  timeout?: number;
}

export const fetchWithTimeout = async (resource: RequestInfo, options: fetchWithTimeoutOptions = {}) => {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });

  clearTimeout(id);
  return response;
};

export const getShippingTypeIcon = (type: ShippingMethodType): string => {
  switch (type) {
    case ShippingMethodType.freeShipping:
      return car;
    case ShippingMethodType.taxiShipping:
      return taxiShipping;
    case ShippingMethodType.pickupPoint:
      return cubeOutline;
    default:
      return car;
  }
};

export const getPaymentTypeIcon = (type: PaymentMethodIdEnum): string => {
  switch (type) {
    case PaymentMethodIdEnum.card:
      return cardOutline;
    case PaymentMethodIdEnum.cash:
      return cashOutline;
    default:
      return walletOutline;
  }
};

export const applyRegExOnPhoneNumber = (phoneNumber: string): string => {
  let regex = /[+\s()-]/g;
  return phoneNumber.replace(regex, "");
};
