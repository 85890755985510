import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { productsClient } from "../../data/clients";
import { Product } from "../../data/types/entities";

type IncludeProductsQuery = {
  include: string[];
  params?: {
    per_page: string;
  };
  options?: UseQueryOptions<Product[], any, Product[], any>;
};

const useGetIncludeProductsQuery = ({ include, params, options }: IncludeProductsQuery) => {
  return useQuery(
    ["products", "include", include, params],
    async () => {
      return await productsClient.getIncludeProducts(include, params);
    },
    {
      cacheTime: 50000,
      staleTime: 50000,
      ...options,
    }
  );
};

export default useGetIncludeProductsQuery;
