import { combineReducers } from "./combineReducers";
import reducer2 from "./reducers2";
import Global from "../../i18n/GlobalLanguageJson";

export function getCurrentLanguage() {
  switch (localStorage.languageCode) {
    case "en":
      return Global.a2;
    default:
      return Global.RU;
  }
}

export const initialState: AppState = {
  config: {
    languageJson: getCurrentLanguage(),
    showIntroPage: 1, // show/hide intro page value 1 for show, 0 for hide
    appInProduction: true, //////////////////////////////////////////////////////////////////////// in production

    // For Language And Site Settings
    languageCode: localStorage.languageCode, // Default Language Of App
    languageDirection: localStorage.languageDirection, // Default Direction Of App
    appDirection: localStorage.languageDirection, // Application Direction
    currency: localStorage.currency, // Default Currency Of App
    dir: "ltr", // Default Direction Of App
    // For Default Site Settings Attributes
    appSettings: {}, // Application Site Settings
    langId: "1",
    loader: "dots",
    newProductDuration: 20, // For New Product Duration
    cartButton: 1, // 1 : show and 0 : hide
    address: "",
    fbId: "",
    email: "",
    latitude: "40.730610",
    longitude: "-73.935242",
    phoneNo: "",
    notifText: "",
    notifTitle: "",
    notifDuration: "",
    footerShowHide: 1,
    homePage: 1,
    categoryPage: 2,
    siteUrl: "",
    appName: "",
    packgeName: 1,
    introPage: 1,
    myOrdersPage: 1,
    newsPage: 1,
    wishListPage: 1,
    shippingAddressPage: 1,
    aboutUsPage: 1,
    editProfilePage: 1,
    settingPage: 1,
    rateApp: 1,
    shareApp: 1,
    fbButton: 1,
    googleButton: 1,
    notificationType: "",
    refundPolicy: "",
    termServices: "",
    aboutUs: "",
    filterMaxPrice: 99999999999,
    guestCheckOut: true,
    checkOutPage: 1,
    defaultIcons: false,
    orderCancelButton: false,
    addressPage: true,
    downloadPage: true,
    cancelOrderTime: 0,
    showVendorInfo: false, //for dokan plugin
    showWcVendorInfo: false,
    multiLanguage: false,
    multiCurrency: false,
    enableGeoFencing: false,
    enableDeliveryTracking: false,
    enableWpPointReward: false,
    trackingUrl: "",
    currentSettings: 1,
    aboutPageId: 1,
    refundPageId: 1,
    termsPageId: 1,
    vendorEnable: "0",
    wishListProducts: [], // For WishList Products
    recentViewedProducts: [], // For RecentlyViewed Products
    cartProducts: [], // For Cart Products
    cartQuantity: 0, // For Cart Quantity
    totalPrice: 0,
    singleProductPageData: [], // For Single Product Data
    singlePostData: [], // For News Single Post Data
    customerData: {} as any, // For Customer Data //
    couponArray: [] as any, // For Coupon Array
    myOrderDetailPageData: {}, // For Order Detail Page Data

    // For Shipping And Billing Address
    billing: {
      first_name: "",
      last_name: "",
      company: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      email: "",
      phone: "",
    },
    billingCountryName: "",
    billingStateName: "",
    shipping: {
      first_name: "",
      last_name: "",
      company: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      email: "",
      phone: "",
    },
    shippingCountryName: "",
    shippingStateName: "",
    order: {},
    shipping_lines: [],
    listTaxRates: [],
    sameAddress: false,
    checkOutPageText: "Place Your Order",

    // For Google MAP API Key
    googleMapId: "Place_Your_Google_API", // For Google Map API Key For Test

    // For OneSiganl
    onesignalAppId: "Place_Your_OneSignal_App_ID",
    onesignalSenderId: "Place_Your_OneSignal_Sender_ID",

    // For Featured Vendor
    storePageData: [], // For Featured Vendor
    device: "",

    // For App Themes
    appTheme: "default",
    darkMode: false,

    // For Color Schemes
    colorTheme: "",
    colorThemeSelected: false,

    // For Mode Dark/Light
    // modeTheme: 'light',
    modeThemeSelected: false,

    // For AppBrowser Colors
    headerHexColor: "#51688F",
    headerHexColorContrast: "#ffffff",
    myOrders: [],
  },
};

export const c_reducers = combineReducers({
  config: reducer2,
});

export type AppState = ReturnType<typeof c_reducers>;
