import { IUsersClient } from "../../types/clients";
import { User, UserWithPassword } from "../../types/entities";
import BaseClient from "../base-client";
import { converParhatoUserToUser } from "./utils";

class UsersClient extends BaseClient implements IUsersClient {
  async getProfile(userId: string): Promise<User> {
    const response = await this.instance.get(
      `/wp-json/wc/v3/customers/${userId}`,
    );

    return converParhatoUserToUser(response.data);
  }

  async updateProfile(
    userId: string,
    updateData: Partial<UserWithPassword>,
  ): Promise<Partial<User>> {
    const userPayload = {
      first_name: updateData.firstName,
      last_name: updateData.lastName,
      email: updateData.email || undefined,
      shipping: {
        first_name: updateData.firstName,
        last_name: updateData.lastName,
      },
      billing: {
        first_name: updateData.firstName,
        last_name: updateData.lastName,
        email: updateData.email || undefined,
        phone: updateData.phone,
      },
      password: updateData.password,
    };

    await this.instance.put<any>(`/wp-json/wc/v3/customers/${userId}`, {
      ...userPayload,
    });
    return updateData;
  }
}

export default UsersClient;
