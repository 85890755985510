import React from 'react'
import { IonButton, IonTextarea } from '@ionic/react'
import { getCurrentLanguage } from '../../providers/Redux/Reducers/state';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from '../UI/CommonModal/CommonModal';
import StarRating from '../UI/StarRating/StarRating';
import CommonFormElement from '../UI/CommonFormElements/CommonFormElement';

import classNames from "classnames/bind";

import styles from './OrderReview.module.scss'
import useAddReviewSetOrder from '../../providers/Orders/useAddReviewSetOrder';

const cx = classNames.bind(styles);

const ts = getCurrentLanguage()!;

const areaPlaceholder = ts["Please rate the service and leave any comments or feedback that can help us make it better. The main criteria include the quality of the products, speed of delivery, courtesy of the delivery person and the fact of delivery to the door. Your feedback is very important to us!"]

export interface OrderReviewProps {
  orderNumber: string;
  onDismiss: () => void;
}

const OrderReview = ({ onDismiss, orderNumber, }: OrderReviewProps) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onSubmit",
  });

  const { mutate: addReviewOrder } = useAddReviewSetOrder(orderNumber)

  const handleFSubmit = async ({ customerNote, productQuality }: any) => {
    const reviewData = {
      customerNote,
      productQuality,
      deliverySpeed: productQuality,
      driverCourtesy: productQuality,
      assemblyQuality: productQuality,
    }

    addReviewOrder({
      orderNumber,
      reviewData
    });

    onDismiss();
  };

  return (
    <CommonModal onDismiss={onDismiss} title={ts["Order review"]}>
      <form onSubmit={handleSubmit(handleFSubmit)}>
        <div className='flex flex-col justify-between h-full relative'>
          <Controller
            name='productQuality'
            control={control}
            rules={{
              required: ts["Needed to evaluate the order"],
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <CommonFormElement
                  commonLabelClassName='!p-0'
                  label={ts["Rate the order"]}
                  isValid={value !== 0 && value !== undefined}
                  errorMessage={errors.productQuality?.message}
                >
                  <StarRating onChange={onChange} value={value} />
                </CommonFormElement>
              )
            }}
          />
          <Controller
            name='customerNote'
            control={control}
            rules={{
              required: ts["Required field"],
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <CommonFormElement
                  commonLabelClassName='!p-0'
                  classListItem='mb-0'
                  label={ts["Order comment"]}
                  isValid={value?.length > 0}
                  errorMessage={errors.customerNote?.message}
                >
                  <IonTextarea
                    inputMode='text'
                    value={value}
                    placeholder={areaPlaceholder}
                    className={cx('textArea', "px-2 min-h-36 text-sm rounded-3xl border-[1px_solid_gray]")}
                    onIonChange={e => onChange(e.detail.value!)}
                  />
                </CommonFormElement>
              )
            }}
          />

          <IonButton
            mode='ios'
            type='submit'
            expand="block"
            color="primary"
            className='fixed w-[98%] ml-1 left-0 bottom-1 pl-[1.65rem] pr-[1.35rem] '
          >
            {ts["Send"]}
          </IonButton>
        </div>
      </form>
    </CommonModal >
  )
};

export default OrderReview;