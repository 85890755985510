import BaseClient from "../base-client";
import {
  Product,
  ProductReview,
  ProductsQueryParams,
  SearchProductsQueryParams,
  saleType as TsaleType,
} from "../../types/entities";
import { IProductsClient } from "../../types/clients";
import { IrskomMainPageProducts, IrskomProductDetails } from "./types";
import { converterIrskomProductDetails, converterIrskomProductList } from "./utils";

class ProductsClient extends BaseClient implements IProductsClient {
  async getProducts(params: ProductsQueryParams): Promise<Product[]> {
    const { categoryId, pageSize, pageNumber, minPrice, maxPrice } = params;
    const saleType = (params.featured && TsaleType.featured) || (params.on_sale && TsaleType.on_sale);
    const { data } = await this.instance.get<IrskomMainPageProducts>("commodity/list/filtered", {
      params: {
        PageSize: pageSize,
        PageNumber: pageNumber,
        CategoryId: categoryId,
        SaleType: saleType,
        MinPrice: minPrice,
        MaxPrice: maxPrice,
        // sortOrder: sortOrder,
      },
    });

    return data?.items.map(converterIrskomProductList);
  }

  async getProductById(id: string): Promise<Product> {
    const { data } = await this.instance.get<IrskomProductDetails>(`commodity/${id}`);

    return converterIrskomProductDetails(data);
  }

  async searchProducts(params: SearchProductsQueryParams): Promise<Product[]> {
    const { data } = await this.instance.get<IrskomMainPageProducts>(`search/result`, {
      params: {
        searchText: params.keyword,
        pageNumber: params.pageNumber,
      },
    });

    return data?.items.map(converterIrskomProductList);
  }

  async getNewestProducts({ categoryId, pageSize, pageNumber }: Partial<ProductsQueryParams> = {}): Promise<Product[]> {
    const { data } = await this.instance.get<IrskomMainPageProducts>("commodity/list/filtered", {
      params: {
        PageSize: pageSize,
        PageNumber: pageNumber,
        SaleType: "3",
        CategoryId: categoryId,
      },
    });

    return data?.items.map(converterIrskomProductList);
  }

  async getOnSaleProducts({ categoryId, pageSize, pageNumber }: Partial<ProductsQueryParams> = {}) {
    const { data } = await this.instance.get<IrskomMainPageProducts>("commodity/list/filtered", {
      params: {
        PageSize: pageSize,
        PageNumber: pageNumber,
        SaleType: "2",
        CategoryId: categoryId,
      },
    });

    return data?.items.map(converterIrskomProductList);
  }

  async getFeaturedProducts({ categoryId, pageSize, pageNumber }: Partial<ProductsQueryParams> = {}): Promise<
    Product[]
  > {
    const { data } = await this.instance.get<IrskomMainPageProducts>("commodity/list/filtered", {
      params: {
        PageSize: pageSize,
        PageNumber: pageNumber,
        SaleType: "1",
        CategoryId: categoryId,
      },
    });

    return data?.items.map(converterIrskomProductList);
  }

  async getIncludeProducts(include: string[], params?: { per_page?: string }) {
    const requests: Promise<any>[] = [];
    include.forEach((id) => {
      requests.push(this.getProductById(id));
    });

    return await Promise.all(requests);
  }

  async getCutomersChoiceProducts({ categoryId, pageSize, pageNumber }: Partial<ProductsQueryParams> = {}): Promise<
    Product[]
  > {
    const { data } = await this.instance.get<IrskomMainPageProducts>("commodity/list/filtered", {
      params: {
        PageSize: pageSize,
        PageNumber: pageNumber,
        SaleType: "1",
        CategoryId: categoryId,
      },
    });

    return data?.items.map(converterIrskomProductList);
  }

  async getProductReviews(id: string): Promise<ProductReview[]> {
    throw new Error("getProductReviews is not implemented");
  }
}

export default ProductsClient;
