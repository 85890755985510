import React, { useContext, useMemo, useState } from "react";

import CouponDetail from "./CouponDetail";
import CouponActionSheet from "./CouponActionSheet";
import CouponInput from "./CouponInput/CouponInput";
import { AppContext } from "../../../providers/Redux/Reducers/AppContext";
import { useCartData } from "../../../providers/cartDataStore";

interface ICouponUnited {
  getCoupon: (value: string) => void;
  isCouponLoading?: boolean;
}

const CouponUnited: React.FC<ICouponUnited> = ({ getCoupon, isCouponLoading = false }) => {
  const { state } = useContext(AppContext); // For Store
  const ts = useMemo(() => state.config.languageJson, [state.config.languageJson]);
  const { coupons, removeCoupon } = useCartData();

  const [couponValue, setCouponValue] = useState(""); // For ActionSheet Coupon Value
  const [showActionSheet, setShowActionSheet] = useState(false); // For ActionSheet

  return (
    <>
      {/*Детальная информация о купоне*/}
      {coupons.map((coupon) => (
        <CouponDetail
          key={coupon.id}
          languageJson={ts}
          amount={coupon.discountAmount}
          couponCode={coupon.code}
          discountType={coupon.discountType}
          onRemoveClick={() => removeCoupon(coupon.code)}
        />
      ))}
      {/*Панель которая появляется если ставить !showActionSheet*/}
      <CouponActionSheet
        showActionSheet={showActionSheet}
        setShowActionSheet={() => setShowActionSheet(false)}
        setCouponValue={setCouponValue}
        couponValue={couponValue}
      />

      {/*Блок ввода кода купона*/}
      <CouponInput
        languageJson={ts}
        getCoupon={() => getCoupon(couponValue)}
        onInputCouponCode={(value) => setCouponValue(value)}
        setShowActionSheet={setShowActionSheet}
        isCouponLoading={isCouponLoading}
      />
    </>
  );
};

export default CouponUnited;
