// Import Ionic Components
import { IonCard, IonCardSubtitle, IonGrid, IonRow, IonCol, IonSkeletonText, useIonViewWillLeave } from "@ionic/react";

// Import React Libraries
import React, { useCallback, useContext, useState } from "react";
import { useHistory } from "react-router";

// Import Css File
import styles from "./searchMainCategories.module.scss";
import classNames from "classnames/bind";

// Import custom providers and components
import { AppContext } from "../../providers/Redux/Reducers/AppContext";
import { ProductsSearchPageLayout } from "../../components/ProductsSearchPageLayout/ProductsSearchPageLayout";
import { WCProduct } from "../../providers/WooComAPI/woo-com-api";

import { Image } from "../../components/Image/Image";
import { useGetAllCategoriesQuery } from "../../providers/categories";

const cx = classNames.bind(styles);

const skeletonItemList = [...Array(5).keys()];

const CategoriesSkeleton = () => {
  return (
    <IonGrid>
      <IonRow className={cx("skeletonRow")}>
        {skeletonItemList.map((num) => {
          return (
            <IonCol key={num} sizeSm="6" sizeMd="4" sizeLg="2.36">
              <IonSkeletonText animated={true} className={cx("skeletonCard")}></IonSkeletonText>
              <IonSkeletonText animated={true} className={cx("skeletonText")}></IonSkeletonText>

              <IonSkeletonText animated={true} className={cx("skeletonCard")}></IonSkeletonText>
              <IonSkeletonText animated={true} className={cx("skeletonText")}></IonSkeletonText>

              <IonSkeletonText animated={true} className={cx("skeletonCard")}></IonSkeletonText>
              <IonSkeletonText animated={true} className={cx("skeletonText")}></IonSkeletonText>

              <IonSkeletonText animated={true} className={cx("skeletonCard")}></IonSkeletonText>
              <IonSkeletonText animated={true} className={cx("skeletonText")}></IonSkeletonText>
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
};

const SearchMainCategories: React.FC = (props: any) => {
  let history = useHistory(); // For Push page
  const { state } = useContext(AppContext);
  const [searchResult, setSearchResult] = useState<WCProduct[]>([]);

  const { data: { allCategories, mainCategories } = {}, isLoading } = useGetAllCategoriesQuery();

  const openSubcategories = useCallback(
    (id: string | number, category: { [key: string]: any }) => {
      if (allCategories?.some((x) => x.parent === id)) {
        history.push("/categories/" + id + "/subcategories", { parentCategory: category });
      } else {
        history.push("/products/" + id + "/" + category.name + "/popular");
      }
    },
    [allCategories, history]
  );

  useIonViewWillLeave(() => {
    setSearchResult([]);
  }, [props.match.params]);

  return (
    <ProductsSearchPageLayout
      pageTitle={state.config.languageJson["Catalog"]}
      breadcrumbsTitle={state.config.languageJson["Catalog"]}
    >
      {isLoading && <CategoriesSkeleton />}
      {mainCategories?.length && (
        <div className={searchResult.length ? cx("hide") : cx("categoriesList")}>
          {mainCategories?.map((category) => {
            return (
              <IonCard
                className={cx("productCard", "ion-no-margin")}
                key={category.id}
                onClick={() => openSubcategories(category.id, category)}
              >
                <Image
                  className={cx(
                    "categoryIcon",
                    `${category.image?.src.includes("svg") ? "image-transform" : "productCard__image"}`
                  )}
                  id="image"
                  src={category.image?.src}
                  downsize={false}
                />
                <IonCardSubtitle className={cx("productCard__subtitle")}>{category.name}</IonCardSubtitle>
              </IonCard>
            );
          })}
        </div>
      )}
    </ProductsSearchPageLayout>
  );
};

export default SearchMainCategories;
