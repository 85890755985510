import BaseClient from "../base-client";
import { IPaymentMethodsClient } from "../../types/clients";
import { PaymentMethod, PaymentMethodIdEnum } from "../../types/entities";
import { ParhatoPaymentMethodType } from "./types";

class PaymentMethodsClient extends BaseClient implements IPaymentMethodsClient {
  async getPaymentMethods(): Promise<PaymentMethod[]> {
    const response = await this.instance.get<ParhatoPaymentMethodType[]>("/wp-json/wc/v3/payment_gateways");
    const data: PaymentMethod[] = response.data.map(({ id, title, enabled }) => ({
      id,
      title,
      enabled,
      type: id === "bacs" ? PaymentMethodIdEnum.card : PaymentMethodIdEnum.cash,
    }));

    return data;
  }
}

export default PaymentMethodsClient;
