export const PRODUCTS_QUERY_CACHE_EXPIRE_TIME_MS = 600000; // 10 minutes
export const CATEGORIES_QUERY_CACHE_EXPIRE_TIME_MS = 86400000; //day
export const APP_SETTINGS_QUERY_CACHE_EXPIRE_TIME_MS = 86400000; //day
export const USERS_QUERY_CACHE_EXPIRE_TIME_MS = 86400000; //day
export const BANNERS_QUERY_CACHE_EXPIRE_TIME_MS = 86400000; //day
export const SHIPPING_ZONES_QUERY_CACHE_EXPIRE_TIME_MS = 86400000; //day
export const PAYMENT_METHODS_QUERY_CACHE_EXPIRE_TIME_MS = 86400000; //day
export const SHIPPING_METHODS_QUERY_CACHE_EXPIRE_TIME_MS = 86400000; //day
export const PRODUCT_QUERY_CACHE_EXPIRE_TIME_MS = 600000; // 10 minutes
export const ORDERS_QUERY_CACHE_EXPIRE_TIME_MS = 600000; // 10 minutes
export const SHIPPING_DELIVERY_DATES_INTERVALS_CACHE_EXPIRE_TIME_MS = 600000; // 10 minutes
export const DEFAULT_CART_REMIND_DELAY_MINUTES = 180;
export const REVIEW_ORDER_SET_QUERY_CACHE_EXPIRE_TIME_MS = 86400000; //day
export const RESEND_SMS_CODE_DELAY_MS = 60000; //60 seconds
